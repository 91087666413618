/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Collapse,
  Form,
  Modal,
  Radio,
  Select,
  Tabs,
  notification,
} from "antd";
import { Link, useHistory } from "react-router-dom";
// @ts-ignore
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch } from "react-redux";
import Dropdown from "./Dropdown";
import Loader from "./Loader";

import Table from "./Table";
import Button from "./Button";
import Input from "./Input";
import { BiTrash } from "react-icons/bi";
import DatePicker from "./DatePicker";
import { useMediaQuery } from "react-responsive";
import {
  addProductToProduceOrConsume,
  consumeAssetProduct,
  createPartyAssign,
  getConsumeAssetsList,
  getConsumedAssets,
  getFacilityList,
  getOrganizationList,
  getPickerList,
  getProduceContainerList,
  getProducedAssets,
  getProductionRunDetails,
  getProductsToConsume,
  getProductsToProduce,
  getStatusList,
  produceAssetProduct,
  searchProductListing,
  updateProductRun,
  updateProductionRUnStatus,
} from "../redux/authSlice";
import moment from "moment";
import axios from "axios";
import { debounceFn } from "../helper/function";

const ProductsComponent = ({
  data,
  type,
  setIsAddProductModalOpen,
  addProductForm,
  onAssetsBtnClick,
  onUpdatebtnClick,
}: {
  data: any;
  type: any;
  setIsAddProductModalOpen: Function;
  addProductForm: any;
  onAssetsBtnClick: Function;
  onUpdatebtnClick: Function;
}) => {
  const { Panel } = Collapse;

  const columns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">SKU/UPC</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.productName}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Product Name</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.productName}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">WH ATP</p>
        </div>
      ),
      render: (availableToPromise: string) =>
        type === "Produce" ? 0 : availableToPromise ?? 0,
      dataIndex: "availableToPromiseTotal",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">WH QOH</p>
        </div>
      ),
      render: (quantityOnHand: string) =>
        type === "Produce" ? 0 : quantityOnHand ?? 0,
      dataIndex: "quantityOnHandTotal",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Est. Quantity</p>
        </div>
      ),
      dataIndex: "estimatedQuantity",
    },
    ...(type === "Produce"
      ? [
          {
            title: (
              <div className="flex items-center relative h-5 p-2 leading-3">
                <p className="mr-1 text-xs">Produced</p>
              </div>
            ),
            render: (producedCurrentReceiptQuantity: any, data: any) => (
              <h1
                style={{
                  color:
                    data?.estimatedQuantity === producedCurrentReceiptQuantity
                      ? "green"
                      : "red",
                }}
              >
                {producedCurrentReceiptQuantity ?? "None"}
              </h1>
            ),
            dataIndex: "producedCurrentReceiptQuantity",
          },
        ]
      : type === "Consume"
      ? [
          {
            title: (
              <div className="flex items-center relative h-5 p-2 leading-3">
                <p className="mr-1 text-xs">Consumed</p>
              </div>
            ),
            render: (consumedCurrentIssuanceQuantity: any, data: any) => (
              <h1
                style={{
                  color:
                    data?.estimatedQuantity === consumedCurrentIssuanceQuantity
                      ? "green"
                      : "red",
                }}
              >
                {consumedCurrentIssuanceQuantity ?? "None"}
              </h1>
            ),
            dataIndex: "consumedCurrentIssuanceQuantity",
          },
        ]
      : []),

    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1"></p>
    //     </div>
    //   ),
    //   render: () => {
    //     return (
    //       <div className="flex flex-row items-center">
    //         <Button buttonText="Update" />
    //         <h1 className="" onClick={() => {}}>
    //           <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
    //         </h1>
    //       </div>
    //     );
    //   },
    //   dataIndex: "update",
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      render: (productId: any, data: any) => {
        return (
          <div className="flex flex-row items-center">
            <Button
              buttonText={type}
              onClick={(e: any) => {
                onUpdatebtnClick(e, data);
              }}
            />
          </div>
        );
      },
      dataIndex: "productId",
    },
  ];

  return (
    <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
      <Collapse
        defaultActiveKey={["1"]}
        className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
        expandIconPosition="left"
        ghost
      >
        <Panel
          header={
            <span className="text-base font-bold">Products to {type}</span>
          }
          key="1"
        >
          <div className="flex justify-end mb-4">
            <Button
              buttonText="Add Product"
              onClick={() => {
                setIsAddProductModalOpen(true);
                addProductForm?.setFieldValue("typeEnumId", `Wept${type}`);
              }}
            />
            <Button
              buttonText={`${type} Asset`}
              classNames="ml-2"
              onClick={onAssetsBtnClick}
            />
          </div>
          <Table
            rowKey="shipMethod"
            dataSource={data}
            columns={columns}
            isPaginated={false}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

const ProductionRunDetails = ({ id }: { id: string }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [addPartyForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [addProductForm] = Form.useForm();
  const [produceAssetForm] = Form.useForm();
  const [updateProduceAssetForm] = Form.useForm();
  const [consumeAssetForm] = Form.useForm();
  const [updateConsumeAssetForm] = Form.useForm();
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 558px)",
  });
  const [productsToConsume, setProductsToConsume] = useState([]);
  const [productsToProduce, setProductsToProduce] = useState([]);
  const [assetConsumedData, setAssetConsumedData] = useState([]);
  const [assetProducedData, setAssetProducedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTimeRendering, setIsFirstTimeRendering] = useState(true);
  const [productionRunData, setProductionRunData] = useState<any>();
  const [partyStatusList, setPartyStatusList] = useState([]);
  const [consumedAssetsList, setConsumedAssetsList] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [produceContainerList, setProduceContainerList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [pickersList, setPickersList] = useState([]);
  const [currStatus, setCurrStatus] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [searchableString, setSearchableString] = useState("");
  const [assetMaxPageIndex, setAssetMaxPageIndex] = useState<any>("");
  const [assetCurrIndex, setAssetCurrIndex] = useState<any>(1);
  const [assetSearchableString, setAssetSearchableString] = useState("");
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [noConsumeProduct, setNoConsumeProduct] = useState("");
  const [isAddPartyModalOpen, setIsAddPartyModalOpen] = useState(false);
  const [isUpdateRunModalOpen, setIsUpdateRunModalOpen] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isProduceAssetModalOpen, setIsProduceAssetModalOpen] = useState(false);
  const [isUpdateProduceAssetModalOpen, setIsUpdateProduceAssetModalOpen] =
    useState(false);
  const [isConsumeAssetModalOpen, setIsConsumeAssetModalOpen] = useState(false);
  const [isUpdateConsumeAssetModalOpen, setIsUpdateConsumeAssetModalOpen] =
    useState(false);
  const [isConsumeByProductModalOpen, setIsConsumeByProductModalOpen] =
    useState(false);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getProductionRunDetails({
        id,
        setProductionRunData,
        setIsFirstTimeRendering,
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      const { facilityId } = productionRunData?.workEffortList?.[0];
      await dispatch(
        getProductsToConsume({
          id,
          setProductsToConsume,
        })
      );
      await dispatch(
        getProductsToProduce({
          id,
          setProductsToProduce,
        })
      );
      await dispatch(
        getStatusList({
          setStatus: setPartyStatusList,
          statusTypeId: "WorkEffortParty",
        })
      );
      await dispatch(getProduceContainerList({ setProduceContainerList }));
      await dispatch(getOrganizationList({ setOrganizationName: setOrgList }));
      await dispatch(
        getFacilityList({ setFacilityList, fromInventory: false })
      );

      await dispatch(
        getConsumedAssets({
          id,
          setAssetConsumedData,
        })
      );
      await dispatch(
        getProducedAssets({
          id,
          setAssetProducedData,
        })
      );
    };
    if (productionRunData?.workEffortList?.[0]?.statusId) {
      setCurrStatus(productionRunData?.workEffortList?.[0]?.statusId);
    }
    if (productionRunData?.workEffortList?.[0]?.facilityId) {
      getData();
    }
  }, [productionRunData]);

  const partyAssignedColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Name</p>
        </div>
      ),
      render: (partyId: string, data: any) => (
        <Link
          to={{
            pathname: ``,
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {partyId}: {`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
          </h1>
        </Link>
      ),
      dataIndex: "partyId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Username</p>
        </div>
      ),
      render: (username: string) => (
        // <Link
        //   to={{
        //     pathname: ``,
        //   }}
        // >
        <h1 className="cursor-pointer underline text-primary">{username}</h1>
        // </Link>
      ),
      dataIndex: "username",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Role</p>
        </div>
      ),
      render: (roleTypeId: string) => <h1 className="px-2">{roleTypeId}</h1>,
      dataIndex: "roleTypeId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      render: (statusId: string) => (
        <h1 className="px-2">
          <Dropdown
            data={partyStatusList}
            placeholder=""
            width="80%"
            value={statusId}
            isDisabled={true}
            // onChange={(val: string) => setProductType(val)}
          />
        </h1>
      ),
      dataIndex: "statusId",
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1"></p>
    //     </div>
    //   ),
    //   render: (data: any, index: any) => {
    //     return (
    //       <div className="flex flex-row items-center">
    //         <Button buttonText="Update" />
    //         <h1 className="" onClick={() => {}}>
    //           <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
    //         </h1>
    //       </div>
    //     );
    //   },
    //   dataIndex: "update",
    // },
  ];

  const assetsConsumeColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">SKU/UPC</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.pseudoId}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Product Name</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.productName}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Qty</p>
        </div>
      ),
      dataIndex: "quantity",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Asset</p>
        </div>
      ),
      // render: (assetId: any) => (
      //   <Link
      //     to={{
      //       pathname: ``,
      //     }}
      //   >
      //     <h1 className="cursor-pointer underline text-primary">{assetId}</h1>
      //   </Link>
      // ),
      dataIndex: "assetId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">ATP</p>
        </div>
      ),
      dataIndex: "availableToPromiseTotal",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">QOH</p>
        </div>
      ),
      dataIndex: "quantityOnHandTotal",
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1 text-xs">Issuance</p>
    //     </div>
    //   ),
    //   dataIndex: "assetIssuanceId",
    // },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1 text-xs"></p>
    //     </div>
    //   ),
    //   render: (quantity: any) => (
    //     <Button buttonText={`Received ${quantity} Back to inventory`} />
    //   ),
    //   dataIndex: "quantity",
    // },
  ];

  const assetsProducedColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">SKU/UPC</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.pseudoId}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Product Name</p>
        </div>
      ),
      render: (productId: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${productId}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {data?.productName}
          </h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">UOM</p>
        </div>
      ),
      dataIndex: "amountUomId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Qty</p>
        </div>
      ),
      dataIndex: "quantityAccepted",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Asset</p>
        </div>
      ),
      // render: (assetId: any) => (
      //   <Link
      //     to={{
      //       pathname: ``,
      //     }}
      //   >
      //     <h1 className="cursor-pointer underline text-primary">{assetId}</h1>
      //   </Link>
      // ),
      dataIndex: "assetId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">ATP</p>
        </div>
      ),
      dataIndex: "availableToPromiseTotal",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">QOH</p>
        </div>
      ),
      dataIndex: "quantityOnHandTotal",
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1 text-xs">Receipt</p>
    //     </div>
    //   ),
    //   dataIndex: "assetReceiptId",
    // },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1 text-xs">Container</p>
    //     </div>
    //   ),
    //   dataIndex: "containerId",
    // },
  ];

  const getData = async (text: any) => {
    if (text === "produceAsset") {
      setIsProduceAssetModalOpen(false);
      setIsUpdateProduceAssetModalOpen(false);
      await dispatch(
        getProducedAssets({
          id,
          setAssetProducedData,
        })
      );
    } else if (text === "productsToProduce" || text === "productsToConsume") {
      setIsAddProductModalOpen(false);
      setProductList([]);
      addProductForm?.resetFields();
      setSelectedProduct("");
      if (text === "productsToProduce") {
        await dispatch(
          getProductsToProduce({
            id,
            setProductsToProduce,
          })
        );
      } else if (text === "productsToConsume") {
        await dispatch(
          getProductsToConsume({
            id,
            setProductsToConsume,
          })
        );
      }
    } else if (text === "consumeAsset") {
      setIsConsumeAssetModalOpen(false);
      setIsUpdateConsumeAssetModalOpen(false);
      consumeAssetForm?.resetFields();
      updateConsumeAssetForm?.resetFields();
      setSearchableString("");
      setSelectedAsset(null);
      setConsumedAssetsList([]);
      setNoConsumeProduct("");
      await dispatch(
        getConsumedAssets({
          id,
          setAssetConsumedData,
        })
      );
    }
  };

  const downloadLabel = async (url: any) => {
    if (id) {
      setIsLoading(true);
      await axios
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `RunPick-${id}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.log(error));
      setIsLoading(false);
    }
  };

  const getOrgName = (ownerPartyId: any) => {
    const org: any = orgList?.find((o: any) => o.key === ownerPartyId);
    return org?.value || "";
  };

  return (
    <>
      <div>
        <Loader
          isLoading={isLoading && isFirstTimeRendering}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          {/* Add Product Modal::START */}
          <Modal
            title="Add Product"
            visible={isAddProductModalOpen}
            footer={null}
            onCancel={() => {
              setIsAddProductModalOpen(false);
              setSelectedProduct("");
              addProductForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={addProductForm}
                initialValues={{}}
                onFinish={async (values: any) => {
                  if (!selectedProduct) {
                    notification["error"]({
                      message: "Error",
                      description: "Please select a product",
                    });
                    return;
                  }

                  setIsForceLoading(true);
                  await dispatch(
                    addProductToProduceOrConsume({
                      id,
                      ...values,
                      productId: selectedProduct,
                      setIsAddProductModalOpen,
                      addProductForm,
                      setSelectedProduct,
                      getData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item name="typeEnumId" hidden />
                <Form.Item
                  name="productId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: false, message: "Select an option" }]}
                >
                  <div>
                    <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                      Product
                    </h1>
                    <Select
                      showSearch
                      value={selectedProduct}
                      placeholder="Search Product"
                      style={{ width: "100%" }}
                      defaultActiveFirstOption={false}
                      suffixIcon={null}
                      className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                      filterOption={false}
                      onSearch={async (value: any) => {
                        setSearchableString(() => value);
                        setProductList([]);
                        debounceFn(
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              setMaxPageIndex,
                              queryString: value,
                              currPage: 0,
                              setHasMoreProds,
                            })
                          )
                        );
                      }}
                      onSelect={(value: any) => {
                        setSelectedProduct(value);
                      }}
                      notFoundContent={null}
                      options={productList?.map((item: any) => {
                        const { identifications, productId, name } = item;
                        const sku = identifications?.find(
                          (iden: any) => iden?.productIdTypeEnumId === "PidtSku"
                        );
                        const upc = identifications?.find(
                          (iden: any) =>
                            iden?.productIdTypeEnumId === "PidtUpca"
                        );
                        const idValue =
                          sku?.idValue || upc?.idValue || productId || "";
                        return {
                          label: `${idValue && `${idValue} | `}${name || ""}`,
                          value: item?.productId,
                        };
                      })}
                      onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                          (target as any).scrollTop +
                            (target as any).offsetHeight ===
                          (target as any).scrollHeight
                        ) {
                          if (!currIndex || currIndex * 1 === 0) {
                            return;
                          }
                          if (currIndex * 1 > maxPageIndex) {
                            return;
                          }
                          setCurrIndex((prev: any) => prev * 1 + 1);
                          localStorage.setItem(
                            "productListCurrIndex",
                            currIndex
                          );
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              queryString: searchableString,
                              currPage: currIndex,
                              setHasMoreProds,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="estimatedQuantity"
                    className="flex-1 w-full"
                    rules={[
                      { required: true, message: "This field is required!" },
                      {
                        pattern: /^[1-9]\d*$/,
                        message: "Please enter valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Est. Quantity"
                      placeholder=""
                      inputType="number"
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Add Product Modal::END */}

          {/* Produce Asset Modal::START */}
          <Modal
            title="Produce Asset"
            visible={isProduceAssetModalOpen}
            footer={null}
            onCancel={() => {
              setIsProduceAssetModalOpen(false);
              produceAssetForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={produceAssetForm}
                // initialValues={{
                //   containerTypeEnumId: "",
                //   containerId: "",
                // }}
                onFinish={async (values: any) => {
                  const {
                    productId,
                    quantity,
                    // containerTypeEnumId,
                    // containerId,
                  } = values;
                  setIsForceLoading(true);
                  await dispatch(
                    produceAssetProduct({
                      id,
                      facilityId:
                        productionRunData?.workEffortList?.[0]?.facilityId,
                      productId,
                      quantity,
                      // containerTypeEnumId,
                      // containerId,
                      getData,
                      setIsProduceAssetModalOpen,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item
                  name="facilityId"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Facility" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="productId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: true, message: "Select an option" }]}
                >
                  <Dropdown
                    searchable={true}
                    title="Assigned Product"
                    data={productsToProduce?.map((pro: any) => {
                      return {
                        key: pro?.productId,
                        value: `${pro?.productId}: ${pro?.productName}`,
                      };
                    })}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      { required: true, message: "This field is required!" },
                      // {
                      //   pattern: /^[1-9]\d*$/,
                      //   message: "Please enter valid number",
                      // },
                    ]}
                  >
                    <Input title="Quantity" placeholder="" inputType="number" />
                  </Form.Item>
                  {/* <Form.Item
                    name="containerTypeEnumId"
                    className="sm:ml-4 flex-1 w-full"
                    rules={[{ required: false, message: "Select an option" }]}
                  >
                    <Dropdown
                      searchable={true}
                      title="Container Type"
                      data={produceContainerList}
                      placeholder="Select one"
                      width="100%"
                    />
                  </Form.Item> */}
                </div>
                {/* <div style={{ width: `${isMobileDevice ? "100%" : "48%"}` }}>
                  <Form.Item
                    name="containerId"
                    className=""
                    rules={[{ required: false, message: "" }]}
                  >
                    <Input
                      title="Add to Container"
                      placeholder=""
                      // inputType="number"
                    />
                  </Form.Item>
                </div> */}
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Produce Asset Modal::END */}
          {/* Consume Asset Modal::START */}
          <Modal
            title="Consume Asset"
            visible={isConsumeAssetModalOpen}
            footer={null}
            onCancel={() => {
              setIsConsumeAssetModalOpen(false);
              setConsumedAssetsList([]);
              consumeAssetForm?.resetFields();
              setSearchableString("");
              setNoConsumeProduct("");
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={consumeAssetForm}
                initialValues={{
                  quantity: "",
                  noConsumeProduct: "",
                }}
                onFinish={async (values: any) => {
                  setIsForceLoading(true);
                  await dispatch(
                    consumeAssetProduct({
                      id,
                      assetId: selectedAsset,
                      quantity: values?.quantity,
                      noConsumeProduct,
                      getData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item
                  name="facilityId"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Facility" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="assetId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: false, message: "Select an option" }]}
                >
                  <div>
                    <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                      Asset
                    </h1>
                    <Select
                      showSearch
                      value={selectedAsset}
                      placeholder="Search Asset"
                      style={{ width: "100%" }}
                      defaultActiveFirstOption={false}
                      suffixIcon={null}
                      className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                      filterOption={false}
                      onSearch={async (value: any) => {
                        setAssetSearchableString(() => value);
                        setConsumedAssetsList([]);
                        debounceFn(
                          await dispatch(
                            getConsumeAssetsList({
                              id,
                              facilityId:
                                productionRunData?.workEffortList?.[0]
                                  ?.facilityId,
                              consumedAssetsList,
                              setConsumedAssetsList,
                              setMaxPageIndex: assetMaxPageIndex,
                              queryString: value,
                              currPage: 0,
                              setHasMoreProds,
                            })
                          )
                        );
                      }}
                      onSelect={(value: any) => {
                        setSelectedAsset(value);
                      }}
                      notFoundContent={null}
                      options={consumedAssetsList?.map((asset: any) => {
                        return {
                          label: asset?.assetInfo,
                          value: asset?.assetId,
                        };
                      })}
                      onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                          (target as any).scrollTop +
                            (target as any).offsetHeight ===
                          (target as any).scrollHeight
                        ) {
                          // if (!currIndex || currIndex * 1 === 0) {
                          //   return;
                          // }
                          if (assetCurrIndex * 1 > assetMaxPageIndex) {
                            return;
                          }
                          setAssetCurrIndex((prev: any) => prev * 1 + 1);
                          localStorage.setItem(
                            "assetListCurrIndex",
                            assetCurrIndex
                          );
                          await dispatch(
                            getConsumeAssetsList({
                              id,
                              facilityId:
                                productionRunData?.workEffortList?.[0]
                                  ?.facilityId,
                              consumedAssetsList,
                              setConsumedAssetsList,
                              queryString: assetSearchableString,
                              currPage: currIndex,
                              setHasMoreProds,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[{ required: false, message: "" }]}
                  >
                    <Input title="Quantity" placeholder="" inputType="number" />
                  </Form.Item>
                  <Form.Item
                    name="noConsumeProduct"
                    className="sm:ml-4 flex-1 w-full"
                    rules={[{ required: false, message: "Select an option" }]}
                  >
                    <div>
                      <div className="flex items-center">
                        <h1 className="font-bold text-xs">
                          No Consume Product
                        </h1>
                      </div>
                      <div className="flex relative mt-4">
                        <Radio.Group
                          value={noConsumeProduct}
                          onChange={(e: any) => {
                            setNoConsumeProduct(e?.target?.value);
                          }}
                        >
                          <Radio value="add">add</Radio>
                          <Radio value="error">error</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Consume" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Consume Asset Modal::END */}
          {/* Update Produce Asset Modal::START */}
          <Modal
            title="Produce"
            visible={isUpdateProduceAssetModalOpen}
            footer={null}
            onCancel={() => {
              setIsUpdateProduceAssetModalOpen(false);
              updateProduceAssetForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={updateProduceAssetForm}
                initialValues={{
                  containerTypeEnumId: "",
                  containerId: "",
                }}
                onFinish={async (values: any) => {
                  const {
                    productId,
                    quantity,
                    // containerTypeEnumId,
                    // containerId,
                  } = values;
                  setIsForceLoading(true);
                  await dispatch(
                    produceAssetProduct({
                      id,
                      facilityId:
                        productionRunData?.workEffortList?.[0]?.facilityId,
                      productId,
                      quantity,
                      // containerTypeEnumId,
                      // containerId,
                      getData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item
                  name="facilityId"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Facility" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="productId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: true, message: "Select an option" }]}
                >
                  <Dropdown
                    searchable={true}
                    isDisabled={true}
                    title="Assigned Product"
                    data={productsToProduce?.map((pro: any) => {
                      return {
                        key: pro?.productId,
                        value: `${pro?.productId}: ${pro?.productName}`,
                      };
                    })}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      { required: true, message: "This field is required!" },
                      // {
                      //   pattern: /^[1-9]\d*$/,
                      //   message: "Please enter valid number",
                      // },
                    ]}
                  >
                    <Input title="Quantity" placeholder="" inputType="number" />
                  </Form.Item>
                  {/* <Form.Item
                    name="containerTypeEnumId"
                    className="sm:ml-4 flex-1 w-full"
                    rules={[{ required: false, message: "Select an option" }]}
                  >
                    <Dropdown
                      searchable={true}
                      title="Container Type"
                      data={produceContainerList}
                      placeholder="Select one"
                      width="100%"
                    />
                  </Form.Item> */}
                </div>
                {/* <div style={{ width: `${isMobileDevice ? "100%" : "48%"}` }}>
                  <Form.Item
                    name="containerId"
                    className=""
                    rules={[{ required: false, message: "" }]}
                  >
                    <Input
                      title="Add to Container"
                      placeholder=""
                      // inputType="number"
                    />
                  </Form.Item>
                </div> */}
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Update Produce Asset Modal::END */}
          {/* Update Consume Asset Modal::START */}
          <Modal
            title="Consume"
            visible={isUpdateConsumeAssetModalOpen}
            footer={null}
            onCancel={() => {
              setIsUpdateConsumeAssetModalOpen(false);
              updateConsumeAssetForm?.resetFields();
              setSearchableString("");
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={updateConsumeAssetForm}
                onFinish={async (values: any) => {
                  setIsForceLoading(true);
                  await dispatch(
                    consumeAssetProduct({
                      id,
                      assetId: selectedAsset,
                      productId: values?.productId,
                      quantity: values?.quantity ? values.quantity : 0,
                      getData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item
                  name="facilityId"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Facility" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="productId"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Product" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="assetId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: false, message: "Select an option" }]}
                >
                  <div>
                    <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                      Asset
                    </h1>
                    <Select
                      showSearch
                      value={selectedAsset}
                      placeholder="Search Asset"
                      style={{ width: "100%" }}
                      defaultActiveFirstOption={false}
                      suffixIcon={null}
                      className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                      filterOption={false}
                      onSearch={async (value: any) => {
                        setAssetSearchableString(() => value);
                        setConsumedAssetsList([]);
                        debounceFn(
                          await dispatch(
                            getConsumeAssetsList({
                              id,
                              facilityId:
                                productionRunData?.workEffortList?.[0]
                                  ?.facilityId,
                              consumedAssetsList,
                              setConsumedAssetsList,
                              setMaxPageIndex: assetMaxPageIndex,
                              queryString: value,
                              currPage: 0,
                              setHasMoreProds,
                              productId:
                                updateConsumeAssetForm?.getFieldValue(
                                  "productId"
                                ),
                            })
                          )
                        );
                      }}
                      onSelect={(value: any) => {
                        setSelectedAsset(value);
                      }}
                      notFoundContent={null}
                      options={consumedAssetsList?.map((asset: any) => {
                        return {
                          label: asset?.assetInfo,
                          value: asset?.assetId,
                        };
                      })}
                      onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                          (target as any).scrollTop +
                            (target as any).offsetHeight ===
                          (target as any).scrollHeight
                        ) {
                          if (!currIndex || currIndex * 1 === 0) {
                            return;
                          }
                          if (currIndex * 1 > maxPageIndex) {
                            return;
                          }
                          setCurrIndex((prev: any) => prev * 1 + 1);
                          localStorage.setItem("assetListCurrIndex", currIndex);
                          await dispatch(
                            getConsumeAssetsList({
                              id,
                              facilityId:
                                productionRunData?.workEffortList?.[0]
                                  ?.facilityId,
                              consumedAssetsList,
                              setConsumedAssetsList,
                              queryString: searchableString,
                              currPage: currIndex,
                              setHasMoreProds,
                              productId:
                                updateConsumeAssetForm?.getFieldValue(
                                  "productId"
                                ),
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="quantity"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Quantity" placeholder="" inputType="number" />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Consume" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Update Consume Asset Modal::END */}
          {/* Consume By Product Modal::START */}
          <Modal
            title="Consume by Product"
            visible={isConsumeByProductModalOpen}
            footer={null}
            onCancel={() => {
              setIsConsumeByProductModalOpen(false);
              consumeAssetForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={produceAssetForm}
                initialValues={{
                  facility: "FP_FACILITY: Fulfillment Plus Default",
                  noConsumeProduct: "error",
                }}
                onFinish={async (values: any) => {}}
              >
                <Form.Item
                  name="facility"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input title="Facility" placeholder="" isDisabled={true} />
                </Form.Item>
                <Form.Item
                  name="product"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: false, message: "Select an option" }]}
                >
                  <Dropdown
                    searchable={true}
                    title="Product"
                    data={[]}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="Quantity"
                    className="flex-1 w-full"
                    rules={[{ required: false, message: "" }]}
                  >
                    <Input title="Quantity" placeholder="" inputType="number" />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Consume" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Consume By Product Modal::END */}
          <div className="flex flex-col mdlarge:flex-row mb-3">
            <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
              <div
                className="flex flex-row items-center mb-6 cursor-pointer w-max"
                onClick={() => {
                  history.push("/run/productionRunListing");
                }}
              >
                <MdOutlineKeyboardBackspace
                  style={{ fontSize: "20px" }}
                  className="text-primary"
                />
                <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                  BACK TO RESULTS
                </p>
              </div>
            </div>
          </div>
          <Tabs type="card" className="mb-10">
            <TabPane tab="Production Run" key="1">
              <Modal
                title="Add Party"
                visible={isAddPartyModalOpen}
                footer={null}
                onCancel={() => {
                  setIsAddPartyModalOpen(false);
                  addPartyForm?.resetFields();
                }}
              >
                <Loader
                  isLoading={isForceLoading}
                  styles={{
                    overlay: (base: any) => ({
                      ...base,
                      backgroundColor: "",
                    }),
                  }}
                >
                  <Form
                    form={addPartyForm}
                    initialValues={{
                      roleTypeId: "Worker",
                      statusId: "WeptAssigned",
                    }}
                    onFinish={async (values: any) => {
                      setIsForceLoading(true);
                      await dispatch(
                        createPartyAssign({
                          id,
                          ...values,
                          fetchData,
                          addPartyForm,
                          setIsAddPartyModalOpen,
                        })
                      );
                      setIsForceLoading(false);
                    }}
                  >
                    <Form.Item
                      name="partyId"
                      className="sm:mr-4 flex-1 w-full"
                      rules={[{ required: true, message: "Select an option" }]}
                    >
                      <Dropdown
                        searchable={true}
                        title="Person"
                        data={pickersList}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                    <div className="sm:flex">
                      <Form.Item
                        name="roleTypeId"
                        className="sm:mr-4 flex-1 w-full"
                        rules={[
                          { required: false, message: "Select an option" },
                        ]}
                      >
                        <Dropdown
                          title="Role"
                          data={[
                            {
                              key: "Worker",
                              value: "Worker",
                            },
                            { key: "Manager", value: "Manager" },
                            { key: "", value: "Not Applicable" },
                          ]}
                          placeholder="Select one"
                          width="100%"
                        />
                      </Form.Item>
                      <Form.Item
                        name="statusId"
                        className="flex-1 w-full"
                        rules={[
                          { required: true, message: "Select an option" },
                        ]}
                      >
                        <Dropdown
                          title="Status"
                          data={partyStatusList}
                          placeholder="Select one"
                          width="100%"
                        />
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <div className="w-full mt-5 flex justify-end">
                        <Button widthFull={true} buttonText="Add Assignment" />
                      </div>
                    </Form.Item>
                  </Form>
                </Loader>
              </Modal>
              <Modal
                title="Update Production Run Details"
                visible={isUpdateRunModalOpen}
                footer={null}
                width={700}
                onCancel={() => {
                  setIsUpdateRunModalOpen(false);
                  form?.resetFields();
                }}
              >
                <Loader
                  isLoading={isForceLoading}
                  styles={{
                    overlay: (base: any) => ({
                      ...base,
                      backgroundColor: "",
                    }),
                  }}
                >
                  <Form
                    name="update_production_run"
                    form={form}
                    initialValues={{}}
                    className="px-2"
                    onFinish={async (values: any) => {
                      setIsForceLoading(true);
                      await dispatch(
                        updateProductRun({
                          id,
                          ...values,
                          form,
                          setIsUpdateRunModalOpen,
                          fetchData,
                        })
                      );
                      setIsForceLoading(false);
                    }}
                  >
                    <div className="sm:flex">
                      <Form.Item
                        name="workEffortName"
                        className="sm:mr-4 flex-1"
                        rules={[
                          {
                            required: true,
                            message: "This is required field!",
                          },
                        ]}
                      >
                        <Input
                          title="Name"
                          placeholder="Name"
                          showDot={false}
                        />
                      </Form.Item>
                      <Form.Item
                        name="facilityId"
                        className="flex-1"
                        rules={[
                          {
                            required: true,
                            message: "This is required field!",
                          },
                        ]}
                      >
                        <Dropdown
                          title="Warehouse"
                          placeholder="Select One"
                          data={facilityList?.map((facility: any) => {
                            return {
                              key: facility?.key,
                              value: `${facility?.key}: ${facility?.value}`,
                            };
                          })}
                          searchable={true}
                        />
                      </Form.Item>
                    </div>
                    <div className="sm:flex">
                      <Form.Item name="estimatedStartDate" className="flex-1">
                        <DatePicker
                          title="Start Start"
                          dateFormat="DD-MM-YYYY"
                          isFullWidth={true}
                          style={{
                            width: "100%",
                            height: "34px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="estimatedWorkDuration"
                        className="flex-1 ml-4"
                        rules={[
                          {
                            pattern: /^[1-9]\d*$/,
                            message: "Please enter valid number",
                          },
                        ]}
                      >
                        <Input
                          title="Est. Hours"
                          inputType="number"
                          placeholder=""
                          showDot={false}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <div className="w-full mt-5 flex justify-end">
                        <Button widthFull={true} buttonText="Update" />
                      </div>
                    </Form.Item>
                  </Form>
                </Loader>
              </Modal>
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
                  <div className="bg-white p-5 mb-5 rounded-lg shadow-sm">
                    <span className="text-base sm:text-lg font-bold mr-5">
                      Production Run ID # {id}
                    </span>
                  </div>
                  <div className="bg-white p-5 rounded-lg shadow-sm">
                    {/* <div className="flex gap-4 justify-end mb-4">
                  <Button buttonText="Update" />
                </div> */}
                    <div className="">
                      <div className="flex flex-row gap-5 justify-between">
                        <div className="flex flex-row gap-5 mt-5">
                          <div className="text-sm font-bold w-24">Name</div>
                          <div>
                            {
                              productionRunData?.workEffortList?.[0]
                                ?.workEffortName
                            }
                          </div>
                        </div>
                        <div>
                          <Button
                            buttonText="Update"
                            onClick={() => {
                              const {
                                workEffortName,
                                facilityId,
                                estimatedStartDate,
                                estimatedWorkDuration,
                              } = productionRunData?.workEffortList?.[0];
                              form?.setFieldsValue({
                                workEffortName,
                                facilityId,
                                estimatedStartDate: estimatedStartDate
                                  ? moment(estimatedStartDate)
                                  : "",
                                estimatedWorkDuration,
                              });
                              setIsUpdateRunModalOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Warehouse</div>
                        <div>
                          {`${
                            productionRunData?.workEffortList?.[0]?.facilityId
                          }: ${getOrgName(
                            productionRunData?.workEffortList?.[0]?.ownerPartyId
                          )}`}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Start Date</div>
                        <div>
                          {productionRunData?.workEffortList?.[0]
                            ?.estimatedStartDate
                            ? moment(
                                productionRunData?.workEffortList?.[0]
                                  ?.estimatedStartDate
                              ).format("DD-MM-YYYY")
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Est. Hours</div>
                        <div>
                          {productionRunData?.workEffortList?.[0]
                            ?.estimatedWorkDuration &&
                            `${productionRunData?.workEffortList?.[0]?.estimatedWorkDuration}h`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full mdlarge:w-4/12 ml-0 mdlarge:ml-3 ">
                  <div className="bg-white p-5 pb-6 rounded-lg shadow-sm mb-6">
                    <div className="flex justify-between items-center">
                      <Button
                        buttonText="Run Pick PDF"
                        classNames="ml-1 mr-1"
                        onClick={() => {
                          const url = `${process.env.REACT_APP_API_BASE_URL}/fop/apps/PopcAdmin/Manufacturing/Run/RunPickDocument?workEffortId=${id}`;
                          downloadLabel(url);
                        }}
                      />
                    </div>
                  </div>
                  <div className="bg-white p-5 pb-6 rounded-lg shadow-sm mb-6">
                    <div className="flex items-center mb-5">
                      <h1 className="text-base font-bold mr-4">Status</h1>
                      <Dropdown
                        width={"70%"}
                        data={[
                          {
                            key: "WeInPlanning",
                            value: "In Planning",
                          },
                          {
                            key: "WeApproved",
                            value: "Approved",
                          },
                          {
                            key: "WeOnHold",
                            value: "On Hold",
                          },
                          {
                            key: "WeComplete",
                            value: "Complete",
                          },
                          {
                            key: "WeCancelled",
                            value: "Cancelled",
                          },
                        ]}
                        placeholder="Select Status"
                        value={currStatus}
                        onChange={async (val: string) => {
                          await dispatch(
                            updateProductionRUnStatus({
                              id,
                              statusId: val,
                              fetchData,
                              setCurrStatus,
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="items-center justify-between mb-2">
                      {productionRunData?.statusHistoryDetail?.map(
                        (status: any, index: any) => (
                          <div className="py-2">
                            <span className="text-base font-bold sm:text-xs">
                              {moment(status?.changedDate).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            </span>
                            <span className="text-base sm:text-xs mx-1">
                              by{" "}
                              {`${status?.userFullName} [${status?.userName}]`}
                            </span>
                            <span
                              className="font-bold sm:text-xs mx-1"
                              style={{
                                color: index === 0 ? "green" : "",
                              }}
                            >
                              {status?.description}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                <Collapse
                  defaultActiveKey={[""]}
                  className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                  expandIconPosition="left"
                  ghost
                >
                  <Panel
                    header={
                      <span className="text-base font-bold">
                        Parties Assigned
                      </span>
                    }
                    key="1"
                  >
                    <div className="flex justify-end mb-4">
                      <Button
                        buttonText="Add Party"
                        onClick={async () => {
                          setIsAddPartyModalOpen(true);
                          setIsForceLoading(true);
                          await dispatch(
                            getPickerList({ workEffortId: id, setPickersList })
                          );
                          setIsForceLoading(false);
                        }}
                      />
                    </div>
                    <Table
                      rowKey="shipMethod"
                      dataSource={productionRunData?.peopleAssignList}
                      columns={partyAssignedColumns}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse>
              </div>
            </TabPane>
            <TabPane tab="Run Products" key="2">
              <ProductsComponent
                data={productsToProduce}
                type="Produce"
                setIsAddProductModalOpen={setIsAddProductModalOpen}
                addProductForm={addProductForm}
                onAssetsBtnClick={() => {
                  const facilityInfo = `${
                    productionRunData?.workEffortList?.[0]?.facilityId
                  }: ${getOrgName(
                    productionRunData?.workEffortList?.[0]?.ownerPartyId
                  )}`;
                  produceAssetForm?.setFieldValue("facilityId", facilityInfo);
                  setIsProduceAssetModalOpen(true);
                }}
                onUpdatebtnClick={(e: any, data: any) => {
                  setIsUpdateProduceAssetModalOpen(true);
                  const facilityInfo = `${
                    productionRunData?.workEffortList?.[0]?.facilityId
                  }: ${getOrgName(
                    productionRunData?.workEffortList?.[0]?.ownerPartyId
                  )}`;
                  updateProduceAssetForm?.setFieldsValue({
                    facilityId: facilityInfo,
                    productId: data?.productId,
                    quantity: data?.estReceiptRemaining,
                  });
                }}
              />
              <ProductsComponent
                data={productsToConsume}
                type="Consume"
                setIsAddProductModalOpen={setIsAddProductModalOpen}
                addProductForm={addProductForm}
                onAssetsBtnClick={async () => {
                  setIsConsumeAssetModalOpen(true);
                  setSelectedAsset(null);
                  setConsumedAssetsList(() => []);
                  setIsForceLoading(true);
                  await dispatch(
                    getConsumeAssetsList({
                      id,
                      facilityId:
                        productionRunData?.workEffortList?.[0]?.facilityId,
                      consumedAssetsList,
                      setConsumedAssetsList,
                      setMaxPageIndex: setAssetMaxPageIndex,
                      queryString: "",
                      currPage: 0,
                      setHasMoreProds,
                    })
                  );
                  setIsForceLoading(false);
                  const facilityInfo = `${
                    productionRunData?.workEffortList?.[0]?.facilityId
                  }: ${getOrgName(
                    productionRunData?.workEffortList?.[0]?.ownerPartyId
                  )}`;
                  consumeAssetForm?.setFieldValue("facilityId", facilityInfo);
                }}
                onUpdatebtnClick={async (e: any, data: any) => {
                  setIsUpdateConsumeAssetModalOpen(true);
                  setSelectedAsset(null);
                  setConsumedAssetsList(() => []);
                  setIsForceLoading(true);
                  await dispatch(
                    getConsumeAssetsList({
                      id,
                      facilityId:
                        productionRunData?.workEffortList?.[0]?.facilityId,
                      consumedAssetsList,
                      setConsumedAssetsList,
                      queryString: "",
                      currPage: 0,
                      setMaxPageIndex: setAssetMaxPageIndex,
                      setHasMoreProds,
                      productId: data?.productId,
                    })
                  );
                  setIsForceLoading(false);
                  const facilityInfo = `${
                    productionRunData?.workEffortList?.[0]?.facilityId
                  }: ${getOrgName(
                    productionRunData?.workEffortList?.[0]?.ownerPartyId
                  )}`;
                  updateConsumeAssetForm?.setFieldsValue({
                    facilityId: facilityInfo,
                    productId: data?.productId,
                    quantity:
                      Number(data?.estimatedQuantity) -
                      Number(data?.consumedCurrentIssuanceQuantity ?? 0),
                  });
                }}
              />
            </TabPane>
            <TabPane tab="Assets Consumed" key="3">
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                {/* <div className="flex">
                  <Button
                    buttonText="Consume Asset"
                    onClick={() => setIsConsumeAssetModalOpen(true)}
                  />
                  <Button
                    buttonText="Consume by Product"
                    classNames="ml-2 mr-2"
                    onClick={() => setIsConsumeByProductModalOpen(true)}
                  />
                  <Button buttonText="Warehouse Assets" />
                </div> */}
                <div className="bg-white p-4 rounded-lg shadow-sm mt-5 mb-5">
                  <Table
                    rowKey="shipMethod"
                    dataSource={assetConsumedData}
                    columns={assetsConsumeColumns}
                    isPaginated={false}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Assets Produced" key="4">
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                {/* <div className="flex">
                  <Button
                    buttonText="Produce Asset"
                    onClick={() => setIsProduceAssetModalOpen(true)}
                  />
                </div> */}
                <div className="bg-white p-4 rounded-lg shadow-sm mt-5 mb-5">
                  <Table
                    rowKey="shipMethod"
                    dataSource={assetProducedData}
                    columns={assetsProducedColumns}
                    isPaginated={false}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Loader>
      </div>
    </>
  );
};

export default ProductionRunDetails;
