import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import { Form, Modal } from "antd";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Button from "./Button";
import Dropdown from "./Dropdown";
import { useDispatch } from "react-redux";
import {
  getFacilityList,
  getPickRunDetails,
  getPickerList,
  getStatusList,
} from "../redux/authSlice";
import Table from "./Table";
import moment from "moment";

const PickRunDetail = ({ pickRunId }: { pickRunId: any }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pickRunDetails, setPickRunDetails] = useState<any>([]);
  const [pickerList, setPickerList] = useState<any>([]);
  const [facilityList, setFacilityList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [isOpenAssignPickRun, setIsOpenAssignPickRun] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getPickRunDetails({ pickRunId, setPickRunDetails }));
      setIsLoading(false);
      await dispatch(
        getPickerList({
          workEffortId: pickRunId,
          setPickerList,
        })
      );
      await dispatch(
        getFacilityList({ setFacilityList, fromInventory: false })
      );
      await dispatch(
        getStatusList({
          setStatus: setStatusList,
          statusTypeId: "PickRunStatus",
        })
      );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Order</p>
        </div>
      ),
      render: (orderId: string) => (
        <Link
          to={{
            pathname: `/order/${orderId}`,
            state: { orderId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">{orderId}</h1>
        </Link>
      ),
      dataIndex: "orderId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Shipment</p>
        </div>
      ),
      render: (shipmentId: string) => (
        <Link to={`/inventory/${shipmentId}`}>
          <h1 className="cursor-pointer underline text-primary">
            {shipmentId}
          </h1>
        </Link>
      ),
      dataIndex: "shipmentId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      render: (product: any) => (
        <Link to={`/product/${product?.productId}`}>
          <h1 className="cursor-pointer underline text-primary">
            {product?.productSku}
          </h1>
        </Link>
      ),
      dataIndex: "product",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      render: (product: any) => (
        <Link to={`/product/${product?.productId}`}>
          <h1 className="cursor-pointer underline text-primary">
            {product?.productUpc}
          </h1>
        </Link>
      ),
      dataIndex: "product",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Quantity</p>
        </div>
      ),
      render: (quantity: string) => (
        <div className="flex items-center relative h-5 p-2 leading-3">
          {quantity}
        </div>
      ),
      dataIndex: "quantity",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Picked Quantity</p>
        </div>
      ),
      render: (pickedQuantity: string) => (
        <div className="flex items-center relative h-5 p-2 leading-3">
          {pickedQuantity ?? 0}
        </div>
      ),
      dataIndex: "pickedQuantity",
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Exception Type</p>
    //     </div>
    //   ),
    //   render: (exceptionType: string) => (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       {exceptionType}
    //     </div>
    //   ),
    //   dataIndex: "exceptionType",
    // },
  ];

  const getPickersName = (pickerId: any) => {
    return (
      pickerList?.find((picker: any) => picker?.key === pickerId)?.value || ""
    );
  };

  const getFacilityDetails = (facilityId: any) => {
    return (
      facilityList?.find((facility: any) => facility?.key === facilityId)
        ?.value || ""
    );
  };

  return (
    <div className="h-screen">
      <Modal
        title="Assign Pick Run to Another User"
        visible={isOpenAssignPickRun}
        footer={null}
        onCancel={() => {
          setIsOpenAssignPickRun(false);
          form?.resetFields();
        }}
        // width={700}
      >
        <Form
          form={form}
          onFinish={async (values: any) => {
            setIsOpenAssignPickRun(false);
          }}
        >
          <div className="sm:flex"></div>
          <div className="sm:flex">
            <Form.Item name="assignTo" className="flex-1 w-full">
              <Dropdown
                title="Assign TO"
                placeholder=""
                data={pickerList}
                searchable={true}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="w-full mt-5 flex justify-end">
              <Button widthFull={true} buttonText="Update PickRun" />
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        <div className="flex flex-col mdlarge:flex-row mb-3">
          <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
            <div
              className="flex flex-row items-center mb-6 cursor-pointer w-max"
              onClick={() => {
                history.push("/picklist/picklistListing");
              }}
            >
              <MdOutlineKeyboardBackspace
                style={{ fontSize: "20px" }}
                className="text-primary"
              />
              <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                BACK TO RESULTS
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <div className="flex flex-row justify-between items-center mb-5">
            <span className="text-base sm:text-lg font-bold mr-5">
              Pick Run ID #{pickRunId}
            </span>
          </div>
        </div>
        <div className="flex flex-col mdlarge:flex-row mb-3">
          <div className="bg-white p-5 mt-5 rounded-lg lg:w-6/12 mdlarge:w-7/12 shadow-sm">
            {pickRunDetails?.assignedToUserId && (
              <div className="mt-4">
                <div className="flex flex-row gap-5">
                  <div className="text-sm font-bold w-1/3">Assigned To</div>

                  <div className="">
                    {getPickersName(pickRunDetails.assignedToUserId)}
                  </div>
                </div>
              </div>
            )}
            {pickRunDetails?.facilityId && (
              <div className="mt-4">
                <div className="flex flex-row gap-5">
                  <div className="text-sm font-bold w-1/3">Facility</div>
                  <div>{getFacilityDetails(pickRunDetails.facilityId)}</div>
                </div>
              </div>
            )}
            {pickRunDetails?.workEffortId && (
              <div className="mt-4">
                <div className="flex flex-row gap-5">
                  <div className="text-sm font-bold w-1/3">Picklist</div>
                  {/* <Link
                  to={{
                    pathname: `/picklist/${pickRunId}`,
                    state: { pickRunId },
                  }}
                >
                  <h1 className="cursor-pointer text-primary">{pickRunId}</h1>
                </Link> */}
                  <div>{pickRunDetails?.workEffortId}</div>
                </div>
              </div>
            )}
            <div className="mt-4">
              <div className="flex flex-row gap-5">
                <div className="text-sm font-bold w-1/3">Start Date</div>
                <div>
                  {pickRunDetails?.startDate
                    ? moment(pickRunDetails.startDate)?.format("YYYY-MM-DD")
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mdlarge:w-5/12 lg:w-6/12 ml-0 mdlarge:ml-3 ">
            <div className="bg-white p-5 pb-6 rounded-lg shadow-sm mb-6">
              <div className="items-center justify-between mb-2">
                <div className="flex flex-row items-center justify-between mb-2">
                  <h1 className="text-base font-bold mr-3">Pick Run Info</h1>
                  <div className="flex">
                    <Button
                      buttonText="Assign"
                      onClick={async () => setIsOpenAssignPickRun(true)}
                      style={{
                        backgroundColor: "#1e107b",
                        borderColor: "#1e107b",

                        padding: "10px 15px",
                      }}
                    />
                    <Button
                      buttonText="Unassign"
                      style={{
                        backgroundColor: "#1e107b",
                        borderColor: "#1e107b",

                        padding: "10px 15px",
                      }}
                      classNames="ml-2"
                    />
                    <Button
                      style={{
                        backgroundColor: "#ffc107",
                        borderColor: "#ffc107",
                        color: "#212529",

                        padding: "10px 15px",
                      }}
                      buttonText="Cancel"
                      classNames="ml-2"
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <h1 className="text-base font-bold mr-4">Status</h1>
                  <Dropdown
                    isDisabled={true}
                    allowClear={true}
                    width={"70%"}
                    data={statusList}
                    value={pickRunDetails?.statusId}
                    placeholder="Select Status"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <h1 className="mb-4 font-bold text-base">Items</h1>
          <Table
            rowKey="picklist"
            dataSource={pickRunDetails?.pickRunItems}
            columns={columns}
            isPaginated={false}
          />
        </div>
      </Loader>
    </div>
  );
};

export default PickRunDetail;
