/* eslint-disable */
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import Sidebar from "../components/Sidebar";
import ProductionRunListing from "../components/ProductionRunListing";

function ProductionRunListingScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let fromDate: any = "";
  let thruDate: any = "";

  if (parsed.fromDate) {
    fromDate = parsed.fromDate;
  }
  if (parsed.thruDate) {
    thruDate = parsed.thruDate;
  }
  // const currentScreen = "Production Run";
  const subScrName = "Production Run Listing";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            {" "}
            Production Run Listing
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
            </h1>
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              <span className="text-primary mx-1">{arrow}</span>
              {subScrName}
            </h1>
          </div>
        </div>
        <div className="">
          <ProductionRunListing fromDate={fromDate} thruDate={thruDate} />
        </div>
      </div>
    </Sidebar>
  );
}

export default ProductionRunListingScreen;
