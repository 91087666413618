import React, { useState, useEffect } from "react";
import { Table as AntTable, Pagination } from "antd";

type TableData = {
  columns: Array<Object>;
  dataSource: Array<any>;
  rowKey: string;
  loadNextProds?: any;
  isPaginated: boolean;
  recordCount?: any;
  isServerRendered?: any;
  onChange?: any;
  className?: any;
  resetInputField?: any;
  rowClassName?: any;
  showHeader?: boolean;
  loading?: any;
};

const Table = ({
  columns,
  dataSource,
  rowKey,
  loadNextProds,
  isPaginated,
  recordCount,
  isServerRendered,
  onChange,
  className,
  resetInputField,
  rowClassName,
  showHeader = true,
  loading,
}: TableData) => {
  const totalItems: any = localStorage.getItem("totalItems");
  const [index, setIndex] = useState(1);
  const [, setPrevIndex] = useState(10);
  const [resetPagination, setResetPagination] = useState(false);

  useEffect(() => {
    setResetPagination(resetInputField);
    if (localStorage.getItem("currPageIndex")) {
      let prevIndex: any = localStorage.getItem("currPageIndex");
      setIndex(prevIndex * 1 + 1);
    }
    if (localStorage.getItem("passedIndexIncomingShipmentListing")) {
      let prevIndex: any = localStorage.getItem(
        "passedIndexIncomingShipmentListing"
      );
      setIndex(prevIndex * 1 + 1);
    }
  }, [resetInputField]);

  return (
    <div className="relative">
      <div>
        <AntTable
          scroll={{ x: true }}
          columns={columns}
          dataSource={dataSource}
          size="middle"
          rowKey={rowKey}
          onChange={onChange}
          loading={loading}
          className={className}
          rowClassName={rowClassName}
          showHeader={showHeader}
          pagination={
            isServerRendered
              ? false
              : isPaginated && {
                  simple: true,
                  position: ["bottomLeft"],
                  onChange: (index) => {
                    setIndex(index * 1);
                    setPrevIndex(10 * index);
                  },
                }
          }
        />
      </div>
      {!isServerRendered ? (
        recordCount && isPaginated ? (
          <h1 className="text-xs font-medium absolute bottom-5 ml-5">
            Showing {index * 10 <= recordCount ? 10 : recordCount % 10} of{" "}
            {recordCount} items
          </h1>
        ) : dataSource && dataSource.length > 0 && isPaginated ? (
          totalItems ? (
            <h1 className="text-xs font-medium absolute bottom-5 ml-5">
              Showing {index * 10 <= totalItems ? 10 : totalItems % 10} of{" "}
              {totalItems} items
            </h1>
          ) : (
            <h1 className="text-xs font-medium absolute bottom-5 ml-5">
              Showing{" "}
              {index * 10 <= dataSource?.length ? 10 : dataSource?.length % 10}{" "}
              of {dataSource?.length} items
            </h1>
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {isServerRendered && isPaginated && dataSource?.length > 0 && (
        <div className="flex flex-col-reverse ltmob:flex-row ltmob:items-center mt-5 mb-6 ml-5">
          <div className="mb-3 ltmob:mb-0">
            {recordCount ? (
              <h1 className="text-xs font-medium ml-2 ltmob:ml-0">
                Showing {index * 10 <= recordCount ? 10 : recordCount % 10} of{" "}
                {recordCount} items
              </h1>
            ) : (
              <h1 className="text-xs font-medium mr-2 ">
                Showing{" "}
                {index * 10 <= dataSource?.length
                  ? 10
                  : dataSource?.length % 10}{" "}
                of {dataSource?.length} items
              </h1>
            )}
          </div>
          <div className="mb-3 ml-0 ltmob:ml-4 ltmob:mb-1">
            <Pagination
              simple
              size="small"
              showSizeChanger={true}
              current={resetPagination ? 1 : index}
              defaultCurrent={index}
              total={recordCount ? recordCount : dataSource?.length}
              onChange={(index) => {
                if (index > 1) {
                  setResetPagination(false);
                }
                setIndex(index * 1);
                setPrevIndex(10 * index);
                if (isServerRendered) {
                  loadNextProds(index, "");
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
Table.defaultProps = {
  loadNextProds: () => {},
};
export default Table;
