import React, { ReactElement, useState } from "react";
import Loader from "./Loader";
import { Form } from "antd";
import Dropdown from "./Dropdown";
import Input from "./Input";
import Button from "./Button";

function CreateFacility(): ReactElement {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<any>(false);

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          <Form
            name="create_facility"
            form={form}
            onFinish={async (values: any) => {
              setIsLoading(true);

              setIsLoading(false);
            }}
          >
            <div>
              <Form.Item name="facilityId" className="w-full">
                <Input title="Facility ID" placeholder="" showDot={false} />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="facilityName" className="w-full">
                <Input title="Facility Name" placeholder="" showDot={false} />
              </Form.Item>
            </div>
            <div className="flex gap-2">
              <Form.Item name="facilityType" className="flex-1 w-1/2 pr-2">
                <Dropdown
                  title="Facility Type"
                  placeholder="Select One"
                  allowClear={true}
                  data={[]}
                  searchable={true}
                  //   value={selectStore}
                  //   onChange={(val: any) => setSelectStore(val)}
                />
              </Form.Item>
              <Form.Item name="enumType" className="flex-1 w-1/2">
                <Dropdown
                  title="Type"
                  placeholder="Select One"
                  allowClear={true}
                  data={[]}
                  searchable={true}
                  //   value={selectEnumType}
                  //   onChange={(val: any) => setSelectEnumType(val)}
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button widthFull={true} buttonText="Create" />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}
export default CreateFacility;
