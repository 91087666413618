/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CalendarOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Table from "./Table";
import Loader from "./Loader";
import Input from "./Input";
import {
  getEnumTypeList,
  getFacilityList,
  getProductionRunList,
  getStatusList,
} from "../redux/authSlice";
import { AiOutlineFilter } from "react-icons/ai";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductionRunListing = ({
  fromDate,
  thruDate,
}: {
  fromDate?: any;
  thruDate?: any;
}) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 638px)",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [isLoading, setIsLoading] = useState(false);
  const [productionRunListingDetail, setProductionRunListingDetail] =
    useState<any>([]);
  const [facilityList, setFacilityList] = useState<any>([]);
  const [recordCount, setRecordCount] = useState("");
  const [runStatusList, setRunStatusList] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [isSearch, setIsSearch] = useState<any>("");
  const [searchableString, setSearchableString] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [sortData, setSortData] = useState<any>("");
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [shipMethodList, setShipMethodList] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(
    fromDate
      ? fromDate
      : localStorage.getItem("productionRunListingFromDate")
      ? localStorage.getItem("productionRunListingFromDate")
      : ""
  );
  const [selectedThruDate, setSelectedThruDate] = useState(
    thruDate
      ? thruDate
      : localStorage.getItem("productionRunListingThruDate")
      ? localStorage.getItem("productionRunListingThruDate")
      : ""
  );
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [datePickerFrom, setDatePickerFrom] = useState<any>(
    fromDate
      ? moment(fromDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingFromDate")
      ? moment(localStorage.getItem("orderListingFromDate"), "YYYY-MM-DD")
      : ""
  );
  const [datePickerThru, setDatePickerThru] = useState<any>(
    thruDate
      ? moment(thruDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingThruDate")
      ? moment(localStorage.getItem("orderListingThruDate"), "YYYY-MM-DD")
      : ""
  );
  const [resetInputField, setResetInputField] = useState<any>(false);
  const salesChannelFilters: any = [];
  const statusFilters: any = [];
  const shipMethodFilters: any = [];
  useEffect(() => {
    const fetchData = async () => {
      if (fromDate) {
        localStorage.setItem("productionRunListingFromDate", fromDate);
      }
      if (thruDate) {
        localStorage.setItem("productionRunListingThruDate", thruDate);
      }
      let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");
      if (!prevAppliedFilter) {
        prevAppliedFilter = {};
      } else {
        prevAppliedFilter = JSON.parse(prevAppliedFilter);
        setSortedInfo(prevAppliedFilter?.sortedInfo);
        setFilteredInfo(prevAppliedFilter?.filteredInfo);
        setActiveAppliedFilters(prevAppliedFilter?.appliedFilters);
      }
      setIsLoading(true);
      await dispatch(
        getProductionRunList({
          setProductionRunListingDetail,
          setMaxPageIndex,
          orderByFields: prevAppliedFilter?.orderByFields
            ? prevAppliedFilter?.orderByFields
            : sortData,
          setRecordCount,
          pageIndex: localStorage.getItem("currPageIndex")
            ? localStorage.getItem("currPageIndex")
            : 0,
          // searchString: orderStatus,
          placedDate_from: selectedFromDate
            ? selectedFromDate
            : fromDate
            ? fromDate
            : "",
          placedDate_thru: selectedThruDate
            ? selectedThruDate
            : thruDate
            ? thruDate
            : "",
          appliedFilters: prevAppliedFilter?.appliedFilters
            ? prevAppliedFilter.appliedFilters
            : "",
        })
      );
      await dispatch(
        getStatusList({
          setStatus: setRunStatusList,
          statusTypeId: "WorkEffort",
        })
      );
      await dispatch(
        getEnumTypeList({ setEnumType, enumTypeId: "SalesChannel" })
      );
      await dispatch(
        getEnumTypeList({
          setEnumType: setShipMethodList,
          enumTypeId: "ShipmentMethod",
        })
      );
      await dispatch(
        getFacilityList({
          setFacilityList,
          fromInventory: false,
        })
      );
      setIsLoading(false);
    };
    fetchData();
    return () => {
      setResetInputField(false);
      localStorage.removeItem("prevAppliedFilter");
    };
  }, [location.key]); // eslint-disable-line
  const nextSetOfProds = async (
    pageIndex: any,
    orderByFields: any,
    appliedFilters: any,
    resetFields?: any
  ) => {
    console.log("ONNNN", orderByFields);
    setIsLoading(true);
    localStorage.setItem("currPageIndex", pageIndex);
    if (resetFields) {
      setResetInputField(true);
    } else {
      setResetInputField(false);
    }
    await dispatch(
      getProductionRunList({
        setProductionRunListingDetail,
        setMaxPageIndex,
        orderByFields,
        setRecordCount,
        pageIndex,
        //searchString: orderStatus,
        placedDate_from: selectedFromDate
          ? selectedFromDate
          : fromDate
          ? fromDate
          : "",
        placedDate_thru: selectedThruDate
          ? selectedThruDate
          : thruDate
          ? thruDate
          : "",
        appliedFilters,
      })
    );
    setIsLoading(false);
  };
  runStatusList.forEach((item: any) => {
    statusFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  enumType.forEach((item: any) => {
    salesChannelFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  shipMethodList.forEach((item: any) => {
    shipMethodFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  const loadNextProds = (index: any) => {
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");

    prevAppliedFilter = JSON.parse(prevAppliedFilter);
    let orderByFields = sortData;
    if (prevAppliedFilter?.orderByFields) {
      orderByFields = prevAppliedFilter?.orderByFields;
    }
    nextSetOfProds(passedIndex, orderByFields, activeAppliedFilters);
  };

  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID</p>
        </div>
      ),
      dataIndex: "workEffortId",
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }: {
      //   setSelectedKeys: any;
      //   selectedKeys: any;
      //   confirm: any;
      //   clearFilters: any;
      // }) => (
      //   <FilterSearchBar
      //     setSelectedKeys={setSelectedKeys}
      //     selectedKeys={selectedKeys}
      //     confirm={confirm}
      //     clearFilters={clearFilters}
      //   />
      // ),
      // filterIcon: () => {
      //   return filteredInfo.workEffortId ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      // filteredValue: filteredInfo?.workEffortId || null,
      // onFilter: (value: any, record: any) =>
      //   record?.workEffortId.includes(value),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.workEffortId === "number") {
            return a.workEffortId - b.workEffortId;
          } else {
            return a.workEffortId?.localeCompare(b.workEffortId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.workEffortId === "workEffortId" &&
        sortedInfo?.workEffortIdType,
      render: (workEffortId: string, data: any) => (
        <Link to={`/run/${workEffortId}`}>
          <h1 className="cursor-pointer underline text-primary">
            #{workEffortId}
          </h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Warehouse</p>
        </div>
      ),
      dataIndex: "facilityId",
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }: {
      //   setSelectedKeys: any;
      //   selectedKeys: any;
      //   confirm: any;
      //   clearFilters: any;
      // }) => (
      //   <FilterSearchBar
      //     setSelectedKeys={setSelectedKeys}
      //     selectedKeys={selectedKeys}
      //     confirm={confirm}
      //     clearFilters={clearFilters}
      //   />
      // ),
      // filteredValue: filteredInfo?.facilityId || null,
      // onFilter: (value: any, record: any) =>
      //   record?.facilityId?.includes(value),
      // filterIcon: () => {
      //   return filteredInfo.facilityId ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.facilityId === "number") {
            return a.facilityId - b.facilityId;
          } else {
            return a.facilityId?.localeCompare(b.facilityId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.facilityId === "facilityId" && sortedInfo?.facilityIdType,
      render: (facilityId: string) => {
        const facility = facilityList?.find(
          (item: any) => facilityId === item?.key
        );
        return <h1>{facility?.value}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      dataIndex: "statusId",
      // filters: statusFilters,
      // filterIcon: () => {
      //   return filteredInfo.statusId ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.statusId === "number") {
            return a.statusId - b.statusId;
          } else {
            return a.statusId?.localeCompare(b.statusId);
          }
        },
        multiple: 1,
      },
      // filteredValue: filteredInfo?.statusId || null,
      // onFilter: (value: any, record: any) => record?.statusId.includes(value),
      // sortOrder:
      //   sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
      render: (statusId: any) => {
        return (
          <div>
            {runStatusList &&
              runStatusList.map((item: any) => {
                if (item.key === statusId) {
                  return (
                    <h1
                      style={{
                        backgroundColor: "#093479",
                        outlineColor: "#707070",
                        border: "1px solid #707070",
                      }}
                      className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
                    >
                      {item.value?.toUpperCase()}
                    </h1>
                  );
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Start Date</p>
        </div>
      ),
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }: {
      //   setSelectedKeys: any;
      //   selectedKeys: any;
      //   confirm: any;
      //   clearFilters: any;
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <div>
      //       <div className="sm:flex sm:flex-row sm:items-center">
      //         <DatePicker
      //           title="From"
      //           value={datePickerFrom}
      //           onChange={(date: any, dateString: any) => {
      //             setDatePickerFrom(date);
      //             setSelectedFromDate(dateString);
      //             localStorage.setItem("orderListingFromDate", dateString);
      //           }}
      //         />
      //         {!isMobileDevice && <BsDash className="mt-5 mx-0.5" />}
      //         <div className="mt-3 sm:mt-0">
      //           <DatePicker
      //             title="Thru"
      //             value={datePickerThru}
      //             onChange={(date: any, dateString: any) => {
      //               setDatePickerThru(date);
      //               setSelectedThruDate(dateString);
      //               localStorage.setItem("orderListingThruDate", dateString);
      //             }}
      //           />
      //         </div>
      //       </div>
      //       <div className="mt-3 flex flex-row items-center justify-between">
      //         <button
      //           onClick={async () => {}}
      //           className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
      //         >
      //           Reset
      //         </button>
      //         <button
      //           onClick={async () => {}}
      //           className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
      //         >
      //           Filter
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // ),
      dataIndex: "estimatedStartDate",
      // filterIcon: () => {
      //   return filteredInfo.estimatedStartDate ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.estimatedStartDate === "number") {
            return a.estimatedStartDate - b.estimatedStartDate;
          } else {
            return a.estimatedStartDate?.localeCompare(b.estimatedStartDate);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.estimatedStartDate === "estimatedStartDate" &&
        sortedInfo?.estimatedStartDateType,
      render: (estimatedStartDate: any) => {
        function dateCon(date: any) {
          const data = new Date(date).toDateString();
          return data;
        }
        return (
          <div className="flex flex-row items-center">
            {estimatedStartDate ? (
              <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
            ) : (
              ""
            )}

            <h1 className="ml-1.5">
              {estimatedStartDate ? dateCon(estimatedStartDate) : ""}
            </h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Produce</p>
        </div>
      ),
      dataIndex: "producePseudoId",
      render: (producePseudoId: any, data: any) => (
        <div>
          <Link to={`/product/${data?.productId}`}>
            <h1 className="cursor-pointer underline text-primary">
              {producePseudoId}
            </h1>
          </Link>
          <p>{data?.produceProductName}</p>
          {data?.produceEstimatedQuantity && (
            <p>Qty: {data?.produceEstimatedQuantity}</p>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Consume</p>
        </div>
      ),
      dataIndex: "consumeProductList",
      render: (consumeProductList: any) => (
        <div>
          {consumeProductList?.map((item: any, index: any) => (
            <>
              <div key={item.consumePseudoId}>
                <Link to={`/product/${item?.productId}`}>
                  <h1 className="cursor-pointer underline text-primary">
                    {item?.consumePseudoId}
                  </h1>
                </Link>
                <p>{item?.consumeProductName}</p>
                {item?.consumeEstimatedQuantity && (
                  <p>Qty: {item?.consumeEstimatedQuantity}</p>
                )}
              </div>
              {index !== consumeProductList?.length - 1 && (
                <hr className="h-px my-2 bg-gray-200 border-0" />
              )}
            </>
          ))}
        </div>
      ),
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let appliedFilters = "";
    // if (filters.workEffortId) {
    //   if (!appliedFilters) {
    //     appliedFilters += `workEffortId=${filters.workEffortId}`;
    //   } else {
    //     appliedFilters += `&workEffortId=${filters.workEffortId}`;
    //   }
    // }
    setFilteredInfo(filters);
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });

    let prevOrderListingValue = {
      orderByFields: outputStr,
      appliedFilters,
      sortedInfo: {
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      },
      filteredInfo: filters,
    };
    localStorage.setItem(
      "prevAppliedFilter",
      JSON.stringify(prevOrderListingValue)
    );
    if (appliedFilters) {
      nextSetOfProds(0, outputStr, appliedFilters, true);
    } else if (recordCount) {
      nextSetOfProds(0, outputStr, "", true);
    }
  }

  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <div className="flex flex-row justify-between">
            <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              <div className="flex flex-row items-center">
                {/* <button
                  onClick={async () => {}}
                  className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Filter
                </button> */}
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await dispatch(
                      getProductionRunList({
                        setProductionRunListingDetail,
                        setMaxPageIndex,
                        orderByFields: "",
                        setRecordCount,
                        pageIndex: currIndex * 1 - 1,
                        //searchString: orderStatus,
                        placedDate_from: selectedFromDate
                          ? selectedFromDate
                          : fromDate
                          ? fromDate
                          : "",
                        placedDate_thru: selectedThruDate
                          ? selectedThruDate
                          : thruDate
                          ? thruDate
                          : "",
                        appliedFilters: activeAppliedFilters,
                      })
                    );
                    localStorage.removeItem("prevAppliedFilter");
                    setSortedInfo({});
                    setSortData("");
                    setIsLoading(false);
                  }}
                  className="ml-4 mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Sorter
                </button>
              </div>
            </div>
            {/* <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              <div className="flex flex-row items-center">
                <div className="flex flex-row items-center" onClick={() => {
                  const url = `${process.env.REACT_APP_API_BASE_URL}/fop/apps/PopcAdmin/Manufacturing/Run/ProductionRunReport?`;
                }}>
                  <RiDownloadLine
                    style={{ fontSize: "16px" }}
                    className="text-primary"
                  />
                  <p className="ml-1 text-xs font-bold text-primary">PDF</p>
                </div>
              </div>
            </div> */}
          </div>

          <Table
            rowKey="shipMethod"
            dataSource={productionRunListingDetail}
            columns={columns}
            isServerRendered={recordCount ? true : false}
            isPaginated={true}
            loadNextProds={loadNextProds}
            recordCount={recordCount}
            onChange={onChange}
            resetInputField={resetInputField}
          />
        </div>
      </Loader>
    </div>
  );
};

export default ProductionRunListing;
