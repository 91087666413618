import React, { ReactElement } from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import moment from 'moment'

// import calendar_svg from "../images/calendar.svg";

function DatePicker({
  value,
  onChange,
  title,
  dateFormat,
  isTimeShown,
  isDateDisabled,
  isFullWidth = false,
  isRangePicker = false,
  style = {},
  disabled = false
}: {
  value?: any
  onChange?: any
  title: string
  dateFormat?: string
  isTimeShown?: boolean
  isDateDisabled?: boolean
  isFullWidth?: boolean
  isRangePicker?: boolean
  style?: any
  disabled?: any
}): ReactElement {
  const { RangePicker } = AntDatePicker
  return (
    <div>
      <h1 className='font-bold text-xs mb-0.5 sm:mb-1.5'>{title}</h1>
      <div
        className={`border border-gray-400 px-2 py-0.5 rounded-md hover:border-primary-light focus:border-primary-light ${
          !isFullWidth ? 'w-max' : ''
        }`}>
        {!isRangePicker ? (
          <AntDatePicker
            //   suffixIcon={<Image width={16} src={calendar_svg} preview={false} />}
            defaultValue={value}
            value={value}
            format={dateFormat}
            onChange={onChange}
            disabled={disabled}
            showTime={isTimeShown ? { defaultValue: moment('00:00:00', 'HH:MM:SS') } : isTimeShown}
            disabledDate={
              isDateDisabled
                ? (current) => {
                    let customDate = moment().format('YYYY-MM-DD, hh:mm A')
                    return current && current < moment(customDate, 'YYYY-MM-DD, hh:mm A')
                  }
                : undefined
            }
            style={style}
          />
        ) : (
          <RangePicker
            defaultValue={value}
            value={value}
            format={dateFormat}
            onChange={onChange}
            disabled={disabled}
            disabledDate={
              isDateDisabled
                ? (current) => {
                    let customDate = moment().format('YYYY-MM-DD, hh:mm A')
                    return current && current < moment(customDate, 'YYYY-MM-DD, hh:mm A')
                  }
                : undefined
            }
            style={style}
          />
        )}
      </div>
    </div>
  )
}

DatePicker.defaultProps = {
  value: '',
  dateFormat: 'YYYY-MM-DD',
  isTimeShown: false,
  isDateDisabled: false
}

export default DatePicker
