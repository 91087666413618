/* eslint-disable */
import React, { useEffect, useState } from "react";
import Table from "../Table";
import Input from "../Input";
import Button from "../Button";
import { Modal, Form } from "antd";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import {
  getProductStoreSettingsList,
  getEnumTypeList,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ProductStoreSettings = (props: any) => {
  const [isSelectStoreSettingsModalOpen, setIsSelectStoreSettingsModalOpen] =
    useState(false);
  const [isEnterStoreSettingsModalOpen, setIsEnterStoreSettingsModalOpen] =
    useState(false);
  const [storeSettingsForm] = Form.useForm();
  const [productStoreSettingsList, setProductStoreSettingsList] = useState<any>(
    []
  );
  const [enumType, setEnumType] = useState<any>([]);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getProductStoreSettingsList({
        productStoreId: props.id,
        setProductStoreSettingsList,
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType,
        enumTypeId: "ProductStoreSettingType",
      })
    );
    setisLoading(false);
  };
  const SettingColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Settings Type</p>
        </div>
      ),
      dataIndex: "settingTypeEnumId",
      render: (settingTypeEnumId: any) => {
        return (
          <div>
            {
              enumType.find((data: any) => data.key === settingTypeEnumId)
                ?.value
            }
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Value
        </div>
      ),
      dataIndex: "settingValue",
      render: (settingValue: any) => {
        return <div>{settingValue}</div>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          From Date
        </div>
      ),
      dataIndex: "fromDate",
      render: (fromDate: any) => {
        return (
          <div>{fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Thru Date
        </div>
      ),
      dataIndex: "thruDate",
      render: (thruDate: any) => {
        return (
          <div>{thruDate ? moment(thruDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Modal
          visible={isSelectStoreSettingsModalOpen}
          title="Select Store Setting"
          onCancel={() => {
            setIsSelectStoreSettingsModalOpen(false);
          }}
          footer={null}>
          <Form form={storeSettingsForm} onFinish={(values: any) => {}}>
            <Form.Item name="settingstype" className="flex-1 w-full">
              <Dropdown
                title="Settings Type"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>
            <Form.Item name="fromdate">
              <DatePicker title="From Date" />
            </Form.Item>
            <Form.Item name="value" className="flex-1 w-full">
              <Dropdown title="Value" data={[]} placeholder="" width="100%" />
            </Form.Item>

            <Form.Item name="create" className="flex-1 w-full">
              <Button
                onClick={() => {
                  setIsSelectStoreSettingsModalOpen(false);
                }}
                widthFull
                buttonText="Create"
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={isEnterStoreSettingsModalOpen}
          title="Enter Store Setting"
          onCancel={() => {
            setIsEnterStoreSettingsModalOpen(false);
          }}
          footer={null}>
          <Form form={storeSettingsForm} onFinish={(values: any) => {}}>
            <Form.Item name="settingstype" className="flex-1 w-full">
              <Dropdown
                title="Settings Type"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>
            <Form.Item name="fromdate">
              <DatePicker title="From Date" />
            </Form.Item>
            <Form.Item name="value" className="flex-1 w-full">
              <Input title="Value" value="" placeholder="" />
            </Form.Item>

            <Form.Item name="create" className="flex-1 w-full">
              <Button
                onClick={() => {
                  setIsEnterStoreSettingsModalOpen(false);
                }}
                widthFull
                buttonText="Create"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {/* <div className="gap-2 flex">
        <div>
          <Button
            onClick={() => {
              setIsSelectStoreSettingsModalOpen(true);
            }}
            classNames="mb-2"
            buttonText="Selet Store Settings"
          />
        </div>
        <div>
          <Button
            onClick={() => {
              setIsEnterStoreSettingsModalOpen(true);
            }}
            classNames="mb-2"
            buttonText="Enter Store Settings"
          />
        </div>
      </div> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          rowKey=""
          dataSource={productStoreSettingsList}
          columns={SettingColoumns}
          isPaginated={false}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ProductStoreSettings;
