/* eslint-disable */
import { useEffect, useState } from "react";
import Table from "../Table";
import Button from "../Button";
import { Modal, Form, Tabs, Radio, RadioChangeEvent, Collapse } from "antd";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import moment from "moment";

import {
  getProductStoreFacilities,
  getFacilityInfo,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";

const ProductStoreFacility = (props: any) => {
  const [isNewStoreFacilityModalOpen, setIsNewStoreFacilityModalOpen] =
    useState(false);
  const [storeFacilityForm] = Form.useForm();
  const dispatch = useDispatch();
  const [productStoreFacilitiesList, setProductStoreFacilitiesList] =
    useState<any>([]);
  const [facilityId, setFacilityId] = useState<any>([]);
  const [facilityInfo, setFacilityInfo] = useState<any>([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getProductStoreFacilities({
        productStoreId: props.id.id,
        setProductStoreFacilitiesList,
      })
    );

    await dispatch(
      getFacilityInfo({
        facilityId: props.id.selectedFacility,
        setFacilityInfo,
      })
    );
    setisLoading(false);
  };
  const FacilityColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Facility</p>
        </div>
      ),
      dataIndex: "facilityId",
      render: (facilityId: any) => {
        return (
          <div>
            {facilityInfo?.contactInfo?.facilityId === facilityId
              ? facilityInfo?.facilityName
              : ""}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          From Date
        </div>
      ),
      dataindex: "fromDate",
      render: (fromDate: any) => {
        return (
          <div>{fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Thru Date
        </div>
      ),
      dataindex: "thruDate",
      render: (thruDate: any) => {
        return (
          <div>{thruDate ? moment(thruDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Sequence Num
        </div>
      ),
      dataIndex: "sequenceNum",
      render: (sequenceNum: any) => {
        return (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <h1 className="px-4">{sequenceNum}</h1>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Modal
          visible={isNewStoreFacilityModalOpen}
          title="New Store Facility"
          onCancel={() => {
            setIsNewStoreFacilityModalOpen(false);
          }}
          footer={null}>
          <Form form={storeFacilityForm} onFinish={(values: any) => {}}>
            <Form.Item name="Facility" className="flex-1 w-full">
              <Dropdown
                title="Facility"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>

            <Form.Item name="fromdate">
              <DatePicker title="From Date" />
            </Form.Item>
            <Form.Item name="thrudate">
              <DatePicker title="Thru Date" />
            </Form.Item>
            <Form.Item name="create" className="flex-1 w-full">
              <Button
                onClick={() => {
                  setIsNewStoreFacilityModalOpen(false);
                }}
                widthFull
                buttonText="Create"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {/* <div>
        <Button
          onClick={() => {
            setIsNewStoreFacilityModalOpen(true);
          }}
          classNames="mb-2"
          buttonText="New Store Facility"
        />
      </div> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          rowKey=""
          dataSource={productStoreFacilitiesList}
          columns={FacilityColoumns}
          isPaginated={false}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ProductStoreFacility;
