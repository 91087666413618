/* eslint-disable */
import Input from "../Input";
import Button from "../Button";
import { Form, Collapse } from "antd";
import Dropdown from "../Dropdown";
import {
  getProductStoreReturnSettings,
  getGeoCountries,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const ProductStoreReturnSettings = (props: any) => {
  const [storeSettingsForm] = Form.useForm();
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [productStoreReturnSettingList, setProductStoreReturnSettingList] =
    useState<any>([]);
  const [geoCountries, setGeoCountries] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await dispatch(
      getProductStoreReturnSettings({
        productStoreId: props.id,
        setProductStoreReturnSettingList,
      })
    );
    await dispatch(
      getGeoCountries({
        setGeoCountries,
      })
    );
  };
  console.log("geoCountries", geoCountries);
  console.log("productStoreReturnSettingList", productStoreReturnSettingList);
  return (
    <>
      {productStoreReturnSettingList?.map((item: any) => {
        return (
          <div className="bg-white p-5 rounded-lg shadow-sm w-1/2 h-fit">
            {/* <div className="flex gap-4 justify-end mb-4">
                  <Button buttonText="Update" />
                </div> */}

            <div className="flex flex-row gap-5 justify-between">
              <div className="flex flex-row gap-1 mt-5">
                <div className="text-sm font-bold w-24">
                  Preferred Shipping Carrier
                </div>
                <div>{item?.PreferredShippingCarrier}</div>
              </div>
            </div>

            <div className="mt-4 flex flex-row gap-1">
              <div className="text-sm font-bold w-24">To Name</div>
              <div>{item?.toName}</div>
            </div>

            <div className="mt-4 flex flex-row gap-1">
              <div className="text-sm font-bold w-24">Attention</div>
              <div>{item?.attnName}</div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Address 1</div>
              <div className="w-1/2">
                <h1>{item?.address1}</h1>
              </div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Address 2</div>
              <div className="w-1/2">
                <h1>{item?.address2}</h1>
              </div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Unit</div>
              <div className="w-1/2">
                <h1>{item?.unitNumber}</h1>
              </div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">City</div>
              <div className="w-1/2">
                <h1>{item?.city}</h1>
              </div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">State</div>
              <div className="w-1/2">
                <h1>NT-New York</h1>
              </div>
            </div>

            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Country</div>
              <div className="w-1/2">
                <h1>
                  {
                    geoCountries?.find(
                      (data: any) => data?.key === item?.countryGeoId
                    )?.value
                  }
                </h1>
              </div>
            </div>
            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Zip</div>
              <div className="w-1/2">
                <h1>{item?.postalCode}</h1>
              </div>
            </div>
            <div className="mt-4 flex gap-1">
              <div className="text-sm font-bold w-24">Phone</div>
              <div className="w-1/2">
                <h1>{item?.contactNumber}</h1>
              </div>
            </div>
          </div>
        );
      })}
      <div className="w-1/2 mdlarge:w-12/12 mr-0 mdlarge:mr-3 mt-3">
        <Collapse
          className="bg-white p-2 rounded-lg shadow-sm"
          defaultActiveKey={["1"]}
          // onChange={onChange}
          expandIconPosition="left">
          <Panel
            header={
              <span className="text-base font-bold">Shipping options</span>
            }
            key="1">
            <div>
              <h1 className="font-bold">Shipment Method</h1>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
export default ProductStoreReturnSettings;
