/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Collapse, Form, Modal, Select, Tabs, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
// @ts-ignore
import Dropdown from "./Dropdown";
import Loader from "./Loader";

import Table from "./Table";
import Button from "./Button";
import DatePicker from "./DatePicker";
import { getStatisticsDetails, getStoreList } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ITEMS_NAMES: any = {
  Package_Material: "Storage and Materials",
  Order_Processing: "Order Processing",
  Return_Processing: "Return Processing",
};

const InvoiceItems = ({ data }: { data: any }) => {
  const { confirm } = Modal;
  const [itemsData, setItemsData] = useState<any>(data);
  const columns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Description</p>
        </div>
      ),
      dataIndex: "productName",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Quantity</p>
        </div>
      ),
      dataIndex: "units",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Amount</p>
        </div>
      ),
      dataIndex: "unitCharge",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Note</p>
        </div>
      ),
      render: (shipments: any) => (
        <div>
          {shipments?.map((shipment: any) => {
            return (
              <>
                <Link to={`/inventory/${shipment}`}>
                  <h1 className="cursor-pointer underline text-primary">
                    {shipment}
                  </h1>
                </Link>
              </>
            );
          })}
        </div>
      ),
      dataIndex: "shipments",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Total</p>
        </div>
      ),
      render: (charges: any) => <h1>{Number(charges)?.toFixed(2)}</h1>,
      dataIndex: "charges",
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1"></p>
    //     </div>
    //   ),
    //   render: (sku: string, data: any) => {
    //     return (
    //       <div className="flex flex-row items-center">
    //         <div className="flex flex-row ">
    //           <h1 className="ml-2" onClick={() => {}}>
    //             <BiEdit className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
    //           </h1>
    //           <h1
    //             className=""
    //             onClick={() => {
    //               confirm({
    //                 title: "Are you sure?",
    //                 icon: "",
    //                 content: "Do you want to delete this item?",
    //                 okText: "Yes",
    //                 onOk: async () => {},
    //                 okButtonProps: {
    //                   style: {
    //                     borderRadius: "0.5rem",
    //                   },
    //                 },
    //                 cancelText: "No",
    //                 cancelButtonProps: {
    //                   style: {
    //                     borderRadius: "0.5rem",
    //                   },
    //                 },

    //                 onCancel() {
    //                   // console.log("Cancel")
    //                 },
    //               });
    //             }}
    //           >
    //             <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
    //           </h1>
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: "total",
    // },
  ];

  return (
    <Table
      rowKey="shipMethod"
      dataSource={itemsData}
      columns={columns}
      isPaginated={false}
    />
  );
};

const Invoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statisticsData, setstatisticsData] = useState<any>({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [productStoreList, setProductStoreList] = useState([]);
  const [selectedProductStore, setSelectedProductStore] = useState(null);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      // await dispatch(
      //   getStatisticsDetails({
      //     productStoreId: selectedProductStore,
      //     startDate: startDate
      //       ? moment(startDate)?.format("YYYY-MM-DD")
      //       : moment()?.startOf("month")?.format("YYYY-MM-DD"),
      //     endDate: endDate
      //       ? moment(endDate)?.format("YYYY-MM-DD")
      //       : moment()?.endOf("month")?.format("YYYY-MM-DD"),
      //     setData: setstatisticsData,
      //   })
      // );
      // setIsLoading(false);
      await dispatch(getStoreList({ setProductStoreList }));
    };
    fetchData();
  }, []);

  const getProductStoreName = (productStoreId: any) => {
    const productStore: any = productStoreList?.find(
      (store: any) => store?.productStoreId === productStoreId
    );
    return productStore?.storeName;
  };

  return (
    <>
      <div>
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          {/* Find Modal::START */}
          <Modal
            title="Find Options"
            visible={isModalOpen}
            footer={null}
            onCancel={() => {
              setIsModalOpen(false);
              setSelectedProductStore(null);
              form?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <div className="flex justify-end mb-2 items-center">
                <Button
                  buttonText="Clear"
                  onClick={() => {
                    form?.resetFields();
                    setStartDate(null);
                    setEndDate(null);
                  }}
                />
              </div>
              <Form
                form={form}
                initialValues={{}}
                onFinish={async (values: any) => {
                  setIsForceLoading(true);
                  // if(!startDate || !endDate){
                  //   notification["error"]({
                  //     message: "Error",
                  //     description: "",
                  //   });
                  //   return;
                  // }
                  setSelectedProductStore(values?.productStoreId);
                  await dispatch(
                    getStatisticsDetails({
                      productStoreId: values?.productStoreId,
                      startDate: startDate
                        ? moment(startDate)?.format("YYYY-MM-DD")
                        : moment()?.startOf("month")?.format("YYYY-MM-DD"),
                      endDate: endDate
                        ? moment(endDate)?.format("YYYY-MM-DD")
                        : moment()?.endOf("month")?.format("YYYY-MM-DD"),
                      setData: setstatisticsData,
                      setShowData,
                    })
                  );
                  setIsForceLoading(false);
                  setIsModalOpen(false);
                }}
              >
                <Form.Item
                  name="productStoreId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: false, message: "Select an option" }]}
                >
                  <Dropdown
                    title="Store"
                    data={productStoreList?.map((item: any) => {
                      return {
                        key: item?.productStoreId,
                        value: item?.storeName,
                      };
                    })}
                    searchable={true}
                    placeholder=""
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    name="startDate"
                    className="sm:mr-4 flex-1 w-full"
                    rules={[{ required: false, message: "Select an option" }]}
                  >
                    <DatePicker
                      title="Start Date"
                      value={startDate}
                      onChange={(date: any, dateString: any) => {
                        console.log("startDate=>", date);
                        setStartDate(date);
                      }}
                      isFullWidth={true}
                      style={{ width: "100%", height: 34 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="endDate"
                    className="flex-1 w-full"
                    rules={[{ required: false, message: "Select an option" }]}
                  >
                    <DatePicker
                      title="End Date"
                      value={endDate}
                      onChange={(date: any, dateString: any) => {
                        console.log("EndDate=>", date);
                        setEndDate(date);
                      }}
                      isFullWidth={true}
                      style={{ width: "100%", height: 34 }}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Run" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Find Modal::END */}

          <Button buttonText="Find" onClick={() => setIsModalOpen(true)} />
          {Object.keys(statisticsData)?.length > 0 && showData && (
            <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
              {Object.keys(statisticsData)?.map((item: any, index: any) => {
                const data = Array.isArray(statisticsData[item])
                  ? statisticsData[item]?.map((entry: Record<string, any>) => {
                      return Object.entries(entry).reduce(
                        (acc, [key, value]) => {
                          const lowercaseKey =
                            key.charAt(0).toLowerCase() + key.slice(1);
                          acc[lowercaseKey] = value;
                          return acc;
                        },
                        {} as Record<string, any>
                      );
                    })
                  : Object.keys(statisticsData[item] || {}).map(
                      (productName) => {
                        const entry: Record<string, any> = {
                          productName: productName?.replace("_Received", ""),
                        };
                        Object.entries(
                          statisticsData[item][productName]
                        ).forEach(([key, value]) => {
                          const lowercaseKey =
                            key.charAt(0).toLowerCase() + key.slice(1);
                          entry[lowercaseKey] = value;
                        });
                        return entry;
                      }
                    );
                return (
                  <Collapse
                    defaultActiveKey={["1"]}
                    className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                    expandIconPosition="left"
                    ghost
                    key={`${new Date()}_${index}`}
                  >
                    <Panel
                      header={
                        <span className="text-base font-bold">
                          {ITEMS_NAMES[item] ??
                            getProductStoreName(item) ??
                            item}
                        </span>
                      }
                      key={++index}
                    >
                      <div className="flex justify-end mb-4"></div>
                      <InvoiceItems data={data} />
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          )}
        </Loader>
      </div>
    </>
  );
};

export default Invoice;
