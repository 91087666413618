/* eslint-disable */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Input from "./Input";
import Dropdown from "./Dropdown";
import Loader from "./Loader";
import DatePicker from "./DatePicker";
import moment from "moment";
import { AiOutlineFilter } from "react-icons/ai";
import {
  addAssocProduct,
  getEnumTypeList,
  getProductCategoryList,
  getProductDetails,
  searchProductListing,
  updateAssocProduct,
  uploadProductImage,
  deleteAssocProduct,
  addProductIdentification,
  deleteProductIdentification,
  updateProductIdentification,
  updateProductNameAndDescription,
  addProductPrice,
  addProductWeight,
  updateProductWeight,
  updateProductPrice,
  getFacilityList,
  getReviewingInventoryHistory,
  receiveDirect,
  getLotList,
  getOrganizationList,
  getStatusList,
  getContainerList,
  adjustingInventory,
  getProductAssetList,
  getFacilityInfo,
  getAllFacilities,
  updateLowStockFacility,
  deletLowStockFacility,
  getProductGlobalLowStock,
} from "../redux/authSlice";
import {
  Form,
  Image,
  Modal,
  Select,
  notification,
  Tabs,
  Descriptions,
  Row,
  Col,
  Tooltip,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import DummyImage from "../images/dummy_img.svg";
import Button from "./Button";
import UploadImageFile from "./UploadImageFile";
import { BiEdit, BiTrash } from "react-icons/bi";
import Table from "./Table";
import { Link, useHistory } from "react-router-dom";
import path from "path";
import OutsideClickHandler from "react-outside-click-handler";
import { BsInfo } from "react-icons/bs";
import { MdInfoOutline } from "react-icons/md";

const EditableText = ({
  type = "",
  inputType,
  value,
  component,
  onSave,
  title = "",
}: {
  type?: any;
  inputType?: any;
  value: any;
  component: any;
  onSave: Function;
  title?: any;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<any>(value);

  const handleClick = () => {
    setIsEditing(true);
    setInputValue(value);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(inputValue);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleOnKeyPress = (event: any) => {
    if (event.key === "Enter" || event.which === 13) {
      handleSaveClick();
    }
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setInputValue(value);
  };

  if (isEditing && ["product", "price", "miniStock"]?.includes(type)) {
    return (
      <div
        className={`p-5 bg-white rounded-lg shadow-sm ${
          type === "product" && "mb-8"
        }`}
      >
        <OutsideClickHandler onOutsideClick={handleCancelClick}>
          <span className="flex">
            <Input
              placeholder=""
              title=""
              value={inputValue}
              inputType={inputType}
              onChange={handleChange}
              onKeyDown={handleOnKeyPress}
              autoFocus
            />
            <button onClick={handleSaveClick} className="ml-3 bg-white">
              <CheckOutlined />
            </button>
            <button onClick={handleCancelClick} className="ml-3 bg-white">
              <CloseOutlined />
            </button>
          </span>
        </OutsideClickHandler>
      </div>
    );
  }

  if (isEditing && type === "description") {
    return (
      <div className="p-5 bg-white rounded-lg shadow-sm mb-8">
        <OutsideClickHandler onOutsideClick={handleCancelClick}>
          <span className="flex">
            <Input
              placeholder=""
              title=""
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleOnKeyPress}
              autoFocus
              multiline={true}
            />
            <button onClick={handleSaveClick} className="ml-3 bg-white">
              <CheckOutlined />
            </button>
            <button onClick={handleCancelClick} className="ml-3 bg-white">
              <CloseOutlined />
            </button>
          </span>
        </OutsideClickHandler>
      </div>
    );
  }
  if (isEditing && type === "Weight") {
    return (
      <OutsideClickHandler onOutsideClick={handleCancelClick}>
        <span className="flex">
          <Input
            placeholder=""
            title={title}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleOnKeyPress}
            autoFocus
          />
          <div className="gap-1 flex ml-1 mt-4">
            <button onClick={handleSaveClick} className=" bg-white">
              <CheckOutlined />
            </button>
            <button onClick={handleCancelClick} className="bg-white">
              <CloseOutlined />
            </button>
          </div>
        </span>
      </OutsideClickHandler>
    );
  }

  return <span onClick={handleClick}>{component}</span>;
};

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      </div>
      <div className="mt-3 flex flex-row items-center justify-between">
        <button
          onClick={() => {
            clearFilters();
          }}
          className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
        >
          Reset
        </button>
        <button
          onClick={confirm}
          className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default function ProductDetail({ id }: { id: string }) {
  const history = useHistory();
  const { state }: any = history?.location;
  const [selectedTab, setSelectedTab] = useState(state?.fromInventory && "3");
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const [formAdd] = Form.useForm();
  const [formAddLowStock] = Form.useForm();
  const [formAddProduct] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [formUpdateProduct] = Form.useForm();
  const [productDetails, setProductDetails] = useState<any>([]);
  const [receiveDirectForm] = Form.useForm();
  const [physicalQunatity] = Form.useForm();
  const [, setProductStatus] = useState("");
  const [productType, setProductType] = useState("");
  const [listPrice, setListPrice] = useState<any>("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [, setAvergeCost] = useState("");
  // const [, setStatus] = useState([]);
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoryListData, setCategoryListData] = useState<any>([]);
  const [uomTypeEnums] = useState<any>([]);
  const [, setHtsValue] = useState<any>("");
  const [, setEccnValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [showUploadList, setShowUploadList] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isUpdateItemModalOpen, setIsUpdateItemModalOpen] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [searchableString, setSearchableString] = useState("");
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [productAssocTypes, setProductAssocTypes] = useState([]);
  const [isAddProductModalOpen, setIsAddProductModalOpen] =
    useState<any>(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] =
    useState<any>(false);
  const [productIdTypes, setProductIdTypes] = useState<any>([]);
  const [uniqueProductIdTypes, setUniqueProductIdTypes] = useState<any>([]);
  const fieldTitleStyle = "font-bold text-xs mb-5";
  const mainTitleStyle = "font-bold text-base";
  const [currentProductPriceId, setCurrentProductPriceId] = useState("");
  const [listProductPriceId, setListProductPriceId] = useState("");
  const { TabPane } = Tabs;
  const [isReceiveDirectModal, setIsReceiveDirectModal] = useState(false);
  const [isPhysicalDiffModal, setIsPhysicalDiffModal] = useState(false);
  const [receivedDate, setReceivedDate] = useState("");
  const [manufacturedDate, setManufacturedDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [inventoryDate, setInventoryDate] = useState("");
  const [facilityList, setFacilityList] = useState<any>([]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [assetHistoryList, setAssetHistoryList] = useState([]);
  const [lotList, setLotList] = useState<any>([]);
  const [organizationName, setOrganizationName] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [statusTypeId, setStatusTypeId] = useState("");
  const [container, setContainer] = useState<any>([]);
  const [productAssetList, setProductAssetList] = useState<any>([]);
  const [varianceReasonList, setVarianceReasonList] = useState<any>([]);
  const [lotDisabled, isLotDisabled] = useState(false);
  const [facilityInfo, setFacilityInfo] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<any>("");
  const [sortData, setSortData] = useState<any>([]);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [currInventoryReport, setCurrInventoryReport] = useState<any>([]);
  const [assetHistoryListPageIndex, setAssetHistoryListPageIndex] =
    useState<any>("1");
  const [assetHistoryListMaxPageIndex, setAssetHistoryListMaxPageIndex] =
    useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [newFacilityValue, setNewFacilityValue] = useState("");
  const [minimumStock, setMinimumStock] = useState<any>();
  const [isOpenLowStockConf, setIsOpenLowStockConf] = useState<any>(false);
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  const [lowStockFilteredFacilitiesList, setLowStockFilteredFacilitiesList] =
    useState<any>([]);
  const [globalLowStock, setGlobalLowStock] = useState<any>("");
  const [assetrecordCount, setAssetRecordCount] = useState<any>("");
  const [productAssetListPageIndex, setProductAssetListPageIndex] =
    useState<any>("1");
  const [productAssetMaxPageIndex, setProductAssetMaxPageIndex] =
    useState<any>("");
  useEffect(() => {
    fetchData();
  }, [id]); // eslint-disable-line
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getProductDetails({
        setProductDetails,
        setCurrentProductPriceId,
        setListProductPriceId,
        id,
        setProductStatus,
        setProductType,
        setListPrice,
        setCurrentPrice,
        setAvergeCost,
        setEccnValue,
        setHtsValue,
        setIsFirstTimeRender,
      })
    );
    setIsLoading(false);
    await dispatch(
      getProductAssetList({
        productId: id,
        setProductAssetList,
        pageIndex: 0,
        setProductAssetMaxPageIndex,
        setAssetRecordCount,
        appliedFilters: "",
      })
    );
    // await dispatch(getStatusList({ setStatus, statusTypeId: "Product" }));

    // await dispatch(getUomTypeEnums(setUomTypeEnums));
    // await dispatch(
    //   getEnumTypeList({
    //     setEnumType: setProductAssocTypes,
    //     enumTypeId: "ProductAssocType",
    //   })
    // );

    await dispatch(
      getLotList({
        productId: id,
        setLotList,
      })
    );
    await dispatch(
      getProductGlobalLowStock({
        partyId: localStorage.getItem("organizationPartyId"),
        setGlobalLowStock,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getEnumTypeList({
        setEnumType: setProductTypeData,
        enumTypeId: "ProductType",
      })
    );
    dispatch(
      getEnumTypeList({
        setEnumType: setProductIdTypes,
        enumTypeId: "ProductIdentificationType",
      })
    );
    dispatch(
      getEnumTypeList({
        setEnumType: setVarianceReasonList,
        enumTypeId: "InventoryVarianceReason",
      })
    );
    dispatch(getProductCategoryList({ setCategoryListData }));

    dispatch(
      getOrganizationList({
        setOrganizationName,
      })
    );

    dispatch(
      getStatusList({
        statusTypeId: "Asset",
        setStatus,
      })
    );

    dispatch(
      getContainerList({
        setContainer,
      })
    );
    dispatch(
      getAllFacilities({
        setFacilitiesList,
      })
    );

    dispatch(
      getFacilityList({
        setFacilityList,
        setSelectedFacility,
        fromInventory: true,
      })
    );
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (productDetails?.productFacilityDetail && facilitiesList.length > 0) {
      const facilityVal = productDetails.productFacilityDetail.map(
        (item: any) => {
          const facilityDes = facilitiesList.find(
            (id: any) => id.key === item.facilityId
          );
          return { ...item, facilityValue: facilityDes?.value };
        }
      );
      setMinimumStock(facilityVal);
    }
    setIsLoading(false);
  }, [productDetails, facilitiesList]);

  useEffect(() => {
    if (selectedFacility) {
      setIsLoading(true);
      // dispatch(
      //   getFacilityInfo({
      //     facilityId: selectedFacility,
      //     setFacilityInfo
      //   })
      // )
      dispatch(
        getReviewingInventoryHistory({
          setAssetHistoryList,
          productId: id,
          facilityId: selectedFacility,
          setRecordCount,
          setAssetHistoryListMaxPageIndex,
          pageIndex: 0,
        })
      );
      setIsLoading(false);
    }
  }, [selectedFacility]);

  const sortedAssetHistoryList = [...assetHistoryList].sort(
    (a: any, b: any) => {
      const dateA = moment(a.Date).valueOf();
      const dateB = moment(b.Date).valueOf();
      return dateB - dateA;
    }
  );
  // const facilityForInventory=(facilityId)=>{

  // }
  const getFacilityDetail = (facilityId: any) => {
    const facility: any = facilityList?.find(
      (item: any) => item?.key === facilityId
    );
    return facility?.value ?? "";
  };

  const itemsColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      dataIndex: "sku",
      render: (sku: string, data: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1
                className="ml-2"
                onClick={() => {
                  const selectedItem = productDetails?.assocs.find(
                    (item: any) => item.toProductId === data?.toProductId
                  );
                  if (selectedItem) {
                    formUpdate?.setFieldsValue({
                      productName: selectedItem?.productName,
                      toProductId: selectedItem?.toProductId,
                      quantity: selectedItem?.quantity,
                    });
                    setIsUpdateItemModalOpen(true);
                  }
                }}
              >
                <BiEdit className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
              <h1
                className=""
                onClick={() => {
                  confirm({
                    title: "Are you sure?",
                    icon: "",
                    content: "Do you want to delete this component?",
                    okText: "Yes",
                    onOk: async () => {
                      setIsForceLoading(true);

                      const selectedItem = productDetails?.assocs.find(
                        (item: any) => item.toProductId === data?.toProductId
                      );
                      if (selectedItem) {
                        const data = {
                          productId: id,
                          toProductId: selectedItem?.toProductId,
                        };
                        await dispatch(
                          deleteAssocProduct({ data, productId: id, fetchData })
                        );
                      }
                      setIsForceLoading(false);
                    },
                    okButtonProps: {
                      style: {
                        borderRadius: "0.5rem",
                      },
                    },
                    cancelText: "No",
                    cancelButtonProps: {
                      style: {
                        borderRadius: "0.5rem",
                      },
                    },
                    onCancel() {
                      // console.log("Cancel")
                    },
                  });
                }}
              >
                <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product Name</p>
        </div>
      ),
      dataIndex: "productName",
      render: (productName: string) => <h1 className="px-2">{productName}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "toProductSku",
      render: (toProductSku: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.toProductId}`,
            state: { fromInventory: true },
          }}
        >
          <h1 className="px-2 cursor-pointer underline text-primary">
            {toProductSku}
          </h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "toProductUpca",
      render: (toProductUpca: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.toProductId}`,
            state: { fromInventory: true },
          }}
        >
          <h1 className="px-2 cursor-pointer underline text-primary">
            {toProductUpca}
          </h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Quantity</p>
        </div>
      ),
      dataIndex: "quantity",
      render: (quantity: string) => <h1 className="px-2">{quantity}</h1>,
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">From Date</p>
    //     </div>
    //   ),
    //   dataIndex: "fromDate",
    //   render: (fromDate: string) => {
    //     function dateCon(date: any) {
    //       const data = new Date(date);
    //       const monthArr = [
    //         "January",
    //         "February",
    //         "March",
    //         "April",
    //         "May",
    //         "June",
    //         "July",
    //         "August",
    //         "September",
    //         "October",
    //         "November",
    //         "December",
    //       ];
    //       const fullYear = data.getFullYear();
    //       const fullMonth = data.getMonth();
    //       const fullDate = data.getDate();
    //       return `${monthArr[fullMonth]} ${fullDate}, ${fullYear}`;
    //     }
    //     return <h1 className="px-2">{fromDate ? dateCon(fromDate) : ""}</h1>;
    //   },
    // },
  ];
  const productColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      dataIndex: "productIdTypeEnumId",
      render: (productIdTypeEnumId: string, data: any, index: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1
                className="ml-2"
                onClick={() => {
                  const selectedItem = productDetails?.identifications.find(
                    (item: any) =>
                      item?.productIdTypeEnumId === productIdTypeEnumId
                  );

                  if (selectedItem) {
                    formUpdateProduct?.setFieldsValue({
                      productIdTypeEnumId: selectedItem?.productIdTypeEnumId,
                      idValue: selectedItem.idValue,
                    });
                    setIsUpdateProductModalOpen(true);
                  }
                }}
              >
                <BiEdit className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID Type</p>
        </div>
      ),
      dataIndex: "productIdTypeEnumId",
      render: (productIdTypeEnumId: string) => {
        const productId = productIdTypes.find(
          (item: any) => item?.key === productIdTypeEnumId
        );

        return <h1 className="px-2">{productId?.value}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID Value</p>
        </div>
      ),
      dataIndex: "idValue",
      render: (idValue: string) => <h1 className="px-2">{idValue}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      dataIndex: "productIdTypeEnumId",
      render: (productIdTypeEnumId: string, data: any, index: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1
                className=""
                onClick={() => {
                  confirm({
                    title: "Are you sure?",
                    icon: "",
                    content: "Do you want to delete this identification?",
                    okText: "Yes",
                    onOk: async () => {
                      setIsForceLoading(true);

                      const selectedItem =
                        productDetails?.identifications[index];

                      const idType = productIdTypes.find(
                        (item: any) =>
                          item?.key === selectedItem.productIdTypeEnumId
                      );
                      if (selectedItem) {
                        await dispatch(
                          deleteProductIdentification({
                            productId: id,
                            productIdTypeEnumId: idType?.key,
                            fetchData,
                          })
                        );
                      }
                      setIsForceLoading(false);
                    },
                    okButtonProps: {
                      style: {
                        borderRadius: "0.5rem",
                      },
                    },
                    cancelText: "No",
                    cancelButtonProps: {
                      style: {
                        borderRadius: "0.5rem",
                      },
                    },
                    onCancel() {},
                  });
                }}
              >
                <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
            </div>
          </div>
        );
      },
    },
  ];
  const assetHistoryListNextSet = async (
    pageIndex: any
    // orderByFields: any
  ) => {
    setIsTableLoading(true);
    await dispatch(
      getReviewingInventoryHistory({
        setAssetHistoryList,
        productId: id,
        facilityId: newFacilityValue || selectedFacility,
        setRecordCount,
        setAssetHistoryListMaxPageIndex,
        pageIndex,
      })
    );
    setIsTableLoading(false);
  };
  const loadNextProds = (index: any) => {
    if (!assetHistoryListPageIndex || assetHistoryListPageIndex * 1 === 0) {
      return;
    }
    if (assetHistoryListPageIndex * 1 > assetHistoryListMaxPageIndex) {
      return;
    }
    const passedIndex: any = index * 1 - 1;
    setAssetHistoryListPageIndex(index);
    localStorage.setItem("passedIndexAssetHistoryListing", passedIndex);

    // let orderByFields = sortData;
    assetHistoryListNextSet(passedIndex);
  };
  const InventoryColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Date</p>
        </div>
      ),
      dataIndex: "effectiveDate",
      render: (Date: any) => {
        return (
          <>
            <div>{Date ? moment(Date)?.format("YYYY-MM-DD") : ""}</div>
            <div>{Date ? moment(Date)?.format("hh:mm:ss a") : ""}</div>
          </>
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.effectiveDate === "number") {
            return a.effectiveDate - b.effectiveDate;
          } else {
            return a.effectiveDate?.localeCompare(b.effectiveDate);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "effectiveDate" && sortedInfo?.order,
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters
      // }: {
      //   setSelectedKeys: any
      //   selectedKeys: any
      //   confirm: any
      //   clearFilters: any
      // }) => {
      //   return (
      //     <FilterSearchBar
      //       setSelectedKeys={setSelectedKeys}
      //       selectedKeys={selectedKeys}
      //       confirm={confirm}
      //       clearFilters={clearFilters}
      //     />
      //   )
      // },
      // filterIcon: () => {
      //   return filteredInfo.Source ? (
      //     <AiOutlineFilter size={17} color='#093479' />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   )
      // },
      // filteredValue: filteredInfo.Date || '',
      // onFilter: (value: any, record: any) => {
      //   return record?.Date?.toLowerCase()?.includes(value?.toLowerCase())
      // }
    },

    {
      title: (
        <div>
          <p className="">QOH Diff</p>
        </div>
      ),
      dataIndex: "quantityOnHandDiff",
      render: (QOH: any) => {
        return <h1>{QOH}</h1>;
      },
      // sorter: {
      //   compare: (a: any, b: any) => {
      //     if (typeof a['QOH Diff'] === 'number') {
      //       return a['QOH Diff'] - b['QOH Diff']
      //     } else {
      //       return a['QOH Diff']?.localeCompare(b['QOH Diff'])
      //     }
      //   },
      //   multiple: 1
      // },
      // sortOrder: sortedInfo?.field === 'productName' && sortedInfo?.order
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Source</p>
        </div>
      ),
      dataIndex: "sources",
      render: (sources: any) => {
        return <h1>{sources}</h1>;
      },
      // sorter: {
      //   compare: (a: any, b: any) => {
      //     if (typeof a.Source === 'number') {
      //       return a.Source - b.Source
      //     } else {
      //       return a.Source?.localeCompare(b.Source)
      //     }
      //   },
      //   multiple: 1
      // },
      // sortOrder: sortedInfo?.field === 'Source' && sortedInfo?.order,
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters
      // }: {
      //   setSelectedKeys: any
      //   selectedKeys: any
      //   confirm: any
      //   clearFilters: any
      // }) => {
      //   return (
      //     <FilterSearchBar
      //       setSelectedKeys={setSelectedKeys}
      //       selectedKeys={selectedKeys}
      //       confirm={confirm}
      //       clearFilters={clearFilters}
      //     />
      //   )
      // },
      // filterIcon: () => {
      //   return filteredInfo.Source ? (
      //     <AiOutlineFilter size={17} color='#093479' />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   )
      // },
      // filteredValue: filteredInfo.Source || '',
      // onFilter: (value: any, record: any) => {
      //   return record?.Source?.toLowerCase()?.includes(value?.toLowerCase())
      // }
    },
  ];
  const nextAssetSetOfProds = async (
    pageIndex: any,
    orderByFields: any,
    appliedFilters?: any
  ) => {
    setIsTableLoading(true);
    await dispatch(
      getProductAssetList({
        productId: id,
        setProductAssetList,
        pageIndex,
        orderByFields,
        setMaxPageIndex,
        setRecordCount,
        appliedFilters,
      })
    );
    setIsTableLoading(false);
  };
  let filterArr: any = [];
  facilityList.forEach((item: any) => {
    filterArr.push({
      text: item.value,
      value: item.key,
    });
  });

  const loadAssetNextProds = (index: any) => {
    if (!productAssetListPageIndex || productAssetListPageIndex * 1 === 0) {
      return;
    }
    if (productAssetListPageIndex * 1 > productAssetMaxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setProductAssetListPageIndex(index);
    let orderByFields = sortData;
    nextAssetSetOfProds(passedIndex, orderByFields);
  };
  const AssetsColoumns = [
    {
      title: (
        <>
          {/* <div className='flex items-center relative h-5 p-2 leading-3'>
            <h1 className='mr-1'>Product Id</h1>
          </div>
          <div className='flex items-center relative h-5 p-2 leading-3'>
            <h1 className='mr-1'>Product Name</h1>
          </div> */}
          <div className="flex items-center relative h-5 p-2 leading-3">
            <h1 className="mr-1">Asset Class</h1>
          </div>
        </>
      ),
      dataIndex: "productId",

      render: (productId: any, data: any) => {
        return (
          <>
            {/* <div className='flex items-center relative h-5 p-2 leading-3'>
              <h1 className='mb-2'>{productId}</h1>
            </div>
            <div className='flex items-center relative h-5 p-2 leading-3'>
              <h1 className='mt-1'>{data?.productName}</h1>
            </div> */}
            <div className="flex items-center relative h-5 p-2 leading-3">
              <h1 className="">{data?.assetClassEnumId}</h1>
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="mr-1">Status</h1>
        </div>
      ),
      dataIndex: "statusId",
      render: (statusId: any) => {
        const statusDescription: any = status?.find(
          (item: any) => item?.key === statusId
        )?.value;
        return <h1>{statusDescription ?? ""}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="mr-1">Facility</h1>
        </div>
      ),
      filteredValue: filteredInfo?.facilityId || null,
      dataIndex: "facilityId",
      filters: filterArr,
      filterIcon: () => {
        return filteredInfo.facilityId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      onFilter: (value: any, record: any) => {
        return record?.facilityId?.indexOf(value) === 0;
      },
      render: (facilityId: any) => {
        return <h1>{getFacilityDetail(facilityId)}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="mr-1">Location</h1>
        </div>
      ),
      dataIndex: "locationSeqId",
      render: (locationSeqId: any, data: any) => {
        return (
          <div>
            <h1>{locationSeqId}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="mr-1">Lot</h1>
        </div>
      ),
      dataIndex: "lotId",
      render: (lot: any) => {
        return (
          <div>
            <h1>{lotList?.find((item: any) => item?.key === lot)?.value}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <>
          <div>
            <h1>Available</h1>
          </div>
          <div>
            <h1>QOH</h1>
          </div>
        </>
      ),
      dataIndex: "availableToPromiseTotal",
      render: (Available: any, data: any) => {
        return (
          <>
            <h1>{Available}</h1>
            <h1>{data?.quantityOnHandTotal}</h1>
          </>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <h1 className="">Phl Qty</h1>
        </div>
      ),
      dataIndex: "facilityId",
      render: (facilityId: any, data: any) => {
        return (
          <Button
            onClick={() => {
              if (data?.locationSeqId || !data?.locationSeqId || data?.lotId) {
                physicalQunatity?.setFieldsValue({
                  location: data?.locationSeqId,
                  lot: lotList?.find((item: any) => item?.key === data?.lotId)
                    ?.key,
                  status: data?.statusId,
                  ownerparty: data?.ownerPartyId,
                  quantity: data?.quantityOnHandTotal,
                  facility: facilityId,
                });
                setIsPhysicalDiffModal(true);
              }
            }}
            buttonText="Phl Qty"
          />
        );
      },
    },
  ];
  function onChangeAssets(
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) {
    let outputStr = "";
    setSortData("");
    setSortedInfo(sorter);

    setFilteredInfo(filters);

    if (Object.keys(sorter)?.length > 0) {
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    const passedIndex = productAssetListPageIndex * 1 - 1;
    if (recordCount) {
      nextAssetSetOfProds(passedIndex, outputStr);
    }
  }
  const debounceFn = (fn: Function, delay = 1000) => {
    let timer: any;
    return function (this: any, ...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  const getProductIdTypes = (method: string = "", Id: any = "") => {
    let existingIdentificationIds: any[] = productDetails?.identifications?.map(
      (item: any) => item?.productIdTypeEnumId
    );
    // if (method && Id) {
    //   existingIdentificationIds = existingIdentificationIds.filter(
    //     (type: any) => type !== Id
    //   );
    // }
    const idTypes = productIdTypes?.filter(
      (item: any) => !existingIdentificationIds?.includes(item?.key)
    );
    setUniqueProductIdTypes(idTypes);
  };

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    localStorage.setItem("totalItems", extra?.currentDataSource.length);
    setCurrInventoryReport(extra?.currentDataSource);
    let outputStr = "";
    setSortData("");
    setSortedInfo(sorter);

    setFilteredInfo(filters);

    if (Object.keys(sorter)?.length > 0) {
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    // const passedIndex = productAssetListPageIndex * 1 - 1
    // if (recordCount) {
    //   nextSetOfProds(passedIndex, outputStr)
    // }
  }

  useEffect(() => {
    const uniqueFacilityIds = new Set(
      minimumStock?.map((item: any) => item.facilityId)
    );
    const filteredFacility: any | undefined = facilitiesList?.filter(
      (item: any) => {
        return !uniqueFacilityIds.has(item.key);
      }
    );
    setLowStockFilteredFacilitiesList(filteredFacility);
  }, [minimumStock, facilitiesList]);
  return (
    <Loader
      isLoading={isLoading && isFirstTimeRender}
      styles={{
        overlay: (base: any) => ({
          ...base,
          backgroundColor: "white",
        }),
      }}
    >
      <div className="w-full h-screen">
        <Tabs
          type="card"
          centered={false}
          defaultActiveKey={selectedTab}
          onChange={(activeKey: any) => {
            setSelectedTab(activeKey);
          }}
        >
          <TabPane tab="Product" key="1">
            <Modal
              title={`${
                productDetails?.images?.[0]?.contentLocation ? "Update" : "Add"
              } Image`}
              visible={isShowModal}
              footer={null}
              onCancel={() => {
                setIsShowModal(false);
                setFile(null);
              }}
            >
              <Form
                onFinish={async (values: any) => {
                  if (!file || file.status === "removed") {
                    notification["error"]({
                      message: "Error",
                      description: `Please select a file.`,
                    });
                    return;
                  }
                  const organizationPartyId: any = localStorage.getItem(
                    "organizationPartyId"
                  );
                  setIsLoading(true);
                  let formData = new FormData();
                  formData.append(
                    "productContentTypeEnumId",
                    "PcntImageOriginal"
                  );
                  formData.append("contentFile", file);
                  formData.append("ownerPartyId", organizationPartyId);
                  await dispatch(
                    uploadProductImage({
                      data: formData,
                      productId: id,
                      fetchData,
                    })
                  );
                  setIsLoading(false);
                  setIsShowModal(false);
                  setFile(null);
                }}
              >
                <Form.Item>
                  <UploadImageFile
                    setFile={setFile}
                    isCloudIconVisible={true}
                    showUploadList={showUploadList}
                    setShowUploadList={setShowUploadList}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button
                      widthFull={true}
                      // isDisabled={isDisabled}
                      buttonText={
                        productDetails?.images?.[0]?.contentLocation
                          ? "Update"
                          : "Add"
                      }
                    />
                  </div>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Add Component Product"
              visible={isAddItemModalOpen}
              footer={null}
              onCancel={() => {
                setIsAddItemModalOpen(false);
                formAdd?.resetFields();
                setSelectedProduct(null);
                setProductList([]);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "white",
                  }),
                }}
              >
                <Form
                  form={formAdd}
                  onFinish={async (values: any) => {
                    if (
                      Number(values?.quantity) < 0 ||
                      Number(values?.quantity) === 0
                    ) {
                      notification["error"]({
                        message: "Error",
                        description: "Quantity must be greater than 0.",
                      });
                      return;
                    }
                    const data = {
                      ...values,
                      toProductId: selectedProduct,
                      associationType: "PatMfgBom",
                    };
                    setIsForceLoading(true);
                    await dispatch(
                      addAssocProduct({
                        data,
                        productId: id,
                        setIsAddItemModalOpen,
                        setSelectedProduct,
                        formAdd,
                        fetchData,
                        setProductList,
                      })
                    );
                    setIsForceLoading(false);
                  }}
                >
                  <Form.Item
                    name="toProductId"
                    className="sm:mr-4 flex-1 w-full"
                    rules={[{ required: true, message: "Select an option" }]}
                  >
                    <div>
                      <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                        Product
                      </h1>
                      <Select
                        showSearch
                        value={selectedProduct}
                        placeholder="Search Product"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                        filterOption={false}
                        onSearch={async (value) => {
                          setSearchableString(() => value);
                          setProductList([]);
                          debounceFn(
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                setMaxPageIndex,
                                queryString: value,
                                currPage: 0,
                                setHasMoreProds,
                              })
                            )
                          );
                        }}
                        onSelect={(value: any) => {
                          setSelectedProduct(value);
                        }}
                        notFoundContent={null}
                        options={productList?.map((item: any) => {
                          const { identifications, productId, name } = item;
                          const sku = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtSku"
                          );
                          const upc = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtUpca"
                          );
                          const idValue =
                            sku?.idValue || upc?.idValue || productId || "";
                          return {
                            label: `${idValue && `${idValue} | `}${name || ""}`,
                            value: item?.productId,
                          };
                        })}
                        onPopupScroll={async (e: any) => {
                          const { target } = e;
                          if (
                            (target as any).scrollTop +
                              (target as any).offsetHeight ===
                            (target as any).scrollHeight
                          ) {
                            if (!currIndex || currIndex * 1 === 0) {
                              return;
                            }
                            if (currIndex * 1 > maxPageIndex) {
                              return;
                            }
                            setCurrIndex((prev: any) => prev * 1 + 1);
                            localStorage.setItem(
                              "productListCurrIndex",
                              currIndex
                            );
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                queryString: searchableString,
                                currPage: currIndex,
                                setHasMoreProds,
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </Form.Item>
                  {/* <Form.Item
                  className="flex-1"
                  name="associationType"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Assoc. Type"
                    placeholder="Select One"
                    data={productAssocTypes}
                    onChange={() => {}}
                    searchable={true}
                  />
                </Form.Item> */}
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Quantity field is required",
                      },
                      {
                        pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Quantity"
                      placeholder="Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Add" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>

            <Modal
              title="Update Component Product"
              visible={isUpdateItemModalOpen}
              footer={null}
              onCancel={() => {
                setIsUpdateItemModalOpen(false);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                }}
              >
                <Form
                  form={formUpdate}
                  onFinish={async (values: any) => {
                    if (
                      Number(values?.quantity) < 0 ||
                      Number(values?.quantity) === 0
                    ) {
                      notification["error"]({
                        message: "Error",
                        description: "Quantity must be greater than 0.",
                      });
                      return;
                    }
                    delete values?.productName;
                    setIsForceLoading(true);
                    await dispatch(
                      updateAssocProduct({
                        data: values,
                        productId: id,
                        setIsUpdateItemModalOpen,
                        formAdd,
                        fetchData,
                      })
                    );
                    setIsForceLoading(false);
                  }}
                >
                  <Form.Item name="toProductId" hidden />
                  <Form.Item name="productName">
                    <Input
                      title="Product"
                      placeholder="Product"
                      showDot={true}
                      isDisabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Quantity field is required",
                      },
                      {
                        pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Quantity"
                      placeholder="Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Update" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>
            <Modal
              title="Add Identification"
              visible={isAddProductModalOpen}
              footer={null}
              onCancel={() => {
                setIsAddProductModalOpen(false);
                formAddProduct?.resetFields();
                setSelectedProduct(null);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "white",
                  }),
                }}
              >
                <Form
                  form={formAddProduct}
                  onFinish={async (values: any) => {
                    setIsForceLoading(true);
                    await dispatch(
                      addProductIdentification({
                        ...values,
                        productId: id,
                        setIsAddProductModalOpen,
                        formAddProduct,
                        fetchData,
                      })
                    );
                    setIsForceLoading(false);
                  }}
                >
                  <Form.Item
                    name="productIdTypeEnumId"
                    className="sm:mr-4 flex-1 w-full"
                    rules={[{ required: true, message: "Select an option" }]}
                  >
                    <Dropdown
                      title="ID Type"
                      data={uniqueProductIdTypes}
                      placeholder="Select one"
                      width="100%"
                      searchable={true}
                    />
                  </Form.Item>

                  <Form.Item
                    name="idValue"
                    className="sm:mr-4 flex-1 w-full"
                    rules={[{ required: true, message: "Input ID Value" }]}
                  >
                    <Input title="ID Value" placeholder="" showDot={true} />
                  </Form.Item>
                  <Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Add" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>
            <Modal
              title="Update Identification"
              visible={isUpdateProductModalOpen}
              footer={null}
              onCancel={() => {
                setIsUpdateProductModalOpen(false);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                }}
              >
                <Form
                  form={formUpdateProduct}
                  onFinish={async (values: any) => {
                    setIsForceLoading(true);
                    await dispatch(
                      updateProductIdentification({
                        productId: id,
                        ...values,
                        setIsUpdateProductModalOpen,
                        formUpdateProduct,
                        fetchData,
                      })
                    );
                    setIsForceLoading(false);
                  }}
                >
                  <Form.Item name="productIdTypeEnumId">
                    <Dropdown
                      title="ID Type"
                      data={productIdTypes}
                      placeholder="Select one"
                      width="100%"
                      isDisabled={true}
                    />
                  </Form.Item>
                  <Form.Item name="idValue" className="flex-1 w-full">
                    <Input title="ID Value" placeholder="" showDot={true} />
                  </Form.Item>
                  <Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Update" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>
            <Modal
              title="Add Low Stock Configuration"
              visible={isOpenLowStockConf}
              footer={null}
              onCancel={() => {
                setIsOpenLowStockConf(false);
                formAddLowStock?.resetFields();
                // setSelectedProduct(null);
                // setProductList([]);
              }}
            >
              <Loader
                isLoading={isLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "white",
                  }),
                }}
              >
                <Form
                  form={formAddLowStock}
                  onFinish={async (values: any) => {
                    if (
                      Number(values?.minimumStock) < 0 ||
                      Number(values?.minimumStock) === 0
                    ) {
                      notification["error"]({
                        message: "Error",
                        description: "Minimum Stock must be greater than 0.",
                      });
                      return;
                    }

                    // const data = {
                    //   ...values,
                    // };
                    setIsForceLoading(true);
                    await dispatch(
                      updateLowStockFacility({
                        facilityId: values?.facilityId,
                        productId: productDetails?.productId,
                        minimumStock: values?.minimumStock,
                      })
                    );
                    fetchData();
                    setIsOpenLowStockConf(false);
                    setIsForceLoading(false);
                    formAddLowStock?.resetFields();
                  }}
                >
                  <Form.Item
                    name="facilityId"
                    className="sm:mr-4 flex-1 w-full"
                    rules={[{ required: true, message: "Select an option" }]}
                  >
                    <Dropdown
                      title="Facility ID"
                      data={lowStockFilteredFacilitiesList}
                      placeholder=""
                      value={""}
                      onChange={(val: string) => setProductType(val)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="minimumStock"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Minimum Stock field is required",
                      },
                      {
                        pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Minimum Stock"
                      placeholder=""
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Add" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>

            {productDetails && productDetails?.productName && (
              <EditableText
                onSave={async (newValue: any) => {
                  setIsLoading(true);
                  await dispatch(
                    updateProductNameAndDescription({
                      productName: newValue,
                      productId: id,
                    })
                  );
                  await dispatch(
                    getProductDetails({
                      setProductDetails,
                      id,
                      setProductStatus,
                      setProductType,
                      setListPrice,
                      setCurrentPrice,
                      setAvergeCost,
                      setEccnValue,
                      setHtsValue,
                    })
                  );

                  setIsLoading(false);
                }}
                type="product"
                value={productDetails.productName}
                component={
                  <div className="p-5 bg-white rounded-lg shadow-sm mb-8">
                    <h1 className="font-bold text-xl">
                      {productDetails.productName}
                    </h1>
                  </div>
                }
              />
            )}
            <div className="mdlarge:flex mdlarge:flex-row">
              <div className="w-full mdlarge:w-1/2 mr-3">
                <div className="p-6 pt-3 shadow-sm rounded-lg bg-white mb-6">
                  <div className="p-6">
                    <div className="mb-6">
                      <Dropdown
                        isDisabled
                        title="Product Type"
                        data={productTypeData}
                        placeholder=""
                        width="80%"
                        value={productType}
                        onChange={(val: string) => setProductType(val)}
                      />
                    </div>
                    <div className="xl:flex xl:flex-row mb-6">
                      <div className="mr-0 mdxlarge:mr-8">
                        <Input
                          isDisabled
                          placeholder="1"
                          title="Amount Included"
                          value={productDetails.amountFixed}
                        />
                      </div>
                      {uomTypeEnums &&
                        uomTypeEnums.map((item: any) => {
                          if (item.key === productDetails.amountUomId) {
                            return (
                              <div className="mt-4 mdxlarge:mt-0">
                                <Input
                                  isDisabled
                                  placeholder="Pair (pr)"
                                  title="Amount UOM"
                                  value={item.value}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                    <div className="xl:flex xl:flex-row mb-6 gap-2">
                      <EditableText
                        onSave={async (newValue: any) => {
                          setIsLoading(true);
                          if (productDetails.weight) {
                            await dispatch(
                              updateProductWeight({
                                productId: id,
                                dimensionTypeId: "Weight",
                                valueUomId: "WT_lb",
                                value: newValue,
                              })
                            );
                          } else {
                            await dispatch(
                              addProductWeight({
                                productId: id,
                                dimensionTypeId: "Weight",
                                valueUomId: "WT_lb",
                                value: newValue,
                              })
                            );
                          }
                          await dispatch(
                            getProductDetails({
                              setProductDetails,
                              id,
                              setProductStatus,
                              setProductType,
                              setListPrice,
                              setCurrentPrice,
                              setAvergeCost,
                              setEccnValue,
                              setHtsValue,
                            })
                          );
                          setIsLoading(false);
                        }}
                        type="Weight"
                        value={productDetails.weight}
                        title="Weight"
                        component={
                          <div className="mr-0 mdxlarge:mr-8">
                            <Input
                              placeholder="1"
                              title="Weight"
                              value={productDetails.weight}
                            />
                          </div>
                        }
                      />
                      {/* <EditableText
                      onSave={async (newValue: any) => {
                        setIsLoading(true);
                        await dispatch(
                          addProductWeight({
                            productId: id,
                            uomId: "WT_lb",
                            value: newValue,
                            setProductUomDimensionId,
                          })
                        );
                        await dispatch(
                          updateProductWeight({
                            productId: id,
                            productUomDimensionId: productUomDimensionId,
                            uomId: "WT_Kg",
                            value: newValue,
                          })
                        );
                        setWeightValue(newValue);
                        setIsLoading(false);
                      }}
                      type="Weight"
                      title="Weight UOM"
                      component={
                        <div className="mr-0 mdxlarge:mr-8">
                          <Input
                            placeholder="Weight"
                            title="Weight UOM"
                            value={weightValue}
                          />
                        </div>
                      }
                      value={weightValue}
                    /> */}
                      <Input
                        isDisabled
                        placeholder="Weight"
                        title="Weight UOM"
                        value={productDetails?.weightUOM}
                      />
                    </div>
                    <div>
                      <div className="mt-4 mdxlarge:mt-0 w-1/2">
                        <Input
                          isDisabled
                          placeholder="Inventory"
                          title="Inventory (QOH)"
                          value={productDetails.inventoryOnHand}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-6 pt-5 shadow-sm rounded-lg bg-white mb-6">
                  <h1 className={mainTitleStyle}>Prices</h1>

                  <Row
                    gutter={{ md: 32, lg: 32 }}
                    className="flex flex-row items-center"
                  >
                    <Col className="gutter-row" span={6}>
                      <h1 className=" font-medium">Current Price</h1>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <EditableText
                        onSave={async (newPrice: any) => {
                          setIsLoading(true);
                          if (
                            productDetails.prices.length > 0 &&
                            productDetails.prices.some(
                              (item: any) =>
                                item.priceTypeEnumId === "PptCurrent"
                            )
                          ) {
                            await dispatch(
                              updateProductPrice({
                                productId: id,
                                productPriceId: currentProductPriceId,
                                priceTypeEnumId: "PptCurrent",
                                price: newPrice,
                              })
                            );
                          } else {
                            await dispatch(
                              addProductPrice({
                                productId: id,
                                priceTypeEnumId: "PptCurrent",
                                pricePurposeEnumId: "PppPurchase",
                                price: newPrice,
                              })
                            );
                          }

                          await dispatch(
                            getProductDetails({
                              setProductDetails,
                              setCurrentProductPriceId,
                              setListProductPriceId,
                              id,
                              setProductStatus,
                              setProductType,
                              setListPrice,
                              setCurrentPrice,
                              setAvergeCost,
                              setEccnValue,
                              setHtsValue,
                            })
                          );
                          setIsLoading(false);
                        }}
                        type="price"
                        value={currentPrice.replace("$", "")}
                        component={
                          <div className="p-5 bg-white rounded-lg shadow-sm items-center">
                            <Input
                              title=""
                              placeholder=""
                              value={`$ ${Number(
                                currentPrice?.replace("$", "")
                              )?.toFixed(2)}`}
                            />
                          </div>
                        }
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={{ md: 32, lg: 32 }}
                    className="flex flex-row items-center"
                  >
                    <Col className="gutter-row" span={6}>
                      <h1 className=" font-medium">List Price</h1>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <EditableText
                        onSave={async (newPrice: any) => {
                          setIsLoading(true);
                          if (
                            productDetails.prices.length > 0 &&
                            productDetails.prices.some(
                              (item: any) => item.priceTypeEnumId === "PptList"
                            )
                          ) {
                            await dispatch(
                              updateProductPrice({
                                productId: id,
                                productPriceId: listProductPriceId,
                                priceTypeEnumId: "PptList",
                                price: newPrice,
                              })
                            );
                          } else {
                            await dispatch(
                              addProductPrice({
                                productId: id,
                                priceTypeEnumId: "PptList",
                                pricePurposeEnumId: "PppPurchase",
                                price: newPrice,
                              })
                            );
                          }
                          await dispatch(
                            getProductDetails({
                              setProductDetails,
                              setCurrentProductPriceId,
                              setListProductPriceId,
                              id,
                              setProductStatus,
                              setProductType,
                              setListPrice,
                              setCurrentPrice,
                              setAvergeCost,
                              setEccnValue,
                              setHtsValue,
                            })
                          );
                          setIsLoading(false);
                        }}
                        type="price"
                        value={listPrice.replace("$", "")}
                        component={
                          <div className="p-5 bg-white rounded-lg shadow-sm  ">
                            <Input
                              title=""
                              placeholder=""
                              inputType="number"
                              value={`$ ${Number(
                                listPrice?.replace("$", "")
                              )?.toFixed(2)}`}
                            />
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
                  <h1 className={mainTitleStyle}>Product Listing Detail</h1>

                  <div className="flex flex-row items-center">
                    <h1 className={fieldTitleStyle}>Description</h1>
                    <EditableText
                      onSave={async (newValue: any) => {
                        setIsLoading(true);
                        await dispatch(
                          updateProductNameAndDescription({
                            description: newValue,
                            productId: id,
                          })
                        );
                        await dispatch(
                          getProductDetails({
                            setProductDetails,
                            id,
                            setProductStatus,
                            setProductType,
                            setListPrice,
                            setCurrentPrice,
                            setAvergeCost,
                            setEccnValue,
                            setHtsValue,
                          })
                        );
                        setIsLoading(false);
                      }}
                      type="description"
                      value={productDetails?.description}
                      component={
                        <div className="p-5 bg-white rounded-lg shadow-sm mb-8">
                          <Input
                            multiline={true}
                            title=""
                            placeholder=""
                            value={productDetails?.description}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
                  <div className="flex justify-between">
                    <div className="">
                      <h1 className={mainTitleStyle}>
                        Low Stock Configuration{" "}
                      </h1>
                    </div>
                    <Button
                      buttonText="Add"
                      onClick={() => {
                        setIsOpenLowStockConf(true);
                      }}
                    />
                  </div>
                  <div className="items-center">
                    <Loader
                      isLoading={isLoading}
                      styles={{
                        overlay: (base: any) => ({
                          ...base,
                          backgroundColor: "white",
                        }),
                      }}
                    >
                      {minimumStock?.length > 0 && (
                        <>
                          <div className="flex">
                            <div className="text-lg mx-1 text-gray-400">
                              Global:{globalLowStock?.[0]?.settingValue}
                            </div>
                            <div>
                              <Tooltip title="This is organization level low stock threshold that applies to all the products. You can update this from Configuration tab. For any product specific configuration add or update details at facility from this page.">
                                <MdInfoOutline className="text-lg mt-1 font-semibold text-primary cursor-pointer" />
                              </Tooltip>
                            </div>
                          </div>
                          {minimumStock?.map((item: any, index: any) => (
                            <div className="flex flex-row items-center">
                              <div className="w-1/3">
                                <h1 className=" font-medium">
                                  {item?.facilityValue}
                                </h1>
                              </div>
                              <div className="w-1/3">
                                <EditableText
                                  onSave={async (minimumStock: any) => {
                                    setIsLoading(true);
                                    if (
                                      productDetails.productFacilityDetail
                                        .length > 0 &&
                                      productDetails.productFacilityDetail.some(
                                        (id: any) =>
                                          id.facilityId === item?.facilityId
                                      )
                                    ) {
                                      await dispatch(
                                        updateLowStockFacility({
                                          facilityId: item?.facilityId,
                                          productId: item?.productId,
                                          minimumStock: minimumStock,
                                        })
                                      );
                                    }

                                    await dispatch(
                                      getProductDetails({
                                        setProductDetails,
                                        setCurrentProductPriceId,
                                        setListProductPriceId,
                                        id,
                                        setProductStatus,
                                        setProductType,
                                        setListPrice,
                                        setCurrentPrice,
                                        setAvergeCost,
                                        setEccnValue,
                                        setHtsValue,
                                      })
                                    );
                                    setIsLoading(false);
                                  }}
                                  type="miniStock"
                                  inputType="number"
                                  value={item?.minimumStock}
                                  component={
                                    <div className="p-5 bg-white rounded-lg shadow-sm items-center">
                                      <Input
                                        title=""
                                        placeholder=""
                                        inputType="number"
                                        value={item?.minimumStock}
                                      />
                                    </div>
                                  }
                                />
                              </div>
                              <div>
                                <h1
                                  className=""
                                  onClick={() => {
                                    confirm({
                                      title: "Are you sure?",
                                      icon: "",
                                      content:
                                        "Do you want to delete this configuration?",
                                      okText: "Yes",
                                      onOk: async () => {
                                        setIsForceLoading(true);

                                        const selectedItem =
                                          productDetails?.productFacilityDetail.find(
                                            (id: any) =>
                                              item.facilityId === id?.facilityId
                                          );
                                        if (selectedItem) {
                                          // const data = {
                                          //   productId: id,
                                          //   toProductId: selectedItem?.toProductId,
                                          // };
                                          await dispatch(
                                            deletLowStockFacility({
                                              facilityId: item?.facilityId,
                                              productId: item?.productId,
                                              fetchData,
                                            })
                                          );
                                        }
                                        setIsForceLoading(false);
                                      },
                                      okButtonProps: {
                                        style: {
                                          borderRadius: "0.5rem",
                                        },
                                      },
                                      cancelText: "No",
                                      cancelButtonProps: {
                                        style: {
                                          borderRadius: "0.5rem",
                                        },
                                      },
                                      onCancel() {
                                        // console.log("Cancel")
                                      },
                                    });
                                  }}
                                >
                                  <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
                                </h1>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </Loader>
                  </div>
                </div>
              </div>
              <div className="w-full mdlarge:w-1/2 mt-6 mdlarge:mt-0 mdlarge:ml-3">
                {/* <div className="py-5 px-4 shadow-sm rounded-lg bg-white mt-6">
                  <h1 className={mainTitleStyle}>Categories</h1>
                  <div className="flex flex-row cursor-pointer items-center p-3 py-1.5 w-3/5 rounded-md mt-6 mx-8 border border-gray-400">
                    <h1 className="text-sm text-gray-400 ml-2">Category</h1>
                  </div>
                  <div className="flex flex-row items-center w-4/5 flex-wrap mx-8 mt-8 mb-3">
                    {categoryListData &&
                      categoryListData.length > 0 &&
                      categoryListData.map((item: any, index: any) => {
                        if (
                          productDetails &&
                          productDetails.categories &&
                          productDetails.categories.length > 0
                        ) {
                          for (
                            let i = 0;
                            i < productDetails.categories.length;
                            i++
                          ) {
                            if (
                              productDetails.categories[i].productCategoryId ===
                              item.value
                            ) {
                              return (
                                <h1
                                  className="border font-medium border-black mr-2 mb-2 py-1.5 px-4 rounded-md"
                                  key={index}
                                >
                                  {item.label}
                                </h1>
                              );
                            }
                          }
                        }
                        return null;
                      })}
                  </div>
                </div> */}
                <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
                  <div className="flex justify-between">
                    <div>
                      <h1 className={mainTitleStyle}>Product Identification</h1>
                    </div>
                    <div>
                      <Button
                        buttonText="Add"
                        onClick={() => {
                          getProductIdTypes();
                          setIsAddProductModalOpen(true);
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-8 mb-3">
                    <Table
                      rowKey="assocProduct"
                      dataSource={productDetails?.identifications ?? []}
                      columns={productColumns}
                      isPaginated={false}
                    />
                  </div>
                </div>
                <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
                  <div className="flex justify-between items-center">
                    <h1 className={mainTitleStyle}>Image</h1>
                    <p
                      className="ml-1 sm:ml-2 text-xs font-semibold text-primary cursor-pointer"
                      onClick={() => {
                        setIsShowModal(true);
                      }}
                    >
                      {productDetails?.images?.[0]?.contentLocation
                        ? "Update"
                        : "Add"}{" "}
                      Image
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div>
                      <h1 className="font-medium text-xs rounded-lg p-4 pb-8">
                        <Image
                          src={
                            productDetails?.images?.[0]?.contentLocation
                              ? `${process.env.REACT_APP_API_BASE_URL}${
                                  productDetails?.images?.[0].contentLocation
                                }?_=${+new Date()}`
                              : DummyImage
                          }
                        />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="py-5 px-4 shadow-sm rounded-lg bg-white mt-6">
                  <div className="flex justify-between">
                    <h1 className={mainTitleStyle}>Components</h1>
                    {/* <p
                    className="ml-1 sm:ml-2 text-xs font-semibold text-primary cursor-pointer"
                    onClick={() => {
                      setIsShowModal(true);
                    }}
                  >
                    Add Component Products
                  </p> */}
                    <Button
                      buttonText="Add"
                      onClick={() => {
                        setIsAddItemModalOpen(true);
                      }}
                    />
                  </div>

                  <div className="mt-8 mb-3">
                    <Table
                      rowKey="assocProduct"
                      dataSource={productDetails?.assocs ?? []}
                      columns={itemsColumns}
                      isPaginated={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Inventory" key="2">
            <div className=" ">
              <h1 className="font-bold text-lg">History</h1>
            </div>
            <div className="mt-2 mb-2 w-1/6">
              <Dropdown
                onSelect={async (value: any) => {
                  setIsLoading(true);
                  await dispatch(
                    getReviewingInventoryHistory({
                      setAssetHistoryList,
                      productId: id,
                      facilityId: value,
                      setAssetHistoryListMaxPageIndex,
                      setRecordCount,
                      pageIndex: 0,
                    })
                  );
                  setNewFacilityValue(value);
                  setIsLoading(false);
                }}
                title=""
                placeholder="Select Facility"
                data={facilityList}
              />
            </div>
            <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
              <Table
                rowKey="assetHistory"
                dataSource={sortedAssetHistoryList}
                columns={InventoryColumns}
                isPaginated={true}
                onChange={onChange}
                loading={isTableLoading}
                loadNextProds={loadNextProds}
                recordCount={recordCount}
                isServerRendered={recordCount ? true : false}
              />
            </div>
          </TabPane>
          <TabPane tab="Assets" key="3">
            <Modal
              title={
                <div>
                  <span>Receive Direct</span>{" "}
                  <strong className="ml-4">Product Id({id})</strong>
                </div>
              }
              visible={isReceiveDirectModal}
              footer={null}
              width={850}
              onCancel={() => {
                receiveDirectForm?.resetFields();
                setIsReceiveDirectModal(false);
                isLotDisabled(false);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                }}
              >
                <Form
                  initialValues={{
                    productid: id,
                    owner: organizationName,
                  }}
                  form={receiveDirectForm}
                  onFinish={async (values: any) => {
                    setIsLoading(true);
                    await dispatch(
                      receiveDirect({
                        productId: id,
                        facilityId: values?.facility,
                        quantity: values?.quantity,
                        ownerPartyId: localStorage.getItem(
                          "organizationPartyId"
                        ),
                        lotId: values?.lot || values?.lotnumber,
                        locationSeqId: values?.location,
                        statusId: "AstAvailable",
                      })
                    );
                    await dispatch(
                      getProductAssetList({
                        productId: id,
                        setProductAssetList,
                      })
                    );
                    setIsReceiveDirectModal(false);
                    receiveDirectForm?.resetFields();
                    isLotDisabled(false);
                    setIsLoading(false);
                  }}
                >
                  <div className="flex">
                    <Form.Item name="receiveddate" className="flex-1 mr-2">
                      <DatePicker
                        title="Received Date"
                        value={receivedDate ? moment(receivedDate) : ""}
                        // onChange={(date: any, dateString: any) => {
                        //   setMfgDate(dateString)
                        // }}
                        isFullWidth={true}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="quantity"
                      className="flex-1 w-full"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Input title="Quantity" placeholder="" showDot={true} />
                    </Form.Item>
                  </div>
                  <div className="flex justify-between gap-2">
                    <Form.Item
                      name="facility"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Dropdown
                        title="Facility"
                        data={facilityList}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item
                      name="location"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Input title="Location" placeholder="" />
                    </Form.Item>
                    {/* <Form.Item name='owner' className='flex-1'>
                        <Dropdown
                          title='Owner'
                          data={organizationName}
                          placeholder='Select one'
                          width='100%'
                        />
                      </Form.Item> */}
                  </div>
                  {/* <div className='flex'>
                      <Form.Item name='pool' className='flex-1 mr-2'>
                        <Dropdown title='Pool' data={[]} placeholder='Select one' width='100%' />
                      </Form.Item>
                      <Form.Item name='container' className='flex-1 '>
                        <Dropdown
                          title='container'
                          data={container}
                          placeholder='Select one'
                          width='100%'
                        />
                      </Form.Item>
                    </div> */}
                  <div className="flex justify-between">
                    <Form.Item name="manufacturer" className="flex-1 mr-2">
                      <Dropdown
                        title="Manufacturer"
                        data={[]}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item name="acquirecost" className="flex-1">
                      <Input
                        title="Acquire Cost"
                        placeholder=""
                        showDot={true}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item name="lot" className="flex-1 mr-2">
                      <Dropdown
                        title="Lot"
                        data={lotList}
                        placeholder="Select one"
                        width="100%"
                        allowClear
                        onClear={() => isLotDisabled(false)}
                        onSelect={() => {
                          receiveDirectForm?.resetFields([
                            "lotnumber",
                            "mfgdate",
                            "expirationdate",
                          ]);
                          isLotDisabled(true);
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="lotnumber" className="flex-1 ">
                      <Input
                        title="Lot Number"
                        placeholder=""
                        showDot={true}
                        isDisabled={lotDisabled ? true : false}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item name="mfgdate" className="flex-1 mr-2">
                      <DatePicker
                        title="Manufactured"
                        value={manufacturedDate ? moment(manufacturedDate) : ""}
                        // onChange={(date: any, dateString: any) => {
                        //   setMfgDate(dateString)
                        // }}
                        isFullWidth={true}
                        style={{ width: "100%" }}
                        disabled={lotDisabled ? true : false}
                      />
                    </Form.Item>
                    <Form.Item name="expirationdate" className="flex-1">
                      <DatePicker
                        title="Expiration"
                        value={expirationDate ? moment(expirationDate) : ""}
                        // onChange={(date: any, dateString: any) => {
                        //   setMfgDate(dateString)
                        // }}
                        isFullWidth={true}
                        style={{ width: "100%" }}
                        disabled={lotDisabled ? true : false}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <Form.Item name="notes" className="flex-1 w-full">
                      <Input
                        title="Notes"
                        placeholder=""
                        showDot={true}
                        multiline
                      />
                    </Form.Item>
                    <div className="w-full mt-5 flex justify-end">
                      <Button widthFull={true} buttonText="Receive" />
                    </div>
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>
            <Modal
              title={
                <div>
                  <span>Physical Inventory Difference</span>
                  <strong className="ml-4">Product Id({id})</strong>
                </div>
              }
              visible={isPhysicalDiffModal}
              footer={null}
              width={850}
              onCancel={() => {
                physicalQunatity?.resetFields();
                setIsPhysicalDiffModal(false);
              }}
            >
              <Loader
                isLoading={isForceLoading}
                styles={{
                  overlay: (base: any) => ({
                    ...base,
                    backgroundColor: "white",
                  }),
                }}
              >
                <Form
                  initialValues={{ productid: id, status: status }}
                  form={physicalQunatity}
                  onFinish={async (values: any) => {
                    setIsLoading(true);
                    await dispatch(
                      adjustingInventory({
                        productId: id,
                        facilityId: values?.facility,
                        quantity: values?.quantity,
                        locationSeqId: values?.location,
                        lotId: values?.lot,
                        statusId: values?.status,
                        ownerPartyId: values?.ownerparty,
                      })
                    );
                    await dispatch(
                      getProductAssetList({
                        productId: id,
                        setProductAssetList,
                      })
                    );
                    setIsPhysicalDiffModal(false);
                    physicalQunatity?.resetFields();
                    setIsLoading(false);
                  }}
                >
                  <div className="flex justify-between">
                    {/* <Form.Item name='productid' className='flex-1 mr-2'>
                        <Input
                          isDisabled
                          value={id}
                          title='Product Id'
                          placeholder=''
                          showDot={true}
                        />
                      </Form.Item> */}
                    <Form.Item
                      name="facility"
                      className="flex-1 mr-2"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Dropdown
                        isDisabled={true}
                        title="Facility"
                        data={facilityList}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item
                      name="quantity"
                      className="flex-1"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Input title="Quantity" placeholder="" showDot={true} />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item name="location" className="flex-1 mr-2">
                      <Input title="Location" placeholder="" showDot={true} />
                    </Form.Item>
                    <Form.Item hidden name="lot" className="flex-1">
                      <Dropdown
                        title="Lot"
                        data={lotList}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item hidden name="ownerparty" className="flex-1 mr-2">
                      <Dropdown
                        title="Owner Party"
                        data={organizationName}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>

                    <Form.Item hidden name="pool" className="flex-1">
                      <Dropdown
                        title="Pool"
                        data={[]}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item hidden name="status" className="flex-1 mr-2">
                      <Dropdown
                        title="Status"
                        data={status}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                  </div>

                  <div className="flex">
                    <Form.Item name="variancereason" className="flex-1 mr-2">
                      <Dropdown
                        title="Variance Reason"
                        data={varianceReasonList}
                        placeholder="Select one"
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item name="inventorydate" className="flex-1">
                      <DatePicker
                        title="Inventory Date"
                        value={inventoryDate ? moment(inventoryDate) : ""}
                        // onChange={(date: any, dateString: any) => {
                        //   setMfgDate(dateString)
                        // }}
                        isFullWidth={true}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item name="comments" className="">
                    <Input
                      title="Comments"
                      placeholder=""
                      showDot={true}
                      multiline
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button widthFull={true} buttonText="Record" />
                  </Form.Item>
                </Form>
              </Loader>
            </Modal>
            <div className="flex gap-2 mb-2">
              <Button
                onClick={() => setIsReceiveDirectModal(true)}
                buttonText="Receive Direct"
              />
            </div>
            <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
              <Table
                rowKey=""
                dataSource={productAssetList}
                columns={AssetsColoumns}
                onChange={onChangeAssets}
                isPaginated={true}
                isServerRendered={assetrecordCount ? true : false}
                recordCount={assetrecordCount}
                loading={isTableLoading}
                loadNextProds={loadAssetNextProds}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Loader>
  );
}
