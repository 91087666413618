import React, { ReactElement } from "react";

import Footer from "./Footer";

function PageNotFound(): ReactElement {
  return (
    <div className="px-8 pt-12 pb-5 w-full lg:w-7/12 mx-auto rounded-3xl shadow-sm bg-white">
      <div className="w-9/12 mx-auto mb-10">
        <h1 className="text-xl font-medium text-black mb-8 mx-auto text-center">
          404 Not Found
        </h1>
        <h1 className="text-lg text-black mx-auto text-center">
          The page you are looking for does not exist.
        </h1>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
