import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import { AiOutlineFilter } from "react-icons/ai";
import DummyImage from "../images/dummy_img.svg";

import Table from "./Table";
import Input from "./Input";
import Loader from "./Loader";
import { getProductListing, searchProductList } from "../redux/authSlice";
import { Image } from "antd";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg">
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg">
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductListing = () => {
  const [productList, setProductList] = useState<any>([]);
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [currSearchIndex, setCurrSearchIndex] = useState<any>("0");
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [, setCurrProductList] = useState<any>([]);
  const [recordCount, setRecordCount] = useState("");
  const [sortData, setSortData] = useState<any>("");
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [resetInputField, setResetInputField] = useState<any>(false);
  const [isSearch, setIsSearch] = useState<any>("");
  const [searchableString, setSearchableString] = useState<any>([]);
  const [isSearchProdsInFocus, setSearchProdsInFocus] = useState(false);
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [listOfProductDetail, setListOfProductDetail] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (isSearch === "Searched") {
        await dispatch(
          getProductListing({
            setProductList,
            orderByFields: "",
            setMaxPageIndex,
            setRecordCount,
            pageIndex: 0,
            appliedFilters: "",
          })
        );
      } else {
        await dispatch(
          getProductListing({
            setProductList,
            orderByFields: sortData,
            setMaxPageIndex,
            setRecordCount,
            pageIndex: 0,
            appliedFilters: "",
          })
        );
      }
      setIsLoading(false);
    };
    fetchData();
  }, []); // eslint-disable-line
  const fetchMoreData = async () => {
    const nextPageIndex = currSearchIndex * 1 + 1;
    setCurrSearchIndex((prev: any) => prev * 1 + 1);
    const dataObj = {
      setSearchProdsInFocus,
      setListOfProductDetail,
      queryString: searchableString,
      currPage: nextPageIndex,
      setHasMoreProds,
      listOfProductDetail,
    };
    await dispatch(searchProductList(dataObj));
  };
  const nextSetOfProds = async (
    pageIndex: any,
    orderByFields: any,
    appliedFilters: any,
    resetFields?: any
  ) => {
    setIsLoading(true);
    if (resetFields) {
      setResetInputField(true);
    } else {
      setResetInputField(false);
    }
    if (isSearch === "Searched") {
      await dispatch(
        getProductListing({
          setProductList,
          orderByFields,
          setMaxPageIndex,
          setRecordCount,
          pageIndex,
          // queryString:searchableString,
          appliedFilters,
        })
      );
    } else {
      await dispatch(
        getProductListing({
          setProductList,
          orderByFields,
          setMaxPageIndex,
          setRecordCount,
          pageIndex,
          // queryString:searchableString,
          appliedFilters,
        })
      );
    }
    setIsLoading(false);
  };
  const loadNextProds = (index: any) => {
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    let orderByFields = sortData;

    nextSetOfProds(passedIndex, orderByFields, activeAppliedFilters);
  };

  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Image</p>
        </div>
      ),
      dataIndex: "images",
      render: (images: any) => {
        if (images && images.length > 0) {
          return (
            <Image
              src={`${process.env.REACT_APP_API_BASE_URL}${
                images[0]
              }?=${+new Date()}`}
              width={50}
              height={50}
            />
          );
        } else {
          return <Image src={DummyImage} width={50} height={50} />;
        }
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID</p>
        </div>
      ),
      dataIndex: "pseudoId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.pseudoId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.pseudoId || null,
      onFilter: (value: any, record: any) => record?.pseudoId.includes(value),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.pseudoId === "number") {
            return a.pseudoId - b.pseudoId;
          } else {
            return a.pseudoId?.localeCompare(b.pseudoId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.pseudoId === "pseudoId" && sortedInfo?.pseudoIdType,
      render: (pseudoId: string, productDetail: any) => (
        <Link
          to={{
            pathname: `/product/${productDetail?.productId}`,
            state: { productId: productDetail?.productId },
          }}>
          <h1 className="cursor-pointer underline text-primary">{pseudoId}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product Name</p>
        </div>
      ),
      dataIndex: "productName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.productName || null,
      onFilter: (value: any, record: any) =>
        record?.productName.includes(value),
      filterIcon: () => {
        return filteredInfo.productName ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.productName === "string") {
            return a.productName - b.productName;
          } else {
            return a.productName?.localeCompare(b.productName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.productName === "productName" &&
        sortedInfo?.productNameType,
      render: (productName: string) => <h1 className="px-2">{productName}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Type</p>
        </div>
      ),
      dataIndex: "productTypeEnumId",
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }: {
      //   setSelectedKeys: any;
      //   selectedKeys: any;
      //   confirm: any;
      //   clearFilters: any;
      // }) => (
      //   <FilterSearchBar
      //     setSelectedKeys={setSelectedKeys}
      //     selectedKeys={selectedKeys}
      //     confirm={confirm}
      //     clearFilters={clearFilters}
      //   />
      // ),
      // filteredValue: filteredInfo?.productTypeEnumId || null,
      // onFilter: (value: any, record: any) =>
      //   record?.productTypeEnumId.includes(value),
      // filterIcon: () => {
      //   return filteredInfo.productTypeEnumId ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.productTypeEnumId === "string") {
            return a.productTypeEnumId - b.productTypeEnumId;
          } else {
            return a.productTypeEnumId?.localeCompare(b.productTypeEnumId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.productTypeEnumId === "productTypeEnumId" &&
        sortedInfo?.productTypeEnumIdType,
      render: (productTypeEnumId: string, data: any) => (
        <>
          <h1 className="px-2">{data?.productType}</h1>
        </>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "sku",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.sku || null,
      onFilter: (value: any, record: any) => record?.sku.includes(value),
      filterIcon: () => {
        return filteredInfo.sku ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.sku === "number") {
            return a.sku - b.sku;
          } else {
            return a.sku?.localeCompare(b.sku);
          }
        },
        multiple: 1,
      },
      render: (sku: any, productDetail: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productDetail?.productId}`,
              state: { productId: productDetail?.productId },
            }}>
            <h1 className="cursor-pointer underline text-primary">{sku}</h1>
          </Link>
        );
      },
      sortOrder: sortedInfo?.sku === "sku" && sortedInfo?.skuType,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "upca",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.upca || null,
      onFilter: (value: any, record: any) => record?.upca.includes(value),
      filterIcon: () => {
        return filteredInfo.upca ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.upca === "number") {
            return a.upca - b.upca;
          } else {
            return a.upca?.localeCompare(b.upca);
          }
        },
        multiple: 1,
      },
      render: (upc: any, productDetail: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productDetail?.productId}`,
              state: { productId: productDetail?.productId },
            }}>
            <h1 className="cursor-pointer underline text-primary">{upc}</h1>
          </Link>
        );
      },
      sortOrder: sortedInfo?.upca === "upca" && sortedInfo?.upcaType,
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    setFilteredInfo(filters);
    let appliedFilters = "";
    if (filters.pseudoId) {
      if (!appliedFilters) {
        appliedFilters += `pseudoId=${filters.pseudoId}`;
      } else {
        appliedFilters += `&pseudoId=${filters.pseudoId}`;
      }
    }
    if (filters.productName) {
      if (!appliedFilters) {
        appliedFilters += `productName=${filters.productName}`;
      } else {
        appliedFilters += `&productName=${filters.productName}`;
      }
    }
    if (filters.sku) {
      if (!appliedFilters) {
        appliedFilters += `idValue=${filters.sku}`;
      } else {
        appliedFilters += `&idValue=${filters.sku}`;
      }
    }
    if (filters.upca) {
      if (!appliedFilters) {
        appliedFilters += `idValue=${filters.upca}`;
      } else {
        appliedFilters += `&idValue=${filters.upca}`;
      }
    }
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    setCurrProductList(extra?.currentDataSource);
    const passedIndex = currIndex * 1 - 1;
    if (appliedFilters) {
      nextSetOfProds(passedIndex, outputStr, appliedFilters, false);
    } else if (recordCount) {
      nextSetOfProds(passedIndex, outputStr, "", false);
    }
  }
  return (
    <div>
      <div className="h-screen">
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setSearchProdsInFocus(false);
            }}>
            <div className="search-prod w-full ltmob:w-4/6 mdlarge:w-3/5 mdxlarge:w-2/5 mb-8">
              <span className="search-icon">
                <SearchOutlined style={{ fontSize: 17 }} />
              </span>
              <input
                placeholder="Search Products"
                defaultValue={searchableString}
                value={searchableString}
                className="border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light"
                onChange={async (e: any) => {
                  // setIsLoading(true);
                  setIsSearch("Searched");
                  setSearchableString(e.target.value);
                  if (!e.target.value.trim()) {
                    setIsLoading(false);
                    return;
                  }
                  await dispatch(
                    searchProductList({
                      setSearchProdsInFocus,
                      setListOfProductDetail,
                      queryString: e.target.value,
                      currPage: 0,
                      setHasMoreProds,
                    })
                  );
                  // setIsLoading(false);
                }}
              />
              {isSearchProdsInFocus &&
                listOfProductDetail &&
                listOfProductDetail.length > 0 && (
                  <div
                    id="scrollableDiv"
                    className="absolute w-full t-0 l-0 z-10 bg-white shadow-md border mb-5 rounded-md px-4 py-2 max-h-48 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-primary-light scrollbar-thumb-rounded-md">
                    <InfiniteScroll
                      dataLength={listOfProductDetail.length}
                      next={fetchMoreData}
                      hasMore={hasMoreProds}
                      loader={<h1>Loading...</h1>}
                      scrollableTarget="scrollableDiv">
                      {listOfProductDetail.map((item: any) => {
                        const { identifications, productId, name } = item;
                        const sku = identifications?.find(
                          (iden: any) => iden?.productIdTypeEnumId === "PidtSku"
                        );
                        const upc = identifications?.find(
                          (iden: any) =>
                            iden?.productIdTypeEnumId === "PidtUpca"
                        );
                        const idValue =
                          sku?.idValue || upc?.idValue || productId || "";
                        return (
                          <h1
                            key={productId}
                            className="my-2 cursor-pointer hover:text-primary"
                            onClick={() => {
                              history.push({
                                pathname: `/product/${productId}`,
                                state: { name },
                              });
                            }}>
                            {idValue && `${idValue} | `}
                            {name || ""}
                          </h1>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                )}
            </div>
          </OutsideClickHandler>
          <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
            <div className="mb-4 flex flex-row items-center">
              <div>
                <button
                  onClick={async () => {
                    setResetInputField(true);
                    setIsLoading(true);
                    await dispatch(
                      getProductListing({
                        setProductList,
                        orderByFields: sortData,
                        setMaxPageIndex,
                        setRecordCount,
                        pageIndex: 0,
                        appliedFilters: "",
                      })
                    );
                    setActiveAppliedFilters("");
                    setFilteredInfo({});
                    setIsLoading(false);
                  }}
                  className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg">
                  Clear Filter
                </button>
              </div>
              <div className="ml-4">
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await dispatch(
                      getProductListing({
                        setProductList,
                        orderByFields: sortData,
                        setMaxPageIndex,
                        setRecordCount,
                        pageIndex: 0,
                        appliedFilters: "",
                      })
                    );
                    setActiveAppliedFilters("");
                    setFilteredInfo({});
                    setIsLoading(false);
                    setSortedInfo({});
                    setSortData("");
                    setIsLoading(false);
                  }}
                  className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg">
                  Clear Sorter
                </button>
              </div>
            </div>
            <Table
              rowKey="shipMethod"
              dataSource={productList}
              columns={columns}
              isServerRendered={recordCount ? true : false}
              isPaginated={true}
              loadNextProds={loadNextProds}
              recordCount={recordCount}
              onChange={onChange}
              resetInputField={resetInputField}
            />
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductListing;
