/* eslint-disable */
import { useEffect, useState } from "react";
import Table from "../Table";
import Button from "../Button";
import { Modal, Form, Tabs, Radio, RadioChangeEvent, Collapse } from "antd";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import { getPartyList, getOrgnizationPartyId } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ProductStoreParty = (props: any) => {
  const [isNewStorePartyModalOpen, setIsNewStorePartyModalOpen] =
    useState(false);
  const [storePartyForm] = Form.useForm();
  const dispatch = useDispatch();
  const [partyList, setPartyList] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getPartyList({
        productStoreId: props.id,
        setPartyList,
      })
    );
    await dispatch(
      getOrgnizationPartyId({
        setOwnerPartyIdList,
      })
    );
    setisLoading(false);
  };

  console.log("ownerPartyIdList", ownerPartyIdList);

  const PartyColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Party</p>
        </div>
      ),
      dataIndex: "partyId",
      render: (partyId: any) => {
        return (
          <div>
            {ownerPartyIdList.find((item: any) => item.key === partyId)?.value}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Role Type
        </div>
      ),
      dataIndex: "roleTypeId",
      render: (roleTypeId: any) => {
        return <div>{roleTypeId}</div>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          From Date
        </div>
      ),
      dataIndex: "fromDate",
      render: (fromDate: any) => {
        return (
          <div>{fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Thru Date
        </div>
      ),
      dataIndex: "thruDate",
      render: (thruDate: any) => {
        return (
          <div>{thruDate ? moment(thruDate)?.format("YYYY-MM-DD") : ""}</div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Sequence Num
        </div>
      ),
      dataIndex: "sequenceNum",
      render: (sequenceNum: any) => {
        return (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <h1 className="px-4">{sequenceNum}</h1>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Modal
          visible={isNewStorePartyModalOpen}
          title="New Store Party"
          onCancel={() => {
            setIsNewStorePartyModalOpen(false);
          }}
          footer={null}>
          <Form form={storePartyForm} onFinish={(values: any) => {}}>
            <Form.Item name="party" className="flex-1 w-full">
              <Dropdown title="Party" data={[]} placeholder="" width="100%" />
            </Form.Item>

            <Form.Item name="roletype" className="flex-1 w-full">
              <Dropdown
                title="Role Type"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>
            <Form.Item name="fromdate">
              <DatePicker title="From Date" />
            </Form.Item>
            <Form.Item name="create" className="flex-1 w-full">
              <Button
                onClick={() => {
                  setIsNewStorePartyModalOpen(false);
                }}
                widthFull
                buttonText="Create"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {/* <div>
        <Button
          onClick={() => {
            setIsNewStorePartyModalOpen(true);
          }}
          classNames="mb-2"
          buttonText="New Store Party"
        />
      </div> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          rowKey=""
          dataSource={partyList}
          columns={PartyColoumns}
          isPaginated={false}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ProductStoreParty;
