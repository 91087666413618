/* eslint-disable */
import { useEffect, useState } from "react";
import Table from "./Table";
import Input from "./Input";
import Button from "./Button";
import { Modal, Form, Tabs, Radio, Collapse } from "antd";
import Dropdown from "./Dropdown";
import ProductStoreEmail from "./ProductStore/Email";
import ProductStoreCatagory from "./ProductStore/Catagory";
import ProductStoreParty from "./ProductStore/Party";
import ProductStoreFacility from "./ProductStore/Facility";
import ProductStoreSettings from "./ProductStore/Settings";
import ProductStoreInsuranceSettings from "./ProductStore/InsuranceSettings";
import ProductStoreReturnSettings from "./ProductStore/ReturnSettings";
import ProductStoreInsert from "./ProductStore/Insert";
import ShippingRule from "./ProductStore/ShippingRule";
import PackingSlipConfiguration from "./ProductStore/PackingSlipConfiguration";
import {
  getEnumTypeList,
  getProductStoreDetailList,
  getDefaultLocale,
  getDefaultCurrency,
  getGeoCountries,
  getProductStoreCatagories,
  getFacilityList,
} from "../redux/authSlice";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
import ChannelIntegration from "./ProductStore/ChannelIntegration";
import SignatureSettings from "./ProductStore/SignatureSettings";
const ProductStoreDetail = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const [storeForm] = Form.useForm();
  const [productStoreUpdateForm] = Form.useForm();
  const [isNewStoreModalOpen, setIsNewStoreModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Product Store");
  const { TabPane } = Tabs;
  const [value, setValue] = useState(1);
  const { Panel } = Collapse;
  const [isProductStoreUpdateModal, setIsProductStoreUpdateModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assetReservationAuto, setAssetReservationAuto] = useState<any>([]);
  const [assetReservationOrder, setAssetReservationOrder] = useState<any>([]);
  const [productstoreDetailList, setProductstoreDetailList] = useState<any>([]);
  const [defaultLocaleList, setDefaultLocaleList] = useState<any>([]);
  const [defaultCurrencyList, setDefaultCurrencyList] = useState<any>([]);
  const [defaultOrderSourceList, setDefaultOrderSourceList] = useState<any>([]);
  const [geoCountries, setGeoCountries] = useState<any>([]);
  const [productStoreCatagoriesList, setProductStoreCatagoriesList] =
    useState<any>([]);
  const [locale, setLocale] = useState<any>();
  const [selectedFacility, setSelectedFacility] = useState("");
  const [facilityList, setFacilityList] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getEnumTypeList({
        setEnumType: setAssetReservationAuto,
        enumTypeId: "AssetReservationAuto",
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setAssetReservationOrder,
        enumTypeId: "AssetReservationOrder",
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setDefaultOrderSourceList,
        enumTypeId: "SalesChannel",
      })
    );
    await dispatch(
      getDefaultLocale({
        setDefaultLocaleList,
      })
    );
    await dispatch(
      getProductStoreDetailList({
        productStoreId: id,
        setProductstoreDetailList,
        defaultLocaleList,
      })
    );

    await dispatch(
      getDefaultCurrency({
        setDefaultCurrencyList,
        uomTypeEnumId: "UT_CURRENCY_MEASURE",
      })
    );

    await dispatch(
      getGeoCountries({
        setGeoCountries,
      })
    );

    await dispatch(
      getProductStoreCatagories({
        productStoreId: id,
        setProductStoreCatagoriesList,
      })
    );

    await dispatch(
      getFacilityList({
        setFacilityList,
        setSelectedFacility,
        fromInventory: true,
      })
    );
    setIsLoading(false);
  };

  console.log("defaultCurrencyList", defaultCurrencyList);
  console.log("productstoreDetailList", productstoreDetailList);
  console.log("assetReservationAuto", assetReservationAuto);

  // const mappedArray: any = defaultLocaleList?.find(
  //   (item: any) =>
  //     item?.key === productstoreDetailList?.productStore?.defaultLocale
  // );
  // // console.log("mappedArray", mappedArray.value);

  //  useEffect(()=>{
  //   if (mappedArray) {
  //     console.log("hello");
  //     setLocale(mappedArray);
  //   }
  //  },[])
  const defaultLocalVal = () => {
    const mappedArray: any = defaultLocaleList?.find(
      (item: any) =>
        item?.key === productstoreDetailList?.productStore?.defaultLocale
    );
    return mappedArray?.value;
  };

  const defaultCurrencyVal = () => {
    const mappedArray: any = defaultCurrencyList?.find(
      (item: any) =>
        item?.key === productstoreDetailList?.productStore?.defaultCurrencyUomId
    );
    return mappedArray?.value;
  };

  const defaultOrderSourceVal = () => {
    const mappedArray: any = defaultOrderSourceList?.find(
      (item: any) =>
        item?.key ===
        productstoreDetailList?.productStore?.defaultSalesChannelEnumId
    );
    return mappedArray?.value;
  };

  const autoReservation = () => {
    const mappedArray: any = assetReservationAuto?.find(
      (item: any) =>
        item?.key ===
        productstoreDetailList?.productStore?.reservationAutoEnumId
    );
    return mappedArray?.value;
  };

  const ReservationOrder = () => {
    const mappedArray: any = assetReservationOrder?.find(
      (item: any) =>
        item?.key ===
        productstoreDetailList?.productStore?.reservationOrderEnumId
    );
    return mappedArray?.value;
  };
  // console.log("enumtype", enumType);
  const productColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Id</p>
        </div>
      ),
      dataindex: "",
      render: () => {
        return (
          <div>
            <h1>FP_SHOPIFY_APP_TEST_STORE</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">Name</div>
      ),
      dataindex: "",
      render: () => {
        return (
          <div>
            <h1>FP SHOPIFY APP TEST STORE</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Organization
        </div>
      ),
      dataindex: "",
      render: () => {
        return (
          <div>
            <h1>FP: Fulfillment Plus</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Organization Priority
        </div>
      ),
      dataindex: "",
      render: () => {
        return (
          <div>
            <Input value={""} placeholder="" title="" />
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3"></div>
      ),
      dataindex: "",
      render: () => {
        return (
          <div>
            <Button buttonText="Update" />
          </div>
        );
      },
    },
  ];

  const shippingOptions = [
    {
      title: (
        <div>
          <h1>Shipping Method</h1>
        </div>
      ),
      dataIndex: "description",
      render: (description: any) => {
        return (
          <div>
            <h1>{description}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Billing Country </h1>
          <h1>Billing Account</h1>
        </div>
      ),
      dataIndex: "billingCountry",
      render: (billingCountry: any, data: any) => {
        return (
          <div className="w-30">
            <h1>{billingCountry}</h1>
            <h1>{data?.billingAccount}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Billing Zip </h1>
          <h1>Billing Type</h1>
        </div>
      ),
      dataIndex: "billingZip",
      render: (billingZip: any, data: any) => {
        return (
          <div className="w-24">
            <h1>{billingZip}</h1>
            <h1>{data?.billingType}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Sequence </h1>
          <h1>Markup %</h1>
        </div>
      ),
      dataIndex: "sequenceNum",
      render: (sequenceNum: any, data: any) => {
        return (
          <div className="w-24">
            <h1>{sequenceNum}</h1>
            <h1>{data?.markupPercent}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Markup Amt</h1>
        </div>
      ),
      dataIndex: "markupAmount",
      render: (markupAmount: any) => {
        return <div className="w-24">{markupAmount}</div>;
      },
    },
  ];
  const shippingGateways = [
    {
      title: (
        <div>
          <h1>Carrier</h1>
          <h1>Shipment Gateway</h1>
        </div>
      ),
      dataIndex: "carrierPartyId",
      render: (carrier: any, data: any) => {
        return (
          <div>
            <h1>{carrier}</h1>
            <h1>{data.shippingGateway}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Billing Type </h1>
          <h1>Billing Account</h1>
          <h1>Billing Country</h1>
          <h1>Billing Zip</h1>
        </div>
      ),
      dataIndex: "billingType",
      render: (billingType: any, data: any) => {
        return (
          <div className="w-30">
            <h1>{billingType}</h1>
            <h1>{data?.billingAccount}</h1>
            <h1>{data?.billingCountry}</h1>
            <h1>{data?.billingZip}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Customs Billing Type </h1>
          <h1>Customs Billing Account</h1>
          <h1>Customs Billing Country</h1>
          <h1>Customs Billing Zip</h1>
        </div>
      ),
      dataIndex: "customsBillingType",
      render: (customsBillingType: any, data: any) => {
        return (
          <div className="w-24">
            <h1>{customsBillingType}</h1>
            <h1>{data?.customsBillingAccount}</h1>
            <h1>{data?.customsBillingCountry}</h1>
            <h1>{data?.customsBillingZip}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Ins. Percent</h1>
        </div>
      ),
      dataIndex: "insurancePercent",
      render: (insPercent: any) => {
        return (
          <div className="w-24">
            <h1>{insPercent}</h1>
          </div>
        );
      },
    },
  ];
  const paymentGateways = [
    {
      title: (
        <div>
          <h1>Instrument</h1>
        </div>
      ),
      dataIndex: "instrument",
      render: (instrument: any) => {
        return (
          <div>
            <h1>{instrument}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Gateway Config </h1>
        </div>
      ),
      dataIndex: "gatewayConfig",
      render: (gatewayConfig: any) => {
        return (
          <div className="w-30">
            <h1>{gatewayConfig}</h1>
          </div>
        );
      },
    },
  ];

  console.log(
    "productstoreDetailList?.shippingGatewayList",
    productstoreDetailList?.productStoreShippingGatewayList
  );
  return (
    <>
      {isLoading ? (
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}>
          <div className="w-96 h-screen"></div>
        </Loader>
      ) : (
        <Tabs
          type="card"
          centered={false}
          defaultActiveKey={selectedTab}
          onChange={(activeKey: any) => {
            setSelectedTab(activeKey);
          }}>
          <TabPane tab="Product Store">
            <div className="flex justify-between gap-2">
              <Modal
                title="Update product details"
                visible={isProductStoreUpdateModal}
                onCancel={() => {
                  setIsProductStoreUpdateModal(false);
                }}
                footer={null}
                width={700}>
                <Form
                  form={productStoreUpdateForm}
                  onFinish={(values) => {
                    setIsProductStoreUpdateModal(false);
                  }}>
                  <div className="flex gap-2">
                    <Form.Item name="name" className="w-full">
                      <Input value="" title="Name" placeholder="" />
                    </Form.Item>
                    <Form.Item name="organization" className="w-full">
                      <Dropdown title="Organization" data={[]} placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item name="facility" className="w-full">
                      <Dropdown title="Facility" data={[]} placeholder="" />
                    </Form.Item>
                    <Form.Item name="autoreservation" className="w-full">
                      <Dropdown
                        title="Auto Reservation"
                        data={assetReservationAuto}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <Form.Item name="reservationorder">
                    <Dropdown
                      title="Reservation Order"
                      data={assetReservationOrder}
                      placeholder=""
                    />
                  </Form.Item>
                  <div className="flex">
                    <Form.Item name="enableimport" className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">Enable Import</h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                    <Form.Item name="RequireInventory" className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">Require Inventory</h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                  </div>
                  <div className="flex">
                    <Form.Item
                      name="DefaultDisablePromotions"
                      className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">
                          Default Disable Promotions
                        </h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                    <Form.Item
                      name="DefaultDisableShippingCalc"
                      className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">
                          Default Disable Shipping Calc
                        </h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                  </div>
                  <div className="flex">
                    <Form.Item name="DefaultDisableTaxCalc" className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">Default Disable Tax Calc</h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                    <Form.Item
                      name="MarkupOrderShippingPrice"
                      className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">
                          Markup Order Shipping Price
                        </h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                  </div>
                  <div className="flex">
                    <Form.Item name="enableimport" className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">
                          Markup Shipment Ship Labels
                        </h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>{" "}
                    <Form.Item name="AnyCarrierMethod?" className="w-full">
                      <div className="flex">
                        <h1 className="font-bold">Any Carrier Method?</h1>
                        <Radio.Group className="ml-2">
                          <Radio value={1}>Y</Radio>
                          <Radio value={2}>N</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item name="insurancethreshold" className="w-full">
                      <Input
                        title="Insurance Threshold"
                        placeholder=""
                        value=""
                      />
                    </Form.Item>
                    <Form.Item name="autoapprovedelay" className="w-full">
                      <Input
                        title="Auto Approve Delay"
                        placeholder=""
                        value=""
                      />
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item name="storedomain" className="w-full">
                      <Input title="Store Domain" placeholder="" value="" />
                    </Form.Item>
                    <Form.Item name="profileurlpath" className="w-full">
                      <Input title="Profile Url Path" placeholder="" value="" />
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item name="wikispaceid" className="w-full">
                      <Dropdown
                        data={[]}
                        title="Wiki Space ID"
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item name="defaultlocale" className="w-full">
                      <Dropdown
                        title="Default Locale"
                        data={defaultLocaleList}
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item name="defaultcurrency" className=" w-full">
                      <Dropdown
                        title="Default Currency"
                        data={defaultCurrencyList}
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item name="defaultordersource" className=" w-full">
                      <Dropdown
                        title="Default Order Source"
                        data={defaultOrderSourceList}
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex gap-2">
                    <Form.Item
                      name="requirecustomerrole"
                      className="flex-1 w-full">
                      <Dropdown
                        title="Require Customer Role"
                        data={[]}
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item name="taxgateway" className="flex-1 w-full">
                      <Dropdown
                        title="Tax Gateway"
                        data={[]}
                        placeholder=""
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item name="remote" className="flex-1 w-full">
                    <Dropdown
                      title="Remote"
                      data={[]}
                      placeholder=""
                      width="100%"
                    />
                  </Form.Item>
                  <Form.Item name="update" className="flex-1 w-full">
                    <Button buttonText="update" widthFull />
                  </Form.Item>
                </Form>
              </Modal>
              <div className="bg-white p-5 rounded-lg shadow-sm w-full h-fit">
                {/* <div className="flex gap-4 justify-end mb-4">
                  <Button buttonText="Update" />
                </div> */}

                <div className="flex flex-row gap-5 justify-between">
                  <div className="flex flex-row gap-1 mt-5">
                    <div className="text-sm font-bold w-24">Name</div>
                    <div>{productstoreDetailList?.productStore?.storeName}</div>
                  </div>
                  {/* <div>
                    <Button
                      buttonText="Update"
                      onClick={() => {
                        setIsProductStoreUpdateModal(true);
                      }}
                    />
                  </div> */}
                </div>

                <div className="flex flex-row gap-1 mt-5">
                  <div className="text-sm font-bold w-24">Product Store Id</div>
                  <div>{id}</div>
                </div>

                <div className="mt-4 flex flex-row gap-1">
                  <div className="text-sm font-bold w-24">Facility</div>
                  <div>
                    {productstoreDetailList?.productStore?.inventoryFacilityId}
                  </div>
                </div>

                <div className="mt-4 flex flex-row gap-1">
                  <div className="text-sm font-bold w-24">Owner Org</div>
                  <div>
                    {productstoreDetailList?.productStore?.organizationPartyId}
                  </div>
                </div>

                <div className="mt-4 flex flex-row gap-1">
                  <div className="text-sm font-bold w-24">Store</div>
                  <div>FP_DEFAULT: FulfillmentPlus Default Store</div>
                </div>

                <div className="mt-4 flex gap-1">
                  <div className="text-sm font-bold w-24">Default Locale</div>
                  <div className="w-1/2">
                    <h1>{defaultLocalVal()}</h1>
                  </div>
                </div>

                <div className="mt-4 flex gap-1">
                  <div className="text-sm font-bold w-24">Default Currency</div>
                  <div className="w-1/2">
                    <h1>{defaultCurrencyVal()}</h1>
                  </div>
                </div>

                <div className="mt-4 flex gap-1">
                  <div className="text-sm font-bold w-24">
                    Default Order Source List
                  </div>
                  <div className="w-1/2">
                    <h1>{defaultOrderSourceVal()}</h1>
                  </div>
                </div>

                <div className="mt-4 flex gap-1">
                  <div className="text-sm font-bold w-24">Auto Reservation</div>
                  <div className="w-1/2">
                    <h1>{autoReservation()}</h1>
                  </div>
                </div>

                <div className="mt-4 flex gap-1">
                  <div className="text-sm font-bold w-24">
                    Reservation Order
                  </div>
                  <div className="w-1/2">
                    <h1>{ReservationOrder()}</h1>
                  </div>
                </div>
              </div>
              <div className="w-screen">
                <Collapse
                  className="bg-white p-2 rounded-lg shadow-sm"
                  defaultActiveKey={["1"]}
                  // onChange={onChange}
                  expandIconPosition="left">
                  <Panel
                    header={
                      <span className="text-base font-bold">
                        Shipping options
                      </span>
                    }
                    key="1">
                    <Table
                      rowKey=""
                      dataSource={productstoreDetailList?.shipOptionsList}
                      columns={shippingOptions}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse>
                <Collapse
                  className="bg-white p-2 rounded-lg shadow-sm mt-2"
                  defaultActiveKey={["1"]}
                  // onChange={onChange}
                  expandIconPosition="left">
                  <Panel
                    header={
                      <span className="text-base font-bold">
                        Shipping Gateways
                      </span>
                    }
                    key="2">
                    <Table
                      rowKey=""
                      dataSource={
                        productstoreDetailList?.productStoreShippingGatewayList
                      }
                      columns={shippingGateways}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse>
                {/* <Collapse
                  className="bg-white p-2 rounded-lg shadow-sm mt-2"
                  defaultActiveKey={["1"]}
                  // onChange={onChange}
                  expandIconPosition="left">
                  <Panel
                    header={
                      <span className="text-base font-bold">
                        Payment Gateways
                      </span>
                    }
                    key="3">
                    <Table
                      rowKey=""
                      dataSource={productstoreDetailList?.paymentGatewayList}
                      columns={paymentGateways}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse> */}
              </div>
            </div>
          </TabPane>
          {/* <TabPane tab="Promotions" key="2">
            <div>
              <Modal
                visible={isNewStoreModalOpen}
                title="New Store"
                onCancel={() => {
                  setIsNewStoreModalOpen(false);
                }}
                footer={null}>
                <Form
                  form={storeForm}
                  onFinish={(values: any) => {
                    setIsNewStoreModalOpen(false);
                  }}>
                  <Form.Item name="productid" className="flex-1 w-full">
                    <Input title="ID" value={[]} placeholder="" />
                  </Form.Item>
                  <Form.Item name="name" className="flex-1 w-full">
                    <Input title="Name" value={[]} placeholder="" />
                  </Form.Item>
                  <Form.Item name="organizationparty" className="flex-1 w-full">
                    <Dropdown
                      title="Organization Party"
                      data={[]}
                      placeholder=""
                      width="100%"
                    />
                  </Form.Item>
                  <Form.Item name="create" className="flex-1 w-full">
                    <Button widthFull buttonText="Create" />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsNewStoreModalOpen(true);
                }}
                classNames="mb-2"
                buttonText="New Store"
              />
            </div>
            <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
              <Table
                rowKey=""
                dataSource={[{}]}
                columns={productColoumns}
                isPaginated={false}
              />
            </div>
          </TabPane> */}
          {/* <TabPane tab="Email" key="3">
            <ProductStoreEmail />
          </TabPane> */}
          <TabPane tab="Catagories" key="4">
            <ProductStoreCatagory id={id} />
          </TabPane>
          <TabPane tab="Parties" key="5">
            <ProductStoreParty id={id} />
          </TabPane>
          <TabPane tab="Facilities" key="6">
            <ProductStoreFacility id={{ id, selectedFacility }} />
          </TabPane>
          <TabPane tab="Settings" key="7">
            <ProductStoreSettings id={id} />
          </TabPane>
          <TabPane disabled tab="Packing Slip Configuration" key="8">
            <PackingSlipConfiguration />
          </TabPane>
          {/* <TabPane tab="Insurance Settings" key="9">
            <ProductStoreInsuranceSettings />
          </TabPane> */}
          <TabPane tab="Return Settings" key="10">
            <ProductStoreReturnSettings id={id} />
          </TabPane>
          {/* <TabPane tab="Product Store Insert" key="11">
            <ProductStoreInsert />
          </TabPane> */}
          <TabPane tab="Shipping Rule" key="12">
            <ShippingRule id={id} />
          </TabPane>
          <TabPane tab="Channel Integration" key="13">
            <ChannelIntegration id={id} />
          </TabPane>
          <TabPane tab="Product Store Signature Settings" key="14">
            <SignatureSettings id={id} />
          </TabPane>
        </Tabs>
      )}
    </>
  );
};

export default ProductStoreDetail;
