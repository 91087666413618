import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @ts-ignore
import moment from "moment";

import Table from "./Table";
import Loader from "./Loader";
import {
  getListOfPicklist,
  getOrgnizationPartyId,
  getStatusList,
  userSelector,
} from "../redux/authSlice";
import Input from "./Input";
import { AiOutlineFilter } from "react-icons/ai";
const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const PicklistListing = () => {
  const dispatch = useDispatch();
  const { activeOrgStores } = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [picklistData, setPicklistData] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [, setCurrProductList] = useState<any>([]);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [sortData, setSortData] = useState<any>("");
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [currIndex, setCurrIndex] = useState<any>("1");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getListOfPicklist({
          ownerPartyId: localStorage.getItem("organizationPartyId"),
          setPicklistData,
          pageIndex: 0,
          setMaxPageIndex,
          setRecordCount,
          appliedFilters: "",
        })
      );

      await dispatch(
        getStatusList({ setStatus: setStatusList, statusTypeId: "WorkEffort" })
      );
      setIsLoading(false);
    };
    fetchData();
  }, []);
  const nextSetOfProds = async (
    pageIndex: any,
    orderByFields?: any,
    appliedFilters?: any,
    resetFields?: any
  ) => {
    setIsLoading(true);
    await dispatch(
      getListOfPicklist({
        ownerPartyId: localStorage.getItem("organizationPartyId"),
        setPicklistData,
        pageIndex,
        orderByFields,
        setMaxPageIndex,
        setRecordCount,
        // queryString:searchableString,
        appliedFilters,
      })
    );
    //  }
    setIsLoading(false);
  };
  const loadNextProds = (index: any) => {
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    let orderByFields = sortData;

    nextSetOfProds(passedIndex, orderByFields, activeAppliedFilters);
  };

  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.workEffortId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.workEffortId || null,
      onFilter: (value: any, record: any) =>
        record?.workEffortId?.includes(value),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.workEffortId === "number") {
            return a.workEffortId - b.workEffortId;
          } else {
            return a.workEffortId?.localeCompare(b.workEffortId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.workEffortId === "workEffortId" &&
        sortedInfo?.workEffortIdType,
      render: (workEffortId: string) => (
        <Link
          to={{
            pathname: `/picklist/${workEffortId}`,
            state: { workEffortId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            #{workEffortId}
          </h1>
        </Link>
      ),
      dataIndex: "workEffortId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Start Date</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.estimatedStartDate === "number") {
            return a.estimatedStartDate - b.estimatedStartDate;
          } else {
            return a.estimatedStartDate?.localeCompare(b.estimatedStartDate);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.estimatedStartDate === "estimatedStartDate" &&
        sortedInfo?.estimatedStartDateType,
      render: (estimatedStartDate: string) => (
        <h1 className="px-2">
          {moment(estimatedStartDate)?.format("YYYY-MM-DD HH:MM")}
        </h1>
      ),
      dataIndex: "estimatedStartDate",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.statusId === "string") {
            return a.statusId - b.statusId;
          } else {
            return a.statusId?.localeCompare(b.statusId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
      dataIndex: "statusId",
      render: (statusId: string) => (
        <h1 className="px-2">
          {statusList.find((item: any) => item.key === statusId)?.value}
        </h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Facility</p>
        </div>
      ),
      dataIndex: "facilityName",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.facilityName === "string") {
            return a.facilityName - b.facilityName;
          } else {
            return a.facilityName?.localeCompare(b.facilityName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.facilityName === "facilityName" &&
        sortedInfo?.facilityNameType,
      render: (facilityName: string, data: any) => (
        <Link to={{}}>
          <h1 className="cursor-pointer underline text-primary">
            {facilityName ?? ""}
          </h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Store</p>
        </div>
      ),
      dataIndex: "productStoreId",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.productStoreId === "string") {
            return a.productStoreId - b.productStoreId;
          } else {
            return a.productStoreId?.localeCompare(b.productStoreId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.productStoreId === "productStoreId" &&
        sortedInfo?.productStoreIdType,
      render: (productStoreId: string, data: any) => {
        return (
          <h1 className="px-2">{`${productStoreId ?? ""}${
            data?.storeName ? `: ${data?.storeName}` : ""
          }`}</h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Owner Org</p>
        </div>
      ),
      dataIndex: "organizationName",
      render: (organizationName: string, data: any) => (
        <h1 className="px-2">{`${
          organizationName ? `${organizationName}: ` : ""
        }${data?.ownerPartyId ?? ""}`}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">People</p>
        </div>
      ),

      dataIndex: "partyId",
      render: (partyId: any, data: any) => (
        <div className="px-2">
          {Array.isArray(partyId) ? (
            <div className="px-2">
              {partyId?.map((person: any, index: any) => {
                const first = data?.firstName[index];
                const last = data?.lastName[index];
                return `${person}:${first} ${last ?? ""}`;
              })}
            </div>
          ) : null}
          {/* {partyId} */}
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Equipment</p>
        </div>
      ),
      dataIndex: "assetId",
      render: (assetId: string) => <h1 className="px-2">{assetId}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Shipments</p>
        </div>
      ),
      dataIndex: "shipmentId",
      render: (shipmentId: any, data: any) => (
        <div className="px-2">
          {shipmentId.map((shipmentId: any, index: any) => (
            <Link
              to={{
                pathname: `/inventory/${shipmentId}`,
                state: { shipmentId, entryDate: data?.startDate },
              }}
            >
              <h1 key={index} className="cursor-pointer underline text-primary">
                {shipmentId}
              </h1>
            </Link>
          ))}
        </div>
      ),
    },
  ];

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    setFilteredInfo(filters);
    let appliedFilters = "";
    if (filters.workEffortId) {
      if (!appliedFilters) {
        appliedFilters += `workEffortId=${filters.workEffortId}`;
      } else {
        appliedFilters += `&workEffortId=${filters.workEffortId}`;
      }
    }
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    console.log("outputStr", outputStr);
    // const passedIndex = currIndex * 1 - 1
    if (appliedFilters) {
      nextSetOfProds(0, outputStr, appliedFilters, true);
    } else if (recordCount) {
      nextSetOfProds(0, outputStr, "", true);
    }
  }

  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <div className="flex flex-row justify-between">
            <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              <div className="flex flex-row items-center">
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await dispatch(
                      getListOfPicklist({
                        ownerPartyId: localStorage.getItem(
                          "organizationPartyId"
                        ),
                        setPicklistData,
                        pageIndex: 0,
                        orderByFields: "",
                        setMaxPageIndex,
                        setRecordCount,
                        // queryString:searchableString,
                        appliedFilters: "",
                      })
                    );
                    localStorage.removeItem("prevAppliedFilter");
                    setActiveAppliedFilters("");
                    setFilteredInfo({});
                    setIsLoading(false);
                  }}
                  className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Filter
                </button>
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await dispatch(
                      getListOfPicklist({
                        ownerPartyId: localStorage.getItem(
                          "organizationPartyId"
                        ),
                        setPicklistData,
                        pageIndex: currIndex * 1 - 1,
                        orderByFields: "",
                        setMaxPageIndex,
                        setRecordCount,
                        // queryString:searchableString,
                        appliedFilters: activeAppliedFilters,
                      })
                    );
                    localStorage.removeItem("prevAppliedFilter");
                    setSortedInfo({});
                    setSortData("");
                    setIsLoading(false);
                  }}
                  className="ml-4 mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Sorter
                </button>
              </div>
            </div>
          </div>
          <Table
            rowKey="picklist"
            dataSource={picklistData}
            columns={columns}
            isServerRendered={recordCount ? true : false}
            isPaginated={true}
            onChange={onChange}
            loadNextProds={loadNextProds}
            recordCount={recordCount}
          />
        </div>
      </Loader>
    </div>
  );
};

export default PicklistListing;
