import React, { useEffect, useState } from "react";
import Table from "./Table";
import { Link } from "react-router-dom";
import {
  getReturnListing,
  getStatusList,
  getOrganizationList,
  getAllFacilities,
} from "../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ReturnListing = () => {
  const [returnList, setReturnList] = useState<any>([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<any>([]);
  const [organizationName, setOrganizationName] = useState<any>([]);
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getReturnListing({
        setReturnList,
      })
    );
    await dispatch(
      getStatusList({
        statusTypeId: "Return",
        setStatus,
      })
    );
    await dispatch(
      getOrganizationList({
        setOrganizationName,
      })
    );
    await dispatch(
      getAllFacilities({
        setFacilitiesList,
      })
    );
    setIsLoading(false);
  };
  const returnListing = [
    {
      title: "Return Id",
      dataIndex: "returnId",
      render: (id: any) => {
        return (
          <div>
            <Link to={{ pathname: `/return/${id}`, state: { id } }}>
              <h1 className="cursor-pointer underline text-primary">{id}</h1>
            </Link>
          </div>
        );
      },
    },
    {
      title: "External Id",
      dataIndex: "",
      render: () => {
        return (
          <div>
            <h1></h1>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "statusId",
      render: (statusId: any) => {
        return (
          <div>
            <h1>
              {status?.find((item: any) => item?.key === statusId)?.value}
            </h1>
          </div>
        );
      },
    },
    {
      title: "Entry Date",
      dataIndex: "entryDate",
      render: (entryDate: any) => {
        return (
          <div>
            <h1>
              {entryDate ? moment(entryDate)?.format("YYYY-MM-DD hh:mm") : ""}
            </h1>
          </div>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendorPartyId",
      render: (vendorPartyId: any) => {
        return (
          <div>
            <h1>
              {vendorPartyId}:{" "}
              {
                organizationName?.find(
                  (item: any) => item?.key === vendorPartyId
                )?.value
              }
            </h1>
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customerPartyId",
      render: (customerPartyId: any, data: any) => {
        return (
          <div>
            <h1>
              {customerPartyId}: {data?.firstName} {data?.lastName}
            </h1>
          </div>
        );
      },
    },
    {
      title: "Warehouse",
      dataIndex: "facilityId",
      render: (facilityId: any) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/facility/${facilityId}`,
                state: "",
              }}
            >
              <h1 className="cursor-pointer underline text-primary">
                {facilityId}:{" "}
                {
                  facilitiesList?.find(
                    (item: any) => item?.facilityId === facilityId
                  )?.facilityName
                }
              </h1>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Order ID's",
      dataIndex: "orderId",
      render: (orderId: any) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/order/${orderId}`,
                state: { orderId },
              }}
            >
              <h1 className="cursor-pointer underline text-primary">
                {orderId}
              </h1>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
      <Table
        dataSource={returnList}
        isPaginated={true}
        rowKey="returnListing"
        columns={returnListing}
        loading={isLoading}
      />
    </div>
  );
};

export default ReturnListing;
