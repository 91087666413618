/* eslint-disable */
import Table from "../Table";
import {
  getChannelIntegrationList,
  getEnumTypeList,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
const ChannelIntegration = (props: any) => {
  const dispatch = useDispatch();
  const [channelIntegrationList, setChannelIntegrationList] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getChannelIntegrationList({
        productStoreId: props?.id,
        setChannelIntegrationList,
      })
    );
    await dispatch(
      getEnumTypeList({
        enumTypeId: "IntegrationType",
        setEnumType,
      })
    );
    setIsLoading(false);
  };
  const channelIntegration = [
    {
      title: <div>Integration Type</div>,
      dataIndex: "integrationTypeEnumId",
      render: (integrationTypeEnumId: any) => {
        return (
          <div>
            <h1>
              {
                enumType?.find(
                  (item: any) => item?.key === integrationTypeEnumId
                )?.value
              }
            </h1>
          </div>
        );
      },
    },
    {
      title: <div>Product Store</div>,
      dataIndex: "productStoreId",
      render: (productStoreId: any) => {
        return (
          <div>
            <h1>{productStoreId}</h1>
          </div>
        );
      },
    },
    {
      title: <div>Enabled</div>,
      dataIndex: "enabled",
      render: (enabled: any) => {
        return (
          <div>
            <h1>{enabled}</h1>
          </div>
        );
      },
    },
  ];
  return (
    <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
      <Table
        dataSource={channelIntegrationList}
        rowKey=""
        columns={channelIntegration}
        isPaginated={false}
        loading={isLoading}
      />
    </div>
  );
};

export default ChannelIntegration;
