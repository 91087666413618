/* eslint-disable */
import Button from "../Button";
import { Form, Upload, message } from "antd";
import Dropdown from "../Dropdown";
import Input from "../Input";

const PackingSlipConfiguration = () => {
  // Function to handle file upload
  const handleFileUpload = (info: any) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // Ant Design Upload configuration
  const uploadProps = {
    name: "file",
    action: "your_upload_endpoint", // Replace with your actual upload endpoint
    onChange: handleFileUpload,
  };

  return (
    <div>
      <Form className="w-1/2 bg-white shadow-lg rounded-xl p-5">
        <Form.Item>
          <Dropdown
            title="Template"
            placeholder="Select One Template"
            data={[]}
          />
        </Form.Item>
        <Form.Item label="Logo">
          <Upload {...uploadProps}>
            <Button buttonText="choose file" />
          </Upload>
        </Form.Item>
        <Form.Item>
          <Input multiline={true} title="Organization Name" placeholder="" />
        </Form.Item>
        <Form.Item>
          <Input
            multiline={true}
            title="Shipment Insert Footer Content"
            placeholder=""
          />
        </Form.Item>
        <Button buttonText="save" widthFull />
      </Form>
    </div>
  );
};

export default PackingSlipConfiguration;
