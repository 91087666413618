/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Checkbox, Collapse, Form, Modal, Tabs, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "./Dropdown";
import Loader from "./Loader";
import Table from "./Table";
import Button from "./Button";
import Input from "./Input";
import { BiTrash } from "react-icons/bi";
import DatePicker from "./DatePicker";
import {
  addOrderPicklist,
  createPickRun,
  getEnumTypeList,
  getFacilityList,
  getOrgnizationPartyId,
  getPickRunDetail,
  getPickerList,
  getPicklistDetail,
  getPicklistOrderList,
  getStatusList,
  updadtePicklistStatusForceComplete,
  updatePicklistStatusApproved,
  updatePicklistStatusForceClose,
  userSelector,
} from "../redux/authSlice";
import moment from "moment";

const PicklistDetail = ({ workEffortId }: { workEffortId: string }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [handheldForm] = Form.useForm();
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { activeOrgStores } = useSelector(userSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTimeRendering, setIsFirstTimeRendering] = useState(true);
  const [currStatus, setCurrStatus] = useState<any>("");
  const [isAddPersonModalOpen, setIsAddPersonModalOpen] = useState(false);
  const [isUpdatePicklistModalOpen, setIsUpdatePicklistModalOpen] =
    useState(false);
  // const [currIndex, setCurrIndex] = useState<any>("1");
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [picklistData, setPicklistData] = useState<any>("");
  const [facilityList, setFacilityList] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);
  const [selectedFacility, setSelectedFacility] = useState<any>();
  const [enumTypeList, setEnumTypeList] = useState<any>([]);
  const [workEffortDetail, setWokEffortDetail] = useState<any>();
  const [statusList, setStatusList] = useState<any>([]);
  const [statusShipmentList, setStatusShipmentList] = useState<any>([]);
  const [statusShipmentTypeList, setStatusShipmentTypeList] = useState<any>([]);
  const [statusPeopleAssignedList, setStatusPeopleAssignedList] = useState<any>(
    []
  );
  const [orderStatusList, setOrderStatusList] = useState<any>([]);
  const [filterPeopleAssignedList, setFilterPeopleAssignedList] = useState<any>(
    []
  );
  const [selectedPeopleStatus, setSelectedPeopleStatus] = useState<any>();
  const [storeList, setStoreList] = useState<any>();
  const [orderDataList, setOrderDataList] = useState<any>([]);
  const [orderTypeId, setOrderTypeId] = useState<any>();
  const [orderPicklist, setOrderPicklist] = useState<any>();
  const [pickRunList, setPickRunList] = useState<any>();
  const [pickerList, setPickerList] = useState<any>();
  const [totalOrderNumber, setTotalOrderNumber] = useState<any>();
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [sortData, setSortData] = useState<any>("");
  const [shipmentMethods, setShipmentMethods] = useState<any>([]);
  // const [peopleAssignedList, setPeopleAssignedList] = useState<any>([]);
  const { statusHistoryDetail, shipmentDetail, peopleAssignList } =
    picklistData;

  useEffect(() => {
    fetchData();
    orderList();
  }, []); // eslint-disable-line
  const fetchData = async () => {
    setIsLoading(true);
    onPicklistDetail(workEffortId);
    await dispatch(
      getFacilityList({
        setFacilityList,
        setSelectedFacility,
        fromInventory: true,
      })
    );
    await dispatch(getOrgnizationPartyId({ setOwnerPartyIdList }));
    await dispatch(
      getEnumTypeList({
        setEnumType: setEnumTypeList,
        enumTypeId: "workType",
      })
    );

    await dispatch(
      getEnumTypeList({
        setEnumType: setOrderTypeId,
        enumTypeId: "SalesChannel",
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setStatusShipmentTypeList,
        enumTypeId: "ShipmentType",
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setShipmentMethods,
        enumTypeId: "ShipmentMethod",
      })
    );
    await dispatch(
      getStatusList({
        setStatus: setStatusList,
        statusTypeId: "WorkEffort",
      })
    );
    await dispatch(
      getStatusList({
        setStatus: setStatusShipmentList,
        statusTypeId: "Shipment",
      })
    );
    await dispatch(
      getStatusList({
        setStatus: setOrderStatusList,
        statusTypeId: "OrderHeader",
      })
    );
    await dispatch(
      getStatusList({
        setStatus: setStatusPeopleAssignedList,
        statusTypeId: "WorkEffortParty",
      })
    );
    await dispatch(getPickerList({ workEffortId, setPickerList }));
    fetchPickRunDetail();
    setIsLoading(false);
  };
  const onPicklistDetail = async (workEffortId: any) => {
    await dispatch(
      getPicklistDetail({
        workEffortId,
        setWokEffortDetail,
        setPicklistData,
        setTotalOrderNumber,
        setIsFirstTimeRendering,
      })
    );
  };

  const orderList = async () => {
    await dispatch(
      getPicklistOrderList({
        facilityId: "FP_FACILITY",
        vendorPartyId: "FP",
        productStoreId: "FP_DEFAULT",
        excludeOnPicklist: true,
        excludeNotPickLoc: true,
        setOrderDataList,
        setMaxPageIndex,
        setRecordCount,
        pageIndex: 0,
      })
    );
  };
  const nextSetOfProds = async (pageIndex: any) => {
    setIsLoading(true);
    await dispatch(
      getPicklistOrderList({
        facilityId: "FP_FACILITY",
        vendorPartyId: "FP",
        productStoreId: "FP_DEFAULT",
        excludeOnPicklist: true,
        excludeNotPickLoc: true,
        setMaxPageIndex,
        setRecordCount,
        setOrderDataList,
        pageIndex,
      })
    );
    setIsLoading(false);
  };
  const loadNextProds = (index: any) => {
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    nextSetOfProds(passedIndex);
  };

  useEffect(() => {
    const storeDataList: any = activeOrgStores?.map((item: any) => {
      return {
        key: `${item?.productStoreId}`,
        value: `${item?.productStoreId}:${item?.storeName}`,
      };
    });
    setStoreList([...(storeDataList ?? "")]);
    const filteredPeopleStatus = statusPeopleAssignedList?.filter(
      (item: any) =>
        item?.key === "WeptAssigned" ||
        item.key === "WeptArrived" ||
        item.key === "WeptUnassigned"
    );
    setFilterPeopleAssignedList(filteredPeopleStatus);
  }, [statusPeopleAssignedList, activeOrgStores]);

  const facilityId = (facility: any) => {
    const facilityType = facilityList?.find(
      (item: any) => item.key === facility
    );
    return facility
      ? `${facilityType?.key ?? ""}: ${facilityType?.value ?? ""}`
      : "";
  };
  const ownerOrgnization = (org: any) => {
    const OwnerId = ownerPartyIdList.find((item: any) => item.key === org);
    return OwnerId?.value;
  };
  const storeId = (str: any) => {
    const storeValue = storeList?.find((item: any) => item.key === str);
    return storeValue?.key
      ? `${storeValue?.key ?? ""}:${storeValue?.value ?? ""}`
      : "";
  };
  const enumType = (typ: any) => {
    const typeId = enumTypeList?.find((item: any) => item.key === typ);
    return `${typeId?.value}`;
  };

  const shipmentStatus = (statusId: any) => {
    const shipStatus = statusShipmentList?.find(
      (item: any) => item.key === statusId
    );
    return shipStatus?.value ?? "";
  };
  const shipmentType = (statusId: any) => {
    const shipType = statusShipmentTypeList?.find(
      (item: any) => item.key === statusId
    );
    return shipType?.value;
  };
  const peopleStatus = (statusId: any) => {
    const status = filterPeopleAssignedList?.find(
      (item: any) => item.key === statusId
    );
    return status?.value;
  };
  const orderStatus = (statusId: any) => {
    const status = orderStatusList?.find((item: any) => item.key === statusId);
    return status?.value ?? "";
  };
  const orderType = (enumId: any) => {
    const order = orderTypeId?.find((item: any) => item?.key === enumId);
    return order?.value ?? "";
  };

  const addPicklist = async (orderPicklist: any) => {
    setIsForceLoading(true);
    if (orderPicklist?.length > 0) {
      const picklist = JSON.stringify(orderPicklist);
      setIsLoading(true);
      await dispatch(
        addOrderPicklist({
          orderList: picklist,
          setOrderPicklist,
        })
      );
      onPicklistDetail(workEffortId);
      setOrderPicklist([]);
      setIsLoading(false);
    } else {
      notification["error"]({
        message: "Error",
        description: "Please Select Atleast One Order",
      });
    }
    setIsForceLoading(false);
  };
  const handleSelectAll = () => {
    const allOrderIds = orderDataList.map((item: any) => ({
      orderId: item.orderId,
      orderPartSeqId: item.orderPartSeqId,
      workEffortId,
      allowBulkLocations: true,
    }));

    setOrderPicklist(allOrderIds);
  };

  const handleResetAll = () => {
    setOrderPicklist([]);
  };
  const onCheckedOrder: any = (e: any, data: any) => {
    const updatedPicklist: any = Array.isArray(orderPicklist)
      ? [...orderPicklist]
      : [];

    if (e.target.checked) {
      updatedPicklist.push({
        orderId: data?.orderId,
        orderPartSeqId: data?.orderPartSeqId,
        workEffortId,
        allowBulkLocations: e.target.checked,
      });
    } else {
      const indexToRemove = updatedPicklist.findIndex(
        (item: any) => item.orderId === data?.orderId
      );

      if (indexToRemove !== -1) {
        updatedPicklist.splice(indexToRemove, 1);
      }
    }
    setOrderPicklist(updatedPicklist);
  };

  const onPickRun = async (values: any) => {
    if (values?.totalOrder > 0) {
      // handheldForm.setFieldsValue({
      //   assignTo: "",
      //   totalOrder: values?.totalOrder,
      // });
      setIsForceLoading(true);
      await dispatch(
        createPickRun({
          workEffortId,
          assignedToUserId: values?.assignTo,
          totalOrdersInPicklist: values?.totalOrder,
          handheldForm,
        })
      );
    } else {
      notification["error"]({
        message: "Error",
        description: "Please select a valid Order number",
      });
    }
    setIsForceLoading(false);
    fetchPickRunDetail();
    onPicklistDetail(workEffortId);
  };
  const fetchPickRunDetail = async () => {
    await dispatch(getPickRunDetail({ workEffortId, setPickRunList }));
  };
  const shipmentsColumns = [
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1"></p>
    //     </div>
    //   ),
    //   render: (productIdTypeEnumId: string, data: any, index: any) => {
    //     return (
    //       <div className="flex flex-row items-center">
    //         <div className="flex flex-row ">
    //           <h1 className="" onClick={() => {}}>
    //             <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
    //           </h1>
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: "delete",
    // },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Pick Bin #</p>
    //     </div>
    //   ),
    //   render: (pickBin: string) => (
    //     <h1 className="px-2">
    //       <Input title="" placeholder="" value={pickBin} />
    //     </h1>
    //   ),
    //   dataIndex: "pickBin",
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Ship Method</p>
        </div>
      ),
      dataIndex: "shipMethod",
      render: (shipMethod: string, data: any) => {
        const shipmentMethod: any = shipmentMethods?.find(
          (item: any) => item?.key === data?.shipmentMethodEnumId
        );
        return (
          <h1 className="px-2">{`${shipMethod ?? ""}${
            shipmentMethod?.value ? `- ${shipmentMethod?.value}` : ""
          }`}</h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      dataIndex: "status",
      render: (status: string) =>
        shipmentStatus(status) && (
          <h1
            style={{
              backgroundColor: "#093479",
              outlineColor: "#707070",
              border: "1px solid #707070",
            }}
            className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
          >
            {shipmentStatus(status)}
          </h1>
        ),
    },
    {
      title: (
        <div className="items-center relative h-5 px-2 leading-3">
          <p className="mr-1">Shipment</p>
          <p className="mr-1">Type</p>
        </div>
      ),
      dataIndex: "type",
      render: (type: string, data: any) => {
        return (
          <div>
            <h1 className="px-2">{data?.shipment} </h1>
            <h1 className="px-2">{shipmentType(type)}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">To Party</p>
        </div>
      ),
      dataIndex: "toParty",
      render: (toParty: string) => <h1 className="px-2">{toParty}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Facility</p>
        </div>
      ),
      dataIndex: "facility",
      render: (facility: string) => (
        <h1 className="px-2">{facilityId(facility)}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Orders</p>
        </div>
      ),
      dataIndex: "order",
      render: (order: string) => (
        <Link
          to={{
            pathname: `/order/${order}`,
          }}
        >
          <h1 className="cursor-pointer underline text-primary">{order}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Entry Date</p>
        </div>
      ),
      dataIndex: "entryDate",
      render: (entryDate: string) => (
        <h1 className="px-2">{moment(entryDate).format("YYYY-MM-DD HH:MM")}</h1>
      ),
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Ready Date</p>
    //     </div>
    //   ),
    //   dataIndex: "readyDate",
    //   render: (readyDate: string) => <h1 className="px-2">{readyDate}</h1>,
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Quantity Total Pick</p>
        </div>
      ),
      dataIndex: "quantityTotal",
      // render: (quantityTotal: string, data: any) => (
      //   <div>
      //     <div className="px-2">{quantityTotal}</div>
      //     <div className="px-2 font-bold text-green-700">
      //       {data?.resPickLocQuantity}
      //     </div>
      //   </div>
      // ),
    },
  ];
  const peopleAssignedColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Name</p>
        </div>
      ),
      render: (partyId: string, data: any) => (
        <h1 className="px-2">{`${partyId}:${data?.firstName}`}</h1>
      ),
      dataIndex: "partyId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Username</p>
        </div>
      ),
      render: (username: string, data: string) => (
        <h1 className="px-2">{username}</h1>
      ),
      dataIndex: "username",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      render: (statusId: string) => (
        <h1 className="px-2">
          <Dropdown
            data={filterPeopleAssignedList}
            placeholder=""
            width="80%"
            value={
              selectedPeopleStatus
                ? selectedPeopleStatus
                : peopleStatus(statusId)
            }
            isDisabled={true}
            onChange={(val: string) => setSelectedPeopleStatus(val)}
          />
        </h1>
      ),
      dataIndex: "statusId",
    },
  ];
  const ordersColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      render: (orderId: any, data: any, index: any) => {
        const isChecked = orderPicklist?.find(
          (item: any) => item?.orderId === orderId
        );
        // const isALlChecked = orderPicklist?.map((item: any) => item?.orderId);
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <Checkbox
                checked={isChecked}
                onChange={(e: any) => onCheckedOrder(e, data)}
              />
            </div>
          </div>
        );
      },
      dataIndex: "orderId",
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Ship Method</p>
          <p className="mr-1 text-xs">Carrier</p>
        </div>
      ),
      render: (carrierPartyId: string) => (
        <div>
          <p className="px-2">{""}</p>
          <p className="px-2">{carrierPartyId}</p>
        </div>
      ),
      dataIndex: "carrierPartyId",
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Placed Date</p>
          <p className="mr-1 text-xs">Ship Before Date</p>
          <p className="mr-1 text-xs">Entry Date</p>
        </div>
      ),
      dataIndex: "placedDate",
      render: (placedDate: number, data: any) => (
        <div>
          <p className="px-2">
            {moment(placedDate).format("YYYY-MM-DD HH:MM") ?? ""}
          </p>
          <p className="px-2">{""}</p>
          <p className="px-2">
            {moment(data?.entryDate).format("YYYY-MM-DD HH:MM") ?? ""}
          </p>
        </div>
      ),
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Order</p>
          <p className="mr-1 text-xs">Their Order</p>
          <p className="mr-1 text-xs">Source</p>
        </div>
      ),
      dataIndex: "salesChannelEnumId",
      render: (salesChannelEnumId: string, data: any) => (
        <div>
          <p className="px-2">{`${data?.orderId ?? ""} : ${
            data?.orderPartSeqId ?? ""
          }`}</p>
          <p className="px-2">{""}</p>
          <p className="px-2">{orderType(salesChannelEnumId)}</p>
        </div>
      ),
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Status</p>
          <p className="mr-1 text-xs">Faciity</p>
          <p className="mr-1 text-xs">Store</p>
        </div>
      ),
      dataIndex: "statusId",
      render: (statusId: any, data: any) => (
        <div>
          <h1 className="px-2 text-xs font-bold">{orderStatus(statusId)}</h1>
          <h1 className="px-2 text-xs ">{data?.facilityName ?? ""}</h1>
          <h1 className="px-2 text-xs ">{data?.storeName ?? ""}</h1>
        </div>
      ),
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Vendor</p>
          <p className="mr-1 text-xs">Customer</p>
          <p className="mr-1 text-xs">Order Properties</p>
        </div>
      ),
      dataIndex: "vendorPartyId",
      render: (vendorPartyId: any, data: any) => (
        <div>
          <h1 className="px-2 text-xs ">{data?.organizationName ?? ""}</h1>
          <h1 className="px-2 text-xs ">{`${data?.customerPartyId}: ${
            data?.firstName ?? "NA"
          } ${data?.lastName ?? "NA"} ${data?.partyDescription}`}</h1>
        </div>
      ),
    },
    {
      title: (
        <div className="items-center relative h-10 leading-3">
          <p className="mr-1 text-xs">Amount Total</p>
          <p className="mr-1 text-xs">Quantity Total</p>
          <p className="mr-1 text-xs">Pic Loc Qty</p>
        </div>
      ),
      dataIndex: "partTotal",

      render: (partTotal: string, data: any) => (
        <div>
          <h1 className="px-2 text-xs ">{partTotal}</h1>
          <h1 className="px-2 text-xs ">{data?.quantityTotal}</h1>
          <h1 className="px-2 text-xs text-green-700 font-bold">
            {data?.itemBomQuantityTotal === data?.resQuantity
              ? data?.resQuantity
              : ""}
          </h1>
        </div>
      ),
    },
  ];
  const handheldColumns: any = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">PickRun ID</p>
        </div>
      ),
      render: (pickRunId: number, data: string) => (
        <Link
          to={{
            pathname: `/pickRunDetail/${pickRunId}`,
            state: { pickRunId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">{pickRunId}</h1>
        </Link>
      ),
      dataIndex: "pickRunId",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Status</p>
        </div>
      ),
      render: (status: string, data: string) => <h1 className="px-2">Ready</h1>,
      dataIndex: "status",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Assigned To</p>
        </div>
      ),
      render: (userFullName: string, data: any) => (
        <h1 className="px-2">{`${userFullName ?? ""}${
          data?.userName ? `[${data?.userName}]` : ""
        }`}</h1>
      ),
      dataIndex: "assignedTo",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Assigned Orders</p>
        </div>
      ),
      render: (assignedOrder: string) => (
        <h1 className="px-2">{assignedOrder}</h1>
      ),
      dataIndex: "assignedOrder",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1 text-xs">Start Date</p>
        </div>
      ),
      render: (startDate: string, data: string) => (
        <h1 className="px-2">{moment(startDate).format("YYYY-MM-DD HH:MM")}</h1>
      ),
      dataIndex: "startDate",
    },
  ];
  return (
    <>
      <div>
        <Loader
          isLoading={isLoading && isFirstTimeRendering}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          <div className="flex flex-col mdlarge:flex-row mb-3">
            <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
              <div
                className="flex flex-row items-center mb-6 cursor-pointer w-max"
                onClick={() => {
                  history.push("/picklist/picklistListing");
                }}
              >
                <MdOutlineKeyboardBackspace
                  style={{ fontSize: "20px" }}
                  className="text-primary"
                />
                <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                  BACK TO RESULTS
                </p>
              </div>
            </div>
          </div>
          <Tabs type="card" className="mb-10">
            <TabPane tab="Picklist" key="1">
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
                  <div className="bg-white p-5 mb-5 rounded-lg shadow-sm">
                    <span className="text-base sm:text-lg font-bold mr-5">
                      Picklist ID # {workEffortId}
                    </span>
                  </div>
                  <div className="bg-white p-5 rounded-lg shadow-sm">
                    <div className="">
                      <div className="flex flex-row gap-5 justify-between">
                        <div className="flex flex-row gap-5 mt-5">
                          <div className="text-sm font-bold w-24">Name</div>
                          <div>{workEffortDetail?.name}</div>
                        </div>
                        <div>
                          <Button
                            buttonText="Update"
                            onClick={() => setIsUpdatePicklistModalOpen(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Facility</div>
                        {workEffortDetail?.facility && (
                          <div>{facilityId(workEffortDetail?.facility)}</div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Owner Org</div>
                        {workEffortDetail?.ownerOrg && (
                          <div>
                            {ownerOrgnization(workEffortDetail?.ownerOrg)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Store</div>
                        {workEffortDetail?.store && (
                          <div>{storeId(workEffortDetail?.store)}</div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Type</div>
                        {workEffortDetail?.type && (
                          <div>{enumType(workEffortDetail?.type)}</div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row">
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">Est. Start</div>
                        {workEffortDetail?.startDate && (
                          <div>
                            {moment(workEffortDetail?.startDate).format(
                              "YYYY-MM-DD"
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">Est. Hours</div>
                        <div>{workEffortDetail?.estHours}</div>
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row">
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Actual Start
                        </div>
                        {workEffortDetail?.actualStart && (
                          <div>
                            {moment(workEffortDetail?.actualStart).format(
                              "YYYY-MM-DD"
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Actual Complete
                        </div>
                        {workEffortDetail?.actualComplete && (
                          <div>
                            {moment(workEffortDetail?.actualComplete).format(
                              "YYYY-MM-DD"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:mt-2 lg:mt-0 mdlarge:w-4/12 ml-0 mdlarge:ml-3 ">
                  <div className="bg-white p-5 my-4 md:my-0 pb-6 rounded-lg shadow-sm mb-6">
                    <Loader
                      isLoading={isLoading}
                      styles={{
                        overlay: (base: any) => ({
                          ...base,
                          backgroundColor: "white",
                        }),
                      }}
                    >
                      <div className="items-center justify-between mb-2">
                        <div className="flex items-center">
                          <h1 className="text-base font-bold mr-4">Status</h1>
                          <Dropdown
                            allowClear={true}
                            width={"70%"}
                            data={statusList}
                            placeholder="Select Status"
                            value={
                              currStatus ? currStatus : workEffortDetail?.status
                            }
                            onChange={async (statusId: string) => {
                              if (
                                statusId === "WeApproved" ||
                                statusId === "WeInProgress"
                              ) {
                                setIsLoading(true);
                                await dispatch(
                                  updatePicklistStatusApproved({
                                    statusId,
                                    workEffortId,
                                  })
                                );
                                setCurrStatus(statusId);
                                setIsLoading(false);
                              } else if (statusId === "WeComplete") {
                                setIsLoading(true);
                                await dispatch(
                                  updadtePicklistStatusForceComplete({
                                    workEffortId,
                                    statusId,
                                  })
                                );
                                setCurrStatus(statusId);
                                setIsLoading(false);
                              } else if (statusId === "WeCancelled") {
                                setIsLoading(false);
                                await dispatch(
                                  updatePicklistStatusForceClose({
                                    statusId,
                                    workEffortId,
                                  })
                                );
                                setCurrStatus(statusId);
                                setIsLoading(false);
                              }
                              onPicklistDetail(workEffortId);
                            }}
                          />
                        </div>

                        {statusHistoryDetail
                          ?.slice()
                          .sort(
                            (a: any, b: any) =>
                              new Date(b?.changedDate).getTime() -
                              new Date(a?.changedDate).getTime()
                          )
                          ?.map((data: any, index: any) => (
                            <div key={index} className="flex py-2">
                              <div className="w-2/3">
                                <span className="text-base font-bold sm:text-xs">
                                  {moment(data?.changedDate).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </span>
                                <span>
                                  {` by ${data?.userFullName} [${data?.userName}] `}
                                </span>
                              </div>
                              <div
                                className={`text-base sm:text-xs mx-2 w-1/3 ${
                                  index === 0 ? "text-green-500" : "text-black"
                                }`}
                              >
                                {`-${data?.description}`}
                              </div>
                            </div>
                          ))}
                      </div>
                    </Loader>
                  </div>
                </div>
              </div>
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                <Collapse
                  className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="left"
                >
                  <Panel
                    header={
                      <span className="text-base font-bold">Shipments</span>
                    }
                    key="1"
                  >
                    <div className="flex justify-end mb-4">
                      <Button buttonText="Update" />
                    </div>
                    <Table
                      rowKey="shipMethod"
                      dataSource={shipmentDetail?.filter(
                        (item: any) => item?.type === "ShpTpSales"
                      )}
                      columns={shipmentsColumns}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse>
              </div>
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                <Collapse
                  defaultActiveKey={["1"]}
                  className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                  // onChange={onChange}
                  ghost
                  expandIconPosition="left"
                >
                  <Panel
                    header={
                      <span className="text-base font-bold">
                        People Assigned
                      </span>
                    }
                    key="1"
                  >
                    <div className="flex justify-end mb-4">
                      {/* <Button
                        buttonText="Add Person"
                        onClick={() => setIsAddPersonModalOpen(true)}
                      /> */}
                    </div>
                    <Table
                      rowKey="shipMethod"
                      dataSource={peopleAssignList}
                      columns={peopleAssignedColumns}
                      isPaginated={false}
                    />
                  </Panel>
                </Collapse>
              </div>
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                {/* <Collapse
                  defaultActiveKey={["1"]}
                  className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                  ghost
                  expandIconPosition="left"
                >
                  <Panel
                    header={
                      <span className="text-base font-bold">Equipment</span>
                    }
                    key="1"
                  >
                    <div className="mt-5"></div>
                  </Panel>
                </Collapse> */}
              </div>
            </TabPane>
            <TabPane tab="Add Orders" key="2">
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                  <Loader
                    isLoading={isLoading}
                    styles={{
                      overlay: (base: any) => ({
                        ...base,
                        backgroundColor: "white",
                      }),
                    }}
                  >
                    <div className="bg-white p-3 mb-5 rounded-lg shadow-sm">
                      <div className="m-2 flex gap-4">
                        <Button
                          onClick={handleSelectAll}
                          buttonText="Select All"
                        />
                        <Button
                          onClick={handleResetAll}
                          buttonText="Reset All"
                        />
                        <Button
                          buttonText="Add to Picklist"
                          onClick={(e: any) => {
                            addPicklist(orderPicklist);
                          }}
                        />
                      </div>
                      <Table
                        rowKey="shipMethod"
                        dataSource={orderDataList}
                        columns={ordersColumns}
                        isServerRendered={recordCount ? true : false}
                        isPaginated={true}
                        loadNextProds={loadNextProds}
                        recordCount={recordCount}
                        // onChange={onChange}
                      />
                    </div>
                  </Loader>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Handheld" key="3">
              <div className="w-full mdlarge:w-1/2 mr-0 mdlarge:mr-3">
                <div className="bg-white p-5 mb-5 rounded-lg shadow-sm">
                  <Loader
                    isLoading={isForceLoading}
                    styles={{
                      overlay: (base: any) => ({
                        ...base,
                        backgroundColor: "",
                      }),
                    }}
                  >
                    <Form
                      form={handheldForm}
                      onFinish={(values: any) => onPickRun(values)}
                    >
                      <Form.Item
                        name="assignTo"
                        className="sm:mr-4 flex-1 w-full"
                        rules={[
                          { required: true, message: "Please Select One" },
                        ]}
                      >
                        <Dropdown
                          searchable={true}
                          allowClear={true}
                          title="Assign To"
                          data={pickerList}
                          placeholder="Select one"
                          width="100%"
                        />
                      </Form.Item>

                      <Form.Item
                        name="totalOrder"
                        className="sm:mr-4 flex-1 w-1/2"
                      >
                        <Input
                          value={totalOrderNumber}
                          title="Total Order In Picklist"
                          placeholder=""
                        />
                      </Form.Item>
                      <Form.Item>
                        <div className="w-1/4 sm:mx-5 mt-5">
                          <Button buttonText="Create PickRun" />
                        </div>
                      </Form.Item>
                    </Form>
                  </Loader>
                </div>
              </div>
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                <div className="bg-white p-4 rounded-lg shadow-sm mt-5 mb-5">
                  <Table
                    rowKey="Handheld"
                    dataSource={pickRunList}
                    columns={handheldColumns}
                    isPaginated={false}
                    // loadNextProds={loadNextProds}
                    // recordCount={recordCount}
                    // onChange={onChange}
                    // resetInputField={resetInputField}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
          <Modal
            title="Add Person"
            visible={isAddPersonModalOpen}
            footer={null}
            onCancel={() => {
              setIsAddPersonModalOpen(false);
              form?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "white",
                }),
              }}
            >
              <Form
                form={form}
                onFinish={async (values: any) => {
                  setIsForceLoading(true);
                  setIsForceLoading(false);
                }}
              >
                <Form.Item
                  name="person"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: true, message: "Select an option" }]}
                >
                  <Dropdown
                    searchable={true}
                    title="Person"
                    data={pickerList}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>

                <Form.Item
                  name="status"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: true, message: "Input ID Value" }]}
                >
                  <Dropdown
                    title="Status"
                    data={statusPeopleAssignedList}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add Assignment" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Update Picklist Detail"
            visible={isUpdatePicklistModalOpen}
            footer={null}
            width={700}
            onCancel={() => {
              setIsUpdatePicklistModalOpen(false);
              updateForm?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "white",
                }),
              }}
            >
              <Form
                name="update_picklist"
                form={updateForm}
                initialValues={{
                  name: workEffortDetail?.name,
                  facility: workEffortDetail?.facility,
                  ownerOrg: workEffortDetail?.ownerOrg,
                  store: workEffortDetail?.store,
                  type: workEffortDetail?.type,
                  estStart: moment(workEffortDetail?.startDate),
                  estHours: workEffortDetail?.estHours,
                  actStart: workEffortDetail?.actualStart,
                  actComplete: workEffortDetail?.actualComplete,
                }}
                className="px-2"
                onFinish={async (values: any) => {
                  setIsLoading(true);
                  const data = { ...values };

                  setIsLoading(false);
                }}
              >
                <div className="sm:flex">
                  <div className="w-1/2 pr-3">
                    <Form.Item name="name">
                      <Input title="Name" placeholder="" showDot={false} />
                    </Form.Item>
                  </div>
                </div>
                <div className="sm:flex gap-2">
                  <Form.Item
                    name="facility"
                    className="flex-1 w-1/2 pr-2"
                    rules={[
                      {
                        required: true,
                        message: "This is required field!",
                      },
                    ]}
                  >
                    <Dropdown
                      title="Facility"
                      placeholder="Select One"
                      allowClear={true}
                      data={facilityList}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="ownerOrg" className="flex-1 w-1/2">
                    <Dropdown
                      title="Owner Org"
                      placeholder="Select One"
                      allowClear={true}
                      data={ownerPartyIdList}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-2">
                  <Form.Item name="store" className="flex-1 w-1/2 pr-2">
                    <Dropdown
                      title="Store"
                      placeholder="Select One"
                      allowClear={true}
                      data={storeList}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="type" className="flex-1 w-1/2">
                    <Dropdown
                      title="Type"
                      allowClear={true}
                      placeholder="Select One"
                      data={enumTypeList?.filter((item: any) =>
                        [
                          "WktpBulkSinglePicking",
                          "WktpOrderLevelPicking",
                          "WktpBulkPicking",
                        ]?.includes(item?.key)
                      )}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item name="estStart" className="w-full">
                    <DatePicker
                      isFullWidth
                      title="Est. Start"
                      dateFormat="DD-MM-YYYY"
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item name="estHours" className="w-full sm:ml-4">
                    <Input
                      title="Est. Hours"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="actStart" className="w-full">
                    <DatePicker
                      isFullWidth
                      title="Actual Start"
                      dateFormat="DD-MM-YYYY"
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item name="actComplete" className="w-full sm:ml-4">
                    <DatePicker
                      isFullWidth
                      title="Actual Complete"
                      dateFormat="DD-MM-YYYY"
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
        </Loader>
      </div>
    </>
  );
};

export default PicklistDetail;
