/* eslint-disable */
import { useEffect, useState } from "react";
import Table from "../Table";
import Button from "../Button";
import { Modal, Form } from "antd";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import Loader from "../Loader";
import {
  getProductStoreCatagories,
  getEnumTypeList,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ProductStoreCatagory = (props: any) => {
  const [isNewStoreEmailModalOpen, setIsNewStoreEmailModalOpen] =
    useState(false);
  const [storeCatagoryForm] = Form.useForm();
  const [productStoreCatagoriesList, setProductStoreCatagoriesList] =
    useState<any>([]);
  const [catagoryType, setCatagoryType] = useState<any>([]);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getProductStoreCatagories({
        productStoreId: props.id,
        setProductStoreCatagoriesList,
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setCatagoryType,
        enumTypeId: "ProductStoreCategoryType",
      })
    );
    setisLoading(false);
  };
  const CatagoryColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Sequence Num</p>
        </div>
      ),
      dataIndex: "sequenceNum",
      render: (sequenceNum: any) => {
        return (
          <div className="px-4">
            <h1 className="px-4">{sequenceNum}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Store Catagory Type
        </div>
      ),
      dataIndex: "storeCategoryTypeEnumId",
      render: (storeCategoryTypeEnumId: any) => {
        return (
          <div className="px-2">
            {
              catagoryType.find(
                (data: any) => data?.key === storeCategoryTypeEnumId
              )?.value
            }
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Product Catagory
        </div>
      ),
      dataIndex: "productCategoryId",
      render: (productCategoryId: any) => {
        return <div className="px-2">{productCategoryId}</div>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          From Date
        </div>
      ),
      dataIndex: "fromDate",
      render: (fromDate: any) => {
        return (
          <div className="px-2">
            {fromDate ? moment(fromDate)?.format("YYYY-MM-DD") : ""}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          Thru Date
        </div>
      ),
      dataindex: "thruDate",
      render: (thruDate: any) => {
        return (
          <div className="px-2">
            {thruDate ? moment(thruDate)?.format("YYYY-MM-DD") : ""}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Modal
          visible={isNewStoreEmailModalOpen}
          title="Add Store Catagory"
          onCancel={() => {
            setIsNewStoreEmailModalOpen(false);
          }}
          footer={null}>
          <Form form={storeCatagoryForm} onFinish={(values: any) => {}}>
            <Form.Item name="organizationparty" className="flex-1 w-full">
              <Dropdown
                title="Catagory"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>

            <Form.Item name="storecatagorytype" className="flex-1 w-full">
              <Dropdown
                title="Store Catagory Type"
                data={[]}
                placeholder=""
                width="100%"
              />
            </Form.Item>
            <Form.Item name="fromdate">
              <DatePicker title="From Date" />
            </Form.Item>
            <Form.Item name="create" className="flex-1 w-full">
              <Button
                onClick={() => {
                  setIsNewStoreEmailModalOpen(false);
                }}
                widthFull
                buttonText="Create"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {/* <div>
          <Button
            onClick={() => {
              setIsNewStoreEmailModalOpen(true);
            }}
            classNames="mb-2"
            buttonText="Add Store Catagory"
          />
        </div> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          rowKey=""
          dataSource={productStoreCatagoriesList}
          columns={CatagoryColoumns}
          isPaginated={false}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default ProductStoreCatagory;
