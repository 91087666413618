/* eslint-disable */
import { useState, useEffect, createRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { HiOutlineMail, HiOutlinePhone, HiOutlineUser } from "react-icons/hi";
import { GoLocation } from "react-icons/go";
import { FaWarehouse } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
// // @ts-ignore
// import { useMediaQuery } from 'react-responsive'

import Table from "./Table";
import Dropdown from "./Dropdown";
import Loader from "./Loader";
import {
  addShipmentItem,
  getIncomingShipmentDetails,
  getProductList,
  importData,
  searchProductListing,
  updateShipmentItem,
  userSelector,
  getAllFacilities,
  getOrgnizationPartyId,
  updateVendorFacility,
  updateVendorFormParty,
  updateVendorDetatil,
  getPartyDetailRoleType,
  getGeoStates,
  getGeoCountries,
} from "../redux/authSlice";
import { Form, FormInstance, Modal, Select, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import UploadFile from "./UploadFile";
import Button from "./Button";
import ImportListing from "./ImportListing";
import CountDown from "./Countdown";
import Input from "./Input";
import DatePicker from "./DatePicker";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
import { debounceFn } from "../helper/function";
import { RiDownloadLine } from "react-icons/ri";

const ProgressLine = ({
  backgroundColor = "#b3b3b3",
  visualParts = [
    {
      percentage: "0%",
      value: 0,
      color: "white",
    },
  ],
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );
  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts.map((item: any) => {
          return item.percentage;
        })
      );
    });
  }, [visualParts]);
  return (
    <div
      className="progressVisualFull"
      style={{
        backgroundColor,
        borderRadius: 10,
        height: 20,
        overflow: "hidden",
      }}>
      {visualParts.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              width: widths[index],
              backgroundColor: item.color,
            }}
            className="progressVisualPart"
          />
        );
      })}
    </div>
  );
};

const IncomingShipemtDetails = ({
  id,
  entryDate,
}: {
  id: string;
  entryDate: any;
}) => {
  const formRef = createRef<FormInstance>();
  const [formAdd] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeOrgToggled, allStatesList, countriesList, statesList } =
    useSelector(userSelector);
  const [, setStatusDropdownVal] = useState<any>("");
  const [currentEntryDate, setCurrentEntryDate] = useState(
    entryDate ? entryDate : ""
  );
  const [incomingShipmentDetails, setIncomingShipmentDetails] = useState<any>(
    []
  );
  const [visualParts, setVisualParts] = useState<any>([]);
  const [itemsList, setShipmentItemsList] = useState<any>([]);
  const recordCount: any = "";
  const [sortData, setSortData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<any>(null);
  const [showUploadList, setShowUploadList] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [productRecordCount, setProductRecordCount] = useState("");
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [mfgDate, setMfgDate] = useState("");
  const [expDate, setExpDate] = useState("");
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [searchableString, setSearchableString] = useState("");
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [formCustomerDetail] = Form.useForm();
  const [vendorDetail, setVendorDetail] = useState<any>();
  const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);
  const [isUpdateCustomerDetail, setIsUpdateCustomerDetail] = useState(false);
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [partyIdListing, setPartyIdListing] = useState([]);

  useEffect(() => {
    fetchData();
  }, [activeOrgToggled]); // eslint-disable-line

  useEffect(() => {
    if (shouldRerender) {
      fetchData();
      setShouldRerender(false);
    }
  }, [shouldRerender]);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getIncomingShipmentDetails({
        orderByFields: sortData,
        setVendorDetail,
        setVisualParts,
        setShipmentItemsList,
        setIncomingShipmentDetails,
        setCurrentEntryDate,
        id,
        setIsFirstTimeRender,
      })
    );
    await dispatch(
      getAllFacilities({
        setFacilitiesList,
      })
    );
    await dispatch(getOrgnizationPartyId({ setOwnerPartyIdList }));
    await dispatch(getGeoCountries());
    // getProductListing();
    setIsLoading(false);
  };

  const getProductListing = async (currIndex = 0) => {
    await dispatch(
      getProductList({
        productList,
        setProductList,
        orderByFields: sortData,
        setMaxPageIndex,
        setRecordCount: setProductRecordCount,
        pageIndex: localStorage.getItem("productListCurrIndex")
          ? Number(localStorage.getItem("productListCurrIndex")) + 1
          : currIndex,
        appliedFilters: "",
      })
    );
  };

  const itemsColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      dataIndex: "sku",
      render: (sku: string, data: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row">
              <h1
                className="ml-2"
                onClick={() => {
                  const selectedItem = itemsList.find(
                    (item: any) => item.productId === data?.productId
                  );
                  if (selectedItem) {
                    formUpdate?.setFieldsValue({
                      productSku: selectedItem?.sku
                        ? selectedItem.sku
                        : selectedItem?.upc,
                      productId: selectedItem?.productId,
                      quantity: selectedItem?.expectedQuantity,
                      lotNumber: selectedItem?.lotNumber,
                    });
                    setIsUpdateModalOpen(true);
                  }
                }}>
                <BiEdit className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Item SKU</p>
        </div>
      ),
      dataIndex: "sku",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.sku === "number") {
            return a.sku - b.sku;
          } else {
            return a.sku?.localeCompare(b.sku);
          }
        },
      },
      multiple: 1,
      render: (sku: string, productDetail: any) => (
        <Link
          to={{
            pathname: `/product/${productDetail?.productId}`,
            state: { productId: productDetail?.productId },
          }}>
          <h1 className="cursor-pointer text-primary underline">{sku}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Item UPC</p>
        </div>
      ),
      dataIndex: "upc",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.upc === "number") {
            return a.upc - b.upc;
          } else {
            return a.upc?.localeCompare(b.upc);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Carton Number</p>
        </div>
      ),
      dataIndex: "carton",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.carton === "number") {
            return a.carton - b.carton;
          } else {
            return a.carton?.localeCompare(b.carton);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Tracking Number</p>
        </div>
      ),
      dataIndex: "trackingCode",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.trackingCode === "number") {
            return a.trackingCode - b.trackingCode;
          } else {
            return a.trackingCode?.localeCompare(b.trackingCode);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Expected Quantity</p>
        </div>
      ),
      dataIndex: "expectedQuantity",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.expectedQuantity === "number") {
            return a.expectedQuantity - b.expectedQuantity;
          } else {
            return a.expectedQuantity?.localeCompare(b.expectedQuantity);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Received Quantity</p>
        </div>
      ),
      dataIndex: "receivedQuantity",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.receivedQuantity === "number") {
            return a.receivedQuantity - b.receivedQuantity;
          } else {
            return a.receivedQuantity?.localeCompare(b.receivedQuantity);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Rejected Quantity</p>
        </div>
      ),
      dataIndex: "rejectedQuantity",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.rejectedQuantity === "number") {
            return a.rejectedQuantity - b.rejectedQuantity;
          } else {
            return a.rejectedQuantity?.localeCompare(b.rejectedQuantity);
          }
        },
      },
      multiple: 1,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Remaining Quantity</p>
        </div>
      ),
      dataIndex: "remainingQuantity",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.remainingQuantity === "number") {
            return a.remainingQuantity - b.remainingQuantity;
          } else {
            return a.remainingQuantity?.localeCompare(b.remainingQuantity);
          }
        },
        multiple: 1,
      },
    },
  ];
  const monthNames: any = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let outputStr = "";
    setSortData("");

    if (Object.keys(sorter)?.length > 0) {
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    dispatch(
      getIncomingShipmentDetails({
        orderByFields: outputStr,
        setVisualParts,
        setShipmentItemsList,
        setIncomingShipmentDetails,
        id,
      })
    );
  }

  const getFacilityValue = (item: any) => {
    const facility = facilitiesList?.find((data: any) => data?.key === item);
    return facility?.value || "";
  };

  const getOwerPartyId = (id: any) => {
    const ownerPartyId = ownerPartyIdList?.find(
      (item: any) => item?.key === id
    );
    return ownerPartyId?.value || "";
  };
  const nextSetOfProds = async (pageIndex: any) => {
    setIsLoading(true);
    await dispatch(
      getPartyDetailRoleType({
        organizationPartyId: localStorage.getItem("organizationPartyId"),
        setPartyIdListing,
        setMaxPageIndex,
        pageIndex,
        searchTerm,
      })
    );
    setIsLoading(false);
  };
  const loadProdsOnBtnClick = (type: string) => {
    if (type === "left") {
      if (
        maxPageIndex * 1 === 1 ||
        !currIndex ||
        currIndex * 1 === 0 ||
        (Math.abs(currIndex * 1 - maxPageIndex * 1) > 1 &&
          currIndex * 1 > maxPageIndex * 1) ||
        currIndex * 1 === 1
      ) {
        return;
      }
      const passedIndex = currIndex * 1 - 2;
      setCurrIndex((p: any) => p * 1 - 1);
      nextSetOfProds(passedIndex);
    } else {
      if (!currIndex || currIndex * 1 >= maxPageIndex) {
        return;
      }
      const passedIndex = currIndex;
      setCurrIndex((p: any) => p * 1 + 1);
      nextSetOfProds(passedIndex);
    }
  };
  const searchBoxOnChange = async (input: any) => {
    setSearchTerm(input);
    await dispatch(
      getPartyDetailRoleType({
        organizationPartyId: localStorage.getItem("organizationPartyId"),
        setPartyIdListing,
        setMaxPageIndex,
        pageIndex: 0,
        searchTerm: input,
      })
    );
  };
  return (
    <div>
      <Loader
        isLoading={!incomingShipmentDetails && isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}>
        <Modal
          title="Import Incoming shipment"
          visible={isModalOpen}
          footer={null}
          onCancel={() => {
            setShowUploadList(false);
            setIsModalOpen(false);
            setCsvFile(null);
            setIsLoading(false);
          }}>
          <Form
            ref={formRef}
            onFinish={async (values: any) => {
              if (!csvFile || csvFile.status === "removed") {
                notification["error"]({
                  message: "Error",
                  description: "Please select a CSV file!",
                });
                return;
              }
              const organizationPartyId: any = localStorage.getItem(
                "organizationPartyId"
              );
              setIsLoading(true);
              setIsDisabled(true);
              const partyId: any = localStorage.getItem("partyId");
              let formData = new FormData();
              formData.append("shipmentId", id);
              formData.append("contentFile", csvFile);
              formData.append("ownerPartyId", organizationPartyId);
              formData.append("templateId", "Shipment_Item_Data_Import");
              formData.append("uploadedByPartyId", partyId);
              await dispatch(
                importData({ data: formData, history, notification })
              );
              formRef.current?.resetFields();
              setShowUploadList(false);
              setIsModalOpen(false);
              setCsvFile(null);
              setShouldRerender(true);
              setIsLoading(false);
              setIsDisabled(false);
            }}>
            <Form.Item>
              <UploadFile
                setCsvFile={setCsvFile}
                isCloudIconVisible={true}
                showUploadList={showUploadList}
                setShowUploadList={setShowUploadList}
              />
            </Form.Item>
            <div
              className="flex items-center mb-3 cursor-pointer w-max"
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_API_BASE_URL +
                  "/fp-static/downloadIncomingShipmentTemplate";
              }}>
              <DownloadOutlined style={{ fontSize: "18px" }} />
              <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                DOWNLOAD CSV TEMPLATE
              </p>
            </div>
            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button
                  widthFull={true}
                  isDisabled={isDisabled}
                  buttonText="Inventory's On It's Way"
                />
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Update Item"
          visible={isUpdateModalOpen}
          footer={null}
          onCancel={() => {
            setIsUpdateModalOpen(false);
            formUpdate?.resetFields();
          }}>
          <Loader
            isLoading={isForceLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "",
              }),
            }}>
            <Form
              form={formUpdate}
              onFinish={async (values: any) => {
                delete values?.productSku;
                setIsForceLoading(true);
                await dispatch(
                  updateShipmentItem({
                    data: values,
                    shipmentId: id,
                    setIsUpdateModalOpen,
                    fetchData,
                    formUpdate,
                  })
                );
                setIsForceLoading(false);
              }}>
              <div className="sm:flex">
                <Form.Item name="productId" hidden></Form.Item>
                <Form.Item
                  name="productSku"
                  className="flex-1 w-full"
                  rules={[{ required: false, message: "Select an option!" }]}>
                  <Input
                    title="Product"
                    placeholder="Product"
                    showDot={true}
                    isDisabled
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  name="quantity"
                  className="flex-1 w-full"
                  rules={[
                    {
                      required: true,
                      message: "Expected Quantity field is required!",
                    },
                    {
                      pattern: /^\d+$/,
                      message: "Please enter a valid number!",
                    },
                  ]}>
                  <Input
                    title="Expected Quantity"
                    placeholder="Expected Quantity"
                    showDot={true}
                  />
                </Form.Item>
                {/* <Form.Item name="lotNumber" className="sm:mr-4 flex-1">
                <Input
                  title="Lot Number."
                  placeholder="Lot Number."
                  showDot={true}
                />
              </Form.Item> */}
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button widthFull={true} buttonText="Update" />
                </div>
              </Form.Item>
            </Form>
          </Loader>
        </Modal>
        <Modal
          title="Add Item"
          visible={isAddItemModalOpen}
          footer={null}
          onCancel={() => {
            setIsAddItemModalOpen(false);
            formAdd?.resetFields();
            setProductList([]);
            setSelectedProduct(null);
          }}
          width={700}>
          <Loader
            isLoading={isForceLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "",
              }),
            }}>
            <Form
              form={formAdd}
              onFinish={async (values: any) => {
                if (!selectedProduct) {
                  notification["error"]({
                    message: "Error",
                    description: "Please select a product.",
                  });
                  return;
                }
                const { quantity, cartonNumber, trackingNumber, lotNumber } =
                  values;
                const data = {
                  productId: selectedProduct,
                  quantity,
                  shipmentId: id,
                  cartonNumber: cartonNumber ?? "",
                  trackingNumber: trackingNumber ?? "",
                  lotNumber: lotNumber ?? "",
                  mfgDate,
                  expDate,
                };
                setIsForceLoading(true);

                await dispatch(
                  addShipmentItem({
                    data,
                    shipmentId: id,
                    setIsAddItemModalOpen,
                    fetchData,
                    formAdd,
                    setSelectedProduct,
                  })
                );
                setIsForceLoading(false);
              }}>
              <div className="sm:flex">
                <Form.Item
                  name="productId"
                  className="sm:mr-4 flex-1 w-full"
                  rules={[{ required: true, message: "Select an option" }]}>
                  {/* <Dropdown
                  placeholder="Select one"
                  data={productList?.map((pro: any) => {
                    return {
                      value: pro?.productName,
                      key: pro?.productId,
                    };
                  })}
                  onChange={(value: any) => {
                    console.log("onChange", value);
                  }}
                  title="Product"
                  searchable={true}
                  searchBoxOnChange={async (value: any) => {
                    setSearchableString(value);
                    console.log(value);
                    // await dispatch(
                    //   searchProductListing({
                    //     // setSearchProdsInFocus,
                    //     productList,
                    //     setProductList,
                    //     queryString: value,
                    //     currPage: currIndex * 1,
                    //     setHasMoreProds,
                    //   })
                    // );
                  }}
                  onPopupScroll={async (e: any) => {
                    const { target } = e;
                    if (
                      (target as any).scrollTop +
                        (target as any).offsetHeight ===
                      (target as any).scrollHeight
                    ) {
                      if (!currIndex || currIndex * 1 === 0) {
                        return;
                      }
                      if (currIndex * 1 > maxPageIndex) {
                        return;
                      }
                      setCurrIndex((prev: any) => prev * 1 + 1);
                      localStorage.setItem("productListCurrIndex", currIndex);
                      // getProductListing(currIndex);
                      // if (hasMoreProds) {
                      //   if (searchableString) {
                      //     await dispatch(
                      //       searchProductListing({
                      //         // setSearchProdsInFocus,
                      //         productList,
                      //         setProductList,
                      //         queryString: searchableString,
                      //         currPage: currIndex,
                      //         setHasMoreProds,
                      //       })
                      //     );
                      //   } else {
                      //   }
                      // }
                      // getProductListing(currIndex);
                    }
                  }}
                /> */}
                  <div>
                    <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                      Product
                    </h1>
                    <Select
                      showSearch
                      value={selectedProduct}
                      placeholder="Search Product"
                      style={{ width: "100%", maxWidth: "318px" }}
                      defaultActiveFirstOption={false}
                      suffixIcon={null}
                      className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                      filterOption={false}
                      onSearch={async (value) => {
                        setSearchableString(() => value);
                        setProductList([]);
                        debounceFn(
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              setMaxPageIndex,
                              queryString: value,
                              currPage: 0,
                              setHasMoreProds,
                            })
                          )
                        );
                      }}
                      onSelect={(value: any) => {
                        setSelectedProduct(value);
                      }}
                      notFoundContent={null}
                      options={productList?.map((item: any) => {
                        const { identifications, productId, name } = item;
                        const sku = identifications?.find(
                          (iden: any) => iden?.productIdTypeEnumId === "PidtSku"
                        );
                        const upc = identifications?.find(
                          (iden: any) =>
                            iden?.productIdTypeEnumId === "PidtUpca"
                        );
                        const idValue =
                          sku?.idValue || upc?.idValue || productId || "";
                        return {
                          label: `${idValue && `${idValue} | `}${name || ""}`,
                          value: item?.productId,
                        };
                      })}
                      onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                          (target as any).scrollTop +
                            (target as any).offsetHeight ===
                          (target as any).scrollHeight
                        ) {
                          if (!currIndex || currIndex * 1 === 0) {
                            return;
                          }
                          if (currIndex * 1 > maxPageIndex) {
                            return;
                          }
                          setCurrIndex((prev: any) => prev * 1 + 1);
                          localStorage.setItem(
                            "productListCurrIndex",
                            currIndex
                          );
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              queryString: searchableString,
                              currPage: currIndex,
                              setHasMoreProds,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="quantity"
                  className="flex-1 w-full"
                  rules={[
                    {
                      required: true,
                      message: "Expected Quantity field is required",
                    },
                    {
                      pattern: /^\d+$/,
                      message: "Please enter a valid number",
                    },
                  ]}>
                  <Input
                    title="Expected Quantity"
                    placeholder="Expected Quantity"
                    showDot={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item name="cartonNumber" className="sm:mr-4 flex-1">
                  <Input
                    title="Carton No."
                    placeholder="Carton No."
                    showDot={true}
                  />
                </Form.Item>
                <Form.Item name="trackingNumber" className="flex-1">
                  <Input
                    title="Tracking No."
                    placeholder="Tracking No."
                    showDot={true}
                  />
                </Form.Item>
              </div>
              <Form.Item name="lotNumber">
                <Input title="Lot No." placeholder="Lot No." showDot={true} />
              </Form.Item>
              <div className="sm:flex">
                <Form.Item className="sm:mr-4 flex-1">
                  <DatePicker
                    title="Mfg Date"
                    value={mfgDate ? moment(mfgDate) : ""}
                    onChange={(date: any, dateString: any) => {
                      setMfgDate(dateString);
                    }}
                    isFullWidth={true}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item className="flex-1">
                  <DatePicker
                    title="Exp Date"
                    value={expDate ? moment(expDate) : ""}
                    onChange={(date: any, dateString: any) => {
                      setExpDate(dateString);
                    }}
                    isFullWidth={true}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button
                    widthFull={true}
                    // isDisabled={isDisabled}
                    buttonText="Add"
                  />
                </div>
              </Form.Item>
            </Form>
          </Loader>
        </Modal>
        <Modal
          title="Update Customer Detail"
          visible={isUpdateCustomerDetail}
          footer={null}
          onCancel={() => {
            setIsUpdateCustomerDetail(false);
            formCustomerDetail?.resetFields();
          }}
          width={700}>
          <Loader
            isLoading={isForceLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "white",
              }),
            }}>
            <Form
              form={formCustomerDetail}
              onFinish={async (values: any) => {
                const data = { ...values };

                setIsForceLoading(true);
                if (values?.facility !== vendorDetail?.destinationFacilityId) {
                  await dispatch(
                    updateVendorFacility({
                      shipmentId: id,
                      destinationFacilityId: values.facility,
                    })
                  );
                }
                if (values?.facility !== incomingShipmentDetails?.fromPartyId) {
                  await dispatch(
                    updateVendorFormParty({
                      shipmentId: id,
                      fromPartyId: values.formParty,
                    })
                  );
                }
                await dispatch(
                  updateVendorDetatil({
                    data,
                    shipmentId: id,
                    ownerPartyId: localStorage.getItem("organizationPartyId"),
                    partyId: incomingShipmentDetails?.fromPartyId,
                    postalContactMechId: vendorDetail?.postalContactMechId,
                    emailContactMechId: vendorDetail?.emailContactMechId,
                    telecomContactMechId: vendorDetail?.telecomContactMechId,
                    fetchData,
                  })
                );
                setIsForceLoading(false);
                setIsUpdateCustomerDetail(false);
              }}>
              <Form.Item
                className="sm:mr-4 flex-1"
                name="formParty"
                rules={[{ required: true, message: "Select an option!" }]}>
                <Dropdown
                  isDisabled
                  isSearchBoxVisible={true}
                  searchBoxOnChange={searchBoxOnChange}
                  placeholder="Select one"
                  data={partyIdListing}
                  title="From Party"
                  isPaginated={true}
                  loadProds={loadProdsOnBtnClick}
                  currIndex={currIndex}
                  maxPageIndex={maxPageIndex}
                />
              </Form.Item>
              <Form.Item
                name="toName"
                rules={[{ required: true, message: "Please input to name!" }]}>
                <Input placeholder="" title="Name" autoFocus />
              </Form.Item>
              <Form.Item
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input email Address!",
                  },
                ]}>
                <Input placeholder="" title="Email" autoFocus />
              </Form.Item>
              <Form.Item
                name="address1"
                rules={[
                  { required: false, message: "Address 1 is required!" },
                ]}>
                <Input
                  title="Address 1"
                  placeholder="Address 1"
                  showDot={true}
                />
              </Form.Item>
              <Form.Item name="address2">
                <Input
                  title="Address 2"
                  placeholder="Address 2"
                  showDot={false}
                />
              </Form.Item>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="city"
                  rules={[{ required: false, message: "City is required!" }]}>
                  <Input title="City" placeholder="City" showDot={true} />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="postalCode"
                  rules={[
                    {
                      required: false,
                      message: "Postal Code is required!",
                    },
                  ]}>
                  <Input
                    title="Postal Code"
                    placeholder="Postal Code"
                    showDot={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex items-center">
                <Form.Item
                  className="flex-1"
                  name="countryCode"
                  rules={[
                    {
                      required: false,
                      message: "Country Code is required",
                    },
                  ]}>
                  <Input title="Phone" placeholder="" showDot={true} />
                </Form.Item>
                <p className="mr-1 ml-1">-</p>
                <Form.Item
                  className="flex-1 mt-3"
                  name="areaCode"
                  rules={[
                    {
                      required: false,
                      message: "Area Code is required",
                    },
                  ]}>
                  <Input title="" placeholder="" showDot={true} />
                </Form.Item>
                <p className="mr-1 ml-1">-</p>
                <Form.Item
                  className="flex-1 mt-3"
                  name="contactNumber"
                  rules={[
                    {
                      required: false,
                      message: "Number is required",
                    },
                  ]}>
                  <Input title="" placeholder="" showDot={true} />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="countryGeoId"
                  rules={[{ required: false, message: "Select an option!" }]}>
                  <Dropdown
                    title="Country"
                    placeholder="Country"
                    data={countriesList}
                    onChange={async (value: any) => {
                      formCustomerDetail?.setFieldValue("state", "");
                      await dispatch(getGeoStates(value));
                    }}
                    searchable={true}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="stateProvinceGeoId"
                  rules={[{ required: false, message: "Select an option!" }]}>
                  <Dropdown
                    title="State"
                    placeholder="State"
                    data={statesList}
                    searchable={true}
                  />
                </Form.Item>
              </div>
              <Form.Item
                className="sm:mr-4 flex-1"
                name="facility"
                rules={[{ required: true, message: "Select an option!" }]}>
                <Dropdown
                  title="Facility"
                  placeholder=""
                  data={facilitiesList}
                  searchable={true}
                />
              </Form.Item>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button widthFull={true} buttonText="Update" />
                </div>
              </Form.Item>
            </Form>
          </Loader>
        </Modal>
        <div className="flex flex-col mdlarge:flex-row">
          <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
            <div
              className="flex flex-row items-center mb-6 cursor-pointer w-max"
              onClick={() => {
                history.push("/inventory/incomingShipment");
              }}>
              <MdOutlineKeyboardBackspace
                style={{ fontSize: "20px" }}
                className="text-primary"
              />
              <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                BACK TO RESULTS
              </p>
            </div>
            <div className="bg-white p-5 rounded-lg shadow-sm flex flex-row items-center">
              <span className="text-base sm:text-lg font-bold mr-5">
                PO #{incomingShipmentDetails?.otherPartyOrderId}
              </span>
              <span className="text-gray-500">
                Created {monthNames[new Date(currentEntryDate).getMonth()]}{" "}
                {new Date(currentEntryDate).getDate()},{" "}
                {new Date(currentEntryDate).getFullYear()}
              </span>
            </div>
            <div className="bg-white p-5 pb-6 mb-6 rounded-lg shadow-sm mt-6">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-base font-bold mr-3 mb-4">
                  Customer Contact
                </h1>

                <Button
                  onClick={() => {
                    formCustomerDetail.setFieldsValue({
                      toName: vendorDetail?.toName,
                      emailAddress: vendorDetail?.infoString,
                      address1: vendorDetail?.address1,
                      address2: vendorDetail?.address2,
                      city: vendorDetail?.city,
                      postalCode: vendorDetail?.postalCode,
                      countryCode: vendorDetail?.countryCode,
                      areaCode: vendorDetail?.areaCode,
                      contactNumber: vendorDetail?.contactNumber,
                      countryGeoId: vendorDetail?.countryGeoId,
                      stateProvinceGeoId: vendorDetail?.stateProvinceGeoId,
                      facility: vendorDetail?.destinationFacilityId,
                      formParty: `${incomingShipmentDetails?.fromPartyName}`,
                    });
                    setIsUpdateCustomerDetail(true);
                  }}
                  buttonText="Update"
                />
              </div>
              <div className="flex flex-row">
                <div className="w-1/2">
                  {incomingShipmentDetails?.vendorContactAndDetails &&
                    incomingShipmentDetails?.vendorContactAndDetails?.length >
                      0 &&
                    incomingShipmentDetails?.vendorContactAndDetails?.map(
                      (item: any) => {
                        return (
                          <div className="flex flex-row mb-4 gap-4">
                            <div className="w-2/3">
                              {item?.toName && (
                                <div className="flex flex-row items-center mb-4">
                                  <HiOutlineUser size={23} />
                                  <h1 className="text-primary text-xs font-bold ml-3">
                                    {item?.toName}
                                  </h1>
                                </div>
                              )}
                              {(item?.address1 ||
                                item?.address2 ||
                                item?.city ||
                                item?.postalCode ||
                                item?.countryGeoId) && (
                                <div className="flex flex-row mb-4">
                                  <GoLocation size={22} />
                                  <div className="ml-2.5">
                                    {(item?.address1 || item?.address2) && (
                                      <h1>
                                        {item?.address1 ?? ""},
                                        {item?.address2 ?? ""}
                                      </h1>
                                    )}
                                    <h1>
                                      {" "}
                                      {item?.city ?? `--`},{" "}
                                      {allStatesList?.find(
                                        (state: any) =>
                                          state.geoId ===
                                          item?.stateProvinceGeoId
                                      )?.geoCodeAlpha2 ?? `--`}
                                      , {item?.postalCode ?? `--`},{" "}
                                      {item?.countryGeoId ?? ``}
                                    </h1>
                                  </div>
                                </div>
                              )}
                              {item?.infoString && (
                                <div className="flex flex-row items-center mb-4">
                                  <HiOutlineMail size={23} />
                                  <h1 className="text-primary text-xs font-bold ml-3">
                                    {item?.infoString}
                                  </h1>
                                </div>
                              )}
                              {item?.contactNumber && (
                                <div className="flex flex-row items-center mb-4">
                                  <HiOutlinePhone size={23} />
                                  <h1 className="ml-2.5">
                                    Teh: {item.countryCode}-{item?.areaCode}-
                                    {item?.contactNumber}
                                  </h1>
                                </div>
                              )}
                              {item?.destinationFacilityId && (
                                <div className="flex flex-row items-center mb-4">
                                  <FaWarehouse size={23} />
                                  <h1 className="ml-2.5">
                                    {getFacilityValue(
                                      item?.destinationFacilityId
                                    )}
                                  </h1>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="w-1/2">
                  {incomingShipmentDetails?.toPartyId && (
                    <div className="flex flex-row items-center mb-4 gap-1">
                      <h1 className="font-bold"> To Party: </h1>
                      <h1 className="">
                        {getOwerPartyId(incomingShipmentDetails?.toPartyId)}
                      </h1>
                    </div>
                  )}
                  {incomingShipmentDetails?.fromPartyId && (
                    <div className="flex flex-row items-center mb-4 gap-1">
                      <h1 className="font-bold">From Party: </h1>
                      <h1 className="">
                        {incomingShipmentDetails?.fromPartyName}
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mdlarge:w-4/12 ml-0 mdlarge:ml-3 mt-6 mdlarge:mt-0">
            <div className="bg-white p-5 pb-2 rounded-lg shadow-sm mb-6">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-base font-bold mr-3 mb-3">Shipment Info</h1>
                <div
                  className="flex flex-row items-center cursor-pointer mb-3"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/fop/apps/PopcAdmin/Shipment/IncomingShipmentPDF?filename=IncomingShipmentPDF-${id}.pdf&shipmentId=${id}`;
                  }}>
                  <RiDownloadLine
                    style={{ fontSize: "16px" }}
                    className="text-primary"
                  />
                  <p className="ml-1 text-xs font-bold text-primary">PDF</p>
                </div>
              </div>
              <div className="flex flex-row items-center  pb-6 border-gray-300">
                <h1 className="text-sm font-bold mr-5">Status</h1>
                <Dropdown
                  width={"100%"}
                  isDisabled
                  data={[]}
                  placeholder="Select Status"
                  value={incomingShipmentDetails.shipmentStatus}
                  onChange={(val: any) => {
                    setStatusDropdownVal(val);
                  }}
                />
              </div>
              <div className="pb-5">
                <h1 className="text-sm font-bold mr-5">Progress</h1>
                <ProgressLine visualParts={visualParts} />
                <div>
                  <div className="flex flex-row items-center mb-1">
                    <div
                      className="rounded-full w-4 h-4"
                      style={{ backgroundColor: "#5fbc54" }}></div>
                    <span className="px-5 w-1/2">Items Received</span>
                    <span className="pl-8">
                      {visualParts &&
                        visualParts.length > 0 &&
                        visualParts[0]?.value}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-1">
                    <div
                      className="rounded-full w-4 h-4"
                      style={{ backgroundColor: "#e11f20" }}></div>
                    <span className="px-5 w-1/2">Items Rejected</span>
                    <span className="pl-8">
                      {visualParts &&
                        visualParts.length > 0 &&
                        visualParts[1]?.value}
                    </span>
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className="rounded-full w-4 h-4"
                      style={{ backgroundColor: "#b3b3b3" }}></div>
                    <span className="px-5 w-1/2">Items Remaining</span>
                    <span className="pl-8">
                      {visualParts &&
                        visualParts.length > 0 &&
                        visualParts[2]?.value}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex justify-between">
            <div className="mb-4">
              <Button
                onClick={() => setIsModalOpen(true)}
                buttonText="Import"
              />
              <Button
                onClick={() => setIsAddItemModalOpen(true)}
                buttonText="Add Item"
                classNames="ml-2"
              />
            </div>
          </div>
        </div>
        <div className="bg-white p-5 rounded-lg shadow-sm mt-5 mb-5">
          <h1 className="mb-4 font-bold text-base">Items</h1>
          <div>
            <Table
              columns={itemsColumns}
              dataSource={itemsList}
              isPaginated={false}
              isServerRendered={recordCount}
              rowKey="productId"
              recordCount={recordCount}
              onChange={onChange}
            />
          </div>
        </div>
        <CountDown setShouldRerender={setShouldRerender} />
        <ImportListing
          importType="Shipment_Item_Data_Import"
          showLoader={false}
          shouldRerender={shouldRerender}
          setShouldRerender={setShouldRerender}
          shipmentId={id}
        />
      </Loader>
    </div>
  );
};

export default IncomingShipemtDetails;
