import React, { ReactElement, useEffect, useState } from "react";
import Loader from "./Loader";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { Collapse, Form, Modal, Tabs } from "antd";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Input from "./Input";
import DatePicker from "./DatePicker";
import Table from "./Table";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getFacilityInfo,
  getEnumTypeList,
  getOrgnizationPartyId,
  getBoxTypes,
  getLocationList,
  getLocationCapacityUOMList,
  getProductFacilityList,
  getAllProductListing,
  getFacilityLocationTypeDetail,
  getStatusList,
  getPrinterFacilityList,
} from "../redux/authSlice";
import { AiOutlineFilter } from "react-icons/ai";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

function FacilityDetail({ facilityId }: { facilityId: string }): ReactElement {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [updateForm] = Form.useForm();
  const [locationForm] = Form.useForm();
  const [printerForm] = Form.useForm();
  const [productForm] = Form.useForm();
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isUpdateFacilityModalOpen, setIsUpdateFacilityModalOpen] =
    useState<any>(false);
  const [printerModalOpen, setPrinterModalOpen] = useState<any>(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState<any>(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState<any>(false);
  const [isAddBoxTypeModalOpen, setIsAddBoxTypeModalOpen] =
    useState<any>(false);
  const [isLocationTypeModalOpen, setIsLocationTypeModalOpen] =
    useState<any>(false);
  const [facilityInfo, setFacilityInfo] = useState<any>([]);
  const [facilityType, setFacilityType] = useState<any>([]);
  const [facilityLocationType, setFacilityLocationType] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);
  const [boxType, setBoxType] = useState<any>([]);
  const [locationList, setLocationList] = useState<any>([]);
  const [printerFacilityList, setPrinterFacilityList] = useState<any>([]);
  const [locationCapacityUOMList, setLocationCapacityUOMList] = useState<any>(
    []
  );
  const [printerStatus, setPrinterStatus] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currPrinterPage, setCurrPrinterPage] = useState<any>("");
  const [recordCountPrinterPage, setRecordCountPrinterPage] = useState("");
  const [locationListPageIndex, setLocationListPageIndex] = useState<any>("1");
  const [locationListMaxPageIndex, setLocationListMaxPageIndex] =
    useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [productFacilityList, setProductFacilityList] = useState<any>([]);
  const [allProductListing, setAllProductListing] = useState<any>([]);
  const [facilityLocationTypeDetail, setFacilityLocationTypeDetail] =
    useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [sortData, setSortData] = useState<any>("");
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const statusFilters: any = [];
  useEffect(() => {
    fetchData();
    facilityPrinter();
  }, []);
  const fetchData = async () => {
    let passedIndexLocationListing = localStorage.getItem(
      "passedIndexLocationListing"
    );
    setIsLoading(true);
    await dispatch(
      getFacilityInfo({
        setFacilityInfo,
        facilityId: facilityId,
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setFacilityType,
        enumTypeId: "FacilityType",
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType: setFacilityLocationType,
        enumTypeId: "FacilityLocationType",
      })
    );
    await dispatch(
      getOrgnizationPartyId({
        setOwnerPartyIdList,
      })
    );
    await dispatch(
      getBoxTypes({
        facilityId: facilityId,
        setBoxType,
      })
    );
    await dispatch(
      getLocationList({
        facilityId: facilityId,
        setLocationList,
        setRecordCount,
        setLocationListMaxPageIndex,
        pageIndex: passedIndexLocationListing ? passedIndexLocationListing : 0,
      })
    );
    await dispatch(
      getLocationCapacityUOMList({
        setLocationCapacityUOMList,
      })
    );
    await dispatch(
      getProductFacilityList({
        facilityId: facilityId,
        setProductFacilityList,
      })
    );
    await dispatch(
      getAllProductListing({
        setAllProductListing,
      })
    );
    await dispatch(
      getFacilityLocationTypeDetail({
        facilityId: facilityId,
        setFacilityLocationTypeDetail,
      })
    );

    await dispatch(
      getStatusList({
        setStatus,
        statusTypeId: "Asset",
      })
    );
    await dispatch(
      getStatusList({
        setStatus: setPrinterStatus,
        statusTypeId: "PrinterStatus",
      })
    );
    setIsLoading(false);
  };

  const facilityPrinter = async () => {
    setIsLoading(true);
    await dispatch(
      getPrinterFacilityList({
        facilityId: "FP_FACILITY",
        setPrinterFacilityList,
        setMaxPageIndex,
        setRecordCountPrinterPage,
        orderByFields: sortData,
        pageIndex: 0,
        appliedFilters: "",
      })
    );

    setIsLoading(false);
  };
  const printerNextPage = async (
    pageIndex: any,
    orderByFields?: any,
    appliedFilters?: any
  ) => {
    setIsLoading(true);
    await dispatch(
      getPrinterFacilityList({
        facilityId: "FP_FACILITY",
        setPrinterFacilityList,
        setMaxPageIndex,
        setRecordCountPrinterPage,
        pageIndex,
        orderByFields,
        appliedFilters,
      })
    );
    setIsLoading(false);
  };
  printerStatus.forEach((item: any) => {
    statusFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  const loadPrinterpage = (index: any) => {
    if (!currPrinterPage || currPrinterPage * 1 === 0) {
      return;
    }
    if (currPrinterPage * 1 > maxPageIndex) {
      return;
    }
    const passedIndex: any = index * 1 - 1;
    currPrinterPage(index);
    let orderByFields = sortData;
    printerNextPage(passedIndex, orderByFields, activeAppliedFilters);
  };
  const boxTypeColumns: any = [
    {
      render: (productIdTypeEnumId: string, data: any, index: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1 className="" onClick={() => {}}>
                {/* <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" /> */}
              </h1>
            </div>
          </div>
        );
      },
      dataIndex: "delete",
    },
    {
      dataIndex: "description",
      render: (boxType: string) => {
        return <div className="flex flex-row items-center">{boxType}</div>;
      },
    },
    {
      dataIndex: "description",
      render: (boxType: string) => {
        return <div className="flex flex-row items-center">{boxType}</div>;
      },
    },
  ];
  const locationListNextSet = async (
    pageIndex: any
    // orderByFields: any
  ) => {
    setIsLoading(true);
    await dispatch(
      getLocationList({
        facilityId: facilityId,
        setLocationList,
        setRecordCount,
        setLocationListMaxPageIndex,
        pageIndex,
      })
    );

    setIsLoading(false);
  };
  const loadNextProds = (index: any) => {
    if (!locationListPageIndex || locationListPageIndex * 1 === 0) {
      return;
    }
    if (locationListMaxPageIndex * 1 > locationListMaxPageIndex) {
      return;
    }
    const passedIndex: any = index * 1 - 1;
    setLocationListPageIndex(index);
    localStorage.setItem("passedIndexLocationListing", passedIndex);
    // let orderByFields = sortData;
    locationListNextSet(passedIndex);
  };
  const locationTypeColumns: any = [
    {
      render: (productIdTypeEnumId: string, data: any, index: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1 className="" onClick={() => {}}>
                {/* <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" /> */}
              </h1>
            </div>
          </div>
        );
      },
      dataIndex: "delete",
    },
    {
      render: (location: string) => {
        return (
          <div className="flex flex-row items-center">
            {
              facilityLocationType?.find((item: any) => item?.key === location)
                ?.value
            }
          </div>
        );
      },
      dataIndex: "locationTypeEnumId",
    },
    {
      render: (statusType: string) => {
        return (
          <div className="flex flex-row items-center">
            {status?.find((item: any) => item?.key === statusType)?.value}
          </div>
        );
      },
      dataIndex: "autoStatusId",
    },
  ];
  const locationColumns: any = [
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p>ID</p>
        </div>
      ),
      render: (Id: any) => {
        return <div className="item-center">{Id}</div>;
      },
      dataIndex: "locationSeqId",
    },
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p>Type</p>
        </div>
      ),
      render: (locationTypeEnumId: any) => {
        return (
          <div className="item-center">
            {
              facilityLocationType?.find(
                (item: any) => item?.key === locationTypeEnumId
              )?.value
            }
          </div>
        );
      },
      dataIndex: "locationTypeEnumId",
    },
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p>Name</p>
        </div>
      ),
      render: (name: string) => {
        return <div className="item-center">{name}</div>;
      },
      dataIndex: "description",
    },
    {
      title: (
        <div className="flex item-center h-5 relative h-5 leading-3">
          <p>Seq#</p>
        </div>
      ),
      render: (seqId: any) => {
        return <div className="item-center">{seqId}</div>;
      },
      dataIndex: "sequenceNum",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Area</p>
        </div>
      ),
      render: (area: any) => {
        return <div className="item-center">{area}</div>;
      },
      dataIndex: "areaId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Asile</p>
        </div>
      ),
      render: (asile: any) => {
        return <div className="item-center">{asile}</div>;
      },
      dataIndex: "aisleId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">Section</div>
      ),
      render: (section: any) => {
        return <div className="item-center">{section}</div>;
      },
      dataIndex: "sectionId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">Level</div>
      ),
      render: (level: any) => {
        return <div className="item-center">{level}</div>;
      },
      dataIndex: "levelId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">Position</div>
      ),
      render: (position: any) => {
        return <div className="item-center">{position}</div>;
      },
      dataIndex: "positionId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">Capacity</div>
      ),
      render: (capacity: any) => {
        return <div className="item-center">{capacity}</div>;
      },
      dataIndex: "capacity",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          Capacity UoM
        </div>
      ),
      render: (capacityUomId: any) => {
        return (
          <div className="item-center">
            {
              locationCapacityUOMList?.find(
                (item: any) => item?.uomId === capacityUomId
              )?.typeDescription
            }{" "}
            -{" "}
            {
              locationCapacityUOMList?.find(
                (item: any) => item?.uomId === capacityUomId
              )?.description
            }
          </div>
        );
      },
      dataIndex: "capacityUomId",
    },
  ];
  const productColumns: any = [
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p className="text-center">Product</p>
        </div>
      ),
      render: (productId: any, data: any) => (
        <Link
          to={{
            // pathname: `/facility/${product}`,
            state: { productId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">{`${productId}:${data?.productName}`}</h1>
        </Link>
      ),
      dataIndex: "productId",
    },
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p className="text-center">Minimum Stock</p>
        </div>
      ),
      render: (minimumStock: any) => {
        return <div className="item-center">{minimumStock}</div>;
      },
      dataIndex: "minimumStock",
    },
    {
      title: (
        <div className="flex item-center h-5 relative h-5 leading-3">
          <p className="text-center">Recorder Quantity</p>
        </div>
      ),
      render: (recorderQty: any) => {
        return <div className="item-center">{recorderQty}</div>;
      },
      dataIndex: "reorderQuantity",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Days To Ship</p>
        </div>
      ),
      render: (dayShip: any) => {
        return <div className="item-center">{dayShip}</div>;
      },
      dataIndex: "daysToShip",
    },
  ];
  const printerColumns: any = [
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p className="text-center">Printer Name</p>
        </div>
      ),
      render: (printerName: any) => {
        return <div className="item-center">{printerName}</div>;
      },
      dataIndex: "printerName",
    },
    {
      title: (
        <div className="flex item-center relative h-5 leading-3">
          <p className="text-center">IP Address</p>
        </div>
      ),
      render: (ipAddress: any) => {
        return <div className="item-center">{ipAddress}</div>;
      },
      dataIndex: "ipAddress",
    },
    {
      title: (
        <div className="flex item-center h-5 relative h-5 leading-3">
          <p className="text-center">Server Port</p>
        </div>
      ),
      render: (serverPort: any) => {
        return <div className="item-center">{serverPort}</div>;
      },
      dataIndex: "serverPort",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Status</p>
        </div>
      ),
      filters: statusFilters,
      filterIcon: () => {
        return filteredInfo.statusId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },

      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.statusId === "number") {
            return a.statusId - b.statusId;
          } else {
            return a.statusId?.localeCompare(b.statusId);
          }
        },
        multiple: 1,
      },
      filteredValue: filteredInfo?.statusId || null,
      onFilter: (value: any, record: any) => record?.statusId.includes(value),
      sortOrder:
        sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
      render: (statusId: any) => {
        return (
          <div
            style={{
              backgroundColor: "#093479",
              outlineColor: "#707070",
              border: "1px solid #707070",
            }}
            className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
          >
            {printerStatus?.find((item: any) => item?.key === statusId)?.value}
          </div>
        );
      },
      dataIndex: "statusId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Secure</p>
        </div>
      ),
      render: (secure: any) => {
        return <div className="item-center">{secure}</div>;
      },
      dataIndex: "secure",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Asset ID</p>
        </div>
      ),
      render: (assetId: any) => {
        return <div className="item-center">{assetId}</div>;
      },
      dataIndex: "assetId",
    },
    {
      title: (
        <div className="flex item-center h-5 relative leading-3">
          <p>Description</p>
        </div>
      ),
      render: (description: any) => {
        return <div className="item-center">{description}</div>;
      },
      dataIndex: "description",
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    setFilteredInfo(filters);
    let appliedFilters = "";
    if (filters.statusId) {
      if (!appliedFilters) {
        appliedFilters += `statusId=${filters.statusId}`;
      } else {
        appliedFilters += `&statusId=${filters.statusId}`;
      }
    }
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    console.log("outputStr", outputStr);
    if (appliedFilters) {
      printerNextPage(0, outputStr, appliedFilters);
    } else if (recordCount) {
      printerNextPage(0, outputStr, "");
    }
  }
  return (
    <>
      <div>
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          <div className="flex flex-col mdlarge:flex-row mb-3">
            <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
              <div
                className="flex flex-row items-center mb-6 cursor-pointer w-max"
                onClick={() => {
                  history.push("/facility/facilityList");
                }}
              >
                <MdOutlineKeyboardBackspace
                  style={{ fontSize: "20px" }}
                  className="text-primary"
                />
                <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                  BACK TO RESULTS
                </p>
              </div>
            </div>
          </div>
          <Tabs type="card" className="mb-10">
            <TabPane tab="Facility" key="1">
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-7/12 mr-0 mdlarge:mr-3">
                  <div className="bg-white p-5 mb-5 rounded-lg shadow-sm">
                    <span className="text-base sm:text-lg font-bold mr-5">
                      Facility ID # {facilityId}
                    </span>
                  </div>
                  <div className="bg-white p-5 rounded-lg shadow-sm">
                    {/* <div className="flex gap-4 justify-end mb-4">
                  <Button buttonText="Update" />
                </div> */}
                    <div className="">
                      <div className="flex flex-row gap-5 justify-between">
                        <div className="flex flex-row gap-5 mt-5">
                          <div className="text-sm font-bold w-24">Name</div>
                          <div>{facilityInfo?.facilityName}</div>
                        </div>
                        {/* <div>
                          <Button
                            buttonText="Update"
                            onClick={() => setIsUpdateFacilityModalOpen(true)}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Type</div>

                        <div>
                          {
                            facilityType?.find(
                              (item: any) =>
                                item?.key === facilityInfo?.facilityTypeEnumId
                            )?.value
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Owner</div>
                        {
                          <div>
                            {
                              ownerPartyIdList?.find(
                                (item: any) =>
                                  item?.key === facilityInfo?.ownerPartyId
                              )?.value
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">
                          Parent Facility
                        </div>
                        {
                          <div>
                            {facilityInfo?.parentFacilityInfo?.facilityName}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Size/UoM</div>
                        {
                          <div>
                            {facilityInfo?.parentFacilityInfo?.facilitySize}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row">
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">Opened</div>
                        {facilityInfo?.parentFacilityInfo?.openedDate
                          ? moment(
                              facilityInfo?.parentFacilityInfo?.openedDate
                            )?.format("YYYY-MM-DD")
                          : ""}
                      </div>
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">Closed</div>
                        {facilityInfo?.parentFacilityInfo?.closedDate
                          ? moment(
                              facilityInfo?.parentFacilityInfo?.closedDate
                            )?.format("YYYY-MM-DD")
                          : ""}
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row">
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Asset Allow Issue Over QOH
                        </div>
                        {
                          <div>
                            {
                              facilityInfo?.parentFacilityInfo
                                ?.assetAllowIssueOverQoh
                            }
                          </div>
                        }
                      </div>
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Asset Allow Other Owner
                        </div>
                        {
                          <div>
                            {
                              facilityInfo?.parentFacilityInfo
                                ?.assetAllowOtherOwner
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="mt-4 flex flex-row">
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Require Inventory Location
                        </div>
                        {
                          <div>
                            {
                              facilityInfo?.parentFacilityInfo
                                ?.assetInventoryLocRequire
                            }
                          </div>
                        }
                      </div>
                      <div className="flex flex-row gap-5 w-1/2">
                        <div className="text-sm font-bold w-24">
                          Default Days to Ship
                        </div>
                        {
                          <div>
                            {
                              facilityInfo?.parentFacilityInfo
                                ?.defaultDaysToShip
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">To Name</div>
                        <div>{facilityInfo?.toName}</div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Attn Name</div>
                        <div>{facilityInfo?.attnName}</div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-5 rounded-lg shadow-sm mt-3">
                    {/* <div className="flex gap-4 justify-end mb-4">
                  <Button buttonText="Update" />
                </div> */}

                    <div>
                      <strong>Address Details</strong>
                    </div>

                    <div className="mt-6">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Address 1</div>
                        {facilityInfo?.address1 ?? ""}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Address 2</div>
                        {facilityInfo?.address2 ?? ""}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">City/Zip</div>
                        {`${facilityInfo?.city ?? ""}   ${
                          facilityInfo?.postalCode ?? ""
                        }   ${facilityInfo?.postalCodeExt ?? ""}`}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">
                          Country/State
                        </div>
                        {`${
                          facilityInfo?.contactInfo?.postalAddressCountryGeo
                            ?.geoName ?? ""
                        }/${
                          facilityInfo?.contactInfo?.postalAddressStateGeo
                            ?.geoName ?? ""
                        }`}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">
                          Address Trust
                        </div>
                        {facilityInfo?.contactInfo?.postalTrustLevelEnum
                          ?.description ?? ""}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">Lat/Long</div>
                        <div>
                          {`${
                            facilityInfo?.latitude
                              ? facilityInfo?.latitude
                              : "NA"
                          }/${
                            facilityInfo?.latitude
                              ? facilityInfo?.latitude
                              : "NA"
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">
                          Email Address
                        </div>
                        {facilityInfo?.emailAddress ?? ""}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-row gap-5">
                        <div className="text-sm font-bold w-24">
                          Description
                        </div>
                        <div>
                          <h1>{facilityInfo?.description}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full mdlarge:w-5/12 ml-0 mdlarge:ml-3 ">
                  {/* <Collapse
                    className="bg-white rounded-lg shadow-sm mb-3"
                    defaultActiveKey={["1"]}
                    ghost
                    expandIconPosition="left">
                    <Panel
                      header={
                        <span className="text-base font-bold">
                          Child Facilities
                        </span>
                      }
                      key="1">
                      <Table
                        rowKey="childFacilities"
                        dataSource={childFacilitiesData}
                        columns={childFaciliesColumns}
                        isPaginated={false}
                      />
                    </Panel>
                  </Collapse> */}
                  <Collapse
                    className="bg-white rounded-lg shadow-sm sm:mt-2 lg:mt-0 mb-3"
                    defaultActiveKey={["1"]}
                    ghost
                    expandIconPosition="left"
                  >
                    <Panel
                      header={
                        <span className="text-base font-bold">Box Types</span>
                      }
                      key="1"
                    >
                      <Table
                        rowKey="boxType"
                        dataSource={boxType}
                        columns={boxTypeColumns}
                        isPaginated={false}
                        showHeader={false}
                      />
                    </Panel>
                  </Collapse>
                  <Collapse
                    className="bg-white rounded-lg shadow-sm mb-3"
                    defaultActiveKey={["1"]}
                    ghost
                    expandIconPosition="left"
                  >
                    <Panel
                      header={
                        <span className="text-base font-bold">
                          Location Types
                        </span>
                      }
                      key="1"
                    >
                      <Table
                        rowKey="locationType"
                        dataSource={facilityLocationTypeDetail}
                        columns={locationTypeColumns}
                        isPaginated={false}
                        showHeader={false}
                      />
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Locations" key="2">
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                  <div className="bg-white p-3 mb-5 rounded-lg shadow-sm">
                    {/* <div className="mb-2 flex justify-end">
                      <Button
                        buttonText="New Location"
                        onClick={() => setIsLocationModalOpen(true)}></Button>
                    </div> */}
                    <Table
                      rowKey="location"
                      dataSource={locationList}
                      columns={locationColumns}
                      isPaginated={true}
                      loadNextProds={loadNextProds}
                      isServerRendered={recordCount ? true : false}
                      recordCount={recordCount}
                      // onChange={onChange}
                      // resetInputField={resetInputField}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Products" key="3">
              <div className="flex flex-col mdlarge:flex-row mb-3">
                <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                  <div className="bg-white p-3 mb-5 rounded-lg shadow-sm">
                    {/* <div className="mb-2 flex justify-end">
                      <Button
                        buttonText="Add Product"
                        onClick={() => setIsProductModalOpen(true)}
                      />
                    </div> */}
                    <Table
                      rowKey="product"
                      dataSource={productFacilityList}
                      columns={productColumns}
                      isPaginated={false}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Printers" key="4">
              <div className="w-full mdlarge:w-12/12 mr-0 mdlarge:mr-3">
                <div className="bg-white p-3 mb-5 rounded-lg shadow-sm">
                  <div className="flex flex-row justify-between">
                    <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
                      <div className="flex flex-row items-center">
                        <button
                          onClick={async () => {
                            setIsLoading(true);
                            await dispatch(
                              getPrinterFacilityList({
                                facilityId: "FP_FACILITY",
                                setPrinterFacilityList,
                                setMaxPageIndex,
                                setRecordCountPrinterPage,
                                orderByFields: "",
                                pageIndex: 0,
                                // queryString:searchableString,
                                appliedFilters: "",
                              })
                            );
                            localStorage.removeItem("prevAppliedFilter");
                            setActiveAppliedFilters("");
                            setFilteredInfo({});
                            setIsLoading(false);
                          }}
                          className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                        >
                          Clear Filter
                        </button>
                        <button
                          onClick={async () => {
                            setIsLoading(true);
                            await dispatch(
                              getPrinterFacilityList({
                                facilityId: "FP_FACILITY",
                                setPrinterFacilityList,
                                setMaxPageIndex,
                                setRecordCountPrinterPage,
                                orderByFields: "",
                                pageIndex:
                                  currPrinterPage > 0
                                    ? currPrinterPage * 1 - 1
                                    : 0,
                                appliedFilters: activeAppliedFilters,
                              })
                            );
                            // localStorage.removeItem("prevAppliedFilter");
                            setSortedInfo({});
                            setSortData("");
                            setIsLoading(false);
                          }}
                          className="ml-4 mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                        >
                          Clear Sorter
                        </button>
                      </div>
                    </div>
                  </div>
                  <Table
                    rowKey="printer"
                    dataSource={printerFacilityList}
                    columns={printerColumns}
                    isPaginated={true}
                    loadNextProds={loadPrinterpage}
                    recordCount={recordCountPrinterPage}
                    onChange={onChange}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
          <Modal
            title="Add Person"
            visible={isAddBoxTypeModalOpen}
            footer={null}
            onCancel={() => {
              setIsAddBoxTypeModalOpen(false);
              // form?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Loader
              isLoading={isLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "white",
                }),
              }}
            >
              <Form
                form={form}
                // onFinish={async (values: any) => {
                //   setIsForceLoading(true);

                //   setIsForceLoading(false);
                // }}
              >
                <Form.Item name="boxType" className="sm:mr-4 flex-1 w-full">
                  <Dropdown
                    searchable={true}
                    title="Box Type"
                    data={[]}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>

                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add Box Type" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Add Person"
            visible={isLocationTypeModalOpen}
            footer={null}
            onCancel={() => {
              setIsLocationTypeModalOpen(false);
              // form?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Loader
              isLoading={isLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "white",
                }),
              }}
            >
              <Form
                form={form}
                // onFinish={async (values: any) => {
                //   setIsForceLoading(true);

                //   setIsForceLoading(false);
                // }}
              >
                <Form.Item
                  name="locationType"
                  className="sm:mr-4 flex-1 w-full"
                >
                  <Dropdown
                    searchable={true}
                    title="Add Location Type"
                    data={[]}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>

                <Form.Item name="status" className="sm:mr-4 flex-1 w-full">
                  <Dropdown
                    title="Auto Status"
                    data={[]}
                    placeholder="Select one"
                    width="100%"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Add Location Type" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Update Facility Detail"
            visible={isUpdateFacilityModalOpen}
            footer={null}
            width={700}
            onCancel={() => {
              setIsUpdateFacilityModalOpen(false);
              updateForm?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Loader
              isLoading={isLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "white",
                }),
              }}
            >
              <Form
                name="update_facility"
                form={updateForm}
                // initialValues={{
                //   name: workEffortDetail?.name,
                //   facility: workEffortDetail?.facility,
                //   ownerOrg: workEffortDetail?.ownerOrg,
                //   store: workEffortDetail?.store,
                //   type: workEffortDetail?.type,
                //   estStart: moment(workEffortDetail?.startDate),
                //   estHours: workEffortDetail?.estHours,
                //   actStart: workEffortDetail?.actualStart,
                //   actComplete: workEffortDetail?.actualComplete,
                // }}
                className="px-2"
                onFinish={async (values: any) => {
                  setIsLoading(true);
                  const data = { ...values };

                  setIsLoading(false);
                }}
              >
                <div className="sm:flex gap-4">
                  <Form.Item name="name" className="flex-1">
                    <Input title="Name" placeholder="" showDot={false} />
                  </Form.Item>
                  <Form.Item name="parentFacility" className="flex-1">
                    <Dropdown
                      title="Parent Facility"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-4">
                  <Form.Item
                    name="type"
                    className="flex-1"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "This is required field!",
                    //   },
                    // ]}
                  >
                    <Dropdown
                      title="Type"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="ownerOrg" className="flex-1">
                    <Dropdown
                      title="Owner"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-4">
                  <Form.Item
                    name="size"
                    className="flex-1 w-1/2 pr-2"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "This is required field!",
                    //   },
                    // ]}
                  >
                    <Input title="Size" placeholder="Select One" />
                  </Form.Item>
                  <Form.Item name="uom" className="flex-1 w-1/2">
                    <Dropdown
                      title="UoM"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex"></div>
                <div className="sm:flex gap-4">
                  <Form.Item name="store" className="flex-1 w-1/2 pr-2">
                    <Dropdown
                      title="Open"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="type" className="flex-1 w-1/2">
                    <Dropdown
                      title="Close"
                      allowClear={true}
                      placeholder="Select One"
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-4">
                  <Form.Item name="store" className="flex-1 w-1/2 pr-2">
                    <Dropdown
                      title="Asset Allow Issue Over QOH"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="type" className="flex-1 w-1/2">
                    <Dropdown
                      title="Asset Allow Other Owner"
                      allowClear={true}
                      placeholder="Select One"
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-4">
                  <Form.Item name="store" className="flex-1 w-1/2 pr-2">
                    <Dropdown
                      title="Require Inventory Location"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="type" className="flex-1 w-1/2">
                    <Dropdown
                      title="Default Days to Ship"
                      allowClear={true}
                      placeholder="Select One"
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="estStart" className="w-full">
                    <DatePicker
                      isFullWidth
                      title="Est. Start"
                      dateFormat="DD-MM-YYYY"
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item name="estHours" className="w-full sm:ml-4">
                    <Input
                      title="Est. Hours"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="toName" className="w-full">
                    <Input title="To Name" placeholder="" showDot={false} />
                  </Form.Item>
                  <Form.Item name="attName" className="w-full sm:ml-4">
                    <Input title="Attn Name" placeholder="" showDot={false} />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="address1" className="w-full">
                    <Input title="Address 1" placeholder="" showDot={false} />
                  </Form.Item>
                  <Form.Item name="address2" className="w-full sm:ml-4">
                    <Input title="Address 2" placeholder="" showDot={false} />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="city" className="w-full">
                    <Input
                      title="City"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                  <Form.Item name="zip" className="w-full sm:ml-4">
                    <Input title="Zip" placeholder="" showDot={false} />
                  </Form.Item>
                </div>
                <div className="sm:flex gap-4">
                  <Form.Item name="country" className="flex-1 w-1/2 pr-2">
                    <Dropdown
                      title="Country"
                      placeholder="Select One"
                      allowClear={true}
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item name="type" className="flex-1 w-1/2">
                    <Dropdown
                      title="State"
                      allowClear={true}
                      placeholder="Select One"
                      data={[]}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="addresTrust">
                    <Input
                      title="Address Trust"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="latitude" className="w-full">
                    <Input
                      title="Latitude"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                  <Form.Item name="Longitude" className="w-full sm:ml-4">
                    <Input
                      title="Attn Name"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="phone" className="w-full">
                    <Input
                      title="Phone"
                      inputType="number"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                  <Form.Item name="email" className="w-full sm:ml-4">
                    <Input
                      title="Email Address"
                      placeholder=""
                      showDot={false}
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex justify-between w-full">
                  <Form.Item name="description" className="w-full">
                    <Input
                      title="Description"
                      placeholder=""
                      multiline
                      showDot={false}
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="New Location"
            visible={isLocationModalOpen}
            footer={null}
            width={700}
            onCancel={() => {
              setIsLocationModalOpen(false);
              locationForm?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Form
              name="location_Form"
              form={locationForm}
              className="px-2"
              onFinish={async (values: any) => {
                setIsLoading(true);

                setIsLoading(false);
              }}
            >
              <div className="sm:flex gap-4">
                <Form.Item name="facility" className="flex-1">
                  <Input
                    title="Facility"
                    placeholder=""
                    showDot={false}
                    isDisabled
                  />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="type" className="flex-1 w-1/2 pr-2">
                  <Dropdown
                    title="Type"
                    placeholder="Select One"
                    allowClear={true}
                    data={[]}
                    searchable={true}
                  />
                </Form.Item>
                <Form.Item name="locationId" className="flex-1 w-1/2">
                  <Input title="Location ID" placeholder="" showDot={false} />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="name" className="flex-1 w-1/2 pr-2">
                  <Input title="Name" placeholder="Select One" />
                </Form.Item>
                <Form.Item name="area" className="flex-1 w-1/2">
                  <Input title="Area" placeholder="Select One" />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="asile" className="flex-1 w-1/2 pr-2">
                  <Input title="Asile" placeholder="Select One" />
                </Form.Item>
                <Form.Item name="section" className="flex-1 w-1/2">
                  <Input title="Section" placeholder="Select One" />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="level" className="flex-1 w-1/2 pr-2">
                  <Input title="Level" placeholder="Select One" />
                </Form.Item>
                <Form.Item name="position" className="flex-1 w-1/2">
                  <Input title="Position" placeholder="Select One" />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button widthFull={true} buttonText="Create" />
                </div>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Add Product "
            visible={isProductModalOpen}
            footer={null}
            width={700}
            onCancel={() => {
              setIsProductModalOpen(false);
              productForm?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Form
              name="printer"
              form={productForm}
              className="px-2"
              onFinish={async (values: any) => {
                setIsLoading(true);

                setIsLoading(false);
              }}
            >
              <div className="sm:flex gap-4">
                <Form.Item name="facility" className="flex-1">
                  <Input
                    title="Facility"
                    placeholder=""
                    showDot={false}
                    isDisabled
                  />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="product" className="flex-1 w-1/2 pr-2">
                  <Dropdown
                    title="Product"
                    placeholder="Select One"
                    allowClear={true}
                    data={[]}
                    searchable={true}
                  />
                </Form.Item>
                <Form.Item name="minimumStock" className="flex-1 w-1/2">
                  <Input title="Minimum Stock" placeholder="" showDot={false} />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="reorderQty" className="flex-1 w-1/2 pr-2">
                  <Input title="Reorder Quantity" placeholder="Select One" />
                </Form.Item>
                <Form.Item name="dayToShip" className="flex-1 w-1/2">
                  <Input title="Days To Ship" placeholder="Select One" />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button widthFull={true} buttonText="Add" />
                </div>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="New Printer"
            visible={printerModalOpen}
            footer={null}
            width={700}
            onCancel={() => {
              setPrinterModalOpen(false);
              printerForm?.resetFields();
              // setSelectedProduct(null);
            }}
          >
            <Form
              name="printer"
              form={printerForm}
              className="px-2"
              onFinish={async (values: any) => {
                setIsLoading(true);

                setIsLoading(false);
              }}
            >
              <div className="sm:flex gap-4">
                <Form.Item name="facility" className="flex-1">
                  <Input
                    title="Facility"
                    placeholder=""
                    showDot={false}
                    isDisabled
                  />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="printerName" className="flex-1 w-1/2 pr-2">
                  <Input title="Printer Name" placeholder="" showDot={false} />
                </Form.Item>
                <Form.Item name="purpose" className="flex-1 w-1/2">
                  <Dropdown
                    title="Purpose"
                    placeholder="Select One"
                    allowClear={true}
                    data={[]}
                    searchable={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex gap-4">
                <Form.Item name="ipAdd" className="flex-1 w-1/2 pr-2">
                  <Input title="IP Address" placeholder="Select One" />
                </Form.Item>
                <Form.Item name="serverPort" className="flex-1 w-1/2">
                  <Input title="Server Port" placeholder="Select One" />
                </Form.Item>
              </div>
              <div className="sm:flex"></div>
              <div className="sm:flex gap-4">
                <Form.Item name="status" className="flex-1 w-1/2 pr-2">
                  <Dropdown
                    title="Status"
                    placeholder="Select One"
                    allowClear={true}
                    data={[]}
                    searchable={true}
                  />
                </Form.Item>
                <Form.Item name="secure" className="flex-1 w-1/2">
                  <Dropdown
                    title="Secure"
                    allowClear={true}
                    placeholder="Select One"
                    data={[]}
                    searchable={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex justify-between w-full">
                <Form.Item name="description" className="w-full">
                  <Input
                    title="Description"
                    placeholder=""
                    multiline
                    showDot={false}
                  />
                </Form.Item>
              </div>

              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button widthFull={true} buttonText="Create" />
                </div>
              </Form.Item>
            </Form>
          </Modal>
        </Loader>
      </div>
    </>
  );
}
export default FacilityDetail;
