import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import Table from "./Table";
import {
  getAllFacilities,
  getEnumTypeList,
  getOrgnizationPartyId,
  getFacilityList,
  getFacilityInfo,
} from "../redux/authSlice";
import { useDispatch } from "react-redux";

function FacilityListing(): ReactElement {
  const [isLoading, setIsLoading] = useState<any>(false);
  const dispatch = useDispatch();
  // const [selectedFacility, setSelectedFacility] = useState("");
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getAllFacilities({
        setFacilitiesList,
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType,
        enumTypeId: "FacilityType",
      })
    );
    await dispatch(
      getOrgnizationPartyId({
        setOwnerPartyIdList,
      })
    );
    setIsLoading(false);
  };
  const facilityColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Facility ID</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.facilityId) {
            return a.facilityId - b.facilityId;
          } else {
            return a.facilityId?.localeCompare(b.facilityId);
          }
        },
        multiple: 1,
      },
      dataIndex: "facilityId",
      // sortOrder: sortedInfo?.orderId === "orderId" && sortedInfo?.orderIdType,
      render: (facilityId: any) => {
        return (
          <Link
            to={{
              pathname: `/facility/${facilityId}`,
              state: { facilityId },
            }}>
            <h1 className="cursor-pointer underline text-primary">
              #{facilityId}
            </h1>
          </Link>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Name</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.name === "string") {
            return a.name - b.name;
          } else {
            return a.name?.localeCompare(b.name);
          }
        },
        multiple: 1,
      },
      dataIndex: "facilityName",
      render: (name: string) => <h1 className="px-2">{name}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Type</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.type === "string") {
            return a.type - b.type;
          } else {
            return a.type?.localeCompare(b.type);
          }
        },
        multiple: 1,
      },
      dataIndex: "facilityTypeEnumId",
      render: (type: string) => (
        <h1 className="px-2">
          {enumType?.find((item: any) => item?.key === type)?.value}
        </h1>
      ),
    },

    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Owner</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.ownerOrg === "string") {
            return a.ownerOrg - b.ownerOrg;
          } else {
            return a.ownerOrg?.localeCompare(b.ownerOrg);
          }
        },
        multiple: 1,
      },
      dataIndex: "ownerPartyId",
      render: (ownerPartyId: string) => (
        <h1 className="px-2">
          {
            ownerPartyIdList?.find((item: any) => item?.key === ownerPartyId)
              ?.value
          }
        </h1>
      ),
    },
  ];
  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}>
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <Table
            rowKey="shipMethod"
            dataSource={facilitiesList}
            columns={facilityColumns}
            isPaginated={true}
          />
        </div>
      </Loader>
    </div>
  );
}
export default FacilityListing;
