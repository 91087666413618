import { useEffect, useState } from "react";
import Table from "./Table";
import Input from "./Input";
import Button from "./Button";
import { Modal, Form } from "antd";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import { getStoreList } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
const ProductStoreListing = () => {
  const dispatch = useDispatch();
  const [storeForm] = Form.useForm();
  const [isNewStoreModalOpen, setIsNewStoreModalOpen] = useState(false);
  const [productStoreList, setProductStoreList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const fetchData = async () => {
    setIsLoading(true);

    await dispatch(
      getStoreList({
        setProductStoreList,
      })
    );
    setIsLoading(false);
  };
  const productColoumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Id</p>
        </div>
      ),
      dataIndex: "productStoreId",
      render: (id: any) => {
        return (
          <div>
            <Link to={{ pathname: `/productStoreDetail/${id}` }}>
              <h1 className="cursor-pointer text-primary">{id}</h1>
            </Link>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">Name</div>
      ),
      dataIndex: "storeName",
      render: (storeName: any) => {
        return (
          <div>
            <h1>{storeName}</h1>
          </div>
        );
      },
    },
    {
      title: <div className="">Organization</div>,
      dataIndex: "organizationPartyId",
      render: (organizationPartyId: any) => {
        return (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <h1>{organizationPartyId}</h1>
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       Organization Priority
    //     </div>
    //   ),
    //   dataindex: "",
    //   render: () => {
    //     return (
    //       <div>
    //         <Input value={""} placeholder="" title="" />
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <>
      {isLoading ? (
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}>
          <div className="w-96 h-screen"></div>
        </Loader>
      ) : (
        <>
          <div>
            <Modal
              visible={isNewStoreModalOpen}
              title="New Store"
              onCancel={() => {
                setIsNewStoreModalOpen(false);
              }}
              footer={null}>
              <Form form={storeForm} onFinish={(values: any) => {}}>
                <Form.Item name="productid" className="flex-1 w-full">
                  <Input title="ID" value={[]} placeholder="" />
                </Form.Item>
                <Form.Item name="name" className="flex-1 w-full">
                  <Input title="Name" value={[]} placeholder="" />
                </Form.Item>
                <Form.Item name="organizationparty" className="flex-1 w-full">
                  <Dropdown
                    title="Organization Party"
                    data={[]}
                    placeholder=""
                    width="100%"
                  />
                </Form.Item>
                <Form.Item name="create" className="flex-1 w-full">
                  <Button widthFull buttonText="Create" />
                </Form.Item>
              </Form>
            </Modal>
          </div>
          {/* <div>
            <Button
              onClick={() => {
                setIsNewStoreModalOpen(true);
              }}
              classNames="mb-2"
              buttonText="New Store"
            />
          </div> */}
          <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
            <Table
              rowKey=""
              dataSource={productStoreList}
              columns={productColoumns}
              isPaginated={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProductStoreListing;
