/* eslint-disable */
import Table from "../Table";
import { getStoreSignatureSettingList } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

const SignatureSettings = (props: any) => {
  const dispatch = useDispatch();
  const [storeSignatureSettingList, setStoreSignatureSettingList] =
    useState<any>([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getStoreSignatureSettingList({
        productStoreId: props?.id,
        setStoreSignatureSettingList,
      })
    );
    setisLoading(false);
  };
  const signatureSettings = [
    {
      title: (
        <div>
          <h1>Product Store ID</h1>
        </div>
      ),
      dataIndex: "Product Store ID",
      render: (ProductStoreID: any) => {
        return (
          <div>
            <h1>{ProductStoreID}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Setting Type</h1>
        </div>
      ),
      dataIndex: "settingTypeDescription",
      render: (settingTypeDescription: any) => {
        return (
          <div>
            <h1>{settingTypeDescription}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Signature Required</h1>
        </div>
      ),
      dataIndex: "signatureRequiredDescription",
      render: (signatureRequiredDescription: any) => {
        return (
          <div>
            <h1>{signatureRequiredDescription}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Setting Value</h1>
        </div>
      ),
      dataIndex: "settingValue",
      render: (settingValue: any) => {
        return (
          <div>
            <h1>{settingValue}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Carrier Party ID</h1>
        </div>
      ),
      dataIndex: "carrierPartyId",
      render: (carrierPartyId: any) => {
        return (
          <div>
            <h1>{carrierPartyId}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Shipping Method</h1>
        </div>
      ),
      dataIndex: "shippingMethod",
      render: (shippingMethod: any) => {
        return (
          <div>
            <h1>{shippingMethod}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>From Date</h1>
        </div>
      ),
      dataIndex: "fromDate",
      render: (fromDate: any) => {
        return (
          <div>
            <h1>{fromDate ? moment(fromDate)?.format("DD-MM-YY") : ""}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Thru Date</h1>
        </div>
      ),
      dataIndex: "thruDate",
      render: (thruDate: any) => {
        return (
          <div>
            <h1>{thruDate ? moment(thruDate)?.format("DD-MM-YY") : ""}</h1>
          </div>
        );
      },
    },
  ];
  return (
    <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
      <Table
        rowKey=""
        columns={signatureSettings}
        dataSource={storeSignatureSettingList}
        isPaginated={false}
        loading={isLoading}
      />
    </div>
  );
};

export default SignatureSettings;
