import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
import {
  getReturnDetails,
  getEnumTypeList,
  getOrganizationList,
  getAllFacilities,
} from "../redux/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ReturnDetail = ({ returnId }: { returnId: any }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [returnDetails, setReturnDetails] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [organizationName, setOrganizationName] = useState<any>([]);
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getReturnDetails({
        returnId: returnId,
        setReturnDetails,
      })
    );
    await dispatch(
      getEnumTypeList({
        enumTypeId: "ShipmentMethod",
        setEnumType,
      })
    );
    await dispatch(
      getOrganizationList({
        setOrganizationName,
      })
    );
    await dispatch(
      getAllFacilities({
        setFacilitiesList,
      })
    );
    setIsLoading(false);
  };
  const partyContactMechPostalAddressList =
    returnDetails?.partyContactMechPostalAddressList?.find(
      (item: any) => item?.partyId
    );
  return (
    <>
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div
          className="flex flex-row items-center mb-6 cursor-pointer w-max"
          onClick={() => {
            history.push("/return/returnListing");
          }}
        >
          <MdOutlineKeyboardBackspace
            style={{ fontSize: "20px" }}
            className="text-primary"
          />
          <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
            BACK TO RESULTS
          </p>
        </div>
        <div className="flex justify-between gap-4">
          <div className="w-3/4">
            <div className="bg-white p-5 mb-5 rounded-lg shadow-sm">
              <span className="text-base sm:text-lg font-bold mr-5">
                Return ID # {returnId}
              </span>
            </div>
            <div className="bg-white p-5 rounded-lg shadow-sm w-full h-fit">
              <div className="flex flex-row gap-1 mt-5">
                <div className="text-sm font-bold w-24">External ID</div>
                <div></div>
              </div>

              <div className="mt-4 flex flex-row gap-1">
                <div className="text-sm font-bold w-24">Entry Date</div>
                <div>
                  {moment(returnDetails?.returnHeader?.entryDate)?.format(
                    "YYYY-MM-DD hh:mm"
                  )}
                </div>
              </div>

              <div className="mt-4 flex flex-row gap-1">
                <div className="text-sm font-bold w-24">Vendor</div>
                <div>
                  {returnDetails?.returnHeader?.vendorPartyId}:{" "}
                  {
                    organizationName?.find(
                      (item: any) =>
                        item?.key === returnDetails?.returnHeader?.vendorPartyId
                    )?.value
                  }
                </div>
              </div>

              <div className="mt-4 flex flex-row gap-1">
                <div className="text-sm font-bold w-24">Customer</div>
                {partyContactMechPostalAddressList?.partyId}:
                {partyContactMechPostalAddressList?.toName}
              </div>

              <div className="mt-4 flex gap-1">
                <div className="text-sm font-bold w-24">Warehouse</div>
                <div className="w-1/2">
                  {returnDetails?.returnHeader?.facilityId}:{" "}
                  {
                    facilitiesList?.find(
                      (item: any) =>
                        item?.facilityId ===
                        returnDetails?.returnHeader?.facilityId
                    )?.facilityName
                  }
                </div>
              </div>

              <div className="mt-4 flex gap-1">
                <div className="text-sm font-bold w-24">Credit Account</div>
                <div className="w-1/2"></div>
              </div>

              <div className="mt-4 flex gap-1">
                <div className="text-sm font-bold w-24">Shipping Address</div>
                <div className="w-1/2">
                  {partyContactMechPostalAddressList?.address1},{" "}
                  {partyContactMechPostalAddressList?.city},{" "}
                  {partyContactMechPostalAddressList?.stateGeoCodeAlpha2}
                </div>
              </div>

              <div className="mt-4 flex gap-1">
                <div className="text-sm font-bold w-24">Shipping Phone</div>
                <div className="w-1/2">
                  {
                    returnDetails?.telecomNumberList?.find(
                      (item: any) => item?.partyId
                    )?.contactNumber
                  }
                </div>
              </div>

              <div className="mt-4 flex gap-1">
                <div className="text-sm font-bold w-24">Shipment Method</div>
                <div className="w-1/2">
                  {
                    enumType?.find(
                      (item: any) =>
                        item?.key ===
                        returnDetails?.returnHeader?.shipmentMethodEnumId
                    )?.value
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="items-center justify-between mb-2 ">
            <div className="bg-white p-5 my-4 md:my-0 pb-6 rounded-lg shadow-sm mb-6">
              <div>
                <h1 className="text-base font-bold mr-4 mb-2">
                  {" "}
                  Status History
                </h1>
              </div>
              {returnDetails?.statusHistoryDetail?.map((item: any) => {
                return (
                  <div className="flex gap-5 mb-1">
                    <h1>
                      {moment(item?.changedDate)?.format("YYYY-MM-DD hh:mm")} by{" "}
                      {item?.userFullName} [{item?.userName}]
                    </h1>
                    <h1>{item?.description}</h1>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Loader>
    </>
  );
};

export default ReturnDetail;
