import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Sidebar from "../components/Sidebar";
import SupersetEmbedding from "../components/SupersetEmbedding";
import {
  clearState,
  getDashboardDetails,
  userSelector,
} from "../redux/authSlice";
import { Tabs } from "antd";
import DasboardMain from "../components/DashboardMain";
// const SUPERSET_DOMAIN = "https://uat-reports.fulfillmentplusny.com";

function DashBoardScreen() {
  const dispatch = useDispatch();
  const { activeOrg } = useSelector(userSelector);
  const [dateTop, setDateTop] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const currentScreen = "Dashboard";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const [data, setData] = useState<any>();
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("Order");

  useEffect(() => {
    const fetchDashboardDetails = async () => {
      await dispatch(getDashboardDetails({ setDashboardData }));
      setIsLoading(false);
    };
    fetchDashboardDetails();
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab) {
      const dashboard = dashboardData?.dashboardList?.find(
        (item: any) => item?.dashboardType === `Dstp${selectedTab}`
      );
      if (dashboard) {
        setData({ ...dashboard, supersetUrl: dashboardData?.supersetUrl });
      } else {
        setData(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, dashboardData]);

  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    if (!isAuth) {
      history.push("/");
    }
    const fetchData = async () => {
      localStorage.setItem("currentPath", "/dashboard");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date();
      const dateDashboard = `${months[date.getMonth()]} ${date.getDate()}`;
      setDateTop(dateDashboard);
    };
    fetchData();
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]);

  const TabContent = () => {
    return data?.dashboardId && data?.supersetUrl && data?.guestToken ? (
      <SupersetEmbedding
        id={data?.dashboardId}
        supersetDomain={data?.supersetUrl}
        guestToken={data?.guestToken}
      />
    ) : (
      <h1 className="text-xl mb-4">No dashboard found. Please contact admin</h1>
    );
  };
  return (
    <Sidebar>
      <div className="px-4 sm:px-8 lg:px-16 pt-11 lg:pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mb-3 font-bold text-lg">
            {activeOrg} Dashboard , {dateTop}
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              {currentScreen}
            </h1>
          </div>
        </div>
        {!isLoading ? (
          <Tabs
            type="card"
            centered={false}
            defaultActiveKey={selectedTab}
            onChange={(activeKey: any) => {
              setSelectedTab(activeKey);
            }}
          >
            <Tabs.TabPane tab="Orders" key="Order">
              <DasboardMain />
              {selectedTab === "Order" && (
                <>
                  <TabContent />
                </>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Returns" key="Return">
              {selectedTab === "Return" && <TabContent />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Inventory" key="Inventory">
              {selectedTab === "Inventory" && <TabContent />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Receiving" key="Receiving">
              {selectedTab === "Receiving" && <TabContent />}
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <div>
            <h1 className="text-4xl mb-4">Welcome to Fulfillment Plus.</h1>
            <p>
              Dashboard will be configured as we start fulfilling orders for
              your store.
            </p>
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default DashBoardScreen;
