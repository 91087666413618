/* eslint-disable */
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import ImportOrder from "../components/ImportOrder";
import ImportListing from "../components/ImportListing";
import CountDown from "../components/Countdown";
import { getOrderInstructions, userSelector } from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";

function OrderImportScreen(): ReactElement {
  const dispatch = useDispatch();
  const { activeStore } = useSelector(userSelector);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [csvTemplateRequiredFields, setInstructions] = useState([]);
  const currentScreen = "Import Order";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();

  useEffect(() => {
    if (
      activeStore &&
      activeStore?.productStoreId &&
      activeStore.productStoreId !== "selectStore"
    ) {
      dispatch(
        getOrderInstructions({
          productStoreId: activeStore.productStoreId,
          setInstructions,
        })
      );
    }
  }, [activeStore]);

  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">Import Order</h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/order/importOrder");
              }}
            >
              {currentScreen}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
          <ImportOrder setShouldRerender={setShouldRerender} />
          <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 rounded-3xl shadow-sm bg-white ">
            <h1 className="mb-4 font-bold text-base">Instructions:</h1>
            <div>
              <ul className="list-decimal list-outside">
                <li>Date format for date fields must be MMddyyyy HH:mm</li>
                {csvTemplateRequiredFields?.length > 0 && (
                  <li>
                    List of required fields:
                    <ul className="list-disc ml-5">
                      {csvTemplateRequiredFields.map((instruction: any) => (
                        <li key={instruction}>{instruction}</li>
                      ))}
                      {/* <li>ORDER / SHIPMENT NUMBER</li>
                    <li>ORDER DATE</li>
                    <li>SHIP VIA (i.e. UPS/USPS/FedEx)</li>
                    <li>SHIP TO ADDRESS LINE 1</li>
                    <li>SHIP TO CITY</li>
                    <li>SHIP TO STATE (e.g. NYC,CA)</li>
                    <li>SHIP TO ZIP</li>
                    <li>SHIP TO COUNTRY (e.g. IN,US)</li>
                    <li>ITEM NUMBER (i.e. SKU)</li>
                    <li>ITEM DESCRIPTION</li>
                    <li>QUANTITY TO SHIP(PC)</li> */}
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <CountDown setShouldRerender={setShouldRerender} />
        </div>
        <div className="mt-5">
          <ImportListing
            importType="Orders_Data_Import"
            setShouldRerender={setShouldRerender}
            shouldRerender={shouldRerender}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default OrderImportScreen;
