import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { RiDownloadLine } from "react-icons/ri";
// @ts-ignore
// import { startOfWeek, endOfWeek, addDays } from "date-fns";

import Loader from "./Loader";
import Table from "./Table";
import Input from "./Input";
import { getFacilityList, getSimpleInventoryReport } from "../redux/authSlice";
import { AiOutlineFilter } from "react-icons/ai";
import { Link } from "react-router-dom";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={() => {
            confirm();
          }}></Input>
      </div>
      <div className="mt-3 flex flex-row items-center justify-between">
        <button
          onClick={() => {
            clearFilters();
          }}
          className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg">
          Reset
        </button>
        <button
          onClick={confirm}
          className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg">
          Search
        </button>
      </div>
    </div>
  );
};
const InventoryReportTable = () => {
  //   const defineds = {
  //     startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  //     endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  //   };
  const dispatch = useDispatch();
  //   const isMobileDevice = useMediaQuery({
  //     query: "(max-device-width: 638px)",
  //   });
  // const [inventoryListPageIndex, setInventoryListPageIndex] = useState<any>('1')
  const [simpleInventoryListPageIndex, setSimpleInventoryListPageIndex] =
    useState<any>("1");
  const [isLoading, setIsLoading] = useState(false);
  const [, setInventoryList] = useState<any>([]);
  const [simpleInventoryReport, setSimpleInventoryReport] = useState<any>([]);
  // const [recordCount, setRecordCount] = useState('')
  const recordCount = "";
  // const [inventoryListPageMaxIndex, setInventoryListPageMaxIndex] = useState<any>('')
  // const [simpleInventoryRecordCount, setSimpleInventoryRecordCount] = useState('')
  //const [simpleInventoryListPageMaxIndex, setSimpleInventoryListPageMaxIndex] = useState<any>('')
  const simpleInventoryListPageMaxIndex: any = "";
  const [sortData, setSortData] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<any>("");
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [productStatusData] = useState<any>([]);
  const [assetPoolsList] = useState<any>([]);
  const [facilityList, setFacilityList] = useState<any>([]);
  const [currInventoryReport, setCurrInventoryReport] = useState<any>([]);
  // const [activeAppliedFilters, setActiveAppliedFilters] = useState('')
  // const [duplicateInventoryList, setDuplicateInventoryList] = useState<any>([])
  const duplicateInventoryList: any = [];
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  //   const [effectiveDateFrom, setEffectiveDateFrom] = useState<any>(
  //     defineds.startOfLastWeek.toLocaleDateString("fr-CA")
  //   );
  //   const [effectiveDateThru, setEffectiveDateThru] = useState<any>(
  //     defineds.endOfLastWeek.toLocaleDateString("fr-CA")
  //   );
  //   const [datePickerFrom, setDatePickerFrom] = useState<any>(
  //     moment(defineds.startOfLastWeek, "YYYY-MM-DD")
  //   );
  //   const [datePickerThru, setDatePickerThru] = useState<any>(
  //     moment(defineds.endOfLastWeek, "YYYY-MM-DD")
  //   );
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // await dispatch(getStatusList({ setStatus, statusTypeId: "Product" }));
      await dispatch(
        getFacilityList({ setFacilityList, fromInventory: false })
      );
      //   await dispatch(getAssetPoolsList({ setAssetPoolsList }))
      await dispatch(
        getSimpleInventoryReport({
          setSimpleInventoryReport,
        })
      );
      // await dispatch(
      //   getInventoryList({
      //     setInventoryList,
      //     orderByFields: sortData,
      //     effectiveDate_from: effectiveDateFrom,
      //     effectiveDate_thru: effectiveDateThru,
      //     setRecordCount,
      //     setInventoryListPageMaxIndex,
      //     pageIndex: 0,
      //     setDuplicateInventoryList
      //   })
      // )
      setIsLoading(false);
    };
    fetchData();
    return () => {
      localStorage.removeItem("totalItems");
    };
  }, []); // eslint-disable-line
  const nextSetOfProds = async (pageIndex: any, orderByFields: any) => {
    // setIsLoading(true)
    await dispatch(
      getSimpleInventoryReport({
        setSimpleInventoryReport,
      })
    );
    // await dispatch(
    //   getInventoryList({
    //     setInventoryList,
    //     orderByFields,
    //     setRecordCount,
    //     effectiveDate_from: effectiveDateFrom,
    //     effectiveDate_thru: effectiveDateThru,
    //     setInventoryListPageMaxIndex,
    //     pageIndex,
    //     setDuplicateInventoryList
    //   })
    // )
    // setIsLoading(false)
  };
  let statusArr: any = [];
  let filterArr: any = [];
  let assetArr: any = [];
  productStatusData.forEach((item: any) => {
    statusArr.push({
      text: item.value,
      value: item.key,
    });
  });
  facilityList.forEach((item: any) => {
    filterArr.push({
      text: item.key,
      value: item.key,
    });
  });
  assetPoolsList.forEach((item: any) => {
    assetArr.push({
      text: item.key,
      value: item.key,
    });
  });

  const loadNextProds = (index: any) => {
    if (
      !simpleInventoryListPageIndex ||
      simpleInventoryListPageIndex * 1 === 0
    ) {
      return;
    }
    if (simpleInventoryListPageIndex * 1 > simpleInventoryListPageMaxIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setSimpleInventoryListPageIndex(index);
    let orderByFields = sortData;
    nextSetOfProds(passedIndex, orderByFields);
  };
  //   function updateFormat(input: any) {
  //     let newFormat = input.map(
  //       ({
  //         sku,
  //         upc,
  //         productName,
  //         statusId,
  //         facilityId,
  //         availableToPromiseTotal,
  //         quantityOnHandTotal,
  //       }: {
  //         sku: any;
  //         upc: any;
  //         productName: any;
  //         statusId: any;
  //         facilityId: any;
  //         quantityOnHandTotal: any;
  //         availableToPromiseTotal: any;
  //       }) => {
  //         return {
  //           SKU: sku,
  //           UPC: upc,
  //           ["Product name"]: productName, // eslint-disable-line
  //           Status: statusId?.slice(7),
  //           Facility: facilityId,
  //           Available: availableToPromiseTotal,
  //           ["On Hand"]: quantityOnHandTotal, // eslint-disable-line
  //         };
  //       }
  //     );
  //     return newFormat;
  //   }

  const columns = [
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>SKU</p>
    //     </div>
    //   ),
    //   dataIndex: 'sku',
    //   sorter: {
    //     compare: (a: any, b: any) => {
    //       if (typeof a.sku === 'number') {
    //         return a.sku - b.sku
    //       } else {
    //         return a.sku?.localeCompare(b.sku)
    //       }
    //     },
    //     multiple: 1
    //   },
    //   sortOrder: sortedInfo?.field === 'sku' && sortedInfo?.order,
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters
    //   }: {
    //     setSelectedKeys: any
    //     selectedKeys: any
    //     confirm: any
    //     clearFilters: any
    //   }) => {
    //     return (
    //       <FilterSearchBar
    //         setSelectedKeys={setSelectedKeys}
    //         selectedKeys={selectedKeys}
    //         confirm={confirm}
    //         clearFilters={clearFilters}
    //       />
    //     )
    //   },
    //   filterIcon: () => {
    //     return <Image width={16} src={filteredInfo.sku ? OrangeFilter : Filter} preview={false} />
    //   },
    //   filteredValue: filteredInfo.sku || '',
    //   onFilter: (value: any, record: any) => {
    //     return record?.sku?.toLowerCase()?.includes(value?.toLowerCase())
    //   }
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "sku",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.sku === "number") {
            return a.sku - b.sku;
          } else {
            return a.sku?.localeCompare(b.sku);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "sku" && sortedInfo?.order,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => {
        return (
          <FilterSearchBar
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: () => {
        return filteredInfo.sku ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo.sku || "",
      onFilter: (value: any, record: any) => {
        return record?.sku?.toLowerCase()?.includes(value?.toLowerCase());
      },
      render: (sku: any, productDetail: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productDetail?.productId}`,
              state: { fromInventory: true },
            }}>
            <h1 className="cursor-pointer underline text-primary">{sku}</h1>
          </Link>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "upc",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.upc === "number") {
            return a.upc - b.upc;
          } else {
            return a.upc?.localeCompare(b.upc);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "upc" && sortedInfo?.order,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => {
        return (
          <FilterSearchBar
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: () => {
        return filteredInfo.upc ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo.upc || "",
      onFilter: (value: any, record: any) => {
        return record?.upc?.toLowerCase()?.includes(value?.toLowerCase());
      },
      render: (upc: any, productDetail: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productDetail?.productId}`,
              state: { fromInventory: true },
            }}>
            <h1 className="cursor-pointer underline text-primary">{upc}</h1>
          </Link>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product name</p>
        </div>
      ),
      dataIndex: "productName",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.productName === "number") {
            return a.productName - b.productName;
          } else {
            return a.productName?.localeCompare(b.productName);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "productName" && sortedInfo?.order,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => {
        return (
          <FilterSearchBar
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: () => {
        return filteredInfo.productName ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo.productName ? filteredInfo.productName : "",
      onFilter: (value: any, record: any) => {
        return record?.productName
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      },
    },
    // {
    //   // key: 'statusId',
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Status</p>
    //     </div>
    //   ),
    //   dataIndex: "statusId",
    //   filteredValue: filteredInfo?.statusId || null,
    //   filterIcon: () => {
    //     return filteredInfo.statusId ? (
    //       <AiOutlineFilter size={17} color="#093479" />
    //     ) : (
    //       <AiOutlineFilter size={17} />
    //     );
    //   },
    //   filters: statusArr,
    //   onFilter: async (value: any, record: any) => {
    //     return record?.statusId?.indexOf(value) === 0;
    //   },
    //   render: (statusId: any, data: any) => {
    //     let status: any;
    //     productStatusData.forEach((item: any) => {
    //       if (item.key === statusId) {
    //         status = item.value;
    //       }
    //     });
    //     return <h1 className="px-2">{status}</h1>;
    //   },
    //   sorter: {
    //     compare: (a: any, b: any) => {
    //       if (typeof a.statusId === "number") {
    //         return a.statusId - b.statusId;
    //       } else {
    //         return a.statusId?.localeCompare(b.statusId);
    //       }
    //     },
    //     multiple: 1,
    //   },
    //   sortOrder: sortedInfo?.field === "statusId" && sortedInfo?.order,
    // },
    {
      key: "facilityId",
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Facility</p>
        </div>
      ),
      filteredValue: filteredInfo?.facilityId || null,
      dataIndex: "facilityId",
      filters: filterArr,
      filterIcon: () => {
        return filteredInfo.facilityId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      onFilter: (value: any, record: any) => {
        return record?.facilityId?.indexOf(value) === 0;
      },
      render: (facilityId: any, data: any) => {
        let facility: any;
        facilityList.forEach((item: any) => {
          if (item.key === facilityId) {
            facility = item.value;
          }
        });
        return <h1 className="px-2">{facility}</h1>;
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.facilityId === "number") {
            return a.facilityId - b.facilityId;
          } else {
            return a.facilityId?.localeCompare(b.facilityId);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "facilityId" && sortedInfo?.order,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">On Hand</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.quantityOnHandTotal === "number") {
            return a.quantityOnHandTotal - b.quantityOnHandTotal;
          } else {
            return a.quantityOnHandTotal?.localeCompare(b.quantityOnHandTotal);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.field === "quantityOnHandTotal" && sortedInfo?.order,
      dataIndex: "quantityOnHandTotal",
      render: (quantityOnHandTotal: any) => {
        return <h1>{quantityOnHandTotal ?? 0}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Allocated</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.available === "number") {
            return a.available - b.available;
          } else {
            return a.available?.localeCompare(b.available);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "available" && sortedInfo?.order,
      dataIndex: "available",
      render: (available: any) => {
        return <h1>{available ?? 0}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Available</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.availableToPromiseTotal === "number") {
            return a.availableToPromiseTotal - b.availableToPromiseTotal;
          } else {
            return a.availableToPromiseTotal?.localeCompare(
              b.availableToPromiseTotal
            );
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.field === "availableToPromiseTotal" && sortedInfo?.order,
      dataIndex: "availableToPromiseTotal",
      render: (availableToPromiseTotal: any) => {
        return <h1>{availableToPromiseTotal ?? 0}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Inbound</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.inboundInventory === "number") {
            return a.inboundInventory - b.inboundInventory;
          } else {
            return a.inboundInventory?.localeCompare(b.inboundInventory);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "inboundInventory" && sortedInfo?.order,
      render: (inboundInventory: any) => {
        return <h1>{inboundInventory ?? 0}</h1>;
      },
      dataIndex: "inboundInventory",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Shipped</p>
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.quantityIssued === "number") {
            return a.quantityIssued - b.quantityIssued;
          } else {
            return a.quantityIssued?.localeCompare(b.quantityIssued);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "quantityIssued" && sortedInfo?.order,
      render: (quantityIssued: any) => {
        return <h1>{quantityIssued ?? 0}</h1>;
      },
      dataIndex: "quantityShipped",
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Consumed</p>
        </div>
      ),
      dataIndex: "usedForPreAssembledKit",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.usedForPreAssembledKit === "number") {
            return a.usedForPreAssembledKit - b.usedForPreAssembledKit;
          } else {
            return a.usedForPreAssembledKit?.localeCompare(
              b.usedForPreAssembledKit
            );
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.field === "usedForPreAssembledKit" && sortedInfo?.order,
      render: (usedForPreAssembledKit: any) => {
        return <h1>{usedForPreAssembledKit ?? 0}</h1>;
      },
    },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Asset pool</p>
    //     </div>
    //   ),
    //   filteredValue: filteredInfo?.assetPoolId || null,
    //   filterIcon: () => {
    //     return (
    //       <Image
    //         width={16}
    //         src={filteredInfo.assetPoolId ? OrangeFilter : Filter}
    //         preview={false}
    //       />
    //     )
    //   },
    //   filters: assetArr,
    //   onFilter: (value: any, record: any) => {
    //     return record?.assetPoolId?.indexOf(value) === 0
    //   },
    //   dataIndex: 'assetPoolId',
    //   render: (assetPoolId: any, data: any) => {
    //     return <h1 className='px-2'>{assetPoolId}</h1>
    //   }
    // },

    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Starting inventory</p>
    //     </div>
    //   ),
    //   dataIndex: 'startingInventory',
    //   render: (startingInventory: any, data: any) => {
    //     return <h1 className='px-2'>{startingInventory ? startingInventory : 0}</h1>
    //   }
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Shipments In</p>
    //     </div>
    //   ),
    //   dataIndex: 'shipmentsIn'
    // },

    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Other Receipts</p>
    //     </div>
    //   ),

    //   dataIndex: 'otherReceipts'
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Returns</p>
    //     </div>
    //   ),
    //   dataIndex: 'returns'
    // },

    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Shipments Out</p>
    //     </div>
    //   ),
    //   dataIndex: 'shipmentsOut'
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Adjustments</p>
    //     </div>
    //   ),
    //   dataIndex: 'Adjustments'
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Change</p>
    //     </div>
    //   ),
    //   dataIndex: 'change'
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Ending On Hand Inventory</p>
    //     </div>
    //   ),
    //   render: (_: string, data: any, index: any) => {
    //     let quantityOnHandDiff: any = 0
    //     quantityOnHandDiff += data.change + (data.startingInventory ? data.startingInventory : 0)
    //     return <h1 className='px-2'>{quantityOnHandDiff === 0 ? 0 : quantityOnHandDiff}</h1>
    //   },
    //   dataIndex: 'endingOnHandInventory'
    // },
    // {
    //   title: (
    //     <div className='flex items-center relative h-5 p-2 leading-3'>
    //       <p className='mr-1'>Available to Sell</p>
    //     </div>
    //   ),
    //   render: (_: string, data: any, index: any) => {
    //     let quantityOnHandDiff: any = 0
    //     quantityOnHandDiff += data.change + (data.startingInventory ? data.startingInventory : 0)
    //     return <h1 className='px-2'>{quantityOnHandDiff === 0 ? 0 : quantityOnHandDiff}</h1>
    //   },
    //   dataIndex: 'availabelToSell'
    // }
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Received</p>
        </div>
      ),
      dataIndex: "quantityReceived",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.quantityReceived === "number") {
            return a.quantityReceived - b.quantityReceived;
          } else {
            return a.quantityReceived?.localeCompare(b.quantityReceived);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "quantityReceived" && sortedInfo?.order,
      render: (quantityReceived: any) => {
        return <h1>{quantityReceived ?? 0}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Remaining</p>
        </div>
      ),
      dataIndex: "quantityRemaining",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.quantityRemaining === "number") {
            return a.quantityRemaining - b.quantityRemaining;
          } else {
            return a.quantityRemaining?.localeCompare(b.quantityRemaining);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.field === "quantityRemaining" && sortedInfo?.order,
      render: (quantityRemaining: any) => {
        return <h1>{quantityRemaining ?? 0}</h1>;
      },
    },
  ];
  function onHandleXlsx() {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        (!filteredInfo ||
          filteredInfo.length === 0 ||
          Object.keys(filteredInfo).length === 0 ||
          (filteredInfo.upc === null &&
            filteredInfo.sku === null &&
            filteredInfo.productName === null &&
            filteredInfo.statusId === null &&
            filteredInfo.facilityId === null)) &&
          (!sortedInfo ||
            sortedInfo.length === 0 ||
            sortedInfo?.field === "quantityOnHandTotal")
          ? updateFormat(simpleInventoryReport)
          : updateFormat(currInventoryReport)
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "simpleInventoryReport.xlsx");
  }
  function updateFormat(input: any) {
    let newFormat = input.map(
      ({
        sku,
        upc,
        productName,
        facilityId,
        availableToPromiseTotal,
        quantityOnHandTotal,
        available,
        inboundInventory,
        quantityShipped,
        quantityReceived,
        quantityRemaining,
        usedForPreAssembledKit,
      }: {
        sku: any;
        upc: any;
        productName: any;
        facilityId: any;
        quantityOnHandTotal: any;
        availableToPromiseTotal: any;
        available: any;
        inboundInventory: any;
        quantityShipped: any;
        quantityReceived: any;
        quantityRemaining: any;
        usedForPreAssembledKit: any;
      }) => {
        return {
          SKU: sku,
          UPC: upc,
          ["Product name"]: productName, // eslint-disable-line
          Facility: facilityId,
          ["On Hand"]: quantityOnHandTotal, // eslint-disable-line
          ["Allocated"]: available, // eslint-disable-line
          Available: availableToPromiseTotal,
          ["Inbound"]: inboundInventory, // eslint-disable-line
          ["Shipped"]: quantityShipped, // eslint-disable-line
          ["Consumed"]: usedForPreAssembledKit, // eslint-disable-line
          ["Received"]: quantityReceived, // eslint-disable-line
          ["Remaining"]: quantityRemaining, // eslint-disable-line
        };
      }
    );
    return newFormat;
  }

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    localStorage.setItem("totalItems", extra?.currentDataSource.length);
    setCurrInventoryReport(extra?.currentDataSource);
    let outputStr = "";
    setSortData("");
    setSortedInfo(sorter);

    setFilteredInfo(filters);

    if (Object.keys(sorter)?.length > 0) {
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    const passedIndex = simpleInventoryListPageIndex * 1 - 1;
    if (recordCount) {
      nextSetOfProds(passedIndex, outputStr);
    }
  }
  return (
    <div className="h-screen">
      <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}>
          <div className="flex items-center  mb-4 justify-between">
            <h1 className="font-bold text-sm sm:text-base">Inventory Report</h1>
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={onHandleXlsx}>
              <RiDownloadLine style={{ fontSize: "16px" }} />
              <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                XLSX
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              {/* <div className='mr-0 mb-3 ltmob:mr-5 ltmob:mb-0'>
              <Button
                paddingX='px-6'
                buttonText='Find Options'
                onClick={() => {
                  setIsModalVisible(true)
                }}
              />
            </div> */}
              <div className="flex flex-row items-center">
                <button
                  onClick={async () => {
                    setFilteredInfo("");
                    setInventoryList(duplicateInventoryList);
                    localStorage.removeItem("totalItems");
                  }}
                  className="px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg">
                  Clear Filters
                </button>
                <button
                  onClick={() => {
                    setSortedInfo(null);
                    setInventoryList(duplicateInventoryList);
                  }}
                  className="ml-4 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg">
                  Clear Sorter
                </button>
              </div>
            </div>
          </div>
          <Table
            rowKey="quantity"
            columns={columns}
            dataSource={simpleInventoryReport}
            isPaginated={true}
            isServerRendered={false}
            recordCount={recordCount}
            onChange={onChange}
            loadNextProds={loadNextProds}
          />
        </Loader>
      </div>
    </div>
  );
};

export default InventoryReportTable;
