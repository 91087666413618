import React, { useEffect, useState, ReactElement } from "react";
import { Form, FormInstance, notification, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  userSelector,
  clearState,
  getGeoCountries,
  getGeoStates,
  getCustomersList,
  getShippingAccountList,
  getProductStoresList,
  getShippingMethods,
  checkWholeSalesOrderItem,
  createWholesaleOrder,
  getCustomerShippingAddress,
} from "../redux/authSlice";
// @ts-ignore
import { useMediaQuery } from "react-responsive";
import { DownloadOutlined } from "@ant-design/icons";

import "../antd.css";
import Button from "./Button";
import Loader from "./Loader";
import Input from "./Input";
import UploadFile from "./UploadFile";
import Dropdown from "./Dropdown";

function CreateOrder({ isTestOrder }: { isTestOrder: any }): ReactElement {
  const [mapForm] = Form.useForm();
  const formRef = React.createRef<FormInstance>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerShippingInfo, setCustomerShippingInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const {
    countriesList,
    statesList,
    productStoresList,
    customersList,
    shippingAccountList,
  } = useSelector(userSelector);
  const [, setPageCount] = useState<any>("");
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [currentValue, setCurrentValue] = useState();
  const [csvFileForOrder, setCsvFileForOrder] = useState<any>(null);
  const [isExistingCustomer, setIsExistingCustomer] = useState<any>(false);
  const [importStatus, setImportStatus] = useState("");
  const [importId, setImportId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadCompletionRate, setUploadCompletionRate] = useState(5);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedProductStore, setSelectedProductStore] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const form = React.createRef<FormInstance>();

  useEffect(() => {
    const fetchData = async () => {
      if (csvFileForOrder !== null) {
        setIsUploading(true);
        setUploadCompletionRate(5);
        setImportStatus("");
        let formDataForOrder = new FormData();
        formDataForOrder.append("contentFile", csvFileForOrder);
        await dispatch(
          checkWholeSalesOrderItem({
            data: formDataForOrder,
            setImportStatus,
            setImportId,
            setUploadCompletionRate,
          })
        );
      }
    };
    fetchData();
  }, [csvFileForOrder]); // eslint-disable-line
  useEffect(() => {
    const fetchData = async () => {
      if (isTestOrder) {
        localStorage.setItem("currentPath", "/order/create?isTestOrder=true");
      } else {
        localStorage.setItem("currentPath", "/order/create?isTestOrder=false");
      }
      localStorage.setItem("allSidebarOptionsVisible", "true");
      localStorage.setItem("currentSubScreen", "createOrder");
      const isAuth = localStorage.getItem("loggedInToken");
      if (!isAuth) {
        history.push("/");
      } else {
        setIsLoading(true);
        if (!isTestOrder) {
          setIsLoading(true);
          await dispatch(getProductStoresList());
          await dispatch(
            getCustomersList({ setPageCount, setMaxPageIndex, pageIndex: 0 })
          );
        }
        await dispatch(getGeoCountries());
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]); // eslint-disable-line
  useEffect(() => {
    if (Object.keys(customerShippingInfo).length) {
      form.current?.setFieldsValue({
        ["name"]: customerShippingInfo?.name, // eslint-disable-line
        ["email"]: customerShippingInfo?.email, // eslint-disable-line
        ["contact"]: customerShippingInfo?.contact, // eslint-disable-line
        ["address1"]: customerShippingInfo?.address1, // eslint-disable-line
        ["address2"]: customerShippingInfo?.address2, // eslint-disable-line
        ["city"]: customerShippingInfo?.city, // eslint-disable-line
        ["postalCode"]: customerShippingInfo?.postalCode, // eslint-disable-line
        ["country"]: customerShippingInfo?.country, // eslint-disable-line
        ["state"]: customerShippingInfo?.state, // eslint-disable-line
      });
    }
  }, [customerShippingInfo]); // eslint-disable-line
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 558px)",
  });
  const selectStoresType = async (val: any) => {
    form.current?.setFieldsValue({
      storeType: val,
    });
    setSelectedProductStore(val);
    await dispatch(getShippingAccountList(val));
  };

  const selectShippingAccount = async (val: any) => {
    form.current?.setFieldsValue({
      shippingAccount: val,
    });
    await dispatch(
      getShippingMethods({
        setShippingMethods,
        productStoreId: selectedProductStore,
        carrierPartyId: val,
      })
    );
  };
  const selectMethod = (val: any) => {
    form.current?.setFieldsValue({
      selectMethod: val,
    });
  };
  const selectShipToCustomer = async (val: any) => {
    formRef.current?.setFieldsValue({
      shipToCustomer: val,
    });
    setCurrentValue(val);
    if (val !== "new_customer") {
      setIsExistingCustomer(true);
      await dispatch(
        getCustomerShippingAddress({
          setCustomerShippingInfo,
          customerPartyId: val,
        })
      );
    }
  };
  useEffect(() => {
    if (customerShippingInfo.country) {
      dispatch(getGeoStates(customerShippingInfo.country));
    }
  }, [customerShippingInfo]); // eslint-disable-line

  const selectCountry = (val: any) => {
    formRef.current?.setFieldsValue({
      country: val,
    });
    mapForm.setFieldValue("state", "");
    dispatch(getGeoStates(val));
  };
  const [isCustomerInformationVisible, setCustomerInformationVisible] =
    useState<any>(false);
  const nextSetOfProds = async (pageIndex: any) => {
    setIsLoading(true);
    await dispatch(
      getCustomersList({ setPageCount, setMaxPageIndex, pageIndex, searchTerm })
    );
    setIsLoading(false);
  };

  const loadProdsOnBtnClick = (type: string) => {
    if (type === "left") {
      if (
        maxPageIndex * 1 === 1 ||
        !currIndex ||
        currIndex * 1 === 0 ||
        (Math.abs(currIndex * 1 - maxPageIndex * 1) > 1 &&
          currIndex * 1 > maxPageIndex * 1) ||
        currIndex * 1 === 1
      ) {
        return;
      }
      const passedIndex = currIndex * 1 - 2;
      setCurrIndex((p: any) => p * 1 - 1);
      nextSetOfProds(passedIndex);
    } else {
      if (!currIndex || currIndex * 1 >= maxPageIndex) {
        return;
      }
      const passedIndex = currIndex;
      setCurrIndex((p: any) => p * 1 + 1);
      nextSetOfProds(passedIndex);
    }
  };
  const searchBoxOnChange = async (input: any) => {
    setSearchTerm(input);
    await dispatch(
      getCustomersList({
        setPageCount,
        setMaxPageIndex,
        pageIndex: 0,
        searchTerm: input,
      })
    );
  };
  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          {isTestOrder ? (
            <Form
              name="create_order"
              ref={form}
              form={mapForm}
              onFinish={async (values: any) => {
                // if (!csvFileForOrder || csvFileForOrder.status === "removed") {
                //   notification["error"]({
                //     message: "Error",
                //     description: "Please select a CSV file!",
                //   });
                //   return;
                // }
                const accountId = localStorage.getItem("accountId");
                if (!accountId) {
                  notification["error"]({
                    message: "Error",
                    description: "Accound Id could not be found!",
                  });
                  return;
                }
                setIsLoading(true);
                let formData = new FormData();
                const organizationPartyId: any = localStorage.getItem(
                  "organizationPartyId"
                );
                if (csvFileForOrder) {
                  formData.append("contentFile", csvFileForOrder);
                }
                formData.append("organizationPartyId", organizationPartyId);
                formData.append("fullName", values.name);
                formData.append("email", values.email);
                formData.append("phoneNumber", values.contact);
                formData.append("address1", values.address1);
                if (values.address2) {
                  formData.append("address2", values.address2);
                }
                formData.append("city", values.city);
                formData.append("stateCode", values.state);
                formData.append("countryGeoId", values.country);
                formData.append("postalCode", values.postalCode);
                formData.append("isTestOrder", isTestOrder);
                await dispatch(
                  createWholesaleOrder({ data: formData, history, isTestOrder })
                );
                setIsLoading(false);
              }}
            >
              <div className="w-full">
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Ship to Contact is required!" },
                  ]}
                >
                  <Input
                    title="Ship to Contact"
                    placeholder="Name"
                    showDot={true}
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="email"
                    rules={[
                      { required: true, message: "Ship to Email is required!" },
                      {
                        type: "email",
                        message: "Email should be a valid one!",
                      },
                    ]}
                  >
                    <Input
                      title=""
                      placeholder="Ship to Email"
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Ship To Contact Number is required!",
                      },
                      {
                        pattern:
                          /^(\+?\d{1,3}[- .]?( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4,7}([xX]{1}(\d{1,4}))?$/,
                        message: "Please enter a valid Contact number",
                      },
                    ]}
                  >
                    <Input
                      title=""
                      placeholder="Ship to Contact Number"
                      showDot={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="address1"
                  rules={[
                    { required: true, message: "Address 1 is required!" },
                  ]}
                >
                  <Input
                    title="Address"
                    placeholder="Address 1"
                    showDot={true}
                  />
                </Form.Item>
                <Form.Item name="address2">
                  <Input title="" placeholder="Address 2" showDot={false} />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="city"
                    rules={[{ required: true, message: "City is required!" }]}
                  >
                    <Input title="" placeholder="City" showDot={true} />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="postalCode"
                    rules={[
                      { required: true, message: "Postal Code is required!" },
                    ]}
                  >
                    <Input title="" placeholder="Postal Code" showDot={true} />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="country"
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="Country"
                      data={countriesList}
                      onChange={selectCountry}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="state"
                    rules={[{ required: false, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="State"
                      data={statesList}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <UploadFile
                    setCsvFile={setCsvFileForOrder}
                    isCloudIconVisible={false}
                  />
                </Form.Item>

                {isUploading && (
                  <div className="mb-5">
                    <h1 className="font-semibold text-sm mb-1.5">
                      Uploading...
                    </h1>
                    <ProgressBar
                      completed={uploadCompletionRate}
                      height="7px"
                      borderRadius="0px"
                      bgColor={
                        importStatus === "DataImportFailed" ||
                        importStatus === "DataImportHeaderMatchFailed"
                          ? "#e22426"
                          : "#57bb54"
                      }
                      isLabelVisible={false}
                    />
                    {importStatus === "DataImportSuccess" ? (
                      <h1 className="mt-3">
                        Success! You can review and finalize your order on the
                        next page.
                      </h1>
                    ) : importStatus === "DataImportFailed" ? (
                      <h1 className="mt-3" style={{ color: "#e22426" }}>
                        Something went wrong during upload. Download results to
                        see the errors.
                      </h1>
                    ) : importStatus === "DataImportHeaderMatchFailed" ? (
                      <h1 className="mt-3" style={{ color: "#e22426" }}>
                        Upload Failed.
                      </h1>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {importStatus === "DataImportFailed" && (
                  <div
                    className="flex items-center mb-6 cursor-pointer w-max"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_API_BASE_URL +
                        `/rest/s1/fp/order/downloadOrderItemResultFile?importId=${importId}`;
                    }}
                  >
                    <DownloadOutlined style={{ fontSize: "18px" }} />
                    <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                      VIEW ERRORS
                    </p>
                  </div>
                )}
                <div
                  className="flex items-center mb-3 mt-2 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      "/fp-static/downloadOrderItemTemplate";
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD CSV TEMPLATE
                  </p>
                </div>
              </div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="mt-5 flex justify-end">
                    <Button
                      widthFull
                      buttonText="Submit Order"
                      // isDisabled={
                      //   importStatus !== "DataImportSuccess" ||
                      //   csvFileForOrder === null ||
                      //   !!mapForm
                      //     .getFieldsError()
                      //     .filter(({ errors }) => errors.length).length
                      // }
                    />
                  </div>
                )}
              </Form.Item>
            </Form>
          ) : (
            <Form
              name="create_order"
              initialValues={customerShippingInfo}
              ref={form}
              form={mapForm}
              onFinish={async (values: any) => {
                // console.log(values);
                // return;
                // if (!csvFileForOrder || csvFileForOrder.status === "removed") {
                //   notification["error"]({
                //     message: "Error",
                //     description: "Please select a CSV file!",
                //   });
                //   return;
                // }
                const accountId = localStorage.getItem("accountId");
                if (!accountId) {
                  notification["error"]({
                    message: "Error",
                    description: "Accound Id could not be found!",
                  });
                  return;
                }
                setIsLoading(true);
                let formData = new FormData();
                const organizationPartyId: any = localStorage.getItem(
                  "organizationPartyId"
                );
                let carrierPartyId = "";
                let shipmentMethodEnumId = "";
                if (values.selectMethod) {
                  let splitArray = values.selectMethod.split("_");
                  carrierPartyId = splitArray[0];
                  if (splitArray?.length > 1) {
                    shipmentMethodEnumId = splitArray[1];
                  }
                }
                if (csvFileForOrder) {
                  formData.append("contentFile", csvFileForOrder);
                }
                formData.append("organizationPartyId", organizationPartyId);
                formData.append("productStoreId", selectedProductStore);
                formData.append("fullName", values.name);
                formData.append("email", values.email);
                formData.append("displayId", values.orderNum);
                formData.append("phoneNumber", values.contact);
                formData.append("address1", values.address1);
                if (values.address2) {
                  formData.append("address2", values.address2);
                }
                formData.append("city", values.city);
                if (values.shippingInstructions) {
                  formData.append(
                    "shippingInstructions",
                    values.shippingInstructions
                  );
                }
                formData.append(
                  "stateCode",
                  values?.state ? values.state : "_NA_"
                );
                formData.append("countryGeoId", values.country);
                formData.append("postalCode", values.postalCode);
                formData.append(
                  "saveCustomerInfo",
                  isCustomerInformationVisible
                );
                formData.append("carrierPartyId", carrierPartyId);
                formData.append("shipmentMethodEnumId", shipmentMethodEnumId);
                if (isExistingCustomer) {
                  if (customerShippingInfo.partyId) {
                    formData.append("partyId", customerShippingInfo.partyId);
                  }
                  if (customerShippingInfo.postalContactMechId) {
                    if (
                      customerShippingInfo.address1 === values.address1 &&
                      !!customerShippingInfo.address2 === !!values.address2 &&
                      customerShippingInfo.city === values.city &&
                      customerShippingInfo.postalCode === values.postalCode &&
                      customerShippingInfo.state === values.state &&
                      customerShippingInfo.country === values.country
                    ) {
                      formData.append(
                        "postalContactMechId",
                        customerShippingInfo.postalContactMechId
                      );
                    }
                  }
                  if (
                    customerShippingInfo.telecomContactMechId &&
                    customerShippingInfo.contact === values.contact
                  ) {
                    formData.append(
                      "telecomContactMechId",
                      customerShippingInfo.telecomContactMechId
                    );
                  }
                  if (
                    customerShippingInfo.emailContactMechId &&
                    customerShippingInfo.email === values.email
                  ) {
                    formData.append(
                      "emailContactMechId",
                      customerShippingInfo.emailContactMechId
                    );
                  }
                }
                await dispatch(
                  createWholesaleOrder({ data: formData, history, isTestOrder })
                );
                setIsLoading(false);
              }}
            >
              <div className="w-full">
                <div style={{ width: `${isMobileDevice ? "100%" : "48%"}` }}>
                  <Form.Item
                    name="storeType"
                    className=""
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="Select one"
                      data={productStoresList}
                      onChange={selectStoresType}
                      title="Stores"
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="shippingAccount"
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="Select one"
                      data={shippingAccountList}
                      onChange={selectShippingAccount}
                      title="Shipping Account"
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="selectMethod"
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="Select one"
                      data={shippingMethods}
                      title="Ship Method"
                      onChange={selectMethod}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  className="sm:mr-4 flex-1 w-full"
                  name="shipToCustomer"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    isSearchBoxVisible={true}
                    searchBoxOnChange={searchBoxOnChange}
                    placeholder="Select one"
                    data={customersList}
                    onChange={selectShipToCustomer}
                    title="Ship to Customer"
                    isPaginated={true}
                    loadProds={loadProdsOnBtnClick}
                    currIndex={currIndex}
                    maxPageIndex={maxPageIndex}
                    value={currentValue}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Ship to Contact is required!" },
                  ]}
                >
                  <Input
                    title="Ship to Contact"
                    placeholder="Name"
                    showDot={true}
                  />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="email"
                    rules={[
                      { required: true, message: "Ship to Email is required!" },
                      {
                        type: "email",
                        message: "Email should be a valid one!",
                      },
                    ]}
                  >
                    <Input
                      title=""
                      placeholder="Ship to Email"
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Ship To Contact Number is required!",
                      },
                      {
                        pattern:
                          /^(\+?\d{1,3}[- .]?( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4,7}([xX]{1}(\d{1,4}))?$/,
                        message: "Please enter a valid Contact number",
                      },
                    ]}
                  >
                    <Input
                      title=""
                      placeholder="Ship to Contact Number"
                      showDot={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="address1"
                  rules={[
                    { required: true, message: "Address 1 is required!" },
                  ]}
                >
                  <Input
                    title="Address"
                    placeholder="Address 1"
                    showDot={true}
                  />
                </Form.Item>
                <Form.Item name="address2">
                  <Input title="" placeholder="Address 2" showDot={false} />
                </Form.Item>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="city"
                    rules={[{ required: true, message: "City is required!" }]}
                  >
                    <Input title="" placeholder="City" showDot={true} />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="postalCode"
                    rules={[
                      { required: true, message: "Postal Code is required!" },
                    ]}
                  >
                    <Input title="" placeholder="Postal Code" showDot={true} />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    className="sm:mr-4 flex-1"
                    name="country"
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      placeholder="Country"
                      data={countriesList}
                      onChange={selectCountry}
                      searchable={true}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="state"
                    rules={[
                      {
                        required: false,
                        message: "Select an option!",
                      },
                    ]}
                  >
                    <Dropdown
                      placeholder="State"
                      data={statesList}
                      searchable={true}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-row-reverse mb-3">
                  <div>
                    <Switch
                      checked={isCustomerInformationVisible}
                      onChange={(checked) =>
                        setCustomerInformationVisible(checked)
                      }
                    />
                  </div>
                  <div className="mr-3 font-semibold text-gray-500">
                    Save Customer Information
                  </div>
                </div>
                <Form.Item
                  name="orderNum"
                  rules={[
                    {
                      required: true,
                      message: "PO (Order) Number is required!",
                    },
                  ]}
                >
                  <Input
                    title="PO (Order) Number"
                    placeholder=""
                    showDot={true}
                  />
                </Form.Item>
                <Form.Item name="shippingInstructions">
                  <Input
                    multiline={true}
                    title="Shipping Instructions"
                    placeholder=""
                    showDot={false}
                  />
                </Form.Item>
                <Form.Item>
                  <UploadFile
                    setCsvFile={setCsvFileForOrder}
                    isCloudIconVisible={false}
                  />
                </Form.Item>

                {isUploading && (
                  <div className="mb-5">
                    <h1 className="font-semibold text-sm mb-1.5">
                      Uploading...
                    </h1>
                    <ProgressBar
                      completed={uploadCompletionRate}
                      height="7px"
                      borderRadius="0px"
                      bgColor={
                        importStatus === "DataImportFailed" ||
                        importStatus === "DataImportHeaderMatchFailed"
                          ? "#e22426"
                          : "#57bb54"
                      }
                      isLabelVisible={false}
                    />
                    {importStatus === "DataImportSuccess" ? (
                      <h1 className="mt-3">
                        Success! You can review and finalize your order on the
                        next page.
                      </h1>
                    ) : importStatus === "DataImportFailed" ? (
                      <h1 className="mt-3" style={{ color: "#e22426" }}>
                        Something went wrong during upload. Download results to
                        see the errors.
                      </h1>
                    ) : importStatus === "DataImportHeaderMatchFailed" ? (
                      <h1 className="mt-3" style={{ color: "#e22426" }}>
                        Upload Failed.
                      </h1>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {importStatus === "DataImportFailed" && (
                  <div
                    className="flex items-center mb-6 cursor-pointer w-max"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_API_BASE_URL +
                        `/rest/s1/fp/order/downloadOrderItemResultFile?importId=${importId}`;
                    }}
                  >
                    <DownloadOutlined style={{ fontSize: "18px" }} />
                    <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                      VIEW ERRORS
                    </p>
                  </div>
                )}
                <div
                  className="flex items-center mb-3 mt-2 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      "/fp-static/downloadOrderItemTemplate";
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD CSV TEMPLATE
                  </p>
                </div>
              </div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="mt-5 flex justify-end">
                    <Button
                      widthFull
                      buttonText="Submit Order"
                      // isDisabled={
                      //   importStatus !== "DataImportSuccess" ||
                      //   csvFileForOrder === null ||
                      //   !!mapForm
                      //     .getFieldsError()
                      //     .filter(({ errors }) => errors.length).length
                      // }
                    />
                  </div>
                )}
              </Form.Item>
            </Form>
          )}
        </div>
      </Loader>
    </div>
  );
}

export default CreateOrder;
