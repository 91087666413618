/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Form,
  FormInstance,
  Image,
  Modal,
  Popover,
  Select,
  notification,
} from "antd";
import { useHistory, Link } from "react-router-dom";
// @ts-ignore
// import { useMediaQuery } from "react-responsive";
import { HiOutlineUser, HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { GoLocation } from "react-icons/go";
// import { RiDownloadLine } from "react-icons/ri";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";

import OrderDetailItem from "./OrderDetailItem";
import Dropdown from "./Dropdown";
import Loader from "./Loader";
import {
  getOrderDetail,
  userSelector,
  getStatusList,
  searchProductListing,
  addOrderItem,
  updateOrderItem,
  cancelOrder,
  cancelOrderItem,
  updateShipmentShippingMethod,
  getGeoCountries,
  getGeoStates,
  updateOrderAddress,
  orderHoldStatus,
  orderApprove,
} from "../redux/authSlice";
import Table from "./Table";
import Button from "./Button";
import { debounceFn } from "../helper/function";
import Input from "./Input";
import UploadFile from "./UploadFile";
import { BiEdit, BiTrash } from "react-icons/bi";
import DummyImage from "../images/dummy_img.svg";
import UploadImageFile from "./UploadImageFile";

const OrderDetail = ({ id }: { id: string }) => {
  const history = useHistory();
  const form = React.createRef<FormInstance>();
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [updateAddressForm] = Form.useForm();
  const [importForm] = Form.useForm();
  const [shippingMethodForm] = Form.useForm();
  const { confirm } = Modal;

  // const isMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 450px)",
  // });
  const dispatch = useDispatch();
  const { activeOrgToggled, countriesList, statesList } =
    useSelector(userSelector);
  const [orderDetailData, setOrderDetailData] = useState<any>([]);
  const [currStatus, setCurrStatus] = useState<any>("");
  const [statusList, setStatus] = useState<any>([]);
  const [customerContact, setCustomerContact] = useState<any>([]);
  const [, setCurrentDate] = useState("");
  const [shipping, setShipping] = useState<any>("");
  const [grandTotal, setGrandTotal] = useState<any>("");
  const [discount, setDiscount] = useState<any>(0);
  const [salesTax, setSalesTax] = useState<any>([]);
  const [subTotal, setSubTotal] = useState<any>([]);
  const [paymentList, setPaymentList] = useState<any>([]);
  const [shipmentDetails, setShipmentDetails] = useState<any>([]);
  // const [shipmentGridDetail, setShipmentGridDetail] = useState<any>([]);
  const [shipmentGridDetail, setShipmentGridDetail] = useState<any>([]);
  const [shipmentItemDetail, setShipmentItemDetail] = useState<any>([]);
  const [customerShippingInfo, setCustomerShippingInfo] = useState<any>({});
  const [orderItems, setOrderItems] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  // const [isExistingCustomer, setIsExistingCustomer] = useState<any>(false)
  const [, setStatusOnSave] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTimeRendering, setIsFirstTimeRendering] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<any>(null);
  const [showUploadList, setShowUploadList] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [searchableString, setSearchableString] = useState("");
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isUpadeAddressModalOpen, setIsUpdateAddressModalOpen] =
    useState(false);
  const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] =
    useState(false);
  const [shippingMethods, setShippingMethods] = useState([]);

  useEffect(() => {
    fetchData();
  }, [activeOrgToggled]); // eslint-disable-line
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getOrderDetail({
        setOrderDetailData,
        setTotalQty,
        setCurrStatus,
        setStatusOnSave,
        setCustomerContact,
        setGrandTotal,
        setShipping,
        setDiscount,
        setSalesTax,
        setSubTotal,
        setPaymentList,
        id,
        setShipmentDetails,
        setShipmentGridDetail,
        setCustomerShippingInfo,
        setShipmentItemDetail,
        setOrderItems,
        setShippingMethods,
        setIsFirstTimeRendering,
      })
    );
    await dispatch(getStatusList({ setStatus, statusTypeId: "OrderHeader" }));
    // await dispatch(
    //   getCustomersList({ setPageCount, setMaxPageIndex, pageIndex: 0 })
    // );
    await dispatch(getGeoCountries());
    setIsLoading(false);
    // await dispatch(getStatusListForOrder(setProductStatusData))
    const date = new Date(Date.now() - 2 * 60 * 60 * 1000);
    let formatAMPM: any = date.getHours() / 12;
    if (formatAMPM >= 1) {
      formatAMPM = "PM";
    } else {
      formatAMPM = "AM";
    }
    let minutes: any = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let currDate = date.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    currDate += `, ${
      date.getHours() % 12 === 0 ? 12 : date.getHours() % 12
    }:${minutes} ${formatAMPM}`;
    setCurrentDate(currDate);
  };
  useEffect(() => {
    if (Object.keys(customerShippingInfo).length) {
      form.current?.setFieldsValue({
        ["name"]: customerShippingInfo?.name, // eslint-disable-line
        ["email"]: customerShippingInfo?.email, // eslint-disable-line
        ["contact"]: customerShippingInfo?.contact, // eslint-disable-line
        ["address1"]: customerShippingInfo?.address1, // eslint-disable-line
        ["address2"]: customerShippingInfo?.address2, // eslint-disable-line
        ["city"]: customerShippingInfo?.city, // eslint-disable-line
        ["postalCode"]: customerShippingInfo?.postalCode, // eslint-disable-line
        ["country"]: customerShippingInfo?.country, // eslint-disable-line
        ["state"]: customerShippingInfo?.state, // eslint-disable-line
      });
    }
  }, [customerShippingInfo]); // eslint-disable-line

  const emailAddress =
    orderDetailData &&
    orderDetailData.order &&
    orderDetailData.order.parts[0].customerEmail;

  function dateConvert(date: any) {
    const data = new Date(date).toDateString().split(" ").slice(1).join(" ");
    return data;
  }
  function timeConvert(time: any) {
    const dis = new Date(time).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return dis;
  }
  // const newData =
  //   orderDetailData.order && orderDetailData.order.orderHistoryAndNotes;
  const orderId =
    " " + orderDetailData &&
    orderDetailData.order &&
    orderDetailData.order.orderId;
  const placedDate =
    "Placed" +
    " " +
    dateConvert(
      orderDetailData &&
        orderDetailData.order &&
        orderDetailData.order.placedDate
    ) +
    " " +
    timeConvert(
      orderDetailData &&
        orderDetailData.order &&
        orderDetailData.order.placedDate
    );

  const getShippedDate = (index: any) => {
    const actualStartDate =
      orderDetailData &&
      orderDetailData?.order &&
      orderDetailData?.order?.shipmentRouteSegment &&
      orderDetailData?.order?.shipmentRouteSegment?.length > 0 &&
      orderDetailData?.order?.shipmentRouteSegment?.[index]?.actualStartDate
        ? orderDetailData?.order?.shipmentRouteSegment?.[index]?.actualStartDate
        : "";
    const shippedDate = actualStartDate
      ? "Shipped " +
        dateConvert(actualStartDate) +
        " " +
        timeConvert(actualStartDate)
      : "";
    return shippedDate;
  };
  // const emailAddress =
  //   orderDetailData &&
  //   orderDetailData.order &&
  //   orderDetailData.order.parts[0].customerEmail;
  // const downloadCsv = async () => {
  //   await dispatch(downloadCsvManifestFile({ orderId: id }));
  // };
  // const shipToHeader: any = (
  //   <h1 className="text-xl font-bold">Edit Ship To Address</h1>
  // );
  const columns = [
    ...(shipmentGridDetail &&
    shipmentGridDetail.length === 0 &&
    (!orderDetailData?.order?.salesChannelEnumId ||
      orderDetailData?.order?.salesChannelEnumId === "ScWeb")
      ? [
          {
            title: (
              <div className="flex items-center relative h-5 p-2 leading-3">
                <p className="mr-1"></p>
              </div>
            ),
            dataIndex: "update",
            render: (sku: string, data: any) => {
              return (
                <div className="flex flex-row items-center">
                  <div className="flex flex-row">
                    <h1
                      className="ml-2"
                      onClick={() => {
                        const selectedItem: any = orderItems.find(
                          (item: any) =>
                            item.orderItemSeqId === data?.orderItemSeqId
                        );
                        if (selectedItem) {
                          updateForm?.setFieldsValue({
                            productName: selectedItem?.productName,
                            productId: selectedItem?.productId,
                            orderItemSeqId: selectedItem?.orderItemSeqId,
                            quantity: selectedItem?.quantity,
                          });
                          setIsUpdateModalOpen(true);
                        }
                      }}
                    >
                      <BiEdit className="ml-1 sm:ml-2 text-lg font-semibol text-primary cursor-pointer" />
                    </h1>
                  </div>
                </div>
              );
            },
          },
        ]
      : []),

    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Image</p>
        </div>
      ),
      dataIndex: "images",
      render: (images: string) => (
        <Image
          src={
            images?.length > 0
              ? `${process.env.REACT_APP_API_BASE_URL}${images[0]}`
              : DummyImage
          }
          width={60}
          height={60}
        />
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "sku",
      render: (sku: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.productId}`,
            state: { fromInventory: true },
          }}
        >
          <h1 className="px-2 cursor-pointer underline text-primary">{sku}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "upc",
      render: (upc: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.productId}`,
            state: { fromInventory: true },
          }}
        >
          <h1 className="px-2 cursor-pointer underline text-primary">{upc}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product Name</p>
        </div>
      ),
      dataIndex: "productName",
      render: (productName: string) => <h1 className="px-2">{productName}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">QTY</p>
        </div>
      ),
      dataIndex: "quantity",
      render: (quantity: string) => <h1 className="px-2">{quantity}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Cancelled QTY</p>
        </div>
      ),
      dataIndex: "quantityCancelled",
      render: (quantityCancelled: string) => (
        <h1 className="px-2">{quantityCancelled ?? 0}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Short QTY</p>
        </div>
      ),
      dataIndex: "productId",
      render: (productId: any) => {
        let product: any =
          orderDetailData?.order?.overAllocatedOrderItems?.find((item: any) =>
            item.find((subItem: any) => subItem?.productId === productId)
          );
        return (
          <h1 className="px-2">{product?.[0]?.quantityNotAvailable ?? 0}</h1>
        );
      },
    },
  ].filter((column: any) => {
    if (
      (shipmentGridDetail &&
        shipmentGridDetail.length === 0 &&
        orderDetailData?.order?.statusId !== "OrderCancelled") ||
      column?.dataIndex !== "orderItemSeqId"
    ) {
      return true;
    }
    return false;
  });
  return (
    <>
      <div>
        <Loader
          isLoading={isLoading && isFirstTimeRendering}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          <Modal
            title="Import Incoming shipment"
            visible={isModalOpen}
            footer={null}
            onCancel={() => {
              setShowUploadList(false);
              setIsModalOpen(false);
              setCsvFile(null);
              setIsLoading(false);
            }}
          >
            <Form
              form={importForm}
              onFinish={async (values: any) => {
                if (!csvFile || csvFile.status === "removed") {
                  notification["error"]({
                    message: "Error",
                    description: "Please select a CSV file!",
                  });
                  return;
                }
              }}
            >
              <Form.Item>
                <UploadFile
                  setCsvFile={setCsvFile}
                  isCloudIconVisible={true}
                  showUploadList={showUploadList}
                  setShowUploadList={setShowUploadList}
                />
              </Form.Item>
              <div
                className="flex items-center mb-3 cursor-pointer w-max"
                onClick={() => {
                  window.location.href =
                    process.env.REACT_APP_API_BASE_URL +
                    "/fp-static/downloadIncomingShipmentTemplate";
                }}
              >
                <DownloadOutlined style={{ fontSize: "18px" }} />
                <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                  DOWNLOAD CSV TEMPLATE
                </p>
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button
                    widthFull={true}
                    isDisabled={isDisabled}
                    buttonText="Inventory's On It's Way"
                  />
                </div>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Update Item"
            visible={isUpdateModalOpen}
            footer={null}
            onCancel={() => {
              setIsUpdateModalOpen(false);
              updateForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={updateForm}
                onFinish={async (values: any) => {
                  delete values?.productName;
                  setIsForceLoading(true);
                  await dispatch(
                    updateOrderItem({
                      data: values,
                      orderId: id,
                      setIsUpdateModalOpen,
                      fetchData,
                      updateForm,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <div className="sm:flex">
                  <Form.Item name="productId" hidden></Form.Item>
                  <Form.Item name="orderItemSeqId" hidden></Form.Item>
                  <Form.Item
                    name="productName"
                    className="flex-1 w-full"
                    rules={[{ required: false, message: "Select an option!" }]}
                  >
                    <Input
                      title="Product"
                      placeholder="Product"
                      showDot={true}
                      isDisabled
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Expected Quantity field is required!",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input
                      title="Expected Quantity"
                      placeholder="Expected Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title={`Update Image`}
            visible={isShowModal}
            footer={null}
            onCancel={() => {
              setIsShowModal(false);
              setFile(null);
            }}
          >
            <Form onFinish={async (values: any) => {}}>
              <Form.Item>
                <UploadImageFile
                  setFile={setFile}
                  isCloudIconVisible={true}
                  showUploadList={showUploadList}
                  setShowUploadList={setShowUploadList}
                />
              </Form.Item>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button
                    widthFull={true}
                    onClick={() => {
                      setIsShowModal(false);
                    }}
                    // isDisabled={isDisabled}
                    buttonText={"Update"}
                  />
                </div>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Update Address"
            visible={isUpadeAddressModalOpen}
            footer={null}
            onCancel={() => {
              setIsUpdateAddressModalOpen(false);
              updateAddressForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                name="update_address"
                ref={form}
                form={updateAddressForm}
                onFinish={async (values: any) => {
                  setIsForceLoading(true);
                  const data = { ...values };
                  await dispatch(
                    updateOrderAddress({
                      data,
                      fetchData,
                      setIsUpdateAddressModalOpen,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <div className="w-full">
                  <Form.Item name="partyId" hidden />
                  <Form.Item name="orderId" hidden />
                  <Form.Item name="orderPartSeqId" hidden />
                  <Form.Item
                    name="toName"
                    rules={[
                      { required: true, message: "Please input to name!" },
                    ]}
                  >
                    <Input placeholder="" title="Name" autoFocus />
                  </Form.Item>
                  <Form.Item
                    name="emailAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please input email Address!",
                      },
                    ]}
                  >
                    <Input placeholder="" title="Email" autoFocus />
                  </Form.Item>
                  <Form.Item
                    name="address1"
                    rules={[
                      { required: false, message: "Address 1 is required!" },
                    ]}
                  >
                    <Input
                      title="Address 1"
                      placeholder="Address 1"
                      showDot={true}
                    />
                  </Form.Item>
                  <Form.Item name="address2">
                    <Input
                      title="Address 2"
                      placeholder="Address 2"
                      showDot={false}
                    />
                  </Form.Item>
                  <div className="sm:flex">
                    <Form.Item
                      className="sm:mr-4 flex-1"
                      name="city"
                      rules={[
                        { required: false, message: "City is required!" },
                      ]}
                    >
                      <Input title="City" placeholder="City" showDot={true} />
                    </Form.Item>
                    <Form.Item
                      className="flex-1"
                      name="postalCode"
                      rules={[
                        {
                          required: false,
                          message: "Postal Code is required!",
                        },
                      ]}
                    >
                      <Input
                        title="Postal Code"
                        placeholder="Postal Code"
                        showDot={true}
                      />
                    </Form.Item>
                  </div>
                  <div className="sm:flex items-center">
                    <Form.Item
                      className="flex-1"
                      name="countryCode"
                      rules={[
                        {
                          required: false,
                          message: "Country Code is required",
                        },
                      ]}
                    >
                      <Input title="Phone" placeholder="" showDot={true} />
                    </Form.Item>
                    <p className="mr-1 ml-1">-</p>
                    <Form.Item
                      className="flex-1 mt-3"
                      name="areaCode"
                      rules={[
                        {
                          required: false,
                          message: "Area Code is required",
                        },
                      ]}
                    >
                      <Input title="" placeholder="" showDot={true} />
                    </Form.Item>
                    <p className="mr-1 ml-1">-</p>
                    <Form.Item
                      className="flex-1 mt-3"
                      name="contactNumber"
                      rules={[
                        {
                          required: false,
                          message: "Number is required",
                        },
                      ]}
                    >
                      <Input title="" placeholder="" showDot={true} />
                    </Form.Item>
                  </div>
                  <div className="sm:flex">
                    <Form.Item
                      className="sm:mr-4 flex-1"
                      name="countryGeoId"
                      rules={[
                        { required: false, message: "Select an option!" },
                      ]}
                    >
                      <Dropdown
                        title="Country"
                        placeholder="Country"
                        data={countriesList}
                        onChange={async (value: any) => {
                          updateAddressForm?.setFieldValue(
                            "stateProvinceGeoId",
                            ""
                          );
                          await dispatch(getGeoStates(value));
                        }}
                        searchable={true}
                      />
                    </Form.Item>
                    <Form.Item
                      className="flex-1"
                      name="stateProvinceGeoId"
                      rules={[
                        { required: false, message: "Select an option!" },
                      ]}
                    >
                      <Dropdown
                        title="State"
                        placeholder="State"
                        data={statesList}
                        searchable={true}
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <div className="mt-5 flex justify-end">
                      <Button widthFull buttonText="Update Address" />
                    </div>
                  )}
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Add Item"
            visible={isAddItemModalOpen}
            footer={null}
            onCancel={() => {
              setIsAddItemModalOpen(false);
              addForm?.resetFields();
              setProductList([]);
              setSelectedProduct(null);
            }}
            // width={700}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={addForm}
                onFinish={async (values: any) => {
                  if (!selectedProduct) {
                    notification["error"]({
                      message: "Error",
                      description: "Please select a product.",
                    });
                    return;
                  }

                  const data = {
                    productId: selectedProduct,
                    quantity: values?.quantity,
                    orderId: id,
                  };
                  setIsForceLoading(true);

                  dispatch(
                    addOrderItem({
                      data,
                      orderId: id,
                      setIsAddItemModalOpen,
                      fetchData,
                      setSelectedProduct,
                      addForm,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <div className="sm:flex">
                  <Form.Item
                    name="productId"
                    className="flex-1 w-full"
                    rules={[{ required: true, message: "Select an option" }]}
                  >
                    <div>
                      <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                        Product
                      </h1>
                      <Select
                        showSearch
                        value={selectedProduct}
                        placeholder="Search Product"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                        filterOption={false}
                        onSearch={async (value) => {
                          setSearchableString(() => value);
                          setProductList([]);
                          debounceFn(
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                setMaxPageIndex,
                                queryString: value,
                                currPage: 0,
                                setHasMoreProds,
                              })
                            )
                          );
                        }}
                        onSelect={(value: any) => {
                          setSelectedProduct(value);
                        }}
                        notFoundContent={null}
                        options={productList?.map((item: any) => {
                          const { identifications, productId, name } = item;
                          const sku = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtSku"
                          );
                          const upc = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtUpca"
                          );
                          const idValue =
                            sku?.idValue || upc?.idValue || productId || "";
                          return {
                            label: `${idValue && `${idValue} | `}${name || ""}`,
                            value: item?.productId,
                          };
                        })}
                        onPopupScroll={async (e: any) => {
                          const { target } = e;
                          if (
                            (target as any).scrollTop +
                              (target as any).offsetHeight ===
                            (target as any).scrollHeight
                          ) {
                            if (!currIndex || currIndex * 1 === 0) {
                              return;
                            }
                            if (currIndex * 1 > maxPageIndex) {
                              return;
                            }
                            setCurrIndex((prev: any) => prev * 1 + 1);
                            localStorage.setItem(
                              "productListCurrIndex",
                              currIndex
                            );
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                queryString: searchableString,
                                currPage: currIndex,
                                setHasMoreProds,
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Quantity field is required",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Quantity"
                      placeholder="Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button
                      widthFull={true}
                      // isDisabled={isDisabled}
                      buttonText="Add"
                    />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Update Shipment method */}
          <Modal
            title="Update Shipping method"
            visible={isShippingMethodModalOpen}
            footer={null}
            onCancel={() => {
              setIsShippingMethodModalOpen(false);
              updateForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={shippingMethodForm}
                onFinish={async (values: any) => {
                  const selectedMethod: any = shippingMethods?.find(
                    (item: any) =>
                      values?.ShipmentMethodEnumId ===
                      `${item?.carrierPartyId}${item?.shipmentMethodEnumId}`
                  );
                  setIsForceLoading(true);
                  await dispatch(
                    updateShipmentShippingMethod({
                      shipmentId: values?.shipmentId,
                      carrierPartyId: selectedMethod?.carrierPartyId,
                      ShipmentMethodEnumId:
                        selectedMethod?.shipmentMethodEnumId,
                      setIsShippingMethodModalOpen,
                      fetchData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item name="shipmentId" hidden></Form.Item>
                <Form.Item
                  name="ShipmentMethodEnumId"
                  rules={[
                    {
                      required: true,
                      message: "Shipment method field is required",
                    },
                  ]}
                >
                  <Dropdown
                    searchable={true}
                    placeholder="Select One"
                    data={shippingMethods?.map((s: any) => {
                      return {
                        key: `${s?.carrierPartyId}${s?.shipmentMethodEnumId}`,
                        value: s?.description,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <div className="flex flex-col mdlarge:flex-row mb-3">
            <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
              <div>
                <div
                  className="flex flex-row items-center mb-6 cursor-pointer w-max"
                  onClick={() => {
                    history.push("/order/orderListing");
                  }}
                >
                  <MdOutlineKeyboardBackspace
                    style={{ fontSize: "20px" }}
                    className="text-primary"
                  />
                  <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                    BACK TO RESULTS
                  </p>
                </div>
                <div className="bg-white p-5 rounded-lg shadow-sm">
                  <span className="text-base sm:text-lg font-bold mr-5">
                    Order ID # {orderId}
                  </span>
                  <span className="text-gray-500 block ltmob:inline">
                    {placedDate}
                  </span>
                </div>
              </div>
              {customerContact ? (
                <div className="bg-white p-5 pb-6 mb-6 rounded-lg shadow-sm mt-6">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-base font-bold mr-3 mb-4">
                      Customer Contact
                    </h1>
                    {shipmentGridDetail &&
                      shipmentGridDetail?.length === 0 &&
                      orderDetailData?.order?.statusId !== "OrderCancelled" && (
                        <Button
                          onClick={async () => {
                            updateAddressForm?.resetFields();
                            if (
                              orderDetailData?.order?.parts?.[0]?.postalAddress
                                ?.countryGeoId
                            ) {
                              await dispatch(
                                getGeoStates(
                                  orderDetailData?.order?.parts?.[0]
                                    ?.postalAddress?.countryGeoId
                                )
                              );
                            }
                            updateAddressForm?.setFieldsValue({
                              toName:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddress?.toName,
                              emailAddress:
                                orderDetailData?.order
                                  ?.postalContactEmailAddress,
                              address1:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddress?.address1,
                              address2:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddress?.address2,
                              city: orderDetailData?.order?.parts?.[0]
                                ?.postalAddress?.city,
                              postalCode:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddress?.postalCode,
                              countryGeoId:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddress?.countryGeoId,
                              areaCode:
                                orderDetailData?.order?.parts?.[0]
                                  ?.telecomNumber?.areaCode,
                              countryCode:
                                orderDetailData?.order?.parts?.[0]
                                  ?.telecomNumber?.countryCode,
                              contactNumber:
                                orderDetailData?.order?.parts?.[0]
                                  ?.telecomNumber?.contactNumber,
                              partyId:
                                orderDetailData?.order?.customerDetails
                                  ?.partyId,
                              orderId: id,
                              orderPartSeqId:
                                orderDetailData?.order?.parts?.[0]?.orderPart
                                  ?.orderPartSeqId,
                              stateProvinceGeoId:
                                orderDetailData?.order?.parts?.[0]
                                  ?.postalAddressStateGeo?.geoId,
                            });
                            setIsUpdateAddressModalOpen(true);
                          }}
                          buttonText="Update"
                        />
                      )}
                    {/* {(statusOnSave === 'OrderOpen' || statusOnSave === 'OrderPlaced') && (
                  <FaPen
                    className='ml-3 mb-2 cursor-pointer'
                    fontSize={16}
                    onClick={() => {
                      setShipToModalVisible(true)
                    }}
                  />
                )} */}
                  </div>
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.postalAddress?.toName && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlineUser size={23} />
                        <h1 className="text-primary text-xs font-bold ml-3">
                          {
                            orderDetailData?.order?.parts[0]?.postalAddress
                              ?.toName
                          }
                        </h1>
                      </div>
                    )}
                  {orderDetailData?.order?.postalContactEmailAddress && (
                    <div className="flex flex-row items-center mb-4">
                      <HiOutlineMail size={23} />
                      <h1 className="text-primary text-xs font-bold ml-3">
                        {orderDetailData?.order?.postalContactEmailAddress}
                      </h1>
                    </div>
                  )}
                  {!orderDetailData?.order?.postalContactEmailAddress &&
                    orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.customerEmail && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlineMail size={23} />
                        <h1 className="text-primary text-xs font-bold ml-3">
                          {orderDetailData?.order?.parts[0]?.customerEmail}
                        </h1>
                      </div>
                    )}
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.postalAddress && (
                      <div className="flex flex-row mb-4">
                        <GoLocation size={22} />
                        <div className="ml-2.5">
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.address1
                            }{" "}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.address2 && `,`}
                          </h1>
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.address2
                            }
                          </h1>
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.city
                            }
                            {orderDetailData?.order?.parts[0]
                              ?.postalAddressStateGeo?.geoCodeAlpha2
                              ? `, ${orderDetailData?.order?.parts[0]?.postalAddressStateGeo?.geoCodeAlpha2}`
                              : ", --"}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.countryGeoId &&
                              `, ${orderDetailData?.order?.parts[0]?.postalAddress?.countryGeoId}`}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.postalCodeExt &&
                              `, ${orderDetailData?.order?.parts[0]?.postalAddress?.postalCodeExt} -`}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.postalCode &&
                              ` ${orderDetailData?.order?.parts[0]?.postalAddress?.postalCode}`}
                          </h1>
                        </div>
                      </div>
                    )}
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.telecomNumber && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlinePhone size={23} />
                        <h1 className="ml-2.5">
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.countryCode &&
                            `(${orderDetailData?.order?.parts[0]?.telecomNumber?.countryCode}) `}
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.areaCode &&
                            `${orderDetailData?.order?.parts[0]?.telecomNumber?.areaCode}-`}
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.contactNumber &&
                            `${orderDetailData?.order?.parts[0]?.telecomNumber?.contactNumber}`}
                        </h1>
                      </div>
                    )}
                  {shipmentDetails &&
                  shipmentDetails.partPaymentInfoList &&
                  shipmentDetails.partPaymentInfoList[0] ? (
                    <div>
                      <div className="mt-5">
                        <span className="font-bold mr-3 text-base">
                          Paid Via
                        </span>
                        <span>
                          {customerContact && customerContact.dataSourceId
                            ? customerContact && customerContact.dataSourceId
                            : ""}
                        </span>
                      </div>
                      <div className="mt-3 ml-6">
                        {paymentList &&
                        paymentList[0] &&
                        paymentList[0].value ? (
                          <h1 className="text-xs font-medium">
                            {paymentList && paymentList[0]
                              ? dateConvert(
                                  paymentList &&
                                    paymentList[0] &&
                                    paymentList[0].value
                                ) +
                                " " +
                                timeConvert(
                                  paymentList &&
                                    paymentList[0] &&
                                    paymentList[0].value
                                )
                              : ""}
                          </h1>
                        ) : (
                          ""
                        )}
                        {paymentList &&
                        paymentList[1] &&
                        paymentList[1].value ? (
                          <h1 className="text-xs font-medium">
                            Ref:{" "}
                            {paymentList &&
                              paymentList[1] &&
                              paymentList[1].value}
                          </h1>
                        ) : (
                          ""
                        )}

                        <h1 className="text-xs font-medium">{}</h1>

                        <h1 className="text-xs font-medium">
                          $:
                          {paymentList &&
                            paymentList[2] &&
                            paymentList[2].value}
                        </h1>
                        <h1 className="text-xs font-medium">
                          {paymentList &&
                            paymentList[3] &&
                            paymentList[3].value}
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`w-full ${
                localStorage.getItem("isNavOpen") === "true"
                  ? "mdlarge:w-5/12"
                  : "mdlarge:w-4/12"
              } ml-0 mdlarge:ml-3 mt-6 mdlarge:mt-0`}
            >
              <div className="bg-white p-5 pb-6 rounded-lg shadow-sm mb-6">
                <div className="flex flex-row items-center justify-between mb-2">
                  <h1 className="text-base font-bold mr-3">Order Info</h1>
                  {/* <div
                    className="flex items-center cursor-pointer w-max"
                    // onClick={downloadCsv}
                  >
                    <RiDownloadLine style={{ fontSize: "16px" }} />
                    <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                      DOWNLOAD CSV
                    </p>
                  </div> */}
                  <div className="flex">
                    <Button
                      buttonText="Approve"
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        ![
                          "OrderCancelled",
                          "OrderCompleted",
                          "OrderApproved",
                        ].includes(orderDetailData?.order?.statusId)
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to approve this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              orderApprove({
                                orderId: id,
                                fetchData,
                              })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },

                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#1e107b",
                        borderColor: "#1e107b",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          ![
                            "OrderCancelled",
                            "OrderCompleted",
                            "OrderApproved",
                          ].includes(orderDetailData?.order?.statusId)
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                    />
                    <Button
                      buttonText="Hold"
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        !["OrderCancelled", "OrderHold"].includes(
                          orderDetailData?.order?.statusId
                        )
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to hold this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              orderHoldStatus({
                                orderId: id,
                                statusId: "OrderHold",
                                fetchData,
                              })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },

                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#1e107b",
                        borderColor: "#1e107b",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          !["OrderCancelled", "OrderHold"].includes(
                            orderDetailData?.order?.statusId
                          )
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                      classNames="ml-2"
                    />
                    <Button
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        orderDetailData?.order?.statusId !== "OrderCancelled"
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to cancel this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              cancelOrder({ orderId: id, fetchData })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },
                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#ffc107",
                        borderColor: "#ffc107",
                        color: "#212529",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          orderDetailData?.order?.statusId !== "OrderCancelled"
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                      buttonText="Cancel"
                      classNames="ml-2"
                    />
                  </div>
                </div>
                {orderDetailData?.order?.displayId &&
                  orderDetailData?.order?.displayId !== "undefined" && (
                    <h1 className="mb-2" style={{ overflowWrap: "break-word" }}>
                      Display ID: {orderDetailData?.order?.displayId}
                    </h1>
                  )}
                <div className="flex flex-row items-center border-b pb-6 border-gray-300">
                  <h1 className="text-sm font-bold mr-5">Status</h1>
                  <Dropdown
                    // isDisabled={
                    //   currStatus === "OrderCompleted" ||
                    //   (shipmentGridDetail && shipmentGridDetail.length > 0)
                    // }
                    isDisabled
                    width={"70%"}
                    data={
                      statusList && statusList.length > 0
                        ? statusList.filter(
                            (status: any) =>
                              status.key === "OrderHold" ||
                              status.key === "OrderPlaced" ||
                              status.key === "OrderApproved" ||
                              status.key === "OrderCancelled" ||
                              status.key === "OrderCompleted"
                          )
                        : []
                    }
                    placeholder="Select Status"
                    value={currStatus}
                    onChange={(val: string) => {
                      setCurrStatus(val);
                    }}
                  />
                  {/* {currStatus !== "OrderHeld" &&
                    currStatus !== "OrderCompleted" && (
                      <button
                        className="ml-2 vxsmall:ml-5 px-9 text-xs sm:px-4 sm:text-sm bg-primary hover:shadow-custom-btn hover:text-btn-text-hover focus:outline-none text-white font-semibold py-2 mt-1 rounded-lg"
                        // onClick={async () => {
                        //   setIsLoading(true);
                        //   await dispatch(
                        //     updateStatusInfo({
                        //       statusId: currStatus,
                        //       orderId: id,
                        //       setStatusOnSave,
                        //     })
                        //   );
                        //   setIsLoading(false);
                        // }}
                      >
                        Save
                      </button>
                    )} */}
                </div>
                <div className="flex flex-row items-center justify-between my-6">
                  <div className="flex flex-row items-center">
                    <h1 className="text-sm font-semibold mr-3">Subtotal</h1>
                    <h1 className="text-gray-500">
                      (
                      {(subTotal && subTotal[1] && subTotal[1].value === 1) ||
                      (subTotal && subTotal[1] && subTotal[1].value === 0)
                        ? `${subTotal && subTotal[1] && subTotal[1].value} item`
                        : `${
                            subTotal && subTotal[1] && subTotal[1].value
                          } items`}
                      )
                    </h1>
                  </div>

                  <h1>${subTotal && subTotal[0] && subTotal[0].value}</h1>
                </div>
                {shipping ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <div>
                      <h1 className="text-sm font-semibold mr-3">Shipping</h1>
                      {/* <h1 className='text-gray-500 ml-5'>Next Day Shipping (0.87lbs)</h1> */}
                      <h1 className="text-gray-500 ml-5">
                        {shipping && shipping[1].value}
                      </h1>
                    </div>
                    <h1 className="mt-4">
                      {shipping ? "$" : ""}
                      {shipping && shipping[0].value}
                    </h1>
                  </div>
                ) : (
                  ""
                )}
                {salesTax && salesTax[2] && salesTax[2].value.length > 0 ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <div>
                      <h1 className="text-sm font-semibold mr-3">Tax</h1>
                      {salesTax &&
                        salesTax[2] &&
                        salesTax[2].value.map((item: any) => {
                          if (item)
                            return (
                              <h1 className="text-gray-500 ml-5 ">{item}</h1>
                            );
                          return null;
                        })}
                    </div>
                    <div className="flex flex-col mt-6">
                      {salesTax &&
                        salesTax[0] &&
                        salesTax[0].value.map((item: any) => {
                          if (item)
                            return (
                              <h1 className="text-gray-500 ml-5 text-right">
                                ${Number(item).toFixed(2)}
                              </h1>
                            );
                          return null;
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {discount && discount > 0 ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <h1 className="text-sm font-semibold mr-3">Discounts</h1>
                    <h1>-${discount.toFixed(2)}</h1>
                  </div>
                ) : null}

                <div className="flex flex-row items-center justify-between mt-5">
                  <h1 className="text-base font-bold">Total</h1>
                  <h1 className="text-base font-bold">
                    ${Number(grandTotal ?? 0)?.toFixed(2)}
                  </h1>
                </div>
              </div>
              <div className="bg-white p-5 pb-6 rounded-lg shadow-sm">
                <div className="mt-2 flex flex-row items-center">
                  <h1 className="font-bold">Total units:</h1>
                  <h1 className="ml-1">{totalQty}</h1>
                </div>
                {orderDetailData?.order?.totalEstimatedAmount ? (
                  <div className="mt-2 flex flex-row items-center">
                    <h1 className="font-bold">Shipping label cost:</h1>
                    <h1 className="ml-1">
                      $
                      {orderDetailData?.order?.totalEstimatedAmount?.toFixed(2)}
                    </h1>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {shipmentGridDetail &&
            shipmentGridDetail.length === 0 &&
            orderDetailData?.order?.statusId !== "OrderCancelled" && (
              <div className="mt-5">
                <div className="flex justify-between">
                  <div className="mb-4">
                    {/* <Button
                  onClick={() => setIsModalOpen(true)}
                  buttonText="Import"
                /> */}
                    <Button
                      onClick={() => setIsAddItemModalOpen(true)}
                      buttonText="Add Item"
                      classNames="ml-2"
                    />
                  </div>
                </div>
              </div>
            )}
          <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
            <h1 className="mb-4 font-bold text-base">Items</h1>
            <Table
              rowKey="shipMethod"
              dataSource={orderItems}
              columns={columns}
              isPaginated={false}
              rowClassName={(record: any) => {
                const isMatched =
                  orderDetailData?.order?.overAllocatedOrderItems?.some(
                    (item: any) =>
                      item.some(
                        (subItem: any) =>
                          subItem?.productId === record?.productId
                      )
                  );
                return isMatched ? "ant-row-color" : "";
              }}
            />
          </div>
          {shipmentGridDetail && shipmentGridDetail.length > 0 ? (
            <div>
              {shipmentGridDetail &&
                shipmentGridDetail.length > 0 &&
                shipmentGridDetail.map((item: any, index: any) => {
                  return (
                    <div className="bg-white rounded-lg shadow-sm p-5 pb-6  mt-6 ">
                      <div className="mb-4">
                        <span className="mr-2 font-bold text-lg">
                          Shipment {index + 1}{" "}
                        </span>
                        <span className="text-gray-500 block ltmob:inline">
                          {getShippedDate(index)}
                        </span>
                        {/* <span className='text-gray-600'>Items (01-05)</span> */}
                        <span className="text-gray-600"></span>
                      </div>
                      <div className="flex flex-col justify-between sm:flex-row pl-2 xsmall:pl-3">
                        <div className="mr-0 sm:mr-32 md:mr-0 mb-6 sm:mb-0 flex flex-row">
                          <div className="flex flex-col">
                            <h1 className="flex flex items-center font-bold text-sm mb-2.5">
                              {/* <span className="mr-2">
                                {shipmentDetails &&
                                shipmentDetails.orderPart &&
                                shipmentDetails.orderPart.carrierPartyId
                                  ? shipmentDetails &&
                                    shipmentDetails.orderPart &&
                                    shipmentDetails.orderPart.carrierPartyId
                                  : ""}
                              </span> */}
                              <span className="mr-2">
                                {orderDetailData &&
                                  orderDetailData?.order &&
                                  orderDetailData?.order
                                    ?.shipmentRouteSegment &&
                                  orderDetailData?.order?.shipmentRouteSegment
                                    ?.length > 0 &&
                                  (orderDetailData?.order
                                    ?.shipmentRouteSegment?.[0]?.carrierPartyId
                                    ? orderDetailData?.order
                                        ?.shipmentRouteSegment?.[0]
                                        ?.carrierPartyId
                                    : "")}
                              </span>
                              <span>
                                {orderDetailData &&
                                  orderDetailData?.order &&
                                  orderDetailData?.order
                                    ?.shipmentRouteSegment &&
                                  orderDetailData?.order?.shipmentRouteSegment
                                    ?.length > 0 &&
                                  (orderDetailData?.order
                                    ?.shipmentRouteSegment?.[0]
                                    ?.shippingShipmentMethod
                                    ? orderDetailData?.order
                                        ?.shipmentRouteSegment?.[0]
                                        ?.shippingShipmentMethod
                                    : "")}
                              </span>
                              {orderDetailData &&
                                orderDetailData?.order &&
                                orderDetailData?.order?.shipmentRouteSegment &&
                                orderDetailData?.order?.shipmentRouteSegment
                                  ?.length > 0 && (
                                  <BiEdit
                                    className="ml-1 cursor-pointer"
                                    onClick={() => {
                                      setIsShippingMethodModalOpen(true);
                                      shippingMethodForm?.setFieldValue(
                                        "shipmentId",
                                        item?.shipmentId
                                      );
                                    }}
                                  />
                                )}
                              {/* <span>
                                {shipmentDetails &&
                                shipmentDetails.shipmentMethodEnum &&
                                shipmentDetails.shipmentMethodEnum.description
                                  ? shipmentDetails &&
                                    shipmentDetails.shipmentMethodEnum &&
                                    shipmentDetails.shipmentMethodEnum
                                      .description
                                  : ""}
                              </span> */}
                            </h1>

                            <div className="w-48 ">
                              {item.packages &&
                              item.packages[0] &&
                              item.packages[0].routeSegments[0] &&
                              item.packages[0].routeSegments[0] &&
                              item.packages[0].routeSegments[0].trackingUrl ? (
                                <a
                                  className="underline text-primary font-semibold mr-9 md:mr-4 lg:mr-9 hover:text-text-email"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${
                                    item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingUrl
                                  }`}
                                >
                                  {item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingCode}
                                </a>
                              ) : (
                                <h1 className="underline text-primary font-semibold mr-9 md:mr-4 lg:mr-9 hover:text-primary hover:underline">
                                  {item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingCode}
                                </h1>
                              )}
                            </div>
                          </div>

                          {item.packages &&
                          item.packages[0] &&
                          item.packages[0].routeSegments[0] &&
                          item.packages[0].routeSegments[0] &&
                          item.packages[0].routeSegments[0].labelUrl ? (
                            <div className="mt-7">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="font-bold md:block md:mt-2 lg:mt-0 lg:inline bg-gray-200 text-xs py-0.5 px-2.5 rounded-sm hover:text-text-email"
                                href={`${
                                  item.packages &&
                                  item.packages[0] &&
                                  item.packages[0].routeSegments[0] &&
                                  item.packages[0].routeSegments[0] &&
                                  item.packages[0].routeSegments[0].labelUrl
                                }`}
                              >
                                LABEL MADE
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="">
                          <h1 className="font-bold text-sm mb-2.5">Sent To</h1>
                          <div>
                            <h1 className="font-medium">
                              {shipmentDetails &&
                                shipmentDetails.postalAddress &&
                                shipmentDetails.postalAddress.toName}
                            </h1>
                            <h1 className="font-medium">
                              {shipmentDetails &&
                                shipmentDetails.postalAddress &&
                                shipmentDetails.postalAddress.address1}
                            </h1>
                            <div className="flex flex-row">
                              <h1 className="font-medium">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.city &&
                                  `${shipmentDetails.postalAddress.city} , `}
                              </h1>
                              <h1 className="font-medium ">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddressStateGeo &&
                                  shipmentDetails.postalAddressStateGeo
                                    .geoCodeAlpha2}
                              </h1>
                              <h1 className="font-medium ml-1.5">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.postalCode}
                                {}
                              </h1>
                              <h1 className="font-medium ml-1.5">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.countryGeoId}
                                {"  "}
                              </h1>
                            </div>
                            {customerContact &&
                            customerContact.telecomNumber &&
                            customerContact.telecomNumber.areaCode ? (
                              <h1 className="font-medium mt-4">
                                {" "}
                                {customerContact.telecomNumber ? "+" : ""}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber
                                    .countryCode}{" "}
                                ({" "}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber.areaCode}
                                ){" "}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber.contactNumber}
                              </h1>
                            ) : (
                              ""
                            )}

                            <a
                              href={`mailto:${emailAddress}`}
                              className="underline font-semibold text-primary hover:text-text-email break-all"
                            >
                              {emailAddress}
                            </a>
                          </div>
                        </div>
                      </div>
                      <h1 className="font-bold pb-3 text-sm mb-4 border-b border-gray-300 ">
                        Items
                      </h1>
                      {shipmentItemDetail &&
                        shipmentItemDetail.map((listItem: any, index: any) => {
                          if (listItem.shipmentId === item.shipmentId) {
                            return (
                              <div className="mt-5">
                                <div className="border-b border-gray-300">
                                  <OrderDetailItem
                                    item={listItem}
                                    imageSrc={listItem.images[0]}
                                    sku={listItem.sku}
                                    name={listItem.productName}
                                    price={listItem.unitAmount}
                                    qty={listItem.quantity}
                                    variant=""
                                  />
                                  {/* <h1 className='font-bold pb-3 border-b border-gray-300 text-sm mb-4'></h1> */}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div></div>
          )}
        </Loader>
      </div>
    </>
  );
};

export default OrderDetail;
