import React, { useEffect, useState, ReactElement } from "react";
import { Form } from "antd";
import "../antd.css";
import Button from "./Button";
import Loader from "./Loader";
import Input from "./Input";
import Dropdown from "./Dropdown";
import DatePicker from "./DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrderPicklist,
  getEnumTypeList,
  getFacilityList,
  getOrgnizationPartyId,
  userSelector,
} from "../redux/authSlice";
import moment from "moment";
import { useHistory } from "react-router-dom";

function CreatePicklist(): ReactElement {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeOrgStores } = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [facilityList, setFacilityList] = useState<any>([]);
  const [storeList, setStoreList] = useState<any>();
  const [enumTypeList, setEnumTypeList] = useState<any>([]);
  const [ownerPartyIdList, setOwnerPartyIdList] = useState<any>([]);
  const [picklistType, setPicklistType] = useState<any>();
  const [selectFacilityId, setSelectFacilityId] = useState<any>();
  const [selectStore, setSelectStore] = useState<any>();
  const [selectOrgnizationId, setSelectOrgnizationId] = useState<any>();
  const [selectEnumType, setSelectEnumType] = useState<any>();
  const [, setSelectedFacility] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getFacilityList({
          setFacilityList,
          setSelectedFacility,
          fromInventory: true,
        })
      );
      await dispatch(
        getEnumTypeList({
          setEnumType: setEnumTypeList,
          enumTypeId: "workType",
        })
      );
      await dispatch(getOrgnizationPartyId({ setOwnerPartyIdList }));
      setIsLoading(false);
    };
    fetchData();
  }, []); // eslint-disable-line
  useEffect(() => {
    const storeDataList: any = activeOrgStores?.map((item: any, index: any) => {
      return {
        key: `${item?.productStoreId}`,
        value: `${item?.productStoreId}:${item?.storeName}`,
      };
    });
    setStoreList(storeDataList);

    const filteredEnumType: any = enumTypeList?.filter(
      (item: any) =>
        item.value === "Bulk Picking" ||
        item.value === "Bulk Single Picking" ||
        item.value === "Order Level Picking"
    );
    setPicklistType(filteredEnumType);
  }, [enumTypeList, activeOrgStores]);

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          <Form
            name="create_picklist"
            form={form}
            onFinish={async (values: any) => {
              setIsLoading(true);
              let formData = new FormData();
              formData.append("facilityId", values?.facility);
              formData.append("ownerPartyId", values?.ownerOrg ?? "");
              formData.append("productStoreId", values?.store ?? "");
              formData.append("workTypeEnumId", values?.enumType ?? "");
              formData.append("workEffortName", values?.name ?? "");
              const formattedDate = moment(values?.estStart).format(
                "YYYY-MM-DD HH:mm"
              );
              formData.append("estimatedStartDate", formattedDate);
              formData.append("estimatedWorkDuration", values?.estHours ?? "");

              await dispatch(
                createOrderPicklist({
                  data: formData,
                  history,
                })
              );

              setIsLoading(false);
            }}
          >
            <div className="sm:flex gap-2">
              <Form.Item
                name="facility"
                className="flex-1 w-1/2 pr-2"
                rules={[{ required: true, message: "This is required field!" }]}
              >
                <Dropdown
                  title="Facility"
                  placeholder="Select One"
                  allowClear={true}
                  data={facilityList}
                  searchable={true}
                  value={selectFacilityId}
                  onChange={(val: any) => setSelectFacilityId(val)}
                />
              </Form.Item>
              <Form.Item name="ownerOrg" className="flex-1 w-1/2">
                <Dropdown
                  title="Owner Org"
                  placeholder="Select One"
                  allowClear={true}
                  data={ownerPartyIdList}
                  value={selectOrgnizationId}
                  onChange={(val: any) => setSelectOrgnizationId(val)}
                  searchable={true}
                />
              </Form.Item>
            </div>
            <div className="sm:flex gap-2">
              <Form.Item name="store" className="flex-1 w-1/2 pr-2">
                <Dropdown
                  title="Store"
                  placeholder="Select One"
                  allowClear={true}
                  data={storeList}
                  searchable={true}
                  value={selectStore}
                  onChange={(val: any) => setSelectStore(val)}
                />
              </Form.Item>
              <Form.Item name="enumType" className="flex-1 w-1/2">
                <Dropdown
                  title="Type"
                  placeholder="Select One"
                  allowClear={true}
                  data={picklistType}
                  searchable={true}
                  value={selectEnumType}
                  onChange={(val: any) => setSelectEnumType(val)}
                />
              </Form.Item>
            </div>
            <div className="sm:flex">
              <Form.Item name="name" className="w-full">
                <Input title="Name" placeholder="" showDot={false} />
              </Form.Item>
            </div>
            <div className="sm:flex justify-between w-full">
              <Form.Item
                name="estStart"
                className="w-full"
                rules={[{ required: true, message: "This is required field!" }]}
              >
                <DatePicker
                  isFullWidth
                  title="Est. Start"
                  dateFormat="DD-MM-YYYY"
                  style={{ width: "100%", height: "35px" }}
                />
              </Form.Item>
              <Form.Item name="estHours" className="w-full sm:ml-4">
                <Input
                  title="Est. Hours"
                  inputType="number"
                  placeholder=""
                  showDot={false}
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button widthFull={true} buttonText="Create" />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default CreatePicklist;
