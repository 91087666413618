/* eslint-disable */
import React, { useEffect, useState, ReactElement } from "react";
import { Form, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  createProductionRun,
  getEnumTypeList,
  getFacilityList,
  searchProductListing,
} from "../redux/authSlice";

import "../antd.css";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import DatePicker from "./DatePicker";
import { useMediaQuery } from "react-responsive";
import { debounceFn } from "../helper/function";

function CreateProductionRun(): ReactElement {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 558px)",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [productTypeListData, setProductTypeListData] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [searchableString, setSearchableString] = useState("");
  const [hasMoreProds, setHasMoreProds] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getFacilityList({ setFacilityList, fromInventory: false })
      );
      await dispatch(
        getEnumTypeList({
          setEnumType: setProductTypeListData,
          enumTypeId: "ProductIdentificationType",
        })
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (productTypeListData?.length > 0) {
      form.setFieldValue("idType", "PidtSku");
    }
  }, [productTypeListData]);

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        <div>
          <Form
            name="create_production_run"
            form={form}
            initialValues={{
              workEffortName: "",
              facilityId: "",
              estimatedStartDate: "",
              produceEstimatedHour: "",
              produceProductId: "",
              produceEstimatedQuantity: "",
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              await dispatch(
                createProductionRun({
                  ...values,
                  produceProductId: selectedProduct,
                  history,
                })
              );
              setIsLoading(false);
            }}
          >
            <div className="w-full">
              <div className="sm:flex">
                <Form.Item
                  name="workEffortName"
                  className="sm:mr-4 flex-1"
                  rules={[
                    { required: false, message: "This is required field!" },
                  ]}
                >
                  <Input title="Name" placeholder="Name" showDot={true} />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="facilityId"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Warehouse"
                    placeholder="Select One"
                    data={facilityList}
                    searchable={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="flex-1"
                  name="estimatedStartDate"
                  rules={[
                    { required: false, message: "This field is required!" },
                  ]}
                >
                  <DatePicker
                    title="Run Date"
                    isFullWidth={true}
                    style={{ width: "100%", height: 34 }}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1 ml-4"
                  name="produceEstimatedHour"
                  rules={[
                    { required: false, message: "This field is required!" },
                    {
                      pattern:
                        /^(?!0(?:\.0*)?$)(?:0(?:\.\d+)?|[1-9]\d*(?:\.\d+)?)$/,
                      message: "Please enter valid number",
                    },
                  ]}
                >
                  <Input title="Est. Hours" placeholder="" inputType="number" />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="flex-1"
                  name="produceProductId"
                  rules={[{ required: false, message: "Select an option!" }]}
                >
                  <div>
                    <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                      Product
                    </h1>
                    <Select
                      showSearch
                      value={selectedProduct}
                      placeholder="Search Product"
                      style={{ width: "100%" }}
                      defaultActiveFirstOption={false}
                      suffixIcon={null}
                      className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                      filterOption={false}
                      onSearch={async (value) => {
                        setSearchableString(() => value);
                        setProductList([]);
                        debounceFn(
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              setMaxPageIndex,
                              queryString: value,
                              currPage: 0,
                              setHasMoreProds,
                            })
                          )
                        );
                      }}
                      onSelect={(value: any) => {
                        setSelectedProduct(value);
                      }}
                      notFoundContent={null}
                      options={productList?.map((item: any) => {
                        const { identifications, productId, name } = item;
                        const sku = identifications?.find(
                          (iden: any) => iden?.productIdTypeEnumId === "PidtSku"
                        );
                        const upc = identifications?.find(
                          (iden: any) =>
                            iden?.productIdTypeEnumId === "PidtUpca"
                        );
                        const idValue =
                          sku?.idValue || upc?.idValue || productId || "";
                        return {
                          label: `${idValue && `${idValue} | `}${name || ""}`,
                          value: item?.productId,
                        };
                      })}
                      onPopupScroll={async (e: any) => {
                        const { target } = e;
                        if (
                          (target as any).scrollTop +
                            (target as any).offsetHeight ===
                          (target as any).scrollHeight
                        ) {
                          if (!currIndex || currIndex * 1 === 0) {
                            return;
                          }
                          if (currIndex * 1 > maxPageIndex) {
                            return;
                          }
                          setCurrIndex((prev: any) => prev * 1 + 1);
                          localStorage.setItem(
                            "productListCurrIndex",
                            currIndex
                          );
                          await dispatch(
                            searchProductListing({
                              productList,
                              setProductList,
                              queryString: searchableString,
                              currPage: currIndex,
                              setHasMoreProds,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <div
                className="sm:flex"
                style={{ width: `${isMobileDevice ? "100%" : "48%"}` }}
              >
                <Form.Item
                  className="flex-1"
                  name="produceEstimatedQuantity"
                  rules={[
                    { required: true, message: "This field is required!" },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: "Please enter valid number",
                    },
                  ]}
                >
                  <Input
                    title="Est. Quantity"
                    placeholder=""
                    inputType="number"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button widthFull={true} buttonText="Create" />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default CreateProductionRun;
