import React, { ReactElement } from "react";

import PageNotFound from "../components/PageNotFound";

function PageNotFoundScreen(props: any): ReactElement {
  return (
    <div className="px-10 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      <PageNotFound />
    </div>
  );
}

export default PageNotFoundScreen;
