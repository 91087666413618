/* eslint-disable */
import { useEffect, useState } from "react";
import Button from "../Button";
import Table from "../Table";
import { Form, Modal } from "antd";
import Input from "../Input";
import Dropdown from "../Dropdown";
import {
  getShippingRule,
  getGeoCountries,
  getEnumTypeList,
  getGeoStates,
} from "../../redux/authSlice";
import { useDispatch } from "react-redux";

const ShippingRule = (props: any) => {
  const [isNewShippingRuleModalOpen, setIsNewShippingRuleModalOpen] =
    useState(false);
  const [
    isNewBillingAccountRuleModalOpen,
    setIsNewBillingAccountRuleModalOpen,
  ] = useState(false);
  const [shippingRuleForm] = Form.useForm();
  const dispatch = useDispatch();
  const [shippingRuleList, setShippingRuleList] = useState<any>([]);
  const [geoCountries, setGeoCountries] = useState<any>([]);
  const [geoId, setGeoId] = useState([]);
  const [geoStates, setGeoStates] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setisLoading(true);
    await dispatch(
      getShippingRule({
        productStoreId: props?.id,
        setShippingRuleList,
      })
    );
    await dispatch(
      getGeoCountries({
        setGeoCountries,
      })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType,
        enumTypeId: "ShipmentMethod",
      })
    );
    setisLoading(false);
  };

  console.log("enumType", enumType);
  console.log("geoCountries", geoCountries);
  // geoCountries.map((item: any) => setGeoId(item.key));
  // console.log("geoId", geoId);
  // useEffect(() => {
  //   dispatch(
  //     getGeoStates({
  //       geoId: geoId,
  //       setGeoStates,
  //     })
  //   );
  // }, []);

  console.log("geoStates", geoStates);
  console.log("shippingRuleList", shippingRuleList);

  shippingRuleList.map((item: any) => {
    let mappedArray: any = [];
    let state: any = geoCountries?.find(
      (data: any) => data.key === item?.countryGeoId
    );
    if (state) {
      console.log("state", state);
    }
  });

  const shippingRuleColoumn = [
    {
      title: (
        <div>
          <h1>Weight From(Ounce)</h1>
        </div>
      ),
      dataIndex: "weightFrom",
      render: (weightFromOunce: any) => {
        return (
          <div>
            <h1>{weightFromOunce}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Weight To(Ounce)</h1>
        </div>
      ),
      dataIndex: "weightTo",
      render: (weightToOunce: any) => {
        return (
          <div>
            <h1>{weightToOunce}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Volume From(Cubic inch)</h1>
        </div>
      ),
      dataIndex: "volumeFrom",
      render: (volumeFromCubicinch: any) => {
        return <div>{volumeFromCubicinch}</div>;
      },
    },
    {
      title: (
        <div>
          <h1>Volume To(Cubic inch)</h1>
        </div>
      ),
      dataIndex: "volumeTo",
      render: (volumeToCubicinch: any) => {
        return <div>{volumeToCubicinch}</div>;
      },
    },
    {
      title: (
        <div>
          <h1>Country</h1>
        </div>
      ),
      dataIndex: "countryGeoId",
      render: (countryGeoId: any) => {
        return (
          <div>
            {
              geoCountries?.find((item: any) => item.key === countryGeoId)
                ?.value
            }
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>State</h1>
        </div>
      ),
      dataIndex: "stateGeoId",
      render: (stateGeoId: any, data: any) => {
        return <div></div>;
      },
    },
    {
      title: (
        <div>
          <h1>External Carrier ID</h1>
        </div>
      ),
      dataIndex: "externalCarrierId",
      render: (externalCarrierID: any) => {
        return <div>{externalCarrierID}</div>;
      },
    },
    {
      title: (
        <div>
          <h1>External Ship Method ID</h1>
        </div>
      ),
      dataIndex: "externalShipMethodId",
      render: (externalShipMethodId: any) => {
        return <div>{externalShipMethodId}</div>;
      },
    },
    {
      title: (
        <div>
          <h1>Carrier Party</h1>
        </div>
      ),
      dataIndex: "carrierPartyId",
      render: (carrierParty: any) => {
        return <div>{carrierParty}</div>;
      },
    },
    {
      title: (
        <div>
          <h1>Shipment Method </h1>
        </div>
      ),
      dataIndex: "shipmentMethodEnumId",
      render: (shipmentMethodEnumId: any) => {
        return (
          <div>
            {
              enumType.find((item: any) => item?.key === shipmentMethodEnumId)
                ?.value
            }
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Priority</h1>
        </div>
      ),
      dataIndex: "priority",
      render: (priority: any) => {
        return <div>{priority}</div>;
      },
    },
  ];

  console.log("geoStates", geoStates);
  const billingAccountRuleColoumn = [
    {
      title: (
        <div>
          <h1>Postal Code</h1>
        </div>
      ),
      dataIndex: "weightFrom(Ounce)",
      render: (weightFromOunce: any) => {
        return (
          <div>
            <h1>{weightFromOunce}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <h1>Billing Account</h1>
        </div>
      ),
      dataIndex: "",
      render: () => {
        return <div></div>;
      },
    },
    {
      title: (
        <div>
          <h1>Billing Type</h1>
        </div>
      ),
      dataIndex: "",
      render: () => {
        return <div></div>;
      },
    },
  ];
  return (
    <div>
      <Modal
        title="New Shipping Rule"
        visible={isNewShippingRuleModalOpen}
        onCancel={() => {
          setIsNewShippingRuleModalOpen(false);
        }}
        footer={null}>
        <Form
          form={shippingRuleForm}
          onFinish={() => {
            setIsNewShippingRuleModalOpen(false);
          }}>
          <Form.Item name="weightfromounce">
            <Input title="Weight From(Ounce)" placeholder="" />
          </Form.Item>
          <Form.Item name="weighttoounce">
            <Input title="Weight To(Ounce)" placeholder="" />
          </Form.Item>
          <Form.Item name="volumefrom">
            <Input title="Volume From(Cubic inch)" placeholder="" />
          </Form.Item>
          <Form.Item name="volumeto">
            <Input title="Volume To(Cubic inch)" placeholder="" />
          </Form.Item>
          <Form.Item name="country">
            <Dropdown title="Country" placeholder="" data={[]} />
          </Form.Item>
          <Form.Item name="state">
            <Dropdown title="State" placeholder="" data={[]} />
          </Form.Item>
          <Form.Item name="externalcarrierid">
            <Input title="External Carrier ID" placeholder="" />
          </Form.Item>
          <Form.Item name="externalshipmethodid">
            <Input title="External Ship Method ID" placeholder="" />
          </Form.Item>
          <Form.Item name="carrierparty">
            <Dropdown title="Carrier Party" placeholder="UPS" data={[]} />
          </Form.Item>
          <Form.Item name="shipmentmethod">
            <Dropdown title="Shipment Method" placeholder="" data={[]} />
          </Form.Item>
          <Form.Item name="priority">
            <Input title="Priority" placeholder="" />
          </Form.Item>
          <Form.Item>
            <Button buttonText="Create" widthFull />
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button
        buttonText="New Shipping Rule"
        onClick={() => {
          setIsNewShippingRuleModalOpen(true);
        }}
      /> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          dataSource={shippingRuleList}
          isPaginated={false}
          rowKey="1"
          columns={shippingRuleColoumn}
          loading={isLoading}
        />
      </div>

      <Modal
        title="New Billing Account rule"
        visible={isNewBillingAccountRuleModalOpen}
        onCancel={() => {
          setIsNewBillingAccountRuleModalOpen(false);
        }}
        footer={null}>
        <Form
          form={shippingRuleForm}
          onFinish={() => {
            setIsNewBillingAccountRuleModalOpen(false);
          }}>
          <Form.Item name="zipcode">
            <Input title="Zip Code" placeholder="" />
          </Form.Item>
          <Form.Item name="billingaccount">
            <Input title="Billing Account" placeholder="" />
          </Form.Item>
          <Form.Item name="billingtype">
            <Dropdown title="Billing Type" placeholder="" data={[]} />
          </Form.Item>
          <Form.Item>
            <Button buttonText="Create" widthFull />
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button
        classNames="mt-2"
        buttonText="New Billing Account Rule"
        onClick={() => {
          setIsNewBillingAccountRuleModalOpen(true);
        }}
      /> */}
      <div className="py-5 px-4 shadow-sm rounded-lg bg-white mb-6">
        <Table
          rowKey="2"
          dataSource={shippingRuleList}
          columns={billingAccountRuleColoumn}
          isPaginated={false}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default ShippingRule;
