import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CalendarOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { AiOutlineFilter } from "react-icons/ai";
import { BsDash } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Table from "./Table";
import Loader from "./Loader";
import Input from "./Input";
import DatePicker from "./DatePicker";
import {
  getEnumTypeList,
  getOrderListing,
  getStatusList,
} from "../redux/authSlice";
import { RiDownloadLine } from "react-icons/ri";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const OrderListing = ({
  fromDate,
  thruDate,
  facilityId,
  statusId,
  queryString,
  onlyShipped,
  isReturnedOrders,
}: {
  fromDate?: any;
  thruDate?: any;
  facilityId?: any;
  statusId?: any;
  queryString?: any;
  onlyShipped: boolean;
  isReturnedOrders?: any;
}) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 638px)",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [isLoading, setIsLoading] = useState(false);
  const [getOrderListingDetail, setgetOrderListingDetail] = useState<any>("");
  const [getBulkOrderListingDetail, setGetBulkOrderListingDetail] =
    useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [productStatusData, setStatus] = useState<any>([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [isSearch, setIsSearch] = useState<any>("");
  const [searchableString, setSearchableString] = useState<any>([]);
  const [enumType, setEnumType] = useState<any>([]);
  const [orderType, setOrderType] = useState<any>([]);
  const [sortData, setSortData] = useState<any>("");
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [shipMethodList, setShipMethodList] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(
    fromDate
      ? fromDate
      : localStorage.getItem("orderListingFromDate")
      ? localStorage.getItem("orderListingFromDate")
      : ""
  );
  const [selectedThruDate, setSelectedThruDate] = useState(
    thruDate
      ? thruDate
      : localStorage.getItem("orderListingThruDate")
      ? localStorage.getItem("orderListingThruDate")
      : ""
  );
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [datePickerFrom, setDatePickerFrom] = useState<any>(
    fromDate
      ? moment(fromDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingFromDate")
      ? moment(localStorage.getItem("orderListingFromDate"), "YYYY-MM-DD")
      : ""
  );
  const [datePickerThru, setDatePickerThru] = useState<any>(
    thruDate
      ? moment(thruDate, "YYYY-MM-DD")
      : localStorage.getItem("orderListingThruDate")
      ? moment(localStorage.getItem("orderListingThruDate"), "YYYY-MM-DD")
      : ""
  );
  const [searchedQuery, setSearchedQuery] = useState("");
  const [resetInputField, setResetInputField] = useState<any>(false);
  const salesChannelFilters: any = [];
  const statusFilters: any = [];
  const shipMethodFilters: any = [];
  useEffect(() => {
    const fetchData = async () => {
      if (fromDate) {
        localStorage.setItem("orderListingFromDate", fromDate);
      }
      if (thruDate) {
        localStorage.setItem("orderListingThruDate", thruDate);
      }
      let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");
      if (!prevAppliedFilter) {
        prevAppliedFilter = {};
      } else {
        prevAppliedFilter = JSON.parse(prevAppliedFilter);
        console.log("125", prevAppliedFilter);
        setSortedInfo(prevAppliedFilter?.sortedInfo);
        setFilteredInfo(prevAppliedFilter?.filteredInfo);
        setActiveAppliedFilters(prevAppliedFilter?.appliedFilters);
      }
      setIsLoading(true);
      if (isSearch === "Searched") {
        await dispatch(
          getOrderListing({
            setgetOrderListingDetail,
            setGetBulkOrderListingDetail,
            setMaxPageIndex,
            orderByFields: " ",
            setRecordCount,
            pageIndex: 0,
            queryString: searchableString,
            onlyShipped,
            appliedFilters: "",
            isReturnedOrders: isReturnedOrders ? true : false,
          })
        );
      } else {
        await dispatch(
          getOrderListing({
            setgetOrderListingDetail,
            setGetBulkOrderListingDetail,
            setMaxPageIndex,
            orderByFields: prevAppliedFilter?.orderByFields
              ? prevAppliedFilter?.orderByFields
              : sortData,
            setRecordCount,
            pageIndex: localStorage.getItem("currPageIndex")
              ? localStorage.getItem("currPageIndex")
              : 0,
            // searchString: orderStatus,
            placedDate_from: selectedFromDate
              ? selectedFromDate
              : fromDate
              ? fromDate
              : "",
            placedDate_thru: selectedThruDate
              ? selectedThruDate
              : thruDate
              ? thruDate
              : "",
            statusId,
            facilityId: facilityId ? facilityId : "",
            queryString,
            onlyShipped,
            isReturnedOrders: isReturnedOrders ? true : false,
            appliedFilters: prevAppliedFilter?.appliedFilters
              ? prevAppliedFilter.appliedFilters
              : "",
          })
        );
      }
      await dispatch(getStatusList({ setStatus, statusTypeId: "OrderHeader" }));
      await dispatch(
        getEnumTypeList({ setEnumType, enumTypeId: "SalesChannel" })
      );
      await dispatch(
        getEnumTypeList({ setEnumType: setOrderType, enumTypeId: "OrderType" })
      );
      await dispatch(
        getEnumTypeList({
          setEnumType: setShipMethodList,
          enumTypeId: "ShipmentMethod",
        })
      );
      setIsLoading(false);
    };
    fetchData();
    return () => {
      setResetInputField(false);
      localStorage.removeItem("prevAppliedFilter");
    };
  }, [location.key]); // eslint-disable-line
  console.log("sortedInfo----- in 175-------->", sortedInfo);
  const nextSetOfProds = async (
    pageIndex: any,
    orderByFields: any,
    appliedFilters: any,
    resetFields?: any
  ) => {
    setIsLoading(true);
    console.log("appliedFilters---198-----", appliedFilters);
    console.log("inside the nextSetOfProds", pageIndex);
    localStorage.setItem("currPageIndex", pageIndex);
    if (resetFields) {
      setResetInputField(true);
    } else {
      setResetInputField(false);
    }
    if (isSearch === "Searched") {
      await dispatch(
        getOrderListing({
          setgetOrderListingDetail,
          setGetBulkOrderListingDetail,
          setMaxPageIndex,
          orderByFields,
          setRecordCount,
          pageIndex,
          queryString: searchableString,
          placedDate_from: selectedFromDate
            ? selectedFromDate
            : fromDate
            ? fromDate
            : "",
          placedDate_thru: selectedThruDate
            ? selectedThruDate
            : thruDate
            ? thruDate
            : "",
          statusId,
          facilityId: facilityId ? facilityId : "",
          onlyShipped,
          appliedFilters,
          isReturnedOrders: isReturnedOrders ? true : false,
        })
      );
    } else {
      await dispatch(
        getOrderListing({
          setgetOrderListingDetail,
          setGetBulkOrderListingDetail,
          setMaxPageIndex,
          orderByFields,
          setRecordCount,
          pageIndex,
          //searchString: orderStatus,
          placedDate_from: selectedFromDate
            ? selectedFromDate
            : fromDate
            ? fromDate
            : "",
          placedDate_thru: selectedThruDate
            ? selectedThruDate
            : thruDate
            ? thruDate
            : "",
          statusId,
          queryString,
          facilityId: facilityId ? facilityId : "",
          onlyShipped,
          appliedFilters,
          isReturnedOrders: isReturnedOrders ? true : false,
        })
      );
    }
    setIsLoading(false);
  };
  productStatusData.forEach((item: any) => {
    statusFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  enumType.forEach((item: any) => {
    salesChannelFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  shipMethodList.forEach((item: any) => {
    shipMethodFilters.push({
      text: item.value,
      value: item.key,
    });
  });
  const loadNextProds = (index: any) => {
    console.log("line-249----", index);
    if (!currIndex || currIndex * 1 === 0) {
      return;
    }
    if (currIndex * 1 > maxPageIndex) {
      return;
    }
    const passedIndex = index * 1 - 1;
    setCurrIndex(index);
    console.log("255", passedIndex);
    console.log("256", index);
    let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");

    prevAppliedFilter = JSON.parse(prevAppliedFilter);
    let orderByFields = sortData;
    if (prevAppliedFilter?.orderByFields) {
      orderByFields = prevAppliedFilter?.orderByFields;
    }

    console.log("orderByFields in loadNextProds", orderByFields);
    nextSetOfProds(passedIndex, orderByFields, activeAppliedFilters);
  };

  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">ID</p>
        </div>
      ),
      dataIndex: "orderId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo.orderId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.orderId || null,
      onFilter: (value: any, record: any) => record?.orderId.includes(value),
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.orderId === "number") {
            return a.orderId - b.orderId;
          } else {
            return a.orderId?.localeCompare(b.orderId);
          }
        },
        multiple: 1,
      },
      sortOrder: sortedInfo?.orderId === "orderId" && sortedInfo?.orderIdType,
      render: (orderId: string) => (
        <Link
          to={{
            pathname: `/order/${orderId}`,
            state: { orderId },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">#{orderId}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">External ID</p>
        </div>
      ),
      dataIndex: "displayId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.displayId || null,
      onFilter: (value: any, record: any) => record?.displayId?.includes(value),
      filterIcon: () => {
        return filteredInfo.displayId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.displayId === "number") {
            return a.displayId - b.displayId;
          } else {
            return a.displayId?.localeCompare(b.displayId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.displayId === "displayId" && sortedInfo?.displayIdType,
      render: (displayId: string) => <h1 className="px-2">{displayId}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Type</p>
        </div>
      ),
      dataIndex: "orderTypeEnumId",
      render: (orderTypeEnumId: string) => {
        const orderTypeEnum = orderType?.find(
          (type: any) => type?.key === orderTypeEnumId
        );
        return <h1 className="px-2">{orderTypeEnum?.value}</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Sales Channel</p>
        </div>
      ),
      dataIndex: "salesChannelEnumId",
      filters: salesChannelFilters,
      filteredValue: filteredInfo?.salesChannelEnumId || null,
      onFilter: (value: any, record: any) =>
        record?.salesChannelEnumId?.includes(value),
      filterIcon: () => {
        return filteredInfo.salesChannelEnumId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.salesChannelEnumId === "number") {
            return a.salesChannelEnumId - b.salesChannelEnumId;
          } else {
            return a.salesChannelEnumId?.localeCompare(b.salesChannelEnumId);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.salesChannelEnumId === "salesChannelEnumId" &&
        sortedInfo?.salesChannelEnumIdType,
      render: (salesChannelEnumId: string, prod: any) => {
        return (
          <div>
            {enumType &&
              enumType.map((item: any) => {
                if (item.key === salesChannelEnumId) {
                  return <h1 className="px-2">{item.value}</h1>;
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Date Placed</p>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <div style={{ padding: 8 }}>
          <div>
            <div className="sm:flex sm:flex-row sm:items-center">
              <DatePicker
                title="From"
                value={datePickerFrom}
                onChange={(date: any, dateString: any) => {
                  setDatePickerFrom(date);
                  setSelectedFromDate(dateString);
                  localStorage.setItem("orderListingFromDate", dateString);
                }}
              />
              {!isMobileDevice && <BsDash className="mt-5 mx-0.5" />}
              <div className="mt-3 sm:mt-0">
                <DatePicker
                  title="Thru"
                  value={datePickerThru}
                  onChange={(date: any, dateString: any) => {
                    setDatePickerThru(date);
                    setSelectedThruDate(dateString);
                    localStorage.setItem("orderListingThruDate", dateString);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 flex flex-row items-center justify-between">
              <button
                onClick={async () => {
                  setIsLoading(true);
                  await dispatch(
                    getOrderListing({
                      setgetOrderListingDetail,
                      setGetBulkOrderListingDetail,
                      setMaxPageIndex,
                      orderByFields: sortData,
                      setRecordCount,
                      pageIndex: 0,
                      // searchString: orderStatus,
                      placedDate_from: fromDate ? fromDate : "",
                      placedDate_thru: thruDate ? thruDate : "",
                      statusId,
                      queryString,
                      facilityId: facilityId ? facilityId : "",
                      onlyShipped,
                      appliedFilters: activeAppliedFilters,
                      isReturnedOrders: isReturnedOrders ? true : false,
                    })
                  );
                  setResetInputField(true);
                  setSelectedFromDate("");
                  setSelectedThruDate("");
                  setDatePickerFrom("");
                  setDatePickerThru("");
                  localStorage.removeItem("orderListingThruDate");
                  localStorage.removeItem("orderListingFromDate");
                  filteredInfo["placedDate"] = null;
                  clearFilters();
                  setIsLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
              >
                Reset
              </button>
              <button
                onClick={async () => {
                  if (!selectedFromDate && !selectedThruDate) {
                    return;
                  }
                  setIsLoading(true);
                  document
                    .getElementsByClassName("ant-dropdown")[0]
                    .classList.add("ant-dropdown-hidden");
                  await dispatch(
                    getOrderListing({
                      setgetOrderListingDetail,
                      setGetBulkOrderListingDetail,
                      setMaxPageIndex,
                      orderByFields: sortData,
                      setRecordCount,
                      pageIndex: 0,
                      // searchString: orderStatus,
                      placedDate_from: selectedFromDate,
                      placedDate_thru: selectedThruDate,
                      statusId,
                      queryString,
                      facilityId: facilityId ? facilityId : "",
                      onlyShipped,
                      appliedFilters: activeAppliedFilters,
                      isReturnedOrders: isReturnedOrders ? true : false,
                    })
                  );
                  setResetInputField(true);
                  filteredInfo["placedDate"] = "placedDate";
                  setIsLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      ),
      dataIndex: "placedDate",
      filterIcon: () => {
        return filteredInfo.placedDate ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.placedDate === "number") {
            return a.placedDate - b.placedDate;
          } else {
            return a.placedDate?.localeCompare(b.placedDate);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.placedDate === "placedDate" && sortedInfo?.placedDateType,
      render: (placedDate: any) => {
        function dateCon(date: any) {
          const data = new Date(date).toDateString();
          return data;
        }
        return (
          <div className="flex flex-row items-center">
            {placedDate ? (
              <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
            ) : (
              ""
            )}

            <h1 className="ml-1.5">{placedDate ? dateCon(placedDate) : ""}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Ship Date</p>
        </div>
      ),
      dataIndex: "shippingShipmentActualStartDate",
      // filters: shipMethodFilters,
      // filterIcon: () => {
      //   return filteredInfo.shippingShipmentActualStartDate ? (
      //     <AiOutlineFilter size={17} color="#093479" />
      //   ) : (
      //     <AiOutlineFilter size={17} />
      //   );
      // },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.shippingShipmentActualStartDate === "number") {
            return (
              a.shippingShipmentActualStartDate -
              b.shippingShipmentActualStartDate
            );
          } else {
            return a.shippingShipmentActualStartDate?.localeCompare(
              b.shippingShipmentActualStartDate
            );
          }
        },
        multiple: 1,
      },
      // filteredValue: filteredInfo.shippingShipmentActualStartDate || null,
      // onFilter: (value: any, record: any) => {
      //   let isShipmentEnumIdMatched = false;
      //   record?.items &&
      //     record?.items?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources &&
      //     record?.items?.[0]?.shipmentSources?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0 &&
      //     record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
      //       (item: any) => {
      //         if (
      //           item.shippingShipmentActualStartDate &&
      //           item.shippingShipmentActualStartDate.includes(value)
      //         ) {
      //           isShipmentEnumIdMatched = true;
      //         }
      //       }
      //     );
      //   // record?.parts &&
      //   //   record?.parts.length > 0 &&
      //   //   record?.parts.forEach((item: any) => {
      //   //     if (
      //   //       item.shipmentMethodEnumId &&
      //   //       item.shipmentMethodEnumId.includes(value)
      //   //     ) {
      //   //       isShipmentEnumIdMatched = true;
      //   //     }
      //   //   });
      //   return isShipmentEnumIdMatched;
      // },
      sortOrder:
        sortedInfo.shippingShipmentActualStartDate ===
          "shippingShipmentActualStartDate" &&
        sortedInfo.shippingShipmentActualStartDateType,
      render: (shipmentActualDate: string, order: any) => {
        let shipmentActualStartDate = "";
        if (
          order?.items &&
          order?.items?.length > 0 &&
          order?.items?.[0]?.shipmentSources &&
          order?.items?.[0]?.shipmentSources?.length > 0 &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0
        ) {
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (item.shippingShipmentActualStartDate) {
                shipmentActualStartDate = item.shippingShipmentActualStartDate;
              }
            }
          );
        }
        function dateCon(date: any) {
          const data = new Date(date).toDateString();
          return data;
        }
        return (
          <div
            className="flex flex-row items-center"
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
            }}
          >
            {shipmentActualStartDate ? (
              <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
            ) : (
              ""
            )}

            <h1 className="ml-1.5">
              {shipmentActualStartDate ? dateCon(shipmentActualStartDate) : ""}
            </h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Customer</p>
        </div>
      ),
      dataIndex: "parts.customerName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: () => {
        return filteredInfo["parts.customerName"] ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo["parts.customerName"] || null,
      onFilter: (value: any, record: any) => {
        let isCustomerMatched = false;
        record?.parts &&
          record?.parts.length > 0 &&
          record?.parts.forEach((item: any) => {
            if (item.customerName && item.customerName.includes(value)) {
              isCustomerMatched = true;
            }
          });
        return isCustomerMatched;
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.customerName === "number") {
            return a.customerName - b.customerName;
          } else {
            return a.customerName?.localeCompare(b.customerName);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo["parts.customerName"] === "parts.customerName" &&
        sortedInfo["parts.customerNameType"],
      render: (customerName: string, order: any) => {
        let customer_name = "";
        if (order.parts && order.parts.length > 0) {
          order.parts.forEach((item: any) => {
            if (item.customerName) {
              customer_name = item.customerName;
            }
          });
        }
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row">
              <UserOutlined style={{ fontSize: 18 }} />
              <h1 className="ml-2">{customer_name}</h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      dataIndex: "statusId",
      filters: statusFilters,
      filterIcon: () => {
        return filteredInfo.statusId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.statusId === "number") {
            return a.statusId - b.statusId;
          } else {
            return a.statusId?.localeCompare(b.statusId);
          }
        },
        multiple: 1,
      },
      filteredValue: filteredInfo?.statusId || null,
      onFilter: (value: any, record: any) => record?.statusId.includes(value),
      sortOrder:
        sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
      render: (statusId: any) => {
        return (
          <div>
            {productStatusData &&
              productStatusData.map((item: any) => {
                if (item.key === statusId) {
                  return (
                    <h1
                      style={{
                        backgroundColor: "#093479",
                        outlineColor: "#707070",
                        border: "1px solid #707070",
                      }}
                      className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
                    >
                      {item.value?.toUpperCase()}
                    </h1>
                  );
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Total</p>
        </div>
      ),
      dataIndex: "grandTotal",
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.grandTotal === "number") {
            return a.grandTotal - b.grandTotal;
          } else {
            return a.grandTotal?.localeCompare(b.grandTotal);
          }
        },
        multiple: 1,
      },
      sortOrder:
        sortedInfo?.grandTotal === "grandTotal" && sortedInfo?.grandTotalType,
      render: (grandTotal: any) => <h1>${Number(grandTotal).toFixed(2)}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Ship Method</p>
        </div>
      ),
      dataIndex: "shippingShipmentMethodEnumId",
      filters: shipMethodFilters,
      filterIcon: () => {
        return filteredInfo.shippingShipmentMethodEnumId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.shippingShipmentMethodEnumId === "number") {
            return (
              a.shippingShipmentMethodEnumId - b.shippingShipmentMethodEnumId
            );
          } else {
            return a.shippingShipmentMethodEnumId?.localeCompare(
              b.shippingShipmentMethodEnumId
            );
          }
        },
        multiple: 1,
      },
      filteredValue: filteredInfo.shippingShipmentMethodEnumId || null,
      onFilter: (value: any, record: any) => {
        let isShipmentEnumIdMatched = false;
        record?.items &&
          record?.items?.length > 0 &&
          record?.items?.[0]?.shipmentSources &&
          record?.items?.[0]?.shipmentSources?.length > 0 &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0 &&
          record?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (
                item.shippingShipmentMethodEnumId &&
                item.shippingShipmentMethodEnumId.includes(value)
              ) {
                isShipmentEnumIdMatched = true;
              }
            }
          );
        // record?.parts &&
        //   record?.parts.length > 0 &&
        //   record?.parts.forEach((item: any) => {
        //     if (
        //       item.shipmentMethodEnumId &&
        //       item.shipmentMethodEnumId.includes(value)
        //     ) {
        //       isShipmentEnumIdMatched = true;
        //     }
        //   });
        return isShipmentEnumIdMatched;
      },
      sortOrder:
        sortedInfo.shippingShipmentMethodEnumId ===
          "shippingShipmentMethodEnumId" &&
        sortedInfo.shippingShipmentMethodEnumIdType,
      render: (shipMethod: string, order: any) => {
        let shipmentMethodEnumId = "";
        if (
          order?.items &&
          order?.items?.length > 0 &&
          order?.items?.[0]?.shipmentSources &&
          order?.items?.[0]?.shipmentSources?.length > 0 &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments &&
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.length > 0
        ) {
          order?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.forEach(
            (item: any) => {
              if (item.shippingShipmentMethodEnumId) {
                shipmentMethodEnumId = item.shippingShipmentMethodEnumId;
              }
            }
          );
        }
        // if (order.parts && order.parts.length > 0) {
        //   order.parts.forEach((item: any) => {
        //     if (item.shipmentMethodEnumId) {
        //       shipmentMethodEnumId = item.shipmentMethodEnumId;
        //     }
        //   });
        // }
        return (
          <div>
            {shipMethodList &&
              shipMethodList.map((item: any) => {
                if (item.key === shipmentMethodEnumId) {
                  return <h1 className="px-2">{item.value}</h1>;
                }
                return null;
              })}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Tracking Code</p>
        </div>
      ),
      dataIndex: "items",
      render: (items: any) => {
        let packageRouteSegments: any =
          items?.[0]?.shipmentSources?.[0]?.packageRouteSegments;
        let trackingCode: any = "";
        packageRouteSegments &&
          packageRouteSegments?.forEach((item: any, index: any) => {
            trackingCode += item?.shipmentTrackingCode;
            if (packageRouteSegments.length - 1 !== index) {
              trackingCode += ",\n";
            }
          });
        return trackingCode;
      },
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let appliedFilters = "";
    if (filters.orderId) {
      if (!appliedFilters) {
        appliedFilters += `orderId=${filters.orderId}`;
      } else {
        appliedFilters += `&orderId=${filters.orderId}`;
      }
    }
    if (filters.displayId) {
      if (!appliedFilters) {
        appliedFilters += `displayId=${filters.displayId}`;
      } else {
        appliedFilters += `&displayId=${filters.displayId}`;
      }
    }
    // if (filters.shippingShipmentMethodEnumId) {
    //   if (!appliedFilters) {
    //     appliedFilters += `shippingShipmentMethodEnumId=${filters.shippingShipmentMethodEnumId}`;
    //   } else {
    //     appliedFilters += `&shippingShipmentMethodEnumId=${filters.shippingShipmentMethodEnumId}`;
    //   }
    // }
    if (filters["parts.customerName"]) {
      if (!appliedFilters) {
        appliedFilters += `customerName=${filters["parts.customerName"]}`;
      } else {
        appliedFilters += `&customerName=${filters["parts.customerName"]}`;
      }
    }
    if (filters.statusId) {
      if (!appliedFilters) {
        if (filters?.statusId.length > 0) {
          const joinedStr = filters?.statusId.join(" OR ");
          appliedFilters += `queryString=statusId: ${joinedStr}`;
        }
      } else {
        if (filters?.statusId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters?.statusId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND statusId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=statusId: ${joinedStr}`;
          }
        }
      }
    }
    if (filters.salesChannelEnumId) {
      if (!appliedFilters) {
        if (filters?.salesChannelEnumId.length > 0) {
          const joinedStr = filters?.salesChannelEnumId.join(" OR ");
          appliedFilters += `queryString=salesChannelEnumId: ${joinedStr}`;
        }
      } else {
        if (filters?.salesChannelEnumId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters?.salesChannelEnumId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND salesChannelEnumId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=salesChannelEnumId: ${joinedStr}`;
          }
        }
      }
    }
    if (filters.shippingShipmentMethodEnumId) {
      if (!appliedFilters) {
        if (filters.shippingShipmentMethodEnumId.length > 0) {
          const joinedStr = filters.shippingShipmentMethodEnumId.join(" OR ");
          appliedFilters += `queryString=items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
        }
      } else {
        if (filters.shippingShipmentMethodEnumId.length > 0) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          const joinedStr = filters.shippingShipmentMethodEnumId.join(" OR ");
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=items.shipmentSources.routeSegments.shippingShipmentMethodEnumId: ${joinedStr}`;
          }
        }
      }
    }
    setFilteredInfo(filters);
    setActiveAppliedFilters(appliedFilters);
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      setSortedInfo({
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      });
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              if (sorter["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (
                sorter["field"] === "shippingShipmentActualStartDate"
              ) {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else {
                outputStr += `,${sorter["field"]}`;
              }
            }
          } else {
            if (sorter["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (sorter["field"] === "shippingShipmentActualStartDate") {
              outputStr += `items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else {
              outputStr = `${sorter["field"]}`;
            }
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              if (sorter["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (
                sorter["field"] === "shippingShipmentActualStartDate"
              ) {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else {
                outputStr += `,-${sorter["field"]}`;
              }
            }
          } else {
            if (sorter["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (sorter["field"] === "shippingShipmentActualStartDate") {
              outputStr += `-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else {
              outputStr = `-${sorter["field"]}`;
            }
          }
        }
      });
    }
    if (Array.isArray(sorter)) {
      console.log("inside the 884 condition ", sorter);
      let modifiedObj = {};
      sorter.forEach((item: any) => {
        modifiedObj = {
          ...modifiedObj,
          [item?.field]: item?.field,
          [`${item?.field}Type`]: item?.order,
        };
      });
      console.log("modifiedObj------->", modifiedObj);
      setSortedInfo(modifiedObj);
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              if (item["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (item["field"] === "shippingShipmentActualStartDate") {
                outputStr += `,items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else {
                outputStr += `,${item.field}`;
              }
            }
          } else {
            if (item["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (item["field"] === "shippingShipmentActualStartDate") {
              outputStr += `items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else {
              outputStr = `${item.field}`;
            }
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              if (item["field"] === "shippingShipmentMethodEnumId") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
              } else if (item["field"] === "shippingShipmentActualStartDate") {
                outputStr += `,-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
              } else {
                outputStr += `,-${item.field}`;
              }
            }
          } else {
            if (item["field"] === "shippingShipmentMethodEnumId") {
              outputStr = `-items.shipmentSources.routeSegments.shippingShipmentMethodEnumId`;
            } else if (item["field"] === "shippingShipmentActualStartDate") {
              outputStr += `-items.shipmentSources.routeSegments.shippingShipmentActualStartDate`;
            } else {
              outputStr = `-${item.field}`;
            }
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });

    let prevOrderListingValue = {
      orderByFields: outputStr,
      appliedFilters,
      sortedInfo: {
        [sorter?.field]: sorter?.field,
        [`${sorter?.field}Type`]: sorter?.order,
      },
      filteredInfo: filters,
    };
    localStorage.setItem(
      "prevAppliedFilter",
      JSON.stringify(prevOrderListingValue)
    );
    if (appliedFilters) {
      nextSetOfProds(0, outputStr, appliedFilters, true);
    } else if (recordCount) {
      nextSetOfProds(0, outputStr, "", true);
    }
  }

  const getDownloadData = () => {
    return getBulkOrderListingDetail.length > 0
      ? getBulkOrderListingDetail.map((data: any) => {
          let shipmentMethodEnumId: any =
            data?.items?.[0]?.shipmentSources?.[0]?.routeSegments?.find(
              (item: any) => item
            );
          let Status: any =
            productStatusData &&
            productStatusData.find((item: any) =>
              item?.key === data?.statusId ? item : ""
            );
          let shipMethod: any =
            shipMethodList &&
            shipMethodList?.find((item: any) =>
              item?.key === shipmentMethodEnumId?.shippingShipmentMethodEnumId
                ? item
                : ""
            );
          let packageRouteSegments: any =
            data?.items?.[0]?.shipmentSources?.[0]?.packageRouteSegments;
          let trackingCode: any = packageRouteSegments
            ? packageRouteSegments?.map(
                (item: any) => item?.shipmentTrackingCode
              )
            : [];
          return {
            order: data?.orderId,
            "External Order ID": data?.displayId,
            "Sales Channel": data?.salesChannelEnumId,
            "Date Placed": data?.placedDate
              ? new Date(data?.placedDate).toDateString()
              : "",
            Customer: data?.parts?.length > 0 && data?.parts[0]?.customerName,
            Status: Status?.value?.toUpperCase(),
            Total: data?.grandTotal ? `$${data?.grandTotal}` : 0,
            "Ship Method": shipMethod?.value,
            "Tracking Code": trackingCode?.join(),
          };
        })
      : [];
  };

  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="search-prod w-full ltmob:w-4/6 mdlarge:w-3/5 mdxlarge:w-2/5 mb-8">
          <span className="search-icon">
            <SearchOutlined style={{ fontSize: 17 }} />
          </span>
          <input
            placeholder="Search Orders"
            defaultValue={searchedQuery}
            value={searchedQuery}
            className="border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light"
            onKeyPress={async (e: any) => {
              if (e.key === "Enter") {
                setIsLoading(true);
                setIsSearch("Searched");
                await dispatch(
                  getOrderListing({
                    setgetOrderListingDetail,
                    setGetBulkOrderListingDetail,
                    setMaxPageIndex,
                    setRecordCount,
                    pageIndex: 0,
                    queryString: e.target.value,
                    placedDate_from: selectedFromDate
                      ? selectedFromDate
                      : fromDate
                      ? fromDate
                      : "",
                    placedDate_thru: selectedThruDate
                      ? selectedThruDate
                      : thruDate
                      ? thruDate
                      : "",
                    statusId,
                    facilityId: facilityId ? facilityId : "",
                    onlyShipped,
                    appliedFilters: activeAppliedFilters,
                    isReturnedOrders: isReturnedOrders ? true : false,
                  })
                );
                setSearchedQuery("");
                setSearchableString(e.target.value);
                setIsLoading(false);
              }
            }}
            onChange={async (e: any) => {
              setSearchedQuery(e.target.value);
            }}
          />
        </div>
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <div className="flex flex-row justify-between">
            <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              <div className="flex flex-row items-center">
                <button
                  onClick={async () => {
                    setResetInputField(true);
                    setIsLoading(true);
                    await dispatch(
                      getOrderListing({
                        setgetOrderListingDetail,
                        setGetBulkOrderListingDetail,
                        setMaxPageIndex,
                        orderByFields: sortData,
                        setRecordCount,
                        pageIndex: 0,
                        // searchString: orderStatus,
                        placedDate_from: fromDate ? fromDate : "",
                        placedDate_thru: thruDate ? thruDate : "",
                        statusId,
                        queryString,
                        facilityId: facilityId ? facilityId : "",
                        onlyShipped,
                        appliedFilters: "",
                        isReturnedOrders: isReturnedOrders ? true : false,
                      })
                    );
                    localStorage.removeItem("orderListingFromDate");
                    localStorage.removeItem("orderListingThruDate");
                    localStorage.removeItem("prevAppliedFilter");
                    setActiveAppliedFilters("");
                    setSelectedFromDate("");
                    setSelectedThruDate("");
                    setDatePickerFrom("");
                    setDatePickerThru("");
                    setFilteredInfo({});
                    setIsLoading(false);
                  }}
                  className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Filter
                </button>
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    await dispatch(
                      getOrderListing({
                        setgetOrderListingDetail,
                        setGetBulkOrderListingDetail,
                        setMaxPageIndex,
                        orderByFields: "",
                        setRecordCount,
                        pageIndex: currIndex * 1 - 1,
                        // searchString: orderStatus,
                        placedDate_from: selectedFromDate
                          ? selectedFromDate
                          : fromDate
                          ? fromDate
                          : "",
                        placedDate_thru: selectedThruDate
                          ? selectedThruDate
                          : thruDate
                          ? thruDate
                          : "",
                        statusId,
                        queryString,
                        facilityId: facilityId ? facilityId : "",
                        onlyShipped,
                        appliedFilters: activeAppliedFilters,
                        isReturnedOrders: isReturnedOrders ? true : false,
                      })
                    );
                    localStorage.removeItem("prevAppliedFilter");
                    setSortedInfo({});
                    setSortData("");
                    setIsLoading(false);
                  }}
                  className="ml-4 mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                >
                  Clear Sorter
                </button>
              </div>
            </div>
            <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
              <div className="flex flex-row items-center">
                <CSVLink
                  filename={"simpleOrder.csv"}
                  data={getDownloadData()}
                  className="cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <RiDownloadLine
                      style={{ fontSize: "16px" }}
                      className="text-primary"
                    />
                    <p className="ml-1 text-xs font-bold text-primary">CSV</p>
                  </div>
                </CSVLink>
              </div>
            </div>
          </div>

          <Table
            rowKey="shipMethod"
            dataSource={getOrderListingDetail}
            columns={columns}
            isServerRendered={recordCount ? true : false}
            isPaginated={true}
            loadNextProds={loadNextProds}
            recordCount={recordCount}
            onChange={onChange}
            resetInputField={resetInputField}
          />
        </div>
      </Loader>
    </div>
  );
};

export default OrderListing;
