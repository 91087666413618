import React, { useEffect, ReactElement, useState } from "react";
import { Form, Tooltip } from "antd";
import { useDispatch } from "react-redux";

import {
  getProductGlobalLowStock,
  updateGlobalLowStock,
} from "../redux/authSlice";
import Button from "./Button";
import Input from "./Input";
import Loader from "./Loader";

function ConfigureDetail(): ReactElement {
  const [mapForm] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [globalLowStock, setGlobalLowStock] = useState<any>([]);

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line
  }, []);

  const fetchUserInfo = async () => {
    setIsLoading(true);
    await dispatch(
      getProductGlobalLowStock({
        partyId: localStorage.getItem("organizationPartyId"),
        setGlobalLowStock,
      })
    );
    setIsLoading(false);
  };
  if (globalLowStock) {
    mapForm.setFieldsValue({ lowStock: globalLowStock?.[0]?.settingValue });
  }
  return (
    <div className="px-5 pt-6 pb-1 mr-9 mb-9 w-full sm:w-96 rounded-2xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <h1 className="font-bold text-base mb-7">Global Low Stock Threshold</h1>
        <div className="w-11/12 mx-auto ">
          <Form
            form={mapForm}
            name="account_details"
            className="flex flex-row justify-between"
            onFinish={async (values: any) => {
              setIsLoading(true);
              await dispatch(
                updateGlobalLowStock({
                  partyId: localStorage.getItem("organizationPartyId"),
                  partySettingTypeId: "InventoryThreshold",
                  settingValue: values?.lowStock,
                })
              );
              fetchUserInfo();
              setIsLoading(false);
            }}
          >
            <div className="w-1/2">
              <Tooltip
                placement="bottom"
                title="Low stock threshold used for all the products to check if inventory is low."
              >
                <Form.Item
                  name="lowStock"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                    {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <Input title="" placeholder="" />
                </Form.Item>
              </Tooltip>
            </div>
            <div className="w-1/3 mt-2">
              <div className="flex justify-end">
                <Button widthFull buttonText="Update" />
              </div>
            </div>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default ConfigureDetail;
