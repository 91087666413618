/* eslint-disable */
import React, { useEffect, ReactElement } from "react";
import { useHistory } from "react-router-dom";

import AccountDetails from "../components/AccountDetails";
import SettingsPassword from "../components/SettingPassword";
import Sidebar from "../components/Sidebar";
import UserAccounts from "../components/UserAccounts";
import StoreDetails from "../components/StoreDetails";
import ConfigureDetail from "../components/ConfigureDetail";

function ConfigurationScreen(): ReactElement {
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("currentPath", "/configuration");
    const isAuth = localStorage.getItem("loggedInToken");
    if (!isAuth) {
      history.push("/");
    }
    return () => {
      // clean up function
    };
  }, [history]);
  const currentScreen = "Configuration";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const accountType = localStorage.getItem("accountType");
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            Fulfillment Plus Settings
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              {currentScreen}
            </h1>
          </div>
        </div>
        <div>
          <ConfigureDetail />
        </div>
      </div>
    </Sidebar>
  );
}

export default ConfigurationScreen;
