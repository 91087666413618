/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCart3, BsCartX } from "react-icons/bs";
import { MdOutlineLocalShipping } from "react-icons/md";
import { AiOutlinePause } from "react-icons/ai";
import { TbTruckReturn } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";
import Button from "./Button";
import moment from "moment";

import Loader from "./Loader";
import GridTile from "./GridTile";
import { getDashboardReports } from "../redux/authSlice";

import OutsideClickHandler from "react-outside-click-handler";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const FILTER_BUTTONS = [
  "Last Month",
  "Last 30 Days",
  "Last 90 Days",
  "All Time",
];

function DasboardMain() {
  const [dashboardDetails, setDashboardDetails] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardFromDate, setDashboardFromDate] = useState<any>(
    localStorage.getItem("dashboardFromDate") ?? ""
  );
  const [dashboardThruDate, setDashbordThruDate] = useState<any>(
    localStorage.getItem("dashboardThruDate") ?? ""
  );
  const [activeFilter, setActiveFilter] = useState(
    localStorage.getItem("activeFilter") || ""
  );

  const [isDateRangePickerVisible, setDateRangePickerVisible] = useState(false);
  const [selectedDateRange, setSelectedDateRange] =
    useState<any>("Select Time Range");
  const [date, setDate] = useState<any>(
    !activeFilter
      ? [
          {
            startDate: dashboardFromDate
              ? new Date(Date.parse(dashboardFromDate))
              : new Date(),
            endDate: dashboardThruDate
              ? new Date(Date.parse(dashboardThruDate))
              : new Date(),
            key: "selection",
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]
  );

  let todayDate: any = new Date();
  let dd = String(todayDate.getDate()).padStart(2, "0");
  let mm = String(todayDate.getMonth() + 1).padStart(2, "0");
  let yyyy = todayDate.getFullYear();
  todayDate = yyyy + "-" + mm + "-" + dd;
  const startingDate = yyyy + "-" + mm + "-01";
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let dashboardFromDate;
      let dashboardThruDate;
      if (
        localStorage.getItem("dashboardFromDate") &&
        localStorage.getItem("dashboardThruDate")
      ) {
        const fromDate: any = localStorage
          .getItem("dashboardFromDate")
          ?.split("T");
        const thruDate: any = localStorage
          .getItem("dashboardThruDate")
          ?.split("T");
        dashboardFromDate = fromDate[0];
        dashboardThruDate = thruDate[0];
        if (!activeFilter) {
          setSelectedDateRange(`${dashboardFromDate} - ${dashboardThruDate}`);
        }
      } else {
        const currDate = new Date();
        dashboardFromDate = `${currDate.getFullYear()}-${
          currDate.getMonth() + 1 < 10
            ? `0${currDate.getMonth() + 1}`
            : currDate.getMonth() + 1
        }-01`;
        dashboardThruDate = `${currDate.getFullYear()}-${
          currDate.getMonth() + 1 < 10
            ? `0${currDate.getMonth() + 1}`
            : currDate.getMonth() + 1
        }-${
          currDate.getDate() < 10
            ? `0${currDate.getDate()}`
            : currDate.getDate()
        }`;
        setSelectedDateRange(`${dashboardFromDate} - ${dashboardThruDate}`);
      }
      await dispatch(
        getDashboardReports({
          setDashboardDetails,
          monthStartDate: dashboardFromDate,
          monthEndDate: dashboardThruDate,
        })
      );
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, history]); //eslint-disable-line

  async function handleDateRangeButtonClick(DateRange: any) {
    const currentDateFormatted = moment().format("YYYY-MM-DD");
    const thirtyDaysAgoFormatted = moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    const nintyDaysAgoFormatted = moment()
      .subtract(90, "days")
      .format("YYYY-MM-DD");
    const firstDateOfLastMonthFormatted = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const lastDateOfLastMonthFormatted = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    const AllTimeFormatted = moment(0).format("YYYY-MM-DD");
    let dashboardFromDate;
    let dashboardThruDate;
    if (DateRange === "Last 30 Days") {
      dashboardFromDate = thirtyDaysAgoFormatted;
      dashboardThruDate = currentDateFormatted;
    } else if (DateRange === "Last Month") {
      dashboardFromDate = firstDateOfLastMonthFormatted;
      dashboardThruDate = lastDateOfLastMonthFormatted;
    } else if (DateRange === "Last 90 Days") {
      dashboardFromDate = nintyDaysAgoFormatted;
      dashboardThruDate = currentDateFormatted;
    } else {
      dashboardFromDate = AllTimeFormatted;
      dashboardThruDate = currentDateFormatted;
    }
    setActiveFilter(DateRange);
    setSelectedDateRange("Select Time Range");
    localStorage.setItem("dashboardFromDate", dashboardFromDate);
    localStorage.setItem("dashboardThruDate", dashboardThruDate);
    setDashboardFromDate(dashboardFromDate);
    setDashbordThruDate(dashboardThruDate);
    localStorage.setItem("activeFilter", DateRange);
    setDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setIsLoading(true);
    await dispatch(
      getDashboardReports({
        setDashboardDetails,
        monthStartDate: dashboardFromDate,
        monthEndDate: dashboardThruDate,
      })
    );
    setIsLoading(false);
  }

  return (
    <>
      <div className="mb-5">
        <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">Timeframe</h1>
        <div className="flex">
          <div
            className="flex flex-row items-center justify-between border bg-white mb-2 border-gray-300 rounded-md py-2 px-3 cursor-pointer w-60"
            onClick={() => setDateRangePickerVisible((prev) => !prev)}>
            <h1 className="text-gray-500 text-center ">{selectedDateRange}</h1>
            <IoMdArrowDropdown size={20} />
          </div>
          <div className="ml-3 flex gap-2">
            {FILTER_BUTTONS?.map((btn: any, index: any) => (
              <div key={index} className="inActiveButton">
                <Button
                  defaultColor=""
                  classNames={`${
                    activeFilter === btn
                      ? "activeButton"
                      : "bg-white inActiveButton"
                  }`}
                  buttonText={btn}
                  onClick={() => handleDateRangeButtonClick(btn)}
                />
              </div>
            ))}
          </div>
        </div>
        {isDateRangePickerVisible && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setDateRangePickerVisible(false);
            }}>
            <DateRangePicker
              rangeColors={["#093479"]}
              inputRanges={[]}
              className="z-10 absolute l-0 t-0 shadow-custom-1"
              onChange={async (item) => {
                setIsLoading(true);
                const startDateItem = item.selection.startDate?.toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );
                let fullDate: any = startDateItem?.split(",")[0];
                fullDate = fullDate?.split("/");
                const endDateItem = item.selection.endDate?.toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );

                let fullEndDate: any = endDateItem?.split(",")[0];
                fullEndDate = fullEndDate?.split("/");

                const fromDate = `${fullDate[2]}-${fullDate[0]}-${fullDate[1]}T00:00:00`;
                const thruDate = `${fullEndDate[2]}-${fullEndDate[0]}-${fullEndDate[1]}T23:59:59T23:59:59`;
                const dashboardFromDate = `${fullDate[2]}-${fullDate[0]}-${fullDate[1]}`;
                const dashboardThruDate = `${fullEndDate[2]}-${fullEndDate[0]}-${fullEndDate[1]}`;
                await setDate([item.selection]);
                if (item.selection.endDate !== item.selection.startDate) {
                  setSelectedDateRange(
                    `${dashboardFromDate} - ${dashboardThruDate}`
                  );
                  setActiveFilter("");
                  setDateRangePickerVisible(false);
                  localStorage.removeItem("activeFilter");
                  await dispatch(
                    getDashboardReports({
                      setDashboardDetails,
                      monthStartDate: dashboardFromDate,
                      monthEndDate: dashboardThruDate,
                    })
                  );
                }
                localStorage.setItem("dashboardFromDate", dashboardFromDate);
                localStorage.setItem("dashboardThruDate", dashboardThruDate);
                setIsLoading(false);
                setDashboardFromDate(dashboardFromDate);
                setDashbordThruDate(dashboardThruDate);
              }}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={date}
              staticRanges={[]}
              direction="horizontal"
            />
          </OutsideClickHandler>
        )}
      </div>
      {isLoading ? (
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}>
          <div className="w-96 h-screen"></div>
        </Loader>
      ) : (
        <div>
          <div className="grid grid-rows-5 md:grid-rows-3 lg:grid-rows-1 grid-flow-col gap-5 xl:gap-7 mb-8">
            {dashboardDetails.totalOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate
                      ? dashboardFromDate
                      : startingDate
                      ? startingDate
                      : localStorage.getItem("dashboardFromDate")
                  }&thruDate=${
                    dashboardThruDate
                      ? dashboardThruDate
                      : todayDate
                      ? todayDate
                      : localStorage.getItem("dashboardThruDate")
                  }`,
                }}
                onClick={() => {
                  localStorage.setItem("currentSubScreen", "orderListing");
                  localStorage.setItem("fromDashboardScreen", "true");
                }}>
                <GridTile
                  value={
                    dashboardDetails.totalOrders
                      ? dashboardDetails.totalOrders
                      : "0"
                  }
                  iconComponent={
                    <BsCart3
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Total Orders"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalOrders
                    ? dashboardDetails.totalOrders
                    : "0"
                }
                iconComponent={
                  <BsCart3
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Total Orders"
              />
            )}
            {dashboardDetails.totalShippedOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderCompleted`,
                }}>
                <GridTile
                  value={
                    dashboardDetails.totalShippedOrders
                      ? dashboardDetails.totalShippedOrders
                      : "0"
                  }
                  iconComponent={
                    <MdOutlineLocalShipping
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Orders Shipped"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalShippedOrders
                    ? dashboardDetails.totalShippedOrders
                    : "0"
                }
                iconComponent={
                  <MdOutlineLocalShipping
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Orders Shipped"
              />
            )}

            {dashboardDetails.totalHoldOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderHold`,
                }}>
                <GridTile
                  iconComponent={
                    <AiOutlinePause
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  value={
                    dashboardDetails.totalHoldOrders
                      ? dashboardDetails.totalHoldOrders
                      : "0"
                  }
                  title="Orders On-Hold"
                />
              </Link>
            ) : (
              <GridTile
                iconComponent={
                  <AiOutlinePause
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                value={
                  dashboardDetails.totalHoldOrders
                    ? dashboardDetails.totalHoldOrders
                    : "0"
                }
                title="Orders On-Hold"
              />
            )}
            {dashboardDetails.totalReturnOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&isReturnedOrders=true`,
                }}>
                <GridTile
                  value={
                    dashboardDetails.totalReturnOrders
                      ? dashboardDetails.totalReturnOrders
                      : "0"
                  }
                  iconComponent={
                    <TbTruckReturn
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Orders Returned"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalReturnOrders
                    ? dashboardDetails.totalReturnOrders
                    : "0"
                }
                iconComponent={
                  <TbTruckReturn
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Orders Returned"
              />
            )}

            {dashboardDetails.totalCancelOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderCancelled`,
                }}>
                <GridTile
                  iconComponent={
                    <BsCartX
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />

                    // <CancelledOrders className="rounded-xl shadow-lg mb-5" />
                  }
                  value={
                    dashboardDetails.totalCancelOrders
                      ? dashboardDetails.totalCancelOrders
                      : "0"
                  }
                  title="Orders Cancelled"
                />
              </Link>
            ) : (
              <GridTile
                iconComponent={
                  <BsCartX
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                value={
                  dashboardDetails.totalCancelOrders
                    ? dashboardDetails.totalCancelOrders
                    : "0"
                }
                title="Orders Cancelled"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DasboardMain;
