import React, { ReactElement } from "react";

function Button({
  onClick,
  buttonText,
  isDisabled,
  widthFull,
  defaultColor,
  paddingX,
  classNames,
  style,
}: {
  onClick?: any;
  buttonText: string;
  isDisabled?: any;
  widthFull?: boolean;
  defaultColor?: any;
  paddingX?: any;
  classNames?: any;
  style?: any;
}): ReactElement {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      style={style}
      className={
        isDisabled
          ? `${
              widthFull && "w-full"
            } px-8 text-xs sm:${paddingX} sm:text-sm bg-transparent focus:outline-none border border-gray-400 text-gray-400 font-semibold py-2 rounded-lg ${classNames}`
          : `${
              widthFull && "w-full"
            } px-8 text-xs sm:${paddingX} sm:text-sm ${defaultColor} hover:shadow-custom-btn focus:outline-none text-white font-semibold py-2 rounded-lg ${classNames}`
      }
    >
      {buttonText}
    </button>
  );
}

Button.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  defaultColor: "bg-primary",
  widthFull: false,
  paddingX: "px-12",
};

export default Button;
